import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { vendors, destinationWeddingVenues, venueTypes } from './vendors';
import HamBurgerMenu from './HamburgerMenu';
import axios from 'axios';
import { API_HOST } from '../api/url';

const NavItems = styled.div`
  display: flex;
  @media (max-width: 480px) {
    display: ${(props) => (props.isMenuOpen ? 'flex' : 'none')};
    position: absolute;
    top: 88px;
    left: 0;
    right: 0;
    background-color: white;
    flex-direction: column;
    z-index: 1;
    padding-bottom: 12px;
    width: 320px;
    border-bottom: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
  }
`;

const StyledItem = styled.p`
  color: #4d4f50;
  font-weight: 400;
  font-size: 16px;
  margin-right: 20px;
  cursor: pointer;
  font-family: 'Hanken Grotesk', sans-serif;

  :hover {
    color: #13362d;
    font-weight: 700;
    + .dropdown-menu {
      display: block;
    }
    + .dropdown-vendors-menu {
      display: block;
    }
  }
  @media (max-width: 1129px) {
  margin-right: 17px;
}
@media (max-width: 986px) {
  margin-right: 10px;
}
@media (max-width: 768px) {
  margin-right: 7px;
}
  @media (max-width: 480px) {
    display: none;
  }
`;

const DropdownMenu = styled.div`
  display: none;
  z-index: -1;
  position: absolute;
  top: 50px;
  width: 350px;
  height: 500px;
  border-radius: 0px 0px 16px 16px;
  background: #fff;
  padding: 32px;
  z-index: 1;
  :hover {
    display: block;
  }
  overflow: hidden;
  z-index: 0;
`;

const DropDownText = styled.p`
  color: #1e1e1e;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 16px 0 0 0;
`;

const DropDownTags = styled.p`
  color: #353535;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 16px 0px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
    text-decoration-style: dotted;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDestinationimage = styled.img`
  margin: 20px 28px 10px 0px;
  display: block;
  border-radius: 5px;
  width: 161px;
  height: 112px;
`;

const ImageText = styled.p`
  margin: 0;
  color: #ededed;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: 8px;
  bottom: 8px;
`;

const ImageDiv = styled.div`
  position: relative;
  cursor: pointer;
`;

const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #000 100%);
  width: 161px;
  border-radius: 5px;
`;

const VendorDropDownMenu = styled(DropdownMenu)`
  height: 480px;
  width: 86%;
  left: 50%;
  transform: translateX(-50%);
  padding: 32px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 5);
  border-right: 1px solid #c9c0b6;
  padding: 0 16px;
  &:last-child {
    border-right: none;
  }
`;

export const VendorItemWraper = styled.div`
  display: flex;
  align-items: center;
`;

const VendorItems = styled(DropDownText)`
  margin: 0;
`;

export const StyledTagImage = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 4px;
`;

const VendorTags = styled(DropDownTags)`
  margin: 8px 0px;
  color: #353535;
  :hover {
    text-decoration: underline;
    text-decoration-style: dotted;
  }
`;

const SectionDiv = styled.div`
  margin-bottom: 20px;
`;

const NavBarItems = ({
  isMenuOpen,
  onVendorTagClick,
  clickedVendorTag,
  setIsMenuOpen,
}) => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);


  const fetchProfile = async () => {
    try {
      const response = await axios.get(`${API_HOST}/api/v1/profile`);
      setProfileData(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  useEffect(() => {
    if (clickedVendorTag === null) {
      onVendorTagClick('Wedding Venues');
    }
  }, [clickedVendorTag, onVendorTagClick]);

  return (
    <div>
      <NavItems  isMenuOpen={isMenuOpen}>
        <StyledItem
          onClick={() => {
            onVendorTagClick('Wedding Venues');
            navigate('/venues', {
              state: { category: 'VENUES', categoryItem: { value: 'VENUES' } },
            });
          }}
        >
          Venues
        </StyledItem>
        <DropdownMenu className='dropdown-menu'>
          <DropDownText>By Type</DropDownText>
          <Flex>
            <div>
              {venueTypes.slice(0, 4).map((type) => (
                <DropDownTags
                  key={type.value}
                  onClick={() => {
                    navigate('/venues', {
                      state: {
                        category: 'VENUES',
                        categoryItem: {
                          value: type.value,
                        },
                      },
                    });
                    onVendorTagClick(
                      type.name === 'View All' ? 'Wedding Venues' : type.name
                    );
                  }}
                >
                  {type.name}
                </DropDownTags>
              ))}
            </div>
            <div>
              {venueTypes.slice(4).map((type) => (
                <DropDownTags
                  key={type.value}
                  onClick={() => {
                    navigate('/venues', {
                      state: {
                        category: 'VENUES',
                        categoryItem: { value: type.value },
                      },
                    });
                    onVendorTagClick(
                      type.name === 'View All' ? 'Wedding Venues' : type.name
                    );
                  }}
                >
                  {type.name}
                </DropDownTags>
              ))}
            </div>
          </Flex>
          <div style={{ marginTop: '20px' }}>
            <DropDownText>Destination Wedding Venues</DropDownText>
            <Flex>
              <div>
                {destinationWeddingVenues.slice(0, 2).map((venue) => (
                  <ImageDiv
                    key={venue.name}
                    onClick={() => {
                      navigate('/venues', {
                        state: {
                          category: 'VENUES',
                          categoryItem: {
                            category: 'Wedding Venues',
                            value: venue.value,
                          },
                          city: venue.name,
                        },
                      });
                    }}
                  >
                    <ImageText>{venue.name}</ImageText>
                    <StyledDestinationimage
                      src={venue.image}
                      alt={venue.name}
                    />
                    <GradientOverlay />
                  </ImageDiv>
                ))}
              </div>
              <div>
                {destinationWeddingVenues.slice(2).map((venue) => (
                  <ImageDiv
                    key={venue.name}
                    onClick={() => {
                      navigate('/venues', {
                        state: {
                          category: 'VENUES',
                          categoryItem: {
                            category: 'Wedding Venues',
                            value: venue.value,
                          },
                          city: venue.name,
                        },
                      });
                    }}
                  >
                    <ImageText>{venue.name}</ImageText>
                    <StyledDestinationimage
                      src={venue.image}
                      alt={venue.name}
                    />
                    <GradientOverlay />
                  </ImageDiv>
                ))}
              </div>
            </Flex>
          </div>
        </DropdownMenu>
        <StyledItem
          onClick={() => {
            navigate('/vendors/categories');
          }}
        >
          Vendors
        </StyledItem>
        <VendorDropDownMenu className='dropdown-vendors-menu'>
          <div style={{ marginTop: '20px', display: 'flex' }}>
            {vendors.map((column) => (
              <Section key={column[0].category}>
                {column.map((vendor) => (
                  <SectionDiv key={vendor.category}>
                    <VendorItemWraper>
                      <StyledTagImage
                        src={vendor.image}
                        alt={vendor.category}
                      />
                      <VendorItems>{vendor.category}</VendorItems>
                    </VendorItemWraper>
                    {vendor.tags.map((tag) => (
                      <VendorTags
                        key={tag.subcategoryText}
                        text={tag}
                        onClick={() => {
                          window.open(
                            `/vendor/${tag.vendorSubcategory}`
                          );
                          // navigate(`/vendor/${tag.vendorSubcategory}`)  for same page naviagetion
                          // onVendorTagClick(
                          //   tag === 'View All'
                          //     ? vendor.category
                          //     : tag.subcategoryText
                          // );
                        }}
                      >
                        {tag.subcategoryText}
                      </VendorTags>
                    ))}
                  </SectionDiv>
                ))}
              </Section>
            ))}
          </div>
        </VendorDropDownMenu>
        <StyledItem onClick={()=>window.open("https://blog.maangalbazaar.com","_blank")}>Blog</StyledItem>
        <StyledItem onClick={()=>navigate("/magazine")}>Magazine</StyledItem>
        {isMenuOpen && (
          <HamBurgerMenu
            onVendorTagClick={onVendorTagClick}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            profileData={profileData}
          />
        )}
      </NavItems>
    </div>
  );
};

export default NavBarItems;
