import { createSlice } from "@reduxjs/toolkit";

export const historySlice = createSlice({
    initialState: "/",
    name: "history",
    reducers: {
        updateHistory: (state, action) => {
            return action.payload.toString()
        }
    }
})