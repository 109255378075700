import { Skeleton, Stack } from '@mui/material';
import React from 'react';

const CardSkeleton = () => {
  return (
    <div>
      <Stack
        spacing={1}
        sx={{ border: '1px solid  #EDEDED', borderRadius: '16px' }}
      >
        <Skeleton
          animation='wave'
          variant='rounded'
          width='100%'
          height='200px'
          sx={{ borderRadius: '16px 16px 0 0 ' }}
        />
        <Skeleton animation='wave' variant='text' sx={{ fontSize: '1rem' }} />
        <Skeleton
          animation='wave'
          variant='rounded'
          width='100%'
          height={60}
          sx={{ borderRadius: '0 0 16px 16px ' }}
        />
      </Stack>
    </div>
  );
};

export default CardSkeleton;
