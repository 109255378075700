import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Contact from "./Contact";
import PortFolio from "./PortFolio";
import {
  alcohol,
  catering,
  decor,
  dj,
  nonveg,
  parking,
  room,
  space,
  veg,
  venue,
  wheelchair,
  hotel,
  refund, jain, basic_room, advance_booking, decoration, datetime, gathering
} from "../../../assets/product-details";
import axios from "axios";
import { API_HOST } from "../../api/url";
import VendorMetaData from "./VendorMetaData";
import VendorContact from "./VendorContact";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { locationTypes } from "../wedding-venues/Card";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 0px;
  width:88vw;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const AboutSectionWrapper = styled.div`
 flex:0.58;
`;
const SidePriceWrapper = styled.div`
  display: block;
  /* flex: isVenue ? 2.5 : 3; */
  @media (min-width: 480px) {
    display: none;
  }
`;

const AvailabilityAndPrice = styled.div`
  flex: 2.5;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  margin-left: 40px;
  padding: 24px;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    margin: ${(props) =>
    props.isAuthenticated === "AUTHENTICATED" ? "16px 0px" : "16px -24px"};
    width: ${(props) =>
    props.isAuthenticated === "AUTHENTICATED" ? "" : "100%"};
    ${(props) =>
    props.isAuthenticated === "AUTHENTICATED"
      ? `
    margin-left: 0;
    `
      : `margin-left: -24px`}
  }
`;

const StyledHeading = styled.h2`
  margin: 0;
  color: #000;
  font-size: 21px;
  font-weight: 600;
  text-transform: capitalize;
  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const StyledDescription = styled.pre`
box-sizing: border-box;
max-width:100%;
width:100%;
  color: #585e73;
  font-size: 15px;
  font-weight: 300;
  line-height: 26px;
  /* border:2px solid red; */
  padding:3px 0px 0px 3px;
  white-space: pre-wrap;
  display:block;
  @media (max-width: 480px) {
   display: none;
  }
`;

const StyledDescriptionForMobile = styled.pre`
box-sizing: border-box;
max-width:100%;
width:100%;
  color: #585e73;
  font-size: 12px;
  font-weight: 300;
  line-height: 26px;
  padding:3px 0px 0px 3px;
  white-space: pre-wrap;
  /* border:2px solid red; */
  display: none;
  transition: all 2s ease-in-out;
  @media (max-width: 480px) {
    display: block;
  }
`;

const SeeMoreButton = styled.button`
border: none;
/* background-color: #13362d; */
color: #13362d;
cursor: pointer;
font-weight: bold;
font-family: monospace;
font-size: 12px;
background-color: transparent;




`

const VenueDetails = styled.div`
   display: grid;
   grid-gap: 10px;
   grid-template-columns: repeat(3, 1fr);
   @media (max-width:880px) {
    grid-template-columns: repeat(2, 1fr);
   }
   @media (min-width: 481px) and (max-width: 585px) {
    grid-template-columns: repeat(1, 1fr);
   }
 
`;

export const DetailsHead = styled.p`
  margin: 2px;
  color: #858585;
  font-size: 13px;
  font-family: Outfit;
  font-weight: 300;
  line-height: 22px;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const DetailsText = styled.p`
  margin: 2px;
  color: #353535;
  font-size: 15px;
  font-family: Outfit;
  font-weight: 600;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const MobileScreenVenueDetails = styled.div`
  display: none;
  @media (max-width: 480px) {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
  }
`;

const TabSection = styled.div`
  border: 1px solid #f0f3f8;
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 36px;
`;

const Tab = styled.p`
  margin: 0;
  flex: 1;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &.selectedTab {
    border-bottom: 3px solid #13362d;
  }
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  background: #e2e6e5;
  padding: 4px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    width: 14px;
    height: 14px;
  }
`;

export const Details = styled.div`
  margin-bottom: 20px;
`;

const AreasAvailable = styled.div`
  margin-left: 40px;
  padding: 22px 24px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  @media (max-width: 480px) {
    margin: 20px 0px 0px -24px;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

// const Areas = styled.div`
//   margin-top: 18px;
// `;

const StyledIcon = styled.img`
  @media (max-width: 480px) {
    width: 10px;
    height: 10px;
  }
    width:  20px;
    height: 20px;
`;

const AreasWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

const Areas = styled.div`
  margin-top: 18px;
  width: calc(50% - 10px);
`;

const StyledLink = styled.a`
  &:hover {
    svg {
      color: ${(props) => props.hoverColor} !important;
    }
  }
`;

const LinkSection = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const sx = { fontSize: "40px", color: "rgb(53, 53, 53);" };

export function selectMetadata(metadataList, metaKey, defaultValue) {
  if (!Array.isArray(metadataList)) {
    metadataList = [];
  }
  const filteredMetadata = metadataList.filter((eachObject) => {
    return eachObject.propertyName === metaKey;
  });

  if (filteredMetadata.length !== 1) {
    return defaultValue;
  } else {
    return filteredMetadata[0].propertyValue;
  }
}

const AboutSection = ({ data, isVenue }) => {
  const [descriptionLength, setDescriptionLength] = useState(225)
  const [selectedTabPortFolio, setSelectedTabPortFolio] = useState(0);
  const tabs = ["About", "Portfolio", "Reviews"];
  const [selectedTab, setSelectedTab] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const metaData = data.venueMetadata;
  const metadataArr = isVenue ? data.venueMetadata : data.vendorMetadata;

  const metadataPrefix = isVenue ? "VENUE" : "VENDOR";
  const facebookLink = selectMetadata(
    metadataArr,
    `${metadataPrefix}_SOCIAL_FB_LINK`,
    ""
  );
  const websiteLink = selectMetadata(
    metadataArr,
    `${metadataPrefix}_SOCIAL_WS_LINK`,
    ""
  );
  const instagramLink = selectMetadata(
    metadataArr,
    `${metadataPrefix}_SOCIAL_IG_LINK`,
    ""
  );
  const youtubeLink = selectMetadata(
    metadataArr,
    `${metadataPrefix}_SOCIAL_YT_LINK`,
    ""
  );

  const hasAnySocialLink = [
    facebookLink,
    instagramLink,
    websiteLink,
    youtubeLink,
  ].some((linkStr) => linkStr !== "");

  const checkAuthentication = async () => {
    try {
      const response = await axios.post(`${API_HOST}/api/v1/auth/refresh`);
      setIsAuthenticated(response.data.authStatus);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabClick = (index) => {
    setSelectedTab(index);
    let element;
    switch (index) {
      case 0:
        element = document.getElementById("about-section");
        break;
      case 1:
        element = document.getElementById("portfolio-albums-videos-section")
        setSelectedTabPortFolio(0)
        break;
      case 2:
        element = document.getElementById("reviews-section");
        break;
      default:
        console.log("Invalid index:", index);
        break;
    }
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: 'center' });
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  return (
    <SectionWrapper >
      <AboutSectionWrapper id="about-section">
        <TabSection >
          {tabs.map((item, index) => {
            return (
              <Tab

                key={index}
                className={selectedTab === index ? "selectedTab" : ""}
                onClick={() => {
                  // if(index==3)
                  // setSelectedTabPortFolio(0)
                  setSelectedTab(index);
                  handleTabClick(index);
                }}
              >
                {item}
              </Tab>
            );
          })}
        </TabSection>
        <StyledHeading>
          {data?.venueLocationTypes
            ?.reduce((acc, item) => {
              const locationType = locationTypes.find(
                (type) => type.value === item
              );
              return locationType ? [...acc, locationType.text] : acc;
            }, [])
            .join(", ")}
          {` at`} {data.venueName || data.vendorName}
        </StyledHeading>
        <StyledDescription >
          {data.venueDescription || data.vendorDescription}
        </StyledDescription>
        <StyledDescriptionForMobile>
          {data.venueDescription?data.venueDescription.slice(0, descriptionLength) + (data.venueDescription.length > 224 ? "..." : ""):null}
          {data.venueDescription?data.venueDescription.length > 224 && (
            <SeeMoreButton onClick={() => {
              if (descriptionLength == 225)
                setDescriptionLength(1100)
              else
                setDescriptionLength(225)
            }}>{descriptionLength == 225 ? "see more" : "show less"}</SeeMoreButton>
          ):null}
          {data.vendorDescription?data.vendorDescription.slice(0, descriptionLength) + (data.vendorDescription.length > 224 ? "..." : ""):null}
          {data.vendorDescription?data.vendorDescription.length > 224 && (
            <SeeMoreButton onClick={() => {
              if (descriptionLength == 225)
                setDescriptionLength(1100)
              else
                setDescriptionLength(225)
            }}>{descriptionLength == 225 ? "see more" : "show less"}</SeeMoreButton>
          ):null}
        </StyledDescriptionForMobile>

        {isVenue ? (
          <VenueDetails >
            {
              metaData && metaData.length > 0
                && metaData.find(
                  (property) =>
                    property.propertyName === "VENUE_ROOM_COUNT"
                ) ? <Details>
                <IconWrapper>
                  <StyledIcon src={room} alt="room" />
                </IconWrapper>
                <DetailsHead>Room Count</DetailsHead>
                <DetailsText>
                  {metaData.find(
                    (property) =>
                      property.propertyName === "VENUE_ROOM_COUNT"
                  )?.propertyValue
                  }
                </DetailsText>
              </Details> : null
            }
            {
              metaData && metaData.length > 0
                && metaData.find(
                  (property) =>
                    property.propertyName === "VENUE_BASE_ROOM_COST" && property.propertyValue != 0
                ) ? <Details>
                <IconWrapper>
                  <StyledIcon src={basic_room} alt="MB" />
                </IconWrapper>
                <DetailsHead>Basic Room Price</DetailsHead>
                <DetailsText> ₹
                  {metaData.find(
                    (property) =>
                      property.propertyName ===
                      "VENUE_BASE_ROOM_COST"
                  ).propertyValue
                  }
                </DetailsText>
              </Details> : null
            }


            {
              metaData && metaData.length > 0
                && metaData.find(
                  (property) =>
                    property.propertyName === "VENUE_BOOKING_PAYMENT_PERCENTAGE" && property.propertyValue >= 0
                ) ? <Details>
                <IconWrapper>
                  <StyledIcon src={advance_booking} alt="MB" />
                </IconWrapper>
                <DetailsHead>Advance Booking Payment</DetailsHead>
                <DetailsText>
                  {metaData.find(
                    (property) =>
                      property.propertyName ===
                      "VENUE_BOOKING_PAYMENT_PERCENTAGE"
                  ).propertyValue
                  }%
                </DetailsText>
              </Details> : null
            }
            {
              metaData && metaData.length > 0
                && metaData.find(
                  (property) =>
                    property.propertyName === "VENUE_DATE_ADJUSTMENT"
                ) ? <Details>
                <IconWrapper>
                  <StyledIcon src={datetime} alt="MB" />
                </IconWrapper>
                <DetailsHead>Adjust Dates</DetailsHead>
                <DetailsText>
                  {metaData.find(
                    (property) =>
                      property.propertyName ===
                      "VENUE_DATE_ADJUSTMENT"
                  ).propertyValue ? "Yes" : "No"
                  }
                </DetailsText>
              </Details> : null
            }
            {/* {
              data.venueMaxCapacity && data.venueMaxCapacity > 0 ? <Details>
                <IconWrapper>
                  <StyledIcon src={venue} alt="venue" />
                </IconWrapper>
                <DetailsHead>Small Party </DetailsHead>
                <DetailsText>
                  Less than ${data.venueMaxCapacity} Pax
                </DetailsText>
              </Details> : null

            } */}
            {
              metaData && metaData.length > 0
                && metaData.find(
                  (property) =>
                    property.propertyName === "VENUE_PRIMARY_TYPE"
                ) && locationTypes.find(
                  (type) =>
                    type.value ===
                    metaData.find(
                      (property) =>
                        property.propertyName === "VENUE_PRIMARY_TYPE"
                    ).propertyValue
                ) ? <Details>
                <IconWrapper>
                  <StyledIcon src={hotel} alt="hotel" />
                </IconWrapper>
                <DetailsHead>Primary Venue Type</DetailsHead>
                <DetailsText style={{ textTransform: "capitalize" }}>
                  {locationTypes.find(
                    (type) =>
                      type.value ===
                      metaData.find(
                        (property) =>
                          property.propertyName === "VENUE_PRIMARY_TYPE"
                      ).propertyValue
                  )?.text}
                </DetailsText>
              </Details>
                : null
            }
            {
              data?.venueEnvironment?.length > 0 ? <Details>
                <IconWrapper>
                  <StyledIcon src={space} alt="space" />
                </IconWrapper>
                <DetailsHead>Space</DetailsHead>
                <DetailsText>
                  {data.venueEnvironment
                    .map((item, index) => {
                      return (
                        <span
                          style={{ textTransform: "capitalize" }}
                          key={index}
                        >
                          {item.toLowerCase()}
                        </span>
                      );
                    })
                    ?.reduce((prev, curr) => [prev, ", ", curr])
                  }
                </DetailsText>
              </Details> : null
            }
            {
              metaData && metaData.length > 0
                && metaData.find(
                  (property) =>
                    property.propertyName === "VENUE_SMALL_SIZE_GATHERINGS" && typeof property.propertyValue == "boolean"
                ) ? <Details>
                <IconWrapper>
                  <StyledIcon src={gathering} alt="MB" />
                </IconWrapper>
                <DetailsHead>Small Gatherings</DetailsHead>
                <DetailsText>
                  {metaData.find(
                    (property) =>
                      property.propertyName ===
                      "VENUE_SMALL_SIZE_GATHERINGS"
                  ).propertyValue
                    ? "Allowed"
                    : "Not Allowed"
                  }
                </DetailsText>
              </Details> : null
            }
            {
              metaData && metaData.length > 0
                && metaData.find(
                  (property) =>
                    property.propertyName === "VENUE_DECOR_INTERNAL" && typeof property.propertyValue == "boolean"
                ) ? <Details>
                <IconWrapper>
                  <StyledIcon src={decor} alt="decor" />
                </IconWrapper>
                <DetailsHead>Decor Policy</DetailsHead>
                <DetailsText>
                  {metaData.find(
                    (property) =>
                      property.propertyName === "VENUE_DECOR_INTERNAL"
                  ).propertyValue
                    ? "Decorators from panel"
                    : "Outside Decorators"
                  }
                </DetailsText>
              </Details> : null
            }
            {
              metaData && metaData.length > 0
                && metaData.find(
                  (property) =>
                    property.propertyName === "VENUE_DECOR_COST" && property.propertyValue >= 0
                ) ? <Details>
                <IconWrapper>
                  <StyledIcon src={decoration} alt="MB" />
                </IconWrapper>
                <DetailsHead>Decoration Price</DetailsHead>
                <DetailsText>₹
                  {metaData.find(
                    (property) =>
                      property.propertyName ===
                      "VENUE_DECOR_COST"
                  ).propertyValue
                  }
                </DetailsText>
              </Details> : null
            }
            {
              metaData && metaData.length > 0
                && metaData.find(
                  (property) =>
                    property.propertyName === "VENUE_CATERING_INTERNAL" && typeof property.propertyValue == "boolean"
                ) ? <Details>
                <IconWrapper>
                  <StyledIcon src={catering} alt="catering" />
                </IconWrapper>
                <DetailsHead>Catering Policy</DetailsHead>
                <DetailsText>
                  {metaData.find(
                    (property) =>
                      property.propertyName ===
                      "VENUE_CATERING_INTERNAL"
                  ).propertyValue
                    ? "Internal Only"
                    : "External Allowed"
                  }
                </DetailsText>
              </Details> : null
            }
            {
              data.venuePricingRate && data.venuePricingRate.length > 0 && data.venuePricingRate[2] >= 0 ? <Details >
                <IconWrapper>
                  <StyledIcon src={jain} alt="jain" />
                </IconWrapper>
                <DetailsHead>Jain Plate Starting Price</DetailsHead>
                <DetailsText>
                  ₹{data.venuePricingRate[2]} + Taxes
                </DetailsText>
              </Details> : null
            }

            {
              data.venuePricingRate && data.venuePricingRate.length > 0 && data.venuePricingRate[0] >= 0 ? <Details>
                <IconWrapper>
                  <StyledIcon src={veg} alt="veg" />
                </IconWrapper>
                <DetailsHead>Veg Plate Starting Price</DetailsHead>
                <DetailsText>
                  ₹{data.venuePricingRate[0]} + Taxes
                </DetailsText>
              </Details> : null
            }
            {
              data.venuePricingRate && data.venuePricingRate.length > 0 && data.venuePricingRate[1] >= 0 ? <Details>
                <IconWrapper>
                  <StyledIcon src={nonveg} alt="nonveg" />
                </IconWrapper>
                <DetailsHead>Non-Veg Plate Starting Price</DetailsHead>
                <DetailsText>
                  ₹{data.venuePricingRate[1]} + Taxes
                </DetailsText>
              </Details> : null
            }

            {
              metaData && metaData.length > 0
                && metaData.find(
                  (property) =>
                    property.propertyName === "VENUE_DJ_INTERNAL" && typeof property.propertyValue == "boolean"
                ) ? <Details>
                <IconWrapper>
                  <StyledIcon src={dj} alt="dj" />
                </IconWrapper>
                <DetailsHead>DJ Policy</DetailsHead>
                <DetailsText>
                  <DetailsText>
                    {metaData.find(
                      (property) =>
                        property.propertyName === "VENUE_DJ_INTERNAL"
                    ).propertyValue
                    ? "Internal Only"
                    : "External Allowed"
                    }
                  </DetailsText>
                </DetailsText>
              </Details> : null
            }


            {
              metaData && metaData.length > 0
                && metaData.find(
                  (property) =>
                    property.propertyName === "VENUE_ALCOHOL_INTERNAL" && typeof property.propertyValue == "boolean") ?
                <Details>
                  <IconWrapper>
                    <StyledIcon src={alcohol} alt="alcohol" />
                  </IconWrapper>
                  <DetailsHead>Alcohol Policy</DetailsHead>
                  <DetailsText>
                    {metaData.find(
                      (property) =>
                        property.propertyName === "VENUE_ALCOHOL_INTERNAL"
                    ).propertyValue
                    ? "Internal Only"
                    : "External Allowed"
                    }
                  </DetailsText>
                </Details> : null

            }
            {
              metaData && metaData.length > 0
                && metaData.find(
                  (property) =>
                    property.propertyName ===
                    "VENUE_WHEELCHAIR_ACCESSIBLE" && typeof property.propertyValue == "boolean"
                ) ? <Details>
                <IconWrapper>
                  <StyledIcon src={wheelchair} alt="wheelchair" />
                </IconWrapper>
                <DetailsHead>Wheelchair policy</DetailsHead>
                <DetailsText>
                  {metaData.find(
                    (property) =>
                      property.propertyName ===
                      "VENUE_WHEELCHAIR_ACCESSIBLE"
                  ).propertyValue
                    ? "Available"
                    : "Not available"

                  }
                </DetailsText>
              </Details> : null
            }
            {
              isVenue && data.venueMetadata
                && data.venueMetadata.find(
                  (item) =>
                    item.propertyName === "VENUE_PARKING_AVAILABLE"
                ) ? <Details>
                <IconWrapper>
                  <StyledIcon src={parking} alt="parking" />
                </IconWrapper>
                <DetailsHead>Parking</DetailsHead>
                <DetailsText>
                  {data.venueMetadata.find(
                    (item) =>
                      item.propertyName === "VENUE_PARKING_AVAILABLE"
                  ).propertyValue
                    ? "Available"
                    : "Not Available"
                  }
                </DetailsText>
              </Details> : null
            }

            {
              metaData && metaData.length > 0
                && metaData.find(
                  (property) =>
                    property.propertyName === "VENUE_REFUND_OFFERED" && typeof property.propertyValue == "boolean"
                ) ? <Details>
                <IconWrapper>
                  <StyledIcon src={refund} alt="refund" />
                </IconWrapper>
                <DetailsHead>Refund Policy</DetailsHead>
                <DetailsText>
                  {metaData.find(
                    (property) =>
                      property.propertyName === "VENUE_REFUND_OFFERED"
                  ).propertyValue
                    ? "Refund available"
                    : "No refund"
                  }
                </DetailsText>
              </Details> : null
            }



          </VenueDetails>
        ) : (
          <VendorMetaData data={data} />
        )}


        <PortFolio selectedTab={selectedTabPortFolio} setSelectedTab={setSelectedTabPortFolio} data={data} isVenue={isVenue} />



      </AboutSectionWrapper>
      <SidePriceWrapper>
        <AvailabilityAndPrice
          isAuthenticated={isAuthenticated}
          style={{
            height: isVenue
              ? isAuthenticated === "AUTHENTICATED"
                ? "376px"
                : "160px"
              : "",
          }}
        >
          {isVenue ? (
            <Contact
              isAuthenticated={isAuthenticated}
              isVenue={isVenue}
              data={data}
            />
          ) : (
            <VendorContact
              isAuthenticated={isAuthenticated}
              isVenue={isVenue}
              data={data}
            />
          )}
        </AvailabilityAndPrice>
        {isVenue && data?.venueLocations?.length > 0 && (
          <AreasAvailable>
            <StyledHeading>
              Areas Available ({data?.venueLocations?.length})
            </StyledHeading>
            <Wrapper>
              <AreasWrapper>
                {data?.venueLocations?.map((item) => {
                  return (
                    <Areas>
                      <DetailsHead>
                        {item.locationCapacitySeated} Seating |{" "}
                        {item.locationCapacityFloating} Floating
                      </DetailsHead>
                      <DetailsText>
                        {item.locationName} (
                        {
                          <span style={{ textTransform: "capitalize" }}>
                            {item.locationEnvironment.toLowerCase()}
                          </span>
                        }
                        )
                      </DetailsText>
                    </Areas>
                  );
                })}
              </AreasWrapper>
            </Wrapper>
          </AreasAvailable>
        )}
        {hasAnySocialLink ? (
          <AreasAvailable style={{ marginTop: "20px" }}>
            <StyledHeading>Explore</StyledHeading>
            <LinkSection>
              {websiteLink !== "" ? (
                <StyledLink
                  target="_blank"
                  rel="noreferrer"
                  href={websiteLink}
                  hoverColor="#4285F4"
                >
                  <InsertLinkIcon sx={sx} />
                </StyledLink>
              ) : null}
              {facebookLink !== "" ? (
                <StyledLink
                  target="_blank"
                  rel="noreferrer"
                  href={facebookLink}
                  hoverColor="#4267B2"
                >
                  <FacebookIcon sx={sx} />
                </StyledLink>
              ) : null}
              {instagramLink !== "" ? (
                <StyledLink
                  target="_blank"
                  rel="noreferrer"
                  href={instagramLink}
                  hoverColor="#C13584"
                >
                  <InstagramIcon sx={sx} />
                </StyledLink>
              ) : null}
              {youtubeLink !== "" ? (
                <StyledLink
                  target="_blank"
                  rel="noreferrer"
                  href={youtubeLink}
                  hoverColor="#FF0000"
                >
                  <YouTubeIcon sx={sx} />
                </StyledLink>
              ) : null}
            </LinkSection>
          </AreasAvailable>
        ) : null}
      </SidePriceWrapper>
      {/* <div style={{boxSizing:"border-box",width:"100%",padding:"3px"}}>
      {<PortFolio  id="portfolioalbumsvideossection" data={data} isVenue={isVenue} />}
    
      </div> */}
    </SectionWrapper>
  );
};

export default AboutSection;
