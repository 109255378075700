import {
  bridal,
  camera,
  cosmetics,
  decor,
  food,
  gifts,
  honeymoon,
  jewellery,
  mehendi,
  music,
  pandits,
  shutter,
  spa,
  suit,
  gridal_women
} from "../../assets/dropdown/index";
import botique from "../../assets/botique.svg";
import clinic from "../../assets/clinic.svg";
import furniture from "../../assets/furnitue.svg";
import pandit from "../../assets/pandit.png";
import dumbbell from "../../assets/dumbbell_563828.png"

export const hamburgerMenuItems = [
  {
    category: "Photography",
    image: camera,
    tags: [
      {
        subcategoryText: "Wedding Photographers",
        vendorSubcategory: "WEDDING_PHOTOGRAPHERS",
      },
      {
        subcategoryText: "Pre Wedding Shoot",
        vendorSubcategory: "PRE_WEDDING_SHOOT",
      },
    ],
    value: "PHOTOGRAPHY",
  },
  {
    category: "Makeup",
    image: cosmetics,
    tags: [
      {
        subcategoryText: "Freelance Makeup Artist",
        vendorSubcategory: "FREELANCE_MAKEUP_ARTIST",
      },
      {
        subcategoryText: "Beauty Salon",
        vendorSubcategory: "BEAUTY_SALON",
      },
    ],
    value: "MAKEUP",
  },
  {
    category: "Planning & Decor",
    image: decor,
    tags: [
      {
        subcategoryText: "Wedding Planners",
        vendorSubcategory: "WEDDING_PLANNERS",
      },
      {
        subcategoryText: "Wedding decor",
        vendorSubcategory: "WEDDING_DECOR",
      },
      { subcategoryText: "Florists", vendorSubcategory: "FLORISTS" },
    ],
    value: "PLANNING_AND_DECOR",
  },
  {
    category: "Bridal Wear",
    image: bridal,
    tags: [
      {
        subcategoryText: "Bridal Lehengas",
        vendorSubcategory: "BRIDAL_LEHENGAS",
      },
      {
        subcategoryText: "Light Lehenga",
        vendorSubcategory: "LIGHT_LEHENGA",
      },
      { subcategoryText: "Sarees", vendorSubcategory: "SAREES" },
      {
        subcategoryText: "Cocktail Gowns",
        vendorSubcategory: "COCKTAIL_GOWNS",
      },
      {
        subcategoryText: "Designer Suits",
        vendorSubcategory: "DESIGNER_SUITS",
      },
      {
        subcategoryText: "Bridal Lehenga On Rent",
        vendorSubcategory: "BRIDAL_LEHENGA_ON_RENT",
      },
      // { subcategoryText: 'Lingerie', vendorSubcategory: 'LINGERIE' },
    ],
    value: "BRIDAL_WEAR",
  },
  {
    category: "Bridal Grooming",
    image: gridal_women,
    tags: [
      { subcategoryText: "Beauty & Wellness", vendorSubcategory: "BEAUTY_AND_WELLNESS" }
    ],
    value: "BRIDAL_GROOMING",
  },

  {
    category: "Groom Wear",
    image: suit,
    tags: [
      { subcategoryText: "Sherwani", vendorSubcategory: "SHERWANI" },
      {
        subcategoryText: "Wedding Suits Or Tuxes",
        vendorSubcategory: "WEDDING_SUITS_OR_TUXES",
      },
      {
        subcategoryText: "Sherwani On Rent",
        vendorSubcategory: "SHERWANI_ON_RENT",
      },
      {
        subcategoryText: "Indo Western Outfits",
        vendorSubcategory: "INDO_WESTERN_OUTFITS",
      },
      {
        subcategoryText: "Bandhgalas",
        vendorSubcategory: "BANDHGALAS",
      },
      {
        subcategoryText: "Kurta Pyjama Sets",
        vendorSubcategory: "KURTA_PYJAMA_SETS",
      },
      {
        subcategoryText: "Waistcoats And Nehru Jackets",
        vendorSubcategory: "WAISTCOATS_AND_NEHRU_JACKETS",
      },
      {
        subcategoryText: "Groom Accessories",
        vendorSubcategory: "GROOM_ACCESSORIES",
      },
      {
        subcategoryText: "Saafa And Turban",
        vendorSubcategory: "SAAFA_AND_TURBAN",
      },
      
    ],
    value: "GROOM_WEAR",
  },
  {
    category: "Mehendi",
    image: mehendi,
    tags: [
      {
        subcategoryText: "Mehendi Artist",
        vendorSubcategory: "MEHENDI_ARTIST",
      },
    ],
    value: "MEHENDI",
  },
  {
    category: "Jewellery & Accessories",
    image: jewellery,
    tags: [
      { subcategoryText: "Jeweller", vendorSubcategory: "JEWELLER" },
      {
        subcategoryText: "Artificial Jewellery",
        vendorSubcategory: "ARTIFICIAL_JEWELLERY",
      },
      {
        subcategoryText: "Bridal Jewellery On Rent",
        vendorSubcategory: "BRIDAL_JEWELLERY_ON_RENT",
      },
      { subcategoryText: "Accessories", vendorSubcategory: "ACCESSORIES" },
    ],
    value: "JEWELLERY_AND_ACCESSORIES",
  },
  {
    category: "Invites & Gifts",
    image: gifts,
    tags: [
      { subcategoryText: "Invitations", vendorSubcategory: "INVITATIONS" },
      { subcategoryText: "E-Invites", vendorSubcategory: "E_INVITES" },
      {
        subcategoryText: "Trousseau Packers",
        vendorSubcategory: "TROUSSEAU_PACKERS",
      },
      {
        subcategoryText: "Invitation Gifts",
        vendorSubcategory: "INVITATION_GIFTS",
      },
    ],
    value: "INVITES_AND_GIFTS",
  },
  {
    category: "Furniture",
    image: furniture,
    tags: [
      { subcategoryText: "Furniture", vendorSubcategory: "FURNITURE" },
      {
        subcategoryText: "Modular kitchen",
        vendorSubcategory: "MODULAR_KITCHEN",
      },
      { subcategoryText: "Interiors", vendorSubcategory: "INTERIORS" },
    ],
    value: "FURNITURE",
  },
  {
    category: "Music & Dance",
    image: music,
    tags: [
      { subcategoryText: "DJs", vendorSubcategory: "DJS" },
      { subcategoryText: "Band", vendorSubcategory: "BAND" },
      {
        subcategoryText: "Sangeet Choreographer",
        vendorSubcategory: "SANGEET_CHOREOGRAPHER",
      },
      {
        subcategoryText: "Wedding Entertainment",
        vendorSubcategory: "WEDDING_ENTERTAINMENT",
      },
      {
        subcategoryText: "Dhol & damau",
        vendorSubcategory: "DHOL_AND_DAMAU",
      },
      { subcategoryText: "Maangal geet", vendorSubcategory: "MAANGAL_GEET" },
    ],
    value: "MUSIC_AND_DANCE",
  },

  {
    category: "Food",
    image: food,
    tags: [
      {
        subcategoryText: "Catering Services",
        vendorSubcategory: "CATERING_SERVICES",
      },
      { subcategoryText: "Cake", vendorSubcategory: "CAKE" },
      { subcategoryText: "Bartenders", vendorSubcategory: "BARTENDERS" },
      {
        subcategoryText: "Home Catering",
        vendorSubcategory: "HOME_CATERING",
      },
      { subcategoryText: "Mithai ", vendorSubcategory: "MITHAI" },
      {
        subcategoryText: "Chaat And Food Stalls",
        vendorSubcategory: "CHAAT_AND_FOOD_STALLS",
      },
    ],
    value: "FOOD",
  },
  {
    category: "Clinics",
    image: clinic,
    tags: [
      {
        subcategoryText: "Dermatology clinics",
        vendorSubcategory: "DERMATOLOGY_CLINICS",
      },
      {
        subcategoryText: "Ayurvedic Clinics",
        vendorSubcategory: "AYURVEDIC_CLINICS",
      },
    ],
    value: "CLINICS",
  },
  {
    category: "Boutiques",
    image: botique,
    tags: [{ subcategoryText: "Boutiques", vendorSubcategory: "BOUTIQUES" }],
    value: "BOUTIQUES",
  },
  {
    category: "Honeymoon",
    image: honeymoon,
    tags: [
      {
        subcategoryText: "Honeymoon Packages",
        vendorSubcategory: "HONEYMOON_PACKAGES",
      },
      {
        subcategoryText: "Travel Agents",
        vendorSubcategory: "TRAVEL_AGENTS",
      },
    ],
    value: "HONEYMOON",
  },
  {
    category: "Astrology",
    image: pandit,
    tags: [
      { subcategoryText: "Astrology", vendorSubcategory: "ASTROLOGY" },
    ],
    value: "ASTROLOGY",
  },
  {
    category: "Fitness Center",
    image: dumbbell,
    tags: [
      { subcategoryText: "Gym", vendorSubcategory: "GYM" },
      { subcategoryText: "Yoga Center", vendorSubcategory: "YOGA_CENTER" },
      { subcategoryText: "Zumba Classes", vendorSubcategory: "ZUMBA_CLASSES" },
    ],
    value: "FITNESS_CENTER",
  },
];
