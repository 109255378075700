import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Contact from "./Contact";
import PortFolio from "./PortFolio";
import {
  alcohol,
  catering,
  decor,
  dj,
  nonveg,
  parking,
  room,
  space,
  veg,
  venue,
  wheelchair,
  hotel,
  refund,
} from "../../../assets/product-details";
import axios from "axios";
import { API_HOST } from "../../api/url";
import VendorMetaData from "./VendorMetaData";
import VendorContact from "./VendorContact";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { locationTypes } from "../wedding-venues/Card";

const SectionWrapper = styled.div`
  display: flex;
  width: 100%;
  /* justify-content: end; */
  flex-direction: row;
  margin: 16px 0px;

  /* position: sticky; */
  /* top: 0px; */
  /* right: 0px; */
  /* z-index: 100; */
  @media (max-width: 480px) {
    flex-direction: column;
    display: none;
  }
`;

const AboutSectionWrapper = styled.div`
  flex: 0.5;
`;

const AvailabilityAndPrice = styled.div`
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  margin-left: 40px;
  padding: 24px;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    margin: ${(props) =>
      props.isAuthenticated === "AUTHENTICATED" ? "16px 0px" : "16px -24px"};
    width: ${(props) =>
      props.isAuthenticated === "AUTHENTICATED" ? "" : "100%"};
    ${(props) =>
      props.isAuthenticated === "AUTHENTICATED"
        ? `
    margin-left: 0;
    `
        : `margin-left: -24px`}
  }
`;

const StyledHeading = styled.h2`
  margin: 0;
  color: #000;
  font-size: 21px;
  font-weight: 600;
  text-transform: capitalize;
  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const StyledDescription = styled.p`
  color: #585e73;
  font-size: 15px;
  font-weight: 300;
  line-height: 26px;
  padding-right: 28px;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const VenueDetails = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  flex-wrap: wrap;
  @media (max-width: 480px) {
    display: none;
  }
`;

export const DetailsHead = styled.p`
  margin: 2px;
  color: #858585;
  font-size: 13px;
  font-family: Outfit;
  font-weight: 300;
  line-height: 22px;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const DetailsText = styled.p`
  margin: 2px;
  color: #353535;
  font-size: 15px;
  font-family: Outfit;
  font-weight: 600;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const MobileScreenVenueDetails = styled.div`
  display: none;
  @media (max-width: 480px) {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
  }
`;

const TabSection = styled.div`
  border: 1px solid #f0f3f8;
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 36px;
`;

const Tab = styled.p`
  margin: 0;
  flex: 1;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &.selectedTab {
    border-bottom: 3px solid #13362d;
  }
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  background: #e2e6e5;
  padding: 4px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    width: 14px;
    height: 14px;
  }
`;

export const Details = styled.div`
  margin-bottom: 20px;
`;

const AreasAvailable = styled.div`
  margin-left: 40px;
  padding: 22px 24px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  @media (max-width: 480px) {
    margin: 20px 0px 0px -24px;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

// const Areas = styled.div`
//   margin-top: 18px;
// `;

const StyledIcon = styled.img`
  @media (max-width: 480px) {
    width: 10px;
    height: 10px;
  }
`;

const AreasWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

const Areas = styled.div`
  margin-top: 18px;
  width: calc(50% - 10px);
`;

const StyledLink = styled.a`
  &:hover {
    svg {
      color: ${(props) => props.hoverColor} !important;
    }
  }
`;

const LinkSection = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const sx = { fontSize: "40px", color: "rgb(53, 53, 53);" };

export function selectMetadata(metadataList, metaKey, defaultValue) {
  if (!Array.isArray(metadataList)) {
    metadataList = [];
  }
  const filteredMetadata = metadataList.filter((eachObject) => {
    return eachObject.propertyName === metaKey;
  });

  if (filteredMetadata.length !== 1) {
    return defaultValue;
  } else {
    return filteredMetadata[0].propertyValue;
  }
}

const SideStickyComponent = ({ data, isVenue }) => {
  const tabs = ["About", "Portfolio", "Reviews"];
  const [selectedTab, setSelectedTab] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const metaData = data.venueMetadata;

  const metadataArr = isVenue ? data.venueMetadata : data.vendorMetadata;

  const metadataPrefix = isVenue ? "VENUE" : "VENDOR";
  const facebookLink = selectMetadata(
    metadataArr,
    `${metadataPrefix}_SOCIAL_FB_LINK`,
    ""
  );
  const websiteLink = selectMetadata(
    metadataArr,
    `${metadataPrefix}_SOCIAL_WS_LINK`,
    ""
  );
  const instagramLink = selectMetadata(
    metadataArr,
    `${metadataPrefix}_SOCIAL_IG_LINK`,
    ""
  );
  const youtubeLink = selectMetadata(
    metadataArr,
    `${metadataPrefix}_SOCIAL_YT_LINK`,
    ""
  );

  const hasAnySocialLink = [
    facebookLink,
    instagramLink,
    websiteLink,
    youtubeLink,
  ].some((linkStr) => linkStr !== "");

  const checkAuthentication = async () => {
    try {
      const response = await axios.post(`${API_HOST}/api/v1/auth/refresh`);
      setIsAuthenticated(response.data.authStatus);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabClick = (index) => {
    setSelectedTab(index);
    let element;
    switch (index) {
      case 0:
        element = document.getElementById("about-section");
        break;
      case 1:
        element = document.getElementById("projects-section");
        break;
      case 2:
        element = document.getElementById("reviews-section");
        break;
      default:
        break;
    }
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  return (
    <SectionWrapper >
      {/* <div style={{ flex: isVenue ? 2.5 : 3, width:"42%" }} > */}
      <div
        style={{
          position: "absolute",
          top: "25px",
          right: "0px",
          zIndex: "100",
          width: "42%",
        }}
      >
        <AvailabilityAndPrice
          isAuthenticated={isAuthenticated}
          style={{
            height: isVenue
              ? isAuthenticated === "AUTHENTICATED"
                ? "376px"
                : "160px"
              : "",
          }}
        >
          {isVenue ? (
            <Contact
              isAuthenticated={isAuthenticated}
              isVenue={isVenue}
              data={data}
            />
          ) : (
            <VendorContact
              isAuthenticated={isAuthenticated}
              isVenue={isVenue}
              data={data}
            />
          )}
        </AvailabilityAndPrice>
        {isVenue && data?.venueLocations?.length > 0 && (
          <AreasAvailable>
            <StyledHeading>
              Areas Available ({data?.venueLocations?.length})
            </StyledHeading>
            <Wrapper>
              <AreasWrapper>
                {data?.venueLocations?.map((item) => {
                  return (
                    <Areas>
                      <DetailsHead>
                        {item.locationCapacitySeated} Seating |{" "}
                        {item.locationCapacityFloating} Floating
                      </DetailsHead>
                      <DetailsText>
                        {item.locationName} (
                        {
                          <span style={{ textTransform: "capitalize" }}>
                            {item.locationEnvironment.toLowerCase()}
                          </span>
                        }
                        )
                      </DetailsText>
                    </Areas>
                  );
                })}
              </AreasWrapper>
            </Wrapper>
          </AreasAvailable>
        )}
        {hasAnySocialLink ? (
          <AreasAvailable style={{ marginTop: "20px" }}>
            <StyledHeading>Explore</StyledHeading>
            <LinkSection>
              {websiteLink !== "" ? (
                <StyledLink
                  target="_blank"
                  rel="noreferrer"
                  href={websiteLink}
                  hoverColor="#4285F4"
                >
                  <InsertLinkIcon sx={sx} />
                </StyledLink>
              ) : null}
              {facebookLink !== "" ? (
                <StyledLink
                  target="_blank"
                  rel="noreferrer"
                  href={facebookLink}
                  hoverColor="#4267B2"
                >
                  <FacebookIcon sx={sx} />
                </StyledLink>
              ) : null}
              {instagramLink !== "" ? (
                <StyledLink
                  target="_blank"
                  rel="noreferrer"
                  href={instagramLink}
                  hoverColor="#C13584"
                >
                  <InstagramIcon sx={sx} />
                </StyledLink>
              ) : null}
              {youtubeLink !== "" ? (
                <StyledLink
                  target="_blank"
                  rel="noreferrer"
                  href={youtubeLink}
                  hoverColor="#FF0000"
                >
                  <YouTubeIcon sx={sx} />
                </StyledLink>
              ) : null}
            </LinkSection>
          </AreasAvailable>
        ) : null}
      </div>
    </SectionWrapper>
  );
};

export default SideStickyComponent;
