import styled from "styled-components";
import moment from 'moment-timezone';
const CardDiv = styled.div`
position:relative;
background-color: #FCFEFF;
box-sizing: border-box;
width:430px;
border-radius: 15px;
box-shadow:0 -2px 5px 0 rgba(0, 0, 0, .13), 0 3px 5px 0 rgba(0, 0, 0, .13), 0 2px 2px 0 rgba(0, 0, 0, .11);
&:hover{
    box-shadow:0 -3px 5px 0 rgba(0, 0, 0, .13), 0 5px 8px 0 rgba(0, 0, 0, .13), 0 1px 5px 0 rgba(0, 0, 0, .11);
}
flex-shrink: 0;
padding: 20px 20px;
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
@media (max-width:448px) {
    width:100%;
}
`;

const CardImage = styled.img`
&::selection{
    background-color: transparent;
}
width: 100%;
height: 210px;
border-radius: 20px;
object-fit: cover;
object-position: 0 0;



`;

const CardHeading = styled.h1`
margin: 0;
padding: 5px;
text-align:left;
width:100%;
padding-left:10px;
box-sizing: border-box;
font-size: 22px;
font-weight: 700;
`;

const CardDescription = styled.p`
text-align:left;
width:100%;
padding-left:10px;
box-sizing: border-box;
font-size: 16px;
margin: 0;
/* font-weight: 400; */
/* background-color: white;
box-shadow: 0 5px 12px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11); */

`;

const CardButton = styled.button`
background-color:  #1a3f35;
color: white;
padding: 8px 20px;
font-size: 17px;
align-self: flex-end;
border: 0;
cursor: pointer;
&:active{
    transform: scale(0.97);
}
border-radius: 8px;
`;
const LastDiv=styled.div`
padding: 0;
margin: 0;
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
margin-top: 10px;
`;

const MostPopular = styled.div`
font-size: 20px;
  font-weight: bold;
  color: #fff;
  --r: .4em;
  --c: #FA6900;
  position: absolute;
  top: 15px;
  left: calc(-1*var(--r));
  line-height: 1.8; 
  padding: 0 .5em calc(2*var(--r));
  border-radius: var(--r) 0 0 var(--r);
  background: 
    radial-gradient(100% 50% at left,var(--c) 98%,#0000 101%)
     100% 0/.5lh calc(100% - 2*var(--r)),
    radial-gradient(100% 50% at right,#0005 98%,#0000 101%) 
     0 100%/var(--r) calc(2*var(--r)),
    conic-gradient(from 90deg at var(--r) calc(100% - 2*var(--r)),#0000 25%,var(--c) 0)
     0 0/calc(101% - .5lh) 100%;
  background-repeat: no-repeat;   
`;
export function BlogCard({ date, image, title, description, link, mostpopular }) {
    function exectDate(dateString) {
        return moment.utc(dateString).format('DD MMM YYYY');
    }
    return (
        <CardDiv>
            <CardImage src={image} alt="MB" />
            <CardHeading>{title}</CardHeading>
            <CardDescription>{description}</CardDescription>
            <hr />
            <LastDiv>
                <ul style={{paddingLeft:"20px",margin:"0px"}}><li>{exectDate(date)}</li></ul>
                <CardButton onClick={() => {
                    if (link) {
                        window.open(`https://blog.maangalbazaar.com/blog/${link}`, "_blank");
                    }
                }}>Read More</CardButton>
            </LastDiv>

            {mostpopular && <MostPopular>Most Popular</MostPopular>}

        </CardDiv>
    )
}