import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import CarouselImage1 from "../../../assets/CarouselImage1.svg";
import CarouselImage2 from "../../../assets/CarouselImage2.svg";
import CarouselImage3 from "../../../assets/CarouselImage3.svg";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Button } from "@mui/material";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Card from "./Card";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_HOST } from "../../api/url";

const Wrapper = styled.div`
/* border:2px solid red; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const StyledHeading = styled.h1`
  font-weight: 700;
  font-size: 32px;
  font-family: "Outfit", sans-serif;
  margin: 30px 0px;
  @media (max-width: 480px) {
    font-size: 25px;
    margin: 12px;
    text-align: center;
  }
`;

export const data = [
  CarouselImage2,
  CarouselImage1,
  CarouselImage3,
  CarouselImage2,
];

const Carousel = ({ onVendorTagClick, clickedVendorTag }) => {
  const [topVenues, setTopVenues] = useState([]);
  const navigate = useNavigate();
  const ref = useRef();

  const getTopVenues = async () => {
    try {
      const response = await axios.get(`${API_HOST}/api/v1/meta/top-venues`);
      setTopVenues(response.data.topVenues);
    } catch (error) {
      console.error(error);
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    }
  };

  useEffect(() => {
    getTopVenues();
  }, []);

  return (
    <Wrapper>
      <StyledHeading>Top Wedding Venues</StyledHeading>
      <div style={{ width: "100%", position: "relative" }}>
        <ResponsiveContainer
          carouselRef={ref}
          render={(parentWidth, carouselRef) => {
            let currentVisibleSlide = 5;
            if (parentWidth <= 1440) currentVisibleSlide = 3;
            if (parentWidth <= 1080) currentVisibleSlide = 1;
            return (
              <StackedCarousel
                ref={carouselRef}
                slideComponent={(props) => (
                  <Card
                    {...props}
                    venueData={topVenues[props.dataIndex]}
                    allVenueData={topVenues && topVenues}
                  />
                )}
                slideWidth={parentWidth < 800 ? parentWidth - 40 : 750}
                carouselWidth={parentWidth}
                data={topVenues.length > 0 ? topVenues : data}
                currentVisibleSlide={currentVisibleSlide}
                maxVisibleSlide={5}
                useGrabCursor
              />
            );
          }}
        />
      </div>
      <div style={{ marginTop: "28px" }}>
        <Button
          variant="contained"
          className="ArrowButtons"
          onClick={() => {
            ref.current?.goBack();
          }}
        >
          <ArrowBackIcon />
        </Button>
        <Button
          variant="contained"
          className="ArrowButtons"
          onClick={() => {
            ref.current?.goNext(6);
          }}
        >
          <ArrowForwardIcon />
        </Button>
      </div>
      <Button
        className="carouselButton"
        variant="contained"
        onClick={() => {
          onVendorTagClick('Wedding Venues');
          navigate('/venues', {
            state: { category: 'VENUES', categoryItem: { value: 'VENUES' } },
          });
          window.scrollTo(0, 0);
        }}
      >
        View All <ArrowRightAltIcon sx={{ marginLeft: "4px" }} />
      </Button>
    </Wrapper>
  );
};

export default Carousel;
