import React, { useState } from 'react';
import styled from 'styled-components';
import Bride1 from '../../../assets/Bride.svg';
import Moroso from '../../../assets/Moroso.svg';
import FavoriteIcon from '@mui/icons-material/Favorite';
import axios from 'axios';
import { API_HOST } from '../../api/url';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const StyledCard = styled.div`
  width: 276px;
  max-height: 390px;
  background: #ffffff;
  border: 0.921659px solid #ecece9;
  border-radius: 7.37327px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  :hover {
    box-shadow: 0px 14.7465px 22.1198px rgba(52, 52, 52, 0.12);
    transform: scale(1.1);
    .description {
      background-color: #faaa00;
      border-radius: 0px 0px 7.37327px 7.37327px;
      height: 100%;
      width: 100%;
    }
    .subTitle,
    .title {
      color: #fff;
    }
    :hover {
      .favorite-wrapper {
        width: 25.81px;
        height: 25.81px;
        box-shadow: 0px 0.921659px 3.68664px rgba(0, 0, 0, 0.11);
        border-radius: 92.1659px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        right: 16px;
        :hover {
          background: #f5eaea;
        }
      }
    }
  }

  @media (max-width: 480px) {
    min-width: 90%;
    margin: 0px 20px !important;
    /* min-height: 500px; */
    height: 424px;
    scale: 0.9;
  }
`;

const StyledImage = styled.img`
  width: 85%;
  /* height: auto; */
  object-fit: cover;
  height: 194px;
  @media (max-width: 480px) {
    /* width: 300px; */
    width: 80%;
    height: 200px;
  }
`;

const HeadSection = styled.div`
  display: flex;
  align-items: center;
  margin: 16px;
`;

const FavoriteWrapper = styled.div`
  display: none;
`;

const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  @media (max-width: 480px) {
    /* height: 100%;
    width: 100%; */
  }
`;

const Title = styled.p`
  margin-bottom: 0;
  margin-top: 12px;
  font-size: 20px;
  color: #0a1d3c;
  font-weight: 600;
  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const SubTitle = styled.p`
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #544d45;
  margin-bottom: 16px;
  margin-left: 12px;
  margin-right: 12px;
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const Logo = styled.img`
  @media (max-width: 480px) {
    height: 32px;
  }
`;

const CategoryCard = ({ index, data, category }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [isSaved, setIsSaved] = useState(data.venueSaved || data.vendorSaved);
  const [iconColor, setIconColor] = useState(
    (data.venueSaved || data.vendorSaved) && isAuthenticated === 'AUTHENTICATED'
      ? '#13362D'
      : 'inherit'
  );

  const [isVenue, setIsVenue] = useState(true);
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/login');
  };

  const checkAuthentication = async () => {
    try {
      const response = await axios.post(`${API_HOST}/api/v1/auth/refresh`);
      setIsAuthenticated(response.data.authStatus);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  const handleAdd = async () => {
    if (isAuthenticated === 'AUTHENTICATED') {
      if (category === 'VENUES') {
        try {
          await axios.post(
            `${API_HOST}/api/v1/venues/${data.venueSlug}/save-to-list`
          );
          setIsSaved(true);
          setIconColor('#CD0000');
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          await axios.post(
            `${API_HOST}/api/v1/vendors/${data.vendorSlug}/save-to-list`
          );
          setIsSaved(true);
          setIconColor('#CD0000');
        } catch (error) {
          console.log('error:', error);
        }
      }
    } else {
      handleRedirect();
    }
  };

  const handleDelete = async () => {
    if (category === 'VENUES') {
      try {
        await axios.delete(
          `${API_HOST}/api/v1/venues/${data.venueSlug}/save-to-list`
        );
        setIsSaved(false);
        setIconColor('#FFC9C9');
      } catch (error) {
        console.log('error:', error);
      }
    } else {
      try {
        await axios.delete(
          `${API_HOST}/api/v1/vendors/${data.vendorSlug}/save-to-list`
        );
        setIsSaved(false);
        setIconColor('#FFC9C9');
      } catch (error) {
        console.log('error:', error);
      }
    }
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setIsClicked(!isClicked);
    if (isClicked) {
      handleDelete();
    } else {
      handleAdd();
    }
  };

  useEffect(() => {
    category === 'VENUES' ? setIsVenue(true) : setIsVenue(false);
  }, [category]);

  const maxLength = 100;
  const description = data.venueDescription || data.vendorDescription;

  let shortDescription;
  if (description.length > maxLength) {
    shortDescription = description.substring(0, maxLength) + ', ...';
  } else {
    shortDescription = description;
  }

  return (
    <StyledCard
      style={{ marginTop: index % 2 === 0 ? 0 : '32px' }}
      onClick={() => {
        const url = isVenue
          ? `/venues/${data.venueSlug}`
          : `/vendors/${data.vendorSlug}`;
        window.open(url, '_blank');
      }}
    >
      <HeadSection>
        <FavoriteWrapper
          onClick={handleClick}
          style={{ background: isSaved ? '#FFC9C9' : '#fff' }}
          className='favorite-wrapper'
        >
          <FavoriteIcon
            sx={{
              fontSize: '16px',
              color: isSaved ? '#CD0000' : 'lightgray',
            }}
          />
        </FavoriteWrapper>
      </HeadSection>
      <StyledImage
        src={data?.vendorCoverImageURL || data?.venueCoverImageURL}
        alt={data?.vendorCoverImageURL || data?.venueCoverImageURL}
      />
      <DescriptionBox className='description'>
        <Title className='title'>
          {data && (data.vendorName ? data.vendorName : data.venueName)}
        </Title>
        <SubTitle className='subTitle'>{data && shortDescription}</SubTitle>
      </DescriptionBox>
    </StyledCard>
  );
};

export default CategoryCard;
