import React, { useEffect, useState, useRef } from 'react';
import Intro from './Banner/Intro';
import Categories from './Wedding-Categories/Categories';
import Advertisement from './AdSpace/Advertisement';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Carousel from './Wedding-Venues/Carousel';
import ImageCatalog from './Wedding-Vendor-Categories/ImageCatalog';
import Gallery from './Gallery/Gallery';
import styled from "styled-components";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import FeedBack from './Testimonials/FeedBack';
import { Alert, Snackbar } from '@mui/material';
import { useContext } from 'react';
import { LocationContext, LogInContext } from '../../Layout';
import { caroselData } from "../blog/corosel/data";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
const MainCaraselDiv = styled.div`
position: relative;
margin-bottom: 25px;

`;
const CaroselDiv = styled.div`
box-sizing: border-box;;
width: 95%;
padding: 0px 40px 0px 20px;
display: flex;
justify-content: flex-start;
align-items: flex-start;
gap:40px;
overflow: scroll;
scroll-behavior: smooth;
margin:auto
`;

const CaroselImageDiv = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
&::selection{
    background-color: transparent;
}
`;
const CaroselImageHeading = styled.p`
text-align: center;
font-size: ${(props) => props.main == "latest" ? "17px" : "17px"};
font-weight:${(props) => props.main == "latest" ? 500 : 500};
&::selection{
    background-color: transparent;
}
`;
const CaroselImage = styled.img`
&::selection{
    background-color: transparent;
}
box-sizing: border-box;
border-radius: 150px;
object-fit: cover;
object-position: 50% 10%;
margin-top: 5px;
box-shadow: 1px Explore Insights and Inspiration
1px 2px black;
cursor: pointer;
&:hover{
    box-shadow: 1px 1px 5px black;
}
width: 190px;
height: 190px;
@media (max-width:500px) {
  width: 140px;
height: 140px;
}
`;

const CaroselImageAlter = styled.div`
box-sizing: border-box;
border-radius: 150px;
margin-top: 5px;
box-shadow: 1px 1px 2px black;
cursor: pointer;
display: flex;
background-color: gray;

background-image: url("https://omnileadzdev.s3.amazonaws.com/NjVhNjU1NzhkMTE5NzMwMWE5NzRiNGI1/1711694683914_pexels-photo-12769896 (1).webp");
justify-content: center;
align-items: center;
&:hover{
    box-shadow: 1px 1px 5px black;
}
width: 190px;
height: 190px;
@media (max-width:500px) {
  width: 140px;
height: 140px;
}
/* filter: blur(0.6px);  */
background-size: cover;

`;

const ArrowButton = styled.div`
position: absolute;
color: white;
border-radius: 44px;
background-color:  #1a3f35;
display: flex;
justify-content: center;
align-items: center;
width: 45px;
height: 45px;
z-index: 9999;
left:${(props) => props.side == "left" ? "1.2%" : null};
right:${(props) => props.side == "right" ? "1.2%" : null};
top:30%;
box-shadow: 0.5px 0.5px 2px inset white;
cursor: pointer;
&:hover{
    box-shadow: 0.5px 0.5px 3px black;
}
&:active{
    transform:scale(0.98)
}

`;
const BlogHeading = styled.p`
padding-left: 2%;
padding-right: 2%;
font-size: 32px;
font-weight: 700;
text-align: center;
@media (max-width:550px) {
  font-size: 25px;
  padding-left: 3%;
padding-right: 3%;
}
`
const Root = ({ onVendorTagClick, clickedVendorTag }) => {
  const { isLoggedIn, setIsLoggedIn } = useContext(LogInContext);
  const { setGeoLocation } = useContext(LocationContext);
  const [leftButton, setLeftButton] = useState(false);
  const [rightButton, setRightButton] = useState(true)


  const navigator = useNavigate()
  const ref = useRef(null)

  function CaroselSlider(direction) {

    if (!ref.current)
      return
    let width = 200;
    if (direction == "LEFT")
      ref.current.scrollLeft = ref.current.scrollLeft - width
    else {
      ref.current.scrollLeft = ref.current.scrollLeft + width
    }
    console.log(ref.current.scrollLeft, "scroll amount", ref.current.scrollWidth - 930)
    if (ref.current.scrollLeft > 0)
      setLeftButton(true)
    else if (ref.current.scrollLeft == 0)
      setLeftButton(false)

  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsLoggedIn(false);
  };

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.permissions.query({ name: 'geolocation' }).then((result) => {
  //       if (result.state === 'granted' || result.state === 'prompt') {
  //         navigator.geolocation.getCurrentPosition((position) => {
  //           setGeoLocation({
  //             latitude: position.coords.latitude,
  //             longitude: position.coords.longitude,
  //           });
  //         });
  //       }
  //     });
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log('location: ', geoLocation);
  // }, [geoLocation]);

  return (
    <div style={{ width: "100vw", overflowX: "hidden" }}>
      <Helmet>
        <title>Maangal Bazaar: Your Destination for Big Celebrations</title>
        <meta name="description" content="Maangal Bazaar - Celebrate Big with Extraordinary Moments! Explore a handpicked selection of vendors and venues for your dream celebrations. Elevate your weddings, festivities, and holidays with our premium offerings." />
      </Helmet>
      <Intro />
      <Categories
        onVendorTagClick={onVendorTagClick}
        clickedVendorTag={clickedVendorTag}
      />

      <BlogHeading>Wedding Blogs and Inspirations</BlogHeading>
      <MainCaraselDiv>
        {leftButton && <ArrowButton onClick={() => CaroselSlider("LEFT")} side="left"><KeyboardArrowLeftIcon /></ArrowButton>}
        <CaroselDiv ref={ref}>
          <CaroselImageDiv onClick={() => window.open("https://blog.maangalbazaar.com/", "_blank")}>
            <CaroselImageAlter>

            </CaroselImageAlter>
            <CaroselImageHeading main="latest">Latest</CaroselImageHeading>
          </CaroselImageDiv>
          {
            caroselData.map((data, index) => {
              return <CaroselImageDiv key={index * 10}>
                <CaroselImage onClick={() => window.open(`https://blog.maangalbazaar.com/blog/category/${data.path}`, "_blank")} src={data.image} alt="MB" />
                <CaroselImageHeading main="others">{data.title}</CaroselImageHeading>
              </CaroselImageDiv>

            })
          }

        </CaroselDiv>
        {rightButton && <ArrowButton onClick={() => CaroselSlider("RIGHT")} side="right"><KeyboardArrowRightIcon /></ArrowButton>}

      </MainCaraselDiv>
      <Advertisement min={0} max={5} />
      {/* top wedding venues Carousel */}
      <Carousel
        onVendorTagClick={onVendorTagClick}
        clickedVendorTag={clickedVendorTag}
      />
      <ImageCatalog
        onVendorTagClick={onVendorTagClick}
        clickedVendorTag={clickedVendorTag}
      />
      <Advertisement min={5} max={10} />
      <Gallery />
      <FeedBack />
      <Snackbar
        open={isLoggedIn}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
          Logged In Successfully!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Root;
