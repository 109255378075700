export const magazineData = [{
    id: "56039193-09cb-406a-9a8d-55e798460624",
    image: "https://omnileadzdev.s3.amazonaws.com/NjYwNDZmMWRiNjFiMTk5YmI0ZmRmODVj/1718185565733_blob",
    title: "In this issue",
    description: "Welcome to the second edition of MaangalBazaar, your ultimate guide to the couture trends of 2024! In this edition, we delve into a diverse array of topics designed to inspire and guide brides and grooms as they prepare for their special day. This edition offers an exclusive peek into the runway-to-aisle transformations, where high fashion meets wedding aesthetics. Furthermore, we have an intriguing feature on Manish Malhotra's latest collection, \"Dharohar Kashi Ki,\" a breathtaking blend of traditional craftsmanship and contemporary design. This collection not only celebrates the rich cultural tapestry of Varanasi but also introduces modern elements that make each piece a work of art.",
    iframe: <iframe allowfullscreen="allowfullscreen"
        class="fp-iframe"
        src="https://heyzine.com/flip-book/b00671bcd7.html"
        style={{ border: 0, width: '100vw', height: '99.3vh' }}
    ></iframe>
},
{
    id: "56039193-09cb-406a-9a8d-55e798460625",
    image: "https://omnileadzdev.s3.amazonaws.com/NjYwNDZmMWRiNjFiMTk5YmI0ZmRmODVj/1718185565724_blob",
    title: "In this issue",
    description: "It is with immense joy and pride that we, team MaangalBazaar present this special issue, crafted with love and passion for the celebration of unions amidst the breathtaking landscapes of Uttarakhand. Our theme for this edition is Destination Weddings, an enchanting journey exploring pre-wedding shoots, weddings, and honeymoon destinations in the picturesque landscapes of Uttarakhand and beyond. From the serene hills of Dehradun to exotic global locales, we've crafted a collection of articles to inspire and guide you in creating the wedding of your dreams. We believe that a wedding is not just an event; it is a journey, and Uttarakhand provides the perfect backdrop for these unforgettable moments.",
    iframe: <iframe allowfullscreen="allowfullscreen"
        class="fp-iframe"
        src="https://heyzine.com/flip-book/ff2caa0375.html"
        style={{ border: 0, width: '100vw', height: '99.3vh' }}
    ></iframe>
}
]
