import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { API_HOST } from '../../api/url';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ArrowWrapper, StyledButton } from '../Testimonials/FeedBack';

const HeadingSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 480px) {
    width: 90%;
    margin: auto;
  }
`;

const StyledHeading = styled.h1`
  margin-bottom: 12px;
  color: #1b1d27;
  font-weight: 700;
  font-size: 32px;
  font-family: 'Outfit', sans-serif;
  @media (max-width: 480px) {
    font-size: 25px;
    margin-top: 32px;
  }
`;

const SubTitle = styled.p`
  text-align: center;
  width: 644px;
  margin-top: 0;
  color: #565656;
  font-weight: 300;
  font-size: 14px;
  @media (max-width: 480px) {
    width: 90%;
  }
`;

const ImagesWrapper = styled.div`
padding:20px 24px 0px 24px;
  display: flex;
  gap:25px;
  overflow-y: hidden;
  height: 245px;
  justify-content: flex-start;
  align-items: flex-start;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 480px) {
    /* padding-top: 42px; */
    height: 205px;
  }
`;

const StyledImage = styled.img`
  min-width: 230px !important;
  min-height: 230px;
  max-height: 230px;
  max-width: 230px;
  object-fit: cover !important;
  border-radius: 300px;
  @media (max-width: 480px) {
    min-width: 188.6px !important;
    min-height: 188.6px;
    max-width: 188.6px !important;
    max-height: 188.6px;
    flex-shrink: 0;
  }
  cursor: pointer;
  transition:  all 0.2s ease;
  &:hover{
    transform: scale(0.98);
    box-shadow: 0px 4px 4px 0px black;
  }
  box-shadow: 0px 5px 5px 0px black;
  
`;

const Scrollbar = styled.input`
  width: 390px;
  height: 2px;
  background-color: #e0e0e0;
  -webkit-appearance: none;
  appearance: none;
  text-decoration: line-through;
  scroll-behavior: smooth;
  @media (max-width: 480px) {
    width: 90%;
  }
`;

const StyledCount = styled.p`
  text-align: center;
  color: #000;
  font-family: Hanken Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  margin-bottom: 40px;
`;

const Gallery = () => {
  const [gallary, setGallary] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(1);
  const imagesWrapperRef = useRef(null);
  const scrollbarRef = useRef(null);

  const handleScroll = (e) => {
    if (imagesWrapperRef.current) {
      const scrollLeft =
        (e.target.value / 100) * imagesWrapperRef.current.scrollWidth;
      imagesWrapperRef.current.scrollLeft = scrollLeft;
    }
  };

  useEffect(() => {
    const handleImagesWrapperScroll = () => {
      if (imagesWrapperRef.current && scrollbarRef.current) {
        const scrollLeft = imagesWrapperRef.current.scrollLeft;
        const scrollWidth =
          imagesWrapperRef.current.scrollWidth -
          imagesWrapperRef.current.clientWidth;
        const value = (scrollLeft / scrollWidth) * 100;
        scrollbarRef.current.value = value;

        const imageWidth = imagesWrapperRef.current.firstChild.offsetWidth;
        const currentIndex = Math.ceil(scrollLeft / imageWidth) + 1;
        setCurrentImageIndex(currentIndex);
      }
    };

    if (imagesWrapperRef.current) {
      imagesWrapperRef.current.addEventListener(
        'scroll',
        handleImagesWrapperScroll
      );
    }

    return () => {
      if (imagesWrapperRef.current) {
        imagesWrapperRef.current.removeEventListener(
          'scroll',
          handleImagesWrapperScroll
        );
      }
    };
  }, []);

  const handleRangeClick = (e) => {
    if (scrollbarRef.current) {
      const boundingClientRect = scrollbarRef.current.getBoundingClientRect();
      const clickPosition = e.clientX - boundingClientRect.left;
      const value = (clickPosition / boundingClientRect.width) * 100;
      scrollbarRef.current.value = value;
      handleScroll({ target: { value } });
    }
  };

  const fetchGallary = async () => {
    try {
      const response = await axios.get(`${API_HOST}/api/v1/meta/gallery`);
      setGallary(response.data.galleryData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchGallary();
  }, []);

  const handleNextClick = () => {
    if (imagesWrapperRef.current) {
      const imageWidth = imagesWrapperRef.current.firstChild.offsetWidth;
      const newScrollLeft = imagesWrapperRef.current.scrollLeft + imageWidth;
      imagesWrapperRef.current.scrollLeft = newScrollLeft;
    }
  };

  const handlePrevClick = () => {
    if (imagesWrapperRef.current) {
      const imageWidth = imagesWrapperRef.current.firstChild.offsetWidth;
      const newScrollLeft = imagesWrapperRef.current.scrollLeft - imageWidth;
      imagesWrapperRef.current.scrollLeft = newScrollLeft;
    }
  };

  return (
    <>
      <HeadingSection>
        <StyledHeading>Gallery</StyledHeading>
        <SubTitle>
        Moments Woven Together, Each Frame Tells a Story, A Tapestry of Memories, Captured, Preserved, and Treasured.
        </SubTitle>
      </HeadingSection>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {gallary.length > 0 && (
          <ArrowWrapper style={{ alignSelf: 'end', padding: '0px 6rem' }}>
            <StyledButton
              onClick={handlePrevClick}
              style={{ color: gallary.length === 0 ? '#D1D7D7' : 'inherit' }}
            >
              <ArrowBackIcon />
            </StyledButton>
            <StyledButton
              onClick={handleNextClick}
              style={{ color: gallary.length === 0 ? '#D1D7D7' : 'inherit' }}
            >
              <ArrowForwardIcon />
            </StyledButton>
          </ArrowWrapper>
        )}
        <ImagesWrapper ref={imagesWrapperRef}>
          {gallary.map((item, index) => {
            // console.log(item)
            return <StyledImage onClick={()=>window.open(item.link,"_blank")} src={item.mediaURL} alt={index} key={index} />;
          })}
        </ImagesWrapper>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '40px',
        }}
      >
        <Scrollbar
          ref={scrollbarRef}
          type='range'
          min='0'
          max='100'
          value='0'
          onChange={handleScroll}
          onMouseDown={handleRangeClick}
        />
      </div>
      <StyledCount>{`${currentImageIndex}/${gallary.length}`}</StyledCount>{' '}
    </>
  );
};

export default Gallery;
