import React from "react";
import styled from "styled-components";
import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

const MainBox = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledBox = styled(Box)`
  height: 100%;
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalImage = styled.img`
  height: 80%;
  width: 60%;
  display: block;
  margin: auto;
  /* padding: 20px; */
  object-fit: contain;
  scale: 1;
  @media (max-width: 480px) {
    height: auto;
  }
`;

const PortFolioModal = ({
  selected,
  isModalOpen,
  setIsModalOpen,
  onNextImage,
  onPreviousImage,
  isFirstImage,
  isLastImage,
}) => {
  const handleClose = () => setIsModalOpen(false);

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
      >
        <MainBox>
          <IconButton
            sx={{
              float: "right",
              background: "#a4a4a4",
              alignSelf: "end",
              position: "relative",
              top: "32px",
              right: "32px",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <StyledBox>
            <IconButton
              sx={{ float: "right", margin: "20px", background: "#fff" }}
              onClick={onPreviousImage}
              disabled={isFirstImage}
            >
              <ArrowBackIosRoundedIcon />
            </IconButton>
            {selected && <ModalImage src={selected} alt="img" />}
            <IconButton
              sx={{ float: "right", margin: "20px", background: "#fff" }}
              onClick={onNextImage}
              disabled={isLastImage}
            >
              <ArrowForwardIosRoundedIcon />
            </IconButton>
          </StyledBox>
        </MainBox>
      </Modal>
    </div>
  );
};

export default PortFolioModal;
