import styled from 'styled-components';

const Wrapper = styled.div`
  height: 28px;
  margin-top: 28px;
  display: flex;
  gap:35px;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 20px;
 width: fit-content;
 @media (max-width: 1123px) {
gap:23px;
width:100vw;
 }
  @media (max-width: 893px) {
    border: 1px solid #ededed;
    height: 48px;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    justify-content: flex-start;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Item = styled.p`
word-break: keep-all;
  font-family: Kumbh Sans, sans-serif;
  font-weight: ${(props) => (props.selected ? '700' : '300')};
  font-size: 20px;
  cursor: pointer;
  color: ${(props) => (props.selected ? '#173535' : '#6b6868')};

  :hover {
    color: #173535;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 0px 16px;
  }
`;

const CategoryItems = ({ selected, setSelected, items }) => {
  return (
    <Wrapper>
      {items.map((item,index) => {
        return (
          <Item
            key={index*10}
            onClick={() => setSelected(item.vendorCategory)}
            selected={selected === item.vendorCategory}
          >
            {item.category}
          </Item>
        );
      })}
    </Wrapper>
  );
};

export default CategoryItems;
