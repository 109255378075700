import React from 'react';
import styled from 'styled-components';

const CardWrapper = styled.div`
box-sizing: border-box;
  max-width: 485px;
  min-width: 380px;
  @media (max-width:480px) {
    padding-right: 40px; 
    padding-left: 10px;
  }
`;

const StyledHeading = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #13362d;
  margin-bottom: 0;
  margin-top: 8px;
  padding-right: 26px;
  font-family: Cormorant, serif;
  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const StyledDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6a6a6a;
  word-break: keep-all;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const ReviewerSection = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    padding-top: 8px;
  }
`;

const Name = styled.p`
  margin: 0 0 0 16px;
  font-weight: 700;
  font-size: 18px;
  color: #13362d;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const Company = styled.p`
  margin: 4px 0 0 16px;
  color: #13362d;
  font-weight: 400;
  font-size: 16px;
  @media (max-width: 480px) {
    font-size: 10px;
  }
`;

const StyledImage = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  object-fit: cover;
  @media (max-width: 480px) {
    height: 40px;
    width: 40px;
  }
`;

const FeedBackCard = ({ data }) => {
  return (
    <CardWrapper>
      <StyledHeading>{data.reviewTitle}</StyledHeading>
      <StyledDescription>{data.reviewDescription}</StyledDescription>
      <ReviewerSection>
        <StyledImage
          src={data.reviewAuthorPhotoURL}
          alt={data.reviewAuthorDisplayName}
        />
        <div>
          <Name>{data.reviewAuthorDisplayName}</Name>
          {/* <Company>{data.clientCompany}</Company> */}
        </div>
      </ReviewerSection>
    </CardWrapper>
  );
};

export default FeedBackCard;
