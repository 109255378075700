import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import Card from "./Card";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import Filter from "./Filter";
import ListViewCard from "./ListViewCard";
import axios from "axios";
import {
  venueFilters,
  photographyFilters,
  makeupFilters,
  preWeddingFilters,
  preWeddingLocationsFilters,
  bridalGroomingFilters,
  bridalWearFilters,
  groomWearFilters,
  mehendiFilters,
  dJsFilters,
  bartendersSubCategoryFilters,
  cakeSubCategoryFilters,
  foodFilters,
  invitationFilters,
  invitationGiftsSubCategoryFilters,
  sangeetChoreographersFilters,
  jewelleryAccesoriesSubCategoryFilters,
  jewelleryFilters,
  weddingEntertainmentFilters,
  trosseauPackersSubCategoryFilters,
  panditsFilters,
  planningAndDecorFilters,
  weddingPlannerSubCategoryFilters,
} from "./filters";
import { sortList } from "./sortList";
import CardSkeleton from "./CardSkeleton";
import ListSkeleton from "./ListSkeleton";
import { API_HOST } from "../../api/url";
import BreadCrumb from "./BreadCrumb";
import { destinationWeddingVenues, venueTypes } from "../../NavBar/vendors";
import { useLocation } from "react-router-dom";
import {
  Wrapper,
  CardsWrapper,
  FilterAndCardsWrapper,
  ListView,
} from "./styles/VenuesStyles";
import Banner from "../Banner/Banner";
import { LocationContext } from "../../../Layout";
import { Helmet } from 'react-helmet-async';
const categoryFilters = {
  VENUES: venueFilters,
  PHOTOGRAPHY: photographyFilters,
  MAKEUP: makeupFilters,
  PRE_WEDDING: preWeddingFilters,
  PLANNING_AND_DECOR: planningAndDecorFilters,
  BRIDAL_WEAR: bridalWearFilters,
  GROOM_WEAR: groomWearFilters,
  MEHENDI: mehendiFilters,
  MUSIC_AND_DANCE: dJsFilters,
  JEWELLERY_AND_ACCESSORIES: jewelleryFilters,
  INVITES_AND_GIFTS: invitationFilters,
  FOOD: foodFilters,
  PANDITS: panditsFilters,
  BRIDAL_GROOMING: bridalGroomingFilters,
};

const Venues = ({ clickedVendorTag }) => {
  const [page, setPage] = useState(1);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState("cardView");
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([])
  const [selectedFilters, setSelectedFilters] = useState({});
  const [query, setQuery] = useState("");
  const [selectedSort, setSelectedSort] = useState("Relevance");
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { geoLocation } = useContext(LocationContext);
  const { categoryItem, city, category } = location.state || {};
  const [cityOption,setCityOption]=useState("")
  const [cityOptionDropDown,setCityOptionDropDown]=useState(false)

  const handleSortChange = (sortOption) => {
    setSelectedSort(sortOption);
  };

  // useEffect(() => {
  //   console.log('venue:', categoryItem, city, category);
  // }, []);

  const filterMap = {
    // 'Pre Wedding Shoot Locations': preWeddingLocationsFilters,
    // 'Pre Wedding Photographers': preWeddingFilters,
    // DJs: dJsFilters,
    // 'Sangeet Choreographer': sangeetChoreographersFilters,
    // 'Wedding Entertainment': weddingEntertainmentFilters,
    // Accessories: jewelleryAccesoriesSubCategoryFilters,
    // 'Trousseau Packers': trosseauPackersSubCategoryFilters,
    // Favors: invitationGiftsSubCategoryFilters,
    // 'Mehendi Favors': invitationGiftsSubCategoryFilters,
    // 'Invitation Gifts': invitationGiftsSubCategoryFilters,
    // Bartenders: bartendersSubCategoryFilters,
    // Cake: cakeSubCategoryFilters,
    // 'Wedding Planners': weddingPlannerSubCategoryFilters,
    "Banquet Halls": venueFilters,
    "Destination Wedding": venueFilters,
    "Lawns / Farmhouses": venueFilters,
    // "Kalyana Mandapam": venueFilters,
    "Small Function / Party Halls": venueFilters,
    "4 Star & Above Hotels": venueFilters,
    Resorts: venueFilters,
  };

  const FilterData = () => {
    setFilterData(
      filterMap[clickedVendorTag] || categoryFilters[categoryItem?.value]
    );
  };

  useEffect(() => {
    FilterData();
  }, [categoryItem?.value, clickedVendorTag]);
// console.log(categoryItem)
  const itemsPerPage = selectedButton === "cardView" ? 24 : 25;

  const fetchData = async () => {
    setLoading(true);
    try {
      const requestBody = {
        venueCapacityRanges: selectedFilters["No. of guest"],
        venuePricingRate: selectedFilters["Price per plate"],
        venueLocationTypes:
        categoryItem?.value&&categoryItem?.value !== "VENUES" ? [categoryItem?.value] : [],
        venueEnvironment: selectedFilters["Space"],
        venueBaseRateRanges: selectedFilters["Rental Cost"],
        venuePricingRateRanges: selectedFilters["Price per plate"],
        venueCity: cityOption?cityOption:city,
      };

      let url = `${API_HOST}/api/v1/venues/search?sortBy=${selectedSort.toUpperCase()}`;

      if (geoLocation && selectedSort === "PROXIMITY") {
        url += `&userLong=${geoLocation.latitude}&userLat=${geoLocation.longitude}`;
      }

      const response = await axios.post(url, requestBody);
      setPage(1)
      setData(response.data.searchResults);
      setShowData(response.data.searchResults.slice(0,itemsPerPage))
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [
    geoLocation,
    selectedFilters,
    selectedSort,
    categoryItem,
    category,
    clickedVendorTag,
    cityOption
  ]);

  const handleFilterChange = (checkedState, filters) => {
    const newSelectedFilters = Object.entries(checkedState).reduce(
      (acc, [filterName, checkedValues]) => {
        acc[filterName] = filters
          .find((filter) => filter.name === filterName)
          .data.filter((_, index) => checkedValues[index])
          .map((data) => data.value);
        return acc;
      },
      {}
    );
    setSelectedFilters(newSelectedFilters);
  };

  const handleChange = (event, value) => {
    setPage(value);
    const startIndex = (value - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedItems = data.slice(startIndex, endIndex);
    setShowData(displayedItems)
    document.getElementById('cardViewVenues').scrollIntoView({ behavior: 'smooth' });
  };

  const handleQuery = (e) => {
    setQuery(e.target.value);
    console.log(query);
  };

  useEffect(() => {
    if (window.innerWidth <= 480) {
      setSelectedButton("cardView");
    }
    setCityOption("")
  }, []);

  return (
    <>
     <Helmet>
        <title>Discover the Perfect Venues for Your Events at Maangal Bazaar</title>
        <meta name="description" content="Explore a wide range of venues for weddings, parties, and corporate events at Maangal Bazaar. Find the perfect location with our extensive selection of banquet halls and party places. Book now and make your event unforgettable." />
      </Helmet>
      <Banner />
      <Wrapper onClick={()=>setCityOptionDropDown(false)} id="cardViewVenues">
        {/* <BreadCrumb clickedVendorTag={clickedVendorTag} /> */}
        <Header
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={(value) => {
            if (selectedButton !== "listView") setIsFilterOpen(value);
          }}
          selectedButton={selectedButton}
          setSelectedButton={setSelectedButton}
          handleQuery={handleQuery}
          onSortChange={handleSortChange}
          sortList={sortList}
          clickedVendorTag={clickedVendorTag}
          selectedSort={selectedSort}
          city={city}
          category={categoryItem?.value?categoryItem:{value: 'VENUES'}}
          type={"venue"}
          totalRecords={data.length}
          cityOption={cityOption}
          setCityOption={setCityOption}
          setCityOptionDropDown={setCityOptionDropDown}
          cityOptionDropDown={cityOptionDropDown}
        />
        <CardsWrapper
          style={{
            alignItems: selectedButton === "listView" ? "flex-start" : "center",
          }}
        >
          <FilterAndCardsWrapper>
            {/* {(isFilterOpen || selectedButton === 'listView') && (
            <Filter
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
              selectedButton={selectedButton}
              onFilterChange={handleFilterChange}
              filters={filterData}
            />
          )} */}
            {showData.length > 0 ? (
              selectedButton === "cardView" ? (
                <Grid container spacing={2} justifyContent="center">
                  {isFilterOpen && window.innerWidth <= 480
                    ? null
                    : showData
                        .map((item, index) => {
                          return (
                            <Grid
                              item
                              key={`item-venue-${index}`}
                              xs={12}
                              sm={12}
                              md={isFilterOpen ? 12 : 6}
                              lg={isFilterOpen ? 6 : 4}
                              sx={{ paddingTop: "24px" }}
                            >
                              <Card id={index} data={item} isVenue={true} />
                            </Grid>
                          );
                        })}
                </Grid>
              ) : (
                <ListView>
                  {showData
                    .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                    .map((item, index) => {
                      return (
                        <ListViewCard id={index} data={item} isVenue={true} />
                      );
                    })}
                </ListView>
              )
            ) : loading ? (
              selectedButton === "cardView" ? (
                <Grid container spacing={2}>
                  {[...Array(6)].map((_, index) => (
                    <Grid
                      item
                      key={index}
                      xs={12}
                      sm={12}
                      md={isFilterOpen ? 12 : 6}
                      lg={isFilterOpen ? 6 : 4}
                    >
                      <CardSkeleton />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <ListView>
                  {[...Array(6)].map((_, index) => {
                    return <ListSkeleton key={index} />;
                  })}
                </ListView>
              )
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  fontFamily: "Outfit",
                }}
              >
                <h1>No Data Found</h1>
              </div>
            )}
          </FilterAndCardsWrapper>
          {data.length > 0 && (
            <div style={{ margin: "auto" }}>
              {isFilterOpen && window.innerWidth <= 480 ? null : (
                <Pagination
                  count={Math.ceil(data.length / itemsPerPage)}
                  page={page}
                  onChange={handleChange}
                  variant="outlined"
                  shape="rounded"
                  sx={{
                    paddingTop: "40px",
                  }}
                />
              )}
            </div>
          )}
        </CardsWrapper>
      </Wrapper>
    </>
  );
};

export default Venues;
