import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { DataText } from '../wedding-venues/styles/FilterStyles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_HOST } from '../../api/url';
import CircularProgress from '@mui/material/CircularProgress';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 14px 0;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 12px);
`;

const StyledInput = styled.input`
  padding: 10px 16px;
  outline: none;
  border-radius: 4px;
  border: 1px solid #d9dcea;
  background: #fff;
  @media (max-width: 480px) {
    font-size: 12px;
    padding: 8px 14px;
  }
`;

const StyledSelect = styled.select`
  padding: 10px 16px;
  height: 100%;
  outline: none;
  border-radius: 4px;
  border: 1px solid #d9dcea;
  background: #fff;
`;

const StyledLabel = styled.label`
  color: #a2a2a2;
  font-size: 14px;
  margin-bottom: 8px;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const StyledHeading = styled.h2`
  margin: 0;
  color: #000;
  font-size: 21px;
  font-weight: 600;
  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const CheckAvailability = ({ isVenue, data, isAuthenticated }) => {
  const [contactNumber, setContactNumber] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [numberOfPeople, setNumberOfPeople] = useState('');
  const [numberOfRooms, setNumberOfRooms] = useState('');
  const [functionType, setFunctionType] = useState('');
  const [functionTime, setFunctionTime] = useState('');
  const [isFlexible, setIsFlexible] = useState(false);
  const [isViewContact, setIsViewContact] = useState(false);
  const [contact, setContact] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [maincontact, setMainContact] = useState("")
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState(null)
  const [vendorContactForApi, setVendorContactForApi] = useState("")

  const handleNumberOfPeopleChange = (event) => {
    const value = event.target.value;
    if (value.length <= 4) {
      setNumberOfPeople(value);
    }
  };

  const handleNumberOfRoomsChange = (event) => {
    const value = event.target.value;
    if (value.length <= 4) {
      setNumberOfRooms(value);
    }
  };

  const handleButtonClick = async () => {
    const message = createMessage(
      contactNumber,
      eventDate,
      numberOfPeople,
      numberOfRooms,
      functionType,
      functionTime,
      isFlexible
    );

    try {
      const requestBody = {
        serviceSlug: isVenue ? data.venueSlug : data.vendorSlug,
        serviceType: isVenue ? 'VENUE' : 'VENDOR',
      };
      AnalyticsChantInitiate(data, vendorContactForApi)
      const response = await axios.post(
        `${API_HOST}/api/v1/conversations`,
        requestBody
      );
      const conversationId = response.data.conversationId;
      navigate('/chat', { state: { message, conversationId } });
    } catch (error) {
      console.log(error);
    }
  };

  const isButtonDisabled =
    contactNumber === '' || numberOfPeople === '' || functionType === '';

  const createMessage = (
    contactNumber,
    eventDate,
    numberOfPeople,
    numberOfRooms,
    functionType,
    functionTime,
    isFlexible
  ) => {
    let message = 'This are my requirements: | ';
    if (contactNumber) {
      message += `Contact Number: ${contactNumber} | `;
    }
    if (eventDate) {
      message += `Event Date: ${eventDate} | `;
    }
    if (numberOfPeople) {
      message += `Number of People: ${numberOfPeople} | `;
    }
    if (numberOfRooms) {
      message += `Number of Rooms: ${numberOfRooms} | `;
    }
    if (functionType) {
      message += `Function Type: ${functionType} | `;
    }
    if (functionTime) {
      message += `Function Time: ${functionTime} | `;
    }
    if (isFlexible !== undefined) {
      message += `Is Flexible: ${isFlexible ? 'Yes' : 'No'} `;
    }
    return message;
  };

  const handleViewContactClick = async () => {
    if (isAuthenticated === 'UNAUTHENTICATED') {
      navigate('/login');
    } else if (contact === "" || contact == null) {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${API_HOST}/api/v1/venues/${data.venueSlug}/contact`
        );
        AnalyticsViewContacts(data, vendorContactForApi)
        setMainContact(response.data.venueContact)
        setContact(response.data.venueContact);
        setIsViewContact(true);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(true);
      navigator.clipboard.writeText(contact)
        .then(() => {
          setContact("Copied")
          setIsLoading(false);
          setDisabled(true)
          setTimeout(() => {
            console.log(maincontact)
            setContact(maincontact)
            setDisabled(false)
          }, 2500)
        })
        .catch(err => {
          setContact("Try Again")
          setIsLoading(false);
        });
    }
  };

  async function AnalyticsViewContacts(vendorData, vendorContact) {
    try {

      const body = {
        userData: {
          linkedServiceSlug: userProfile.linkedServiceSlug,
          linkedServiceType: userProfile.linkedServiceType,
          userContact: userProfile.userContact,
          userFullName: userProfile.userFullName,
          userType: userProfile.userType
        },
        vendorData: {
          vendorCity: vendorData.venueCity,
          vendorCoverImageURL: vendorData.venueCoverImageURL,
          vendorName: vendorData.venueName,
          vendorSubcategory: vendorData.venueSubcategory,
          vendorCategory: vendorData.venueCategory,
          vendorSlug: vendorData.venueSlug,
          vendorContact: vendorContact,
          type: "venue"
        }
      }
      axios.post(`${API_HOST}/api/v1/analytics/addnewviewcontactanalytics/Vendor_Venue_View_Contact`, body);
    } catch (error) {

    }

  }


  async function AnalyticsChantInitiate(vendorData, vendorContactForApi) {
    if (!userProfile || !vendorContactForApi) {
      return
    }
    // console.log(vendorData)
    try {
      const body = {
        userData: {
          linkedServiceSlug: userProfile.linkedServiceSlug,
          linkedServiceType: userProfile.linkedServiceType,
          userContact: userProfile.userContact,
          userFullName: userProfile.userFullName,
          userType: userProfile.userType
        },
        vendorData: {
          vendorCity: vendorData.venueCity,
          vendorCoverImageURL: vendorData.venueCoverImageURL,
          vendorName: vendorData.venueName,
          vendorSubcategory: vendorData.venueSubcategory,
          vendorCategory: vendorData.venueCategory,
          vendorSlug: vendorData.venueSlug,
          vendorContact: vendorContactForApi,
          type: "venue"
        }
      }
      axios.post(`${API_HOST}/api/v1/analytics/addnewviewcontactanalytics/Vendor_Venue_Chat_Initiate`, body);
    } catch (error) {

    }

  }

  async function getUserProfileData() {
    try {
      const response = await axios.get(`${API_HOST}/api/v1/profile`);
      const response2 = await axios.get(
        `${API_HOST}/api/v1/venues/${data.venueSlug}/contact`
      );
      setVendorContactForApi(response2.data.venueContact)
      setUserProfile(response.data.userProfile)
    } catch (error) {

    }
  }


  useEffect(() => {
    getUserProfileData()
  }, []);


  return (
    <>
      <StyledHeading>Check availability and price </StyledHeading>
      <Row>
        <InputWrapper>
          <StyledLabel htmlFor='number'>Enter Contact Number*</StyledLabel>
          <StyledInput
            type='tel'
            id='number'
            maxLength='10'
            required
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper>
          <StyledLabel htmlFor='date'>Date of Event</StyledLabel>
          <StyledInput
            type='date'
            id='date'
            value={eventDate}
            onChange={(e) => setEventDate(e.target.value)}
          />
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <StyledLabel htmlFor='numberOfPeople'>Number of people*</StyledLabel>
          <StyledInput
            type='number'
            id='numberOfPeople'
            value={numberOfPeople}
            onChange={handleNumberOfPeopleChange}
          />
        </InputWrapper>
        <InputWrapper>
          <StyledLabel htmlFor='numberOfRoom'>Number of room</StyledLabel>
          <StyledInput
            type='number'
            id='numberOfRoom'
            value={numberOfRooms}
            onChange={handleNumberOfRoomsChange}
          />
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <StyledLabel htmlFor='functionType'>Function Type*</StyledLabel>
          <StyledSelect
            id='functionType'
            value={functionType}
            onChange={(e) => setFunctionType(e.target.value)}
          >
            <option value=''>Select Function Type</option>
            <option value='birthday'>Birthday</option>
            <option value='engagement'>Engagement</option>
            <option value='wedding'>Wedding</option>
            <option value='other'>Other</option>
          </StyledSelect>
        </InputWrapper>
        <InputWrapper>
          <StyledLabel htmlFor='functionTime'>Function Time</StyledLabel>
          <StyledInput
            type='time'
            id='functionTime'
            value={functionTime}
            onChange={(e) => setFunctionTime(e.target.value)}
          />
        </InputWrapper>
      </Row>
      <Row style={{ marginLeft: ' -10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            className='checkBoxFilter'
            size='small'
            checked={isFlexible}
            onChange={(e) => setIsFlexible(e.target.checked)}
          />
          <DataText
            style={{
              color: '#A2A2A2',
              fontFamily: 'Inter',
              cursor: 'pointer',
            }}
            onClick={() => setIsFlexible(!isFlexible)}
          >
            My event date is flexible
          </DataText>
        </div>
      </Row>
      <Row style={{ alignItems: 'center' }}>
        <Button
          variant='contained'
          className='verifyButton venue'
          disabled={isButtonDisabled}
          onClick={handleButtonClick}
        >
          Send message
        </Button>
        <Button
          id="venuecontact_24"
          disabled={disabled}
          variant='outlined'
          className='viewContact'
          onClick={handleViewContactClick}
          sx={{ width: '44%' }}
        >
          {isLoading ? (
            <CircularProgress size={20} sx={{ color: "#13362d" }} />
          ) : isViewContact ? (
            contact === null ? (
              'NA'
            ) : (
              contact
            )
          ) : (
            'View Contact'
          )}{' '}
        </Button>
      </Row>
    </>
  );
};

export default CheckAvailability;
