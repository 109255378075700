import { Alert, Box, Button, Checkbox, Modal, Snackbar } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";
import { API_HOST } from "../api/url";
import { ForgotPassword } from "./Login";

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 350px;
  background: #fff;
  border-radius: 10px;
  outline: none;
  justify-content: space-between;
  padding: 20px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 480px) {
    width: 298px;
    height: 308px;
    padding: 20px;
  }
`;

const StyledInput = styled.input`
  height: 28px;
  outline: none;
  border: none;
  border-bottom: 1px solid #0f0f0f;
  padding-bottom: 8px;
  font-family: Outfit;
  /* margin-bottom: 32px; */
  @media (max-width: 480px) {
    height: 20px;
  }
`;

const Text = styled.label`
  color: #858585;
  font-size: 14px;
  margin: 10px 0;
`;

const ModalHeading = styled.h2`
  color: #3d3e3d;
  text-align: center;
  font-size: 26px;
  font-family: Hanken Grotesk;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const DIV = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ForgotPasswordModal = ({ isModalOpen, setIsModalOpen }) => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isEmailEntered, setIsEmailEntered] = useState(false);
  const [isOtpEntered, setIsOtpEntered] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [userId, setUserId] = useState("");

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSendOtp = async () => {
    try {
      const response = await axios.post(
        `${API_HOST}/api/v1/auth/forgot-password`,
        { userMail: email }
      );
      setIsEmailEntered(true);
      setOtp(response.data.userTOTP);
      setUserId(response.data.userID);
    } catch (error) {
      console.log(error);
    }
  };

  const handleVerifyOtp = () => {
    setIsOtpEntered(true);
  };

  const handleSubmit = async () => {
    try {
      await axios.post(`${API_HOST}/api/v1/auth/reset-password`, {
        userId: userId,
        userPassword: newPassword,
        resetToken: otp,
      });
      setOpenSnackbar(true);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        open={isModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}
      >
        <StyledBox>
          <div>
            <ModalHeading>Forgot Password</ModalHeading>
            {!isOtpEntered ? (
              <>
                <DIV>
                  <Text htmlFor="otp">Email</Text>
                  <StyledInput
                    type="email"
                    id="otp"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    disabled={isEmailEntered}
                  />
                </DIV>
                {/* {isEmailEntered && ( */}
                <DIV>
                  <Text htmlFor="otp">Enter OTP</Text>
                  <StyledInput
                    type="number"
                    id="otp"
                    value={otp}
                    disabled={!isEmailEntered}
                    onChange={(event) => setOtp(event.target.value)}
                    maxLength={6}
                  />
                </DIV>
                {/* )} */}
              </>
            ) : (
              <>
                <DIV>
                  <Text htmlFor="newPassword">New Password</Text>
                  <StyledInput
                    type={isShow ? "text" : "password"}
                    id="newPassword"
                    value={newPassword}
                    onChange={(event) => setNewPassword(event.target.value)}
                  />
                </DIV>
                <DIV>
                  <Text htmlFor="confirmPassword">Confirm Password</Text>
                  <StyledInput
                    type={isShow ? "text" : "password"}
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                  />
                </DIV>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "-8px",
                  }}
                >
                  <Checkbox
                    size="small"
                    checked={isShow}
                    onChange={(e) => setIsShow(e.target.checked)}
                  />
                  <ForgotPassword
                    onClick={() => setIsShow(!isShow)}
                    style={{
                      marginTop: "4px",
                      color: "#a4a4a4",
                      fontSize: "12px",
                    }}
                  >
                    Show password
                  </ForgotPassword>
                </div>
              </>
            )}
          </div>
          <ButtonContainer>
            {!isEmailEntered && (
              <Button
                variant="contained"
                className="verifyButton sendMessage signinModalButton"
                sx={{ width: "100%" }}
                onClick={handleSendOtp}
                disabled={!email}
              >
                Send OTP
              </Button>
            )}
            {isEmailEntered && !isOtpEntered && (
              <Button
                variant="contained"
                className="verifyButton sendMessage signinModalButton"
                sx={{ width: "100%" }}
                onClick={handleVerifyOtp}
                disabled={otp && otp.length !== 6}
              >
                Verify OTP
              </Button>
            )}
            {isOtpEntered && (
              <Button
                variant="contained"
                sx={{ width: "100%" }}
                onClick={handleSubmit}
                className="verifyButton sendMessage signinModalButton"
                disabled={
                  newPassword !== confirmPassword ||
                  !newPassword ||
                  !confirmPassword
                }
              >
                Submit
              </Button>
            )}
          </ButtonContainer>
        </StyledBox>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setOpenSnackbar(false);
          }}
          severity={"success"}
          sx={{ width: "100%" }}
        >
          Password changed successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default ForgotPasswordModal;
