import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import IconButton from "@mui/material/IconButton";
import ReviewCard from "./ReviewCard";
import axios from "axios";
import { API_HOST } from "../../api/url";
import ReviewSkeleton from "./ReviewSkeleton";

const MainWrapper = styled.div`
  margin: 2em 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledHeading = styled.h2`
  margin: 0;
  color: #000;
  font-size: 22px;
  font-weight: 600;
  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  /* justify-content: space-between; */
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ButtonsWrapper = styled.div`
  display: none;
`;

const SkeletonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Reviews = ({ data, isVenue }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [reviewsPerPage, setReviewsPerPage] = useState(3);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchReviews = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_HOST}/api/v1/${isVenue ? "venues" : "vendors"}/${
          isVenue ? data.venueSlug : data.vendorSlug
        }/reviews`
      );
      setReviews(response.data.reviewData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setReviewsPerPage(1);
      } else {
        setReviewsPerPage(3);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () =>{
      window.removeEventListener("resize", handleResize);
    } 
  }, []);

  const handleNextClick = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  return (
    <MainWrapper  id="reviews-section">
      {reviews && reviews.length > 0 ? (
        <>
          <Header>
            <StyledHeading>Reviews</StyledHeading>
            <ButtonsWrapper>
              <IconButton
                aria-label="Backward"
                className="reviewsNextPreviousButtons"
                onClick={handlePreviousClick}
              >
                <NavigateBeforeIcon />{" "}
              </IconButton>
              <IconButton
                aria-label="Forward"
                className="reviewsNextPreviousButtons"
                onClick={handleNextClick}
              >
                <NavigateNextIcon />
              </IconButton>
            </ButtonsWrapper>
          </Header>
          {loading ? (
            <SkeletonWrapper
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2em",
              }}
            >
              {[1, 2, 3].map((item, index) => {
                return <ReviewSkeleton key={`${index} - ${item}`} />;
              })}
            </SkeletonWrapper>
          ) : (
            <CardsWrapper>
              {reviews.map((item, index) => {
                return <ReviewCard key={index} item={item} />;
              })}
            </CardsWrapper>
          )}
          {reviews.length === 0 && (
            <StyledHeading style={{ fontSize: "16px", marginTop: "20px" }}>
              No Reviews Found
            </StyledHeading>
          )}
        </>
      ) : (
        ""
      )}
    </MainWrapper>
  );
};

export default Reviews;
