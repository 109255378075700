/* global FB */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import LoginPageImage from '../../assets/loginPageImage.svg';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import Google from '../../assets/google.svg';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login';
import { API_HOST } from '../api/url';
import Loginmobilepage from '../../assets/loginmobilepage.svg';
import { useLogin } from '../../context/LoginContext';
import Checkbox from '@mui/material/Checkbox';
import { LogInContext } from '../../Layout';
import ForgotPasswordModal from './ForgotPassword';
import { LS_AUTH_TOKEN } from "../../utils/apiHandler"
import { useSelector } from 'react-redux';
import { notification } from 'antd';
const LoginPageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  @media (max-width: 480px) {
	display: flex;
	justify-content: center;
	align-items: center;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FormWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: calc(100% - 350px / 2 - 50px);
  transform: translate(-68%, -50%);
  width: 400px;
  height: 420px;
  padding: 50px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(42px);
  overflow: hidden;
  z-index: 1;
  @media (max-width: 480px) {
    transform: translate(-50%, -40%);
    width: 302px;
    padding: 26px;
    top: 40%;
    left: 50%;
    right: 50%;
    height: 420px;
    border-radius: 20px;
    backdrop-filter: blur(22.49867820739746px);
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
  }
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 500px;
  background: linear-gradient(271deg, #000 0%, rgba(0, 0, 0, 0) 100%);
  @media (max-width: 480px) {
    display: none;
  }
`;

const StyledHeading = styled.h1`
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 16px 0;
  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const StyledInput = styled.input`
  border-radius: 9px;
  border: 1px solid #4285f4;
  background: #fff;
  height: 40px;
  outline: none;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  padding: 0px 16px;
  @media (max-width: 480px) {
    height: 36px;
    margin-top: 4px;
  }
`;

const StyledLabel = styled.label`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const StyledP = styled.p`
  text-align: center;
  color: #13362d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  @media (max-width: 480px) {
    font-size: 14px;
    margin: 8px;
  }
`;

const StyledGoogle = styled.img`
  margin-right: 12px;
  @media (max-width: 480px) {
    width: 20px;
    height: 20px;
  }
`;

export const ForgotPassword = styled.p`
  margin: -4px 0px 4px 0px;
  color: #4285f4;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* float: right; */
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const SocialLogin = styled.div`
  margin: 18px 0;
  align-items: center;
  @media (max-width: 480px) {
    height: 36px;
  }
`;

const CheckBoxDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -8px;
`;

const LastDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const StyledButton = styled.button`
  color: #fff;
  padding: 8px 12px;
  border-radius: 8px;
  outline: none;
  background-color: rgb(19, 54, 45);
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;
  margin-left: 8px;

  :hover {
    color: rgb(19, 54, 45);
    background-color: #fff;
    text-decoration: underline;
  }
`;

const Login = () => {
	const [api, contextHolder] = notification.useNotification();
	const history = useSelector((state, action) => {
		return state.history.toString()
	})
	const [signUp, setSignUp] = useState(false);
	const [error, setError] = useState(null);
	const [open, setOpen] = useState(false);
	const [provider, setProvider] = useState('');
	const [isMobile, setIsMobile] = useState(false);
	const [isShow, setIsShow] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { setIsLoggedIn } = useContext(LogInContext);
	const navigate = useNavigate();
	const [showSection1, setShowSection1] = useState(true);
	const [phoneNumber, setPhoneNumber] = useState("")
	const [userDataForGoogleSignUp, setUserDataForGoogleSignUp] = useState({})
	const {
		userMail,
		setUserMail,
		userFullName,
		setUserFullName,
		userContact,
		setUserContact,
		userPassword,
		setUserPassword,
		profile,
		setProfile,
	} = useLogin();

	const handleForgotPasswordClick = () => {
		setIsModalOpen(true);
	};

	const handleClick = () => {
		setSignUp(!signUp);
		setUserMail('');
		setUserFullName('');
		setUserContact('');
		setUserPassword('');
	};

	const handleSignIn = async () => {
		if (!userMail || !userPassword) {
			setError('All fields are required');
			setOpen(true);
			return;
		}
		try {
			const response = await axios.post(`${API_HOST}/api/v1/auth/login`, {
				userMail,
				userPassword,
				userType: 'USER',
			});
			const { authToken } = response.data;
			window.localStorage.setItem(LS_AUTH_TOKEN, authToken)
			axios.defaults.headers.common.Authorization = `Bearer ${authToken}`
			setIsLoggedIn(true);
			if (history.length > 0)
				navigate(history)
			else {
				navigate("/")
			}


		} catch (error) {
			if (error.response.data.invalidParams[0] === 'userType') {
				setError('Email has been already registered!');
			}
			if (!error.response) {
				setError(
					'A network error occurred. Please check your internet connection and try again.'
				);
			}
			if (error.response) {
				if (error.response.status === 400) {
					setError('Please check your login credentials');
				}
				if (error.response.status === 404) {
					setError(
						`Failed to load resource status code: ${error.response.status}`
					);
				}

				setOpen(true);
			} else {
				console.log(error);
			}
			setOpen(true);
		}
	};

	const handleSignUp = async () => {
		if (!userMail || !userFullName || !userContact || !userPassword) {
			setError('All fields are required');
			setOpen(true);
			return;
		}

		try {
			const response = await axios.post(`${API_HOST}/api/v1/auth/signup`, {
				userMail,
				userContact,
				userFullName,
				userPassword,
				userType: 'USER',
			});
			const { authToken } = response.data;
			window.localStorage.setItem(LS_AUTH_TOKEN, authToken)
			axios.defaults.headers.common.Authorization = `Bearer ${authToken}`
			setIsLoggedIn(true);
			if (history.length > 0)
				navigate(history)
			else {
				navigate("/")
			}
		} catch (error) {
			if (!error.response) {
				setError(
					'A network error occurred. Please check your internet connection and try again.'
				);
			}
			if (error.response) {
				if (error.response.status === 400) {
					setError('Please enter valid login credentials');
				}
				if (error.response.status === 404) {
					setError(
						`Failed to load resource status code: ${error.response.status}`
					);
				}
				if (error.response.data.responseStatus === 'ERR_DUPLICATE_DATA') {
					setError('Duplicate data found. Please use different credentials.');
				}
				setOpen(true);
			} else {
				console.log(error);
			}
			setOpen(true);
		}
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	// useEffect(() => {
	// 	const handleResize = () => {
	// 		window.innerWidth <= 480 ? setIsMobile(true) : setIsMobile(false);
	// 	};
	// 	window.addEventListener('resize', handleResize);
	// 	return () => {
	// 		window.removeEventListener('resize', handleResize);
	// 	};
	// }, []);

	async function googleSignUpFunction() {
		if (phoneNumber.length == 0) {
			api.warning({
				message: "Field Is Required !",
				placement: "top",
				duration: 2
			})
		} else if (phoneNumber.length == 10 && phoneNumber[0] > 5) {
			const data = {
				userContact: phoneNumber,
				userMail: userDataForGoogleSignUp.email,
				userFullName: userDataForGoogleSignUp.name,
				userProfile: userDataForGoogleSignUp.profile,
				userType: "USER"
			}
			try {
				const response = await axios.post(`${API_HOST}/api/v1/auth/google-signup`, data);
				const { authToken } = response.data;
				window.localStorage.setItem(LS_AUTH_TOKEN, authToken)
				axios.defaults.headers.common.Authorization = `Bearer ${authToken}`
				setIsLoggedIn(true);
				if (history.length > 0)
					navigate(history)
				else {
					navigate("/")
				}
			} catch (error) {
				console.log(error)
				if (!error.response) {
					setError(
						'A network error occurred. Please check your internet connection and try again.'
					);
				}
				if (error.response) {

					if (error.response.status === 400) {
						setError('Please enter valid login credentials');
					}
					if (error.response.status === 404) {
						setError(
							`Failed to load resource status code: ${error.response.status}`
						);
					}
					if (error.response.data.responseStatus === 'ERR_DUPLICATE_DATA') {
						setError('Duplicate data found. Please use different credentials.');
					}
					setOpen(true);
				} else {
					console.log(error);
				}
				setOpen(true);
			}

		} else {
			api.error({
				message: "Enter A Valid Mobile Number",
				placement: "top",
				duration: 2
			})
		}
	}

	return (
		<>
			{contextHolder}
			<LoginPageWrapper>
				<Button
					variant='contained'
					className='loginBackButton'
					onClick={() => {
						if (!showSection1) {
							window.location.reload()
						}
						else if (history.length > 0)
							navigate(history)
						else {
							navigate("/")
						}
					}}
				>
					Back
				</Button>

				<Image src={isMobile ? Loginmobilepage : LoginPageImage} alt='img' />
				<FormWrapper>
					<section style={{ transform: showSection1 ? 'translateY(0)' : 'translateY(-150%)', transition: "all 1s linear" }}>
						<StyledHeading onClick={() => setShowSection1(false)}>{signUp ? 'Sign up' : 'Sign in'}</StyledHeading>
						<SocialLogin
							style={{
								display: signUp ? 'none' : 'flex',
							}}
						>
							{/* client secret */}
							{/* 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 */}
							<div style={{ display: 'flex', flex: 1 }}>
								<LoginSocialGoogle
									className='LoginSocialGoogle'
									client_id='683829292705-ghq4kksaai6ds2g6g4fa1o22tpj0lrru.apps.googleusercontent.com'
									discoveryDocs='claims_supported'
									access_type='offline'
									scope='https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.email '
									onResolve={async ({ provider, data }) => {
										// console.log(provider,data,"first")
										if (data.error && data.error.length > 0) {
											api.info({
												message: "Failed To SignIN With Google",
												placement: "top",
												duration: 3
											})
											return
										} else {
											try {
												console.log(data.code, "code from google")
												const response = await axios.post(`${API_HOST}/api/v1/auth/exchange-google-token`, {
													code: data.code,
												});
												// console.log(response.data,"ye response ha")
												if (response.data && response.data.authToken) {
													const { authToken } = response.data;
													window.localStorage.setItem(LS_AUTH_TOKEN, authToken)
													axios.defaults.headers.common.Authorization = `Bearer ${authToken}`
													setIsLoggedIn(true);
													if (history.length > 0)
														navigate(history)
													else {
														navigate("/")
													}
												} else if (!response.data.status && response.data.data.email && response.data.data.name) {
													setUserDataForGoogleSignUp({ email: response.data.data.email, name: response.data.data.name, profile: response.data.data.picture })
													if (!response.data.data.picture || response.data.data.picture.length == 0) {
														setUserDataForGoogleSignUp({ ...userDataForGoogleSignUp, picture: "https://th.bing.com/th/id/OIP.AdoJAsiWdwMNG0ZTvUoTUQHaHa?pid=ImgDet&rs=1" })
													}
													setShowSection1(false)
												}

											} catch (error) {
												console.error(error);
											}
											// setProvider(provider);
											// setProfile(data);
											// navigate('/');
										}
									}}
									onReject={(err) => {
										console.log(err);
									}}
								>
									{/* Use your Button component here */}
									<Button variant='contained' className='signInWithGoogle'>
										{/* Use your StyledGoogle component here */}
										<StyledGoogle src={Google} alt='google' /> Sign in with Google
									</Button>
								</LoginSocialGoogle>
							</div>
							<LoginSocialFacebook
								appId='620242503521799'
								onResolve={(res) => {
									setProvider(res.provider);
									setProfile(res.data);
									navigate('/');
								}}
								onReject={(err) => {
									console.log(err);
								}}
							>
								<IconButton aria-label='delete' className='signInWithFacebook'>
									<FacebookOutlinedIcon sx={{ color: '#18ACFE' }} />
								</IconButton>
							</LoginSocialFacebook>
						</SocialLogin>

						<StyledLabel htmlFor='userName'>Enter your email address</StyledLabel>
						<StyledInput
							type='text'
							id='userName'
							value={userMail}
							onChange={(e) => setUserMail(e.target.value)}
						/>
						<div
							style={{
								display: signUp ? 'flex' : 'none',
								justifyContent: 'space-between',
							}}
						>
							<div style={{ flex: 1, marginRight: '10px' }}>
								<StyledLabel htmlFor='name'>User Name </StyledLabel>
								<StyledInput
									type='text'
									id='name'
									value={userFullName}
									onChange={(e) => setUserFullName(e.target.value)}
								/>
							</div>
							<div style={{ flex: 1, marginLeft: '10px' }}>
								<StyledLabel htmlFor='contact'>Contact Number</StyledLabel>
								<StyledInput
									type='text'
									id='contact'
									value={userContact}
									onChange={(e) => {
										const input = e.target.value;
										if (input.length <= 10) {
											setUserContact(input);
										}
									}}
								/>
							</div>
						</div>
						<StyledLabel htmlFor='password'>Enter your password</StyledLabel>
						<StyledInput
							type={isShow ? 'text' : 'password'}
							id='password'
							value={userPassword}
							onChange={(e) => setUserPassword(e.target.value)}
						/>
						<CheckBoxDiv>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									marginLeft: '-8px',
								}}
							>
								<Checkbox
									size='small'
									checked={isShow}
									onChange={(e) => setIsShow(e.target.checked)}
								/>
								<ForgotPassword
									onClick={() => setIsShow(!isShow)}
									style={{ marginTop: '4px', color: '#fff' }}
								>
									Show password
								</ForgotPassword>
							</div>
							{!signUp && (
								<ForgotPassword
									style={{ marginTop: '4px' }}
									onClick={handleForgotPasswordClick}
								>
									Forgot Password?
								</ForgotPassword>
							)}
						</CheckBoxDiv>
						<Button
							variant='contained'
							className='signUpButton'
							onClick={signUp ? handleSignUp : handleSignIn}
						>
							{signUp ? ' Sign up' : 'Sign in'}
						</Button>
						<LastDiv>
							<StyledP onClick={handleClick}>
								<span style={{ color: '#8D8D8D' }}>
									{signUp ? 'Have an Account ?' : 'Customer?'}
								</span>{' '}
								{signUp ? 'Sign in' : 'Sign up'}
							</StyledP>
							<StyledP>
								<span>Are you a vendor? </span>
								<a
									href='https://vendor.maangalbazaar.com/login'
									target='_blank'
									rel='noreferrer'
								>
									<StyledButton>Sign In</StyledButton>
								</a>
							</StyledP>
						</LastDiv>

					</section>

					{/* signup mobile number */}
					<section style={{ transform: showSection1 ? 'translateY(100%)' : 'translateY(-250%)', transition: "all 1s linear 0.1s" }}>
						<div style={{ flex: 1, marginRight: '10px', marginTop: "30%" }}>
							<h3 style={{ fontSize: "26px" }}>Enter To Continue .....</h3>
							<StyledLabel htmlFor='MobileNumber'>Mobile Number </StyledLabel>
							<StyledInput
								required
								type='text'
								id='mobilenumber'
								placeholder='Enter Mobile Number'
								value={phoneNumber}
								onChange={(e) => {
									if (e.target.value >= 0 && e.target.value.length <= 10) {
										setPhoneNumber(e.target.value)
									}
								}}
							/>
						</div>
						<Button
							// disabled={true}
							onClick={googleSignUpFunction}
							variant='contained'
							className='signUpButton'

						>
							Sign up
						</Button>
					</section>
				</FormWrapper>
				<Gradient />
				{error && (
					<Snackbar
						open={open}
						autoHideDuration={3000}
						onClose={handleClose}
						anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					>
						<Alert
							onClose={handleClose}
							severity='error'
							sx={{ width: '100%' }}
						>
							{error.message || error}
						</Alert>
					</Snackbar>
				)}
			</LoginPageWrapper>
			<ForgotPasswordModal
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
			/>
		</>
	);
};

export default Login;
