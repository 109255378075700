import React from 'react';
import styled from 'styled-components';

const ChatCardWrapper = styled.div`
  height: 50px;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
  padding: 8px 8px;
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #ffe9c5;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoSpan = styled.span`
  color: #d36b1e;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 41.774px;
`;

const NameDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;
`;

const Name = styled.p`
  margin: 0;
  color: #666;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const DateFormat = styled.p`
  margin: 0;
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const Description = styled.p`
  margin: 0;
  color: #949ab2;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

const DetailsWrapper = styled.div`
  padding-left: 8px;
  width: 100%;
`;

const ChatCard = ({conversationId, conversationData, onChatClick}) => {
	const {
		userMail,
		userName,
		serviceType,
		serviceSlug,
		serviceMail,
		serviceName,
		serviceLastReadMessage,
		userLastReadMessage,
		conversationStatus,
		conversationLastActivityTimestamp
	} = conversationData

	const parsedConversationTimestamp = new Date(conversationLastActivityTimestamp)

	const serviceTypeMap = {
		VENUE: "Venue",
		VENDOR: "Vendor"
	}

	const conversationStatusMap = {
		OPEN: "Active ",
		BOOKED: "Booked ",
		CLOSED: "Conversation closed with "
	}

	return (
		<ChatCardWrapper onClick={onChatClick}>
			<Logo>
				<LogoSpan>{serviceName.toUpperCase().substring(0, 2)}</LogoSpan>
			</Logo>
			<DetailsWrapper>
				<NameDateWrapper>
					<Name>{serviceName}</Name>
					<DateFormat>{parsedConversationTimestamp.toLocaleDateString('en-IN')}</DateFormat>
				</NameDateWrapper>
				<Description>
					{
						conversationStatusMap[conversationStatus]
					}
					{
						serviceTypeMap[serviceType]
					}
				</Description>
			</DetailsWrapper>
		</ChatCardWrapper>
	);
};

export default ChatCard;
