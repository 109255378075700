import React, { useEffect } from 'react';
import styled from 'styled-components';
import Bride from '../../../assets/Bride.svg';
import Sun from '../../../assets/sun.svg';
import { useState } from 'react';
import ReplayIcon from '@mui/icons-material/Replay';
import SignalCellularConnectedNoInternet1BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet1Bar';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { vendors } from './index';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_HOST } from '../../api/url';

const StyledWrapper = styled.div`
  height: 450px;
  background: #173535;
  position: relative;
  @media (max-width: 480px) {
    height: 580px;
  }
`;

const StyledBrideImage = styled.img`
  width: 400px;
  position: absolute;
  bottom: 0;
  z-index: 2;
  /* display: none; */
  right: 174px;
  @media (max-width: 1230px) {
    width: 320px;
    height: 320px;
    right: 100px;
  }
  @media (max-width: 480px) {
    width: 260px;
    height: 240px;
  }
`;

const StyledSunImage = styled.img`
  width: 350px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  @media (max-width: 1350px) {
    width: 290px;
  }
  @media (max-width: 480px) {
    width: 260px;
    height: 240px;
  }
`;

const ImageGradient = styled.div`
  width: 450px;
  z-index: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  background: linear-gradient(130deg, #064646 0%, #091a1a 100%);
  @media (max-width: 1350px) {
    width: 290px;
  }
  @media (max-width: 480px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  padding-top: 4em;
  width: 600px;
  padding-left: 4em;
  display: flex;
  flex-direction: column;


  @media (max-width: 1110px) {
    width: 500px;
    padding-left: 2em;
    align-items: flex-start;
  }
  @media (max-width: 961px) {
    width: 300px;
    padding-left: 2em;
    align-items: flex-start;
  }
  @media (max-width: 480px) {
    width: 90%;
    padding: 20px 0px 0 20px;
    align-items: flex-start;
  }
`;

const Headline = styled.h1`
  font-weight: 700;
  color: rgba(255, 255, 255, 0.99);
  font-family: Cormorant, serif;
  font-size: 52px;
  @media (max-width: 1200px) {
    font-size: 40px;
  }
  @media (max-width: 1100px) {
    font-size: 30px;
  }
  @media (max-width: 480px) {
    margin: 0px 0px 20px 0px;
    font-size: 28px;
  }
`;

const InputWrapper = styled.div`
/* border:2px solid red; */
width:100%;
display: flex;
  @media (max-width: 961px) {
    display: flex;
    gap:10px;
    flex-direction: column;
  }
  @media (max-width: 480px) {
    display: flex;
    gap:0px;
    flex-direction: column;
  }
`;
const Loader = styled.div`
  box-sizing: border-box;
  width:100%;
  border-radius: 4px;
  height:100%;
  z-index: 5;
  backdrop-filter:blur(0.45px) ;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 35px;
`;
const ButtonDiv = styled.div`
box-sizing: border-box;

width:95%;
margin: auto;
display: flex;
justify-content: flex-end;
align-items: center;
gap:9px;






`

const Intro = () => {
  const [activeVendors, SetActiveVendors] = useState(vendors)
  const [loader, setLoader] = useState({ category: false, categoryLoader: false, categoryError: false, city: false, cityLoader: false, cityError: false })
  const [categoryItemSelect, setCategoryItem] = useState('');
  const [city, setCity] = useState('');
  const [cities, setCities] = useState([]);
  const navigate = useNavigate();


  async function handleCityChange(e) {
    setLoader({ category: true, categoryLoader: true, categoryError: false, city: true, cityLoader: false, cityError: false })
    setCity(e.target.value);
    try {
      const response = await axios.get(
        `${API_HOST}/api/v1/meta/service-vendors-basedupon-city/${e.target.value.trim()}`
      );

      const UpdatedVendors = vendors.filter((data, index) => response.data.serviceCities.includes(data.vendorSubcategory));
      SetActiveVendors([...UpdatedVendors])

      if (categoryItemSelect.vendorSubcategory) {
        const checkCategory = response.data.serviceCities.includes(categoryItemSelect.vendorSubcategory)
        if (!checkCategory) {
          setCategoryItem('')
        }
      }
      setLoader({ category: false, categoryLoader: false, categoryError: false, city: false, cityLoader: false, cityError: false })
    } catch (error) {
      console.log(error)
      setLoader({ category: true, categoryLoader: false, categoryError: true, city: false, cityLoader: false, cityError: false })
    }
  };

  function resetAllFunction() {
    setLoader({ category: false, categoryLoader: false, categoryError: false, city: false, cityLoader: false, cityError: false })
    SetActiveVendors(vendors)
    setCity('')
    setCategoryItem('')
  }
  const handleNavigate = () => {
    // console.log(categoryItem, city)

    if (categoryItemSelect && city) {
      // console.log(JSON.parse(categoryItemSelect))
      const category = JSON.parse(categoryItemSelect)
      const categoryItem = {
        category: category.subcategoryText,
        value: category.vendorSubcategory,
        vendorCategory: category.vendorCategory,
      }
      // console.log(categoryItem,"main category")
      if (categoryItem && city) {
        // console.log( categoryItem, city,"check this cate and city")
        if (categoryItem.value === 'VENUES') {
          navigate('/venues', { state: { categoryItem, city } });
        } else {
          navigate('/vendors', { state: { categoryItem, city } });
        }
      }
    }
  };

  const handleCategoryChange = (e) => {

    setLoader({ category: true, categoryLoader: false, city: true, cityLoader: true })
    const vendor = JSON.parse(e.target.value);
    setCategoryItem(e.target.value);
    // console.log(vendor.vendorCategory,vendor.vendorSubcategory)
    fetchUpdatedCities(vendor.vendorCategory, vendor.vendorSubcategory)
  };

  async function fetchUpdatedCities(category, subcategory) {
    try {
      const response = await axios.get(
        `${API_HOST}/api/v1/meta/service-cities/${category}/${subcategory}`
      );
      setCities(response.data.serviceCities);
      if (city.length > 0) {
        const checkCity = response.data.serviceCities.includes(city)
        if (!checkCity) {
          setCity('')
        }
      }
      setLoader({ ...loader, city: false, cityLoader: false, category: false })
    } catch (error) {
      setLoader({ cityLoader: false, category: false, categoryLoader: false, city: true, cityError: true })
    }
  }

  const fetchCities = async () => {
    try {
      setLoader({ ...loader, city: true })
      const response = await axios.get(
        `${API_HOST}/api/v1/meta/service-cities/allcities/allcities`
      );
      setCities(response.data.serviceCities);
      setLoader({ ...loader, city: false })
    } catch (error) {
      setLoader({ ...loader, city: true, cityError: true })
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchCities();
  }, []);

  return (
    <div>
      <StyledWrapper>
        <Wrapper>
          <Headline>
            Plan your love journey with <br></br>us right by your side
          </Headline>
          <InputWrapper className='introInput'>
            <FormControl sx={{ width: "100%", position: "relative" }} className='LandingPageSelect'>
              {
                loader.category ? <Loader onClick={(e) => e.stopPropagation()}>{loader.categoryLoader ? <CircularProgress color="success" style={{ width: "25px", height: "25px" }} /> : null}{loader.categoryError ? <SignalCellularConnectedNoInternet1BarIcon style={{ width: "25px", height: "25px", color: 'skyblue' }} /> : null}</Loader  > : null
              }
              <InputLabel id='category-select-label'>Category</InputLabel>
              <Select
                MenuProps={{
                  PaperProps: {
                    style: { maxHeight: '280px' }
                  },
                }}
                // disabled={true}
                labelId='category-select-label'
                id='category-select'
                value={categoryItemSelect}
                label='Category'
                onChange={handleCategoryChange}
              >
                {activeVendors.map((vendor, index) => {
                  return (
                    <MenuItem
                      style={{ fontWeight: "500", fontSize: "17px", color: "black" }}
                      key={`category-${vendor.subcategoryText}`}
                      value={JSON.stringify(vendor)}
                    >
                      {vendor.subcategoryText}
                    </MenuItem>
                  );
                })}
                {
                  activeVendors.length == 0 ? <MenuItem disabled value="">
                    <em style={{ fontWeight: "500", fontSize: "17px", color: "black" }}>No Such Category</em>
                  </MenuItem> : null
                }

              </Select>
            </FormControl>

            <FormControl sx={{ width: "100%", position: "relative" }} className='LandingPageSelect'>
              {
                loader.city ? <Loader onClick={(e) => e.stopPropagation()}>{loader.cityLoader ? <CircularProgress color="success" style={{ width: "25px", height: "25px" }} /> : null}{loader.cityError ? <SignalCellularConnectedNoInternet1BarIcon style={{ width: "25px", height: "25px", color: 'skyblue' }} /> : null}</Loader> : null

              }
              <InputLabel id='city-select-label'>City</InputLabel>
              <Select
                MenuProps={{
                  PaperProps: {
                    style: { maxHeight: '280px' }
                  },
                }}
                labelId='city-select-label'
                id='city-select'
                // disabled={true}
                value={city}
                label='City'
                onChange={handleCityChange}
              >
                {cities.map((item, index) => {
                  return (
                    <MenuItem
                      style={{ fontWeight: "500", fontSize: "16px", color: "black" }}
                      key={`city-${item}-${index}`} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
                {
                  cities.length == 0 ? <MenuItem disabled value="">
                    <em style={{ fontWeight: "500", fontSize: "16px", color: "black" }}>No Available City</em>
                  </MenuItem> : null
                }
              </Select>
            </FormControl>
          </InputWrapper>
          <ButtonDiv>
            {
              city && categoryItemSelect ? <ReplayIcon onClick={resetAllFunction} style={{ cursor: "pointer", width: "25px", height: "25px", color: 'skyblue', marginTop: "10px" }} />
                : null
            }
            <Button
              className='buttonIntro'
              variant='contained'
              onClick={handleNavigate}
            >
              View results
            </Button>
          </ButtonDiv>
        </Wrapper>
        <StyledBrideImage src={Bride} alt='bride' />
        <StyledSunImage src={Sun} alt='Sun' />
        <ImageGradient />
      </StyledWrapper>
    </div>
  );
};

export default Intro;
