import React from "react";
import styled from "styled-components";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import PinterestIcon from '@mui/icons-material/Pinterest';
import { INSTAGRAM_LINK, PINTEREST_LINK } from "../api/url"
import { FACEBOOK_LINK } from "../api/url"
const StyledFooter = styled.div`
  padding-right:0.5px;
  box-sizing: border-box;
  height: 300px;
  width: 100%;
  background: #13362d;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  margin-top: 4rem;
  /* padding-right: 5px; */
  @media (max-width: 480px) {
    height: 600px;
    flex-direction: column;
  }
`;

const CompanySection = styled.div`
  
max-width: 270px;
  margin-right: 1.5rem;
  height:fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  @media (max-width: 900px) {
    margin-right: 0;
  }

  @media (max-width: 480px) {
    margin-right: 0;
    justify-content: flex-start;
    padding-top: 24px;
  }
`;

const StyledHeading = styled.h1`
/* border:2px solid red; */
font-size: 22px;
  font-family: Inter, sans-serif;
  margin: 12px 12px 2px 0px;
  cursor: pointer;
  color: #fff;
`;

const StyledDescription = styled.p`
  text-align: center;
  color: #cbcbcb;
  font-family: Hanken Grotesk;
  @media (max-width: 480px) {
    font-size: 12px;
    margin: 4px 0px 20px 0px;
  }
`;

const SectionWrapper = styled.div`
  margin-left: 3rem;
  display: inline-flex;
  width: 100%;
  justify-content: space-around;
  @media (max-width: 980px) {
    margin-left: 1rem;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    margin-left: 0;
    align-items: center;
    width: 100%;
  }
`;

const SectionHeading = styled.p`
  color: #ececec;
  font-size: 16px;
  cursor: pointer;
  
`;
const SectionHeadingAddress = styled.p`
color: #ececec;
max-width: 180px;
  font-size: 12px;
  cursor: pointer;
  /* word-break: break-all; */
  @media (min-width:800px) {
    max-width: 400px;
  }

`

const SectionContent = styled.p`
  color: #d3d4d4;
  font-size: 12px;
  cursor: pointer;
`;

const BottomSection = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content:flex-end;
  background-color: #1a3f35;
  @media (max-width: 480px) {
    height: 80px;
    flex-direction: column;
    justify-content: space-evenly;
  }
`;

const StyledTerms = styled.p`
  cursor: pointer;
  color: #c9cfd4;
  font-size: 14px;
  font-family: Hanken Grotesk;
  margin: 0px 100px;

`;

const IconStyles = {
  color: "#E4E4E4",
  marginRight: "12px",
  cursor: "pointer",
};

const Section = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
`;

const MobileScreenDiv = styled.div`
  display: none;
  @media (max-width: 480px) {
    display: flex;
    width: 100%;
    margin: 20px 20px 20px 20px;
    justify-content: space-around;
  }
`;

const Footer = () => {
  return (
    <>
      <StyledFooter>
        <SectionWrapper>
          <CompanySection>
            <StyledHeading>MAANGAL BAZAAR</StyledHeading>
            <StyledDescription>
              Transforming Visions into Unforgettable Celebrations
            </StyledDescription>
            <div>
              <FacebookRoundedIcon onClick={() => window.open(
                `${FACEBOOK_LINK}`,
                "_blank"
              )} sx={IconStyles} />
              <TwitterIcon sx={IconStyles} />
              <LinkedInIcon sx={IconStyles} />
              <InstagramIcon onClick={() => window.open(
                `${INSTAGRAM_LINK}`,
                "_blank"
              )} sx={IconStyles} />
              <PinterestIcon onClick={() => window.open(
                `${PINTEREST_LINK}`,
                "_blank"
              )} sx={IconStyles} />
            </div>
          </CompanySection>
          <Section>
            <SectionHeading>Consumer Policies</SectionHeading>
            <SectionContent>Testimonials</SectionContent>
            <SectionContent>Feedback</SectionContent>
            <SectionContent>DMCA Removal Request</SectionContent>
            <SectionContent>Fees and Pricing</SectionContent>
          </Section>
          <Section>
            <SectionHeading>Resources</SectionHeading>
            <SectionContent>Press & Media</SectionContent>
            <SectionContent>Internship Opportunities</SectionContent>
            <SectionContent>Advertise with Us</SectionContent>
            <SectionContent>Become Our Agent</SectionContent>
            <SectionContent>Franchise Opportunities</SectionContent>
          </Section>
          <Section>
            <SectionHeading>Company</SectionHeading>
            <SectionContent>About Us</SectionContent>
            <SectionContent>Careers</SectionContent>
            <SectionContent>Privacy Policy</SectionContent>
            <SectionContent>Sitemap</SectionContent>
            <SectionContent>Terms & Conditions</SectionContent>
          </Section>
          <Section>
            <SectionHeading>Contact Us</SectionHeading>
            <SectionHeadingAddress>10-B, Mothorowala Rd, near Rawat Wedding Point and, opp. PNB Bank, Anand Vihar, Ajabpur Kalan, Dehradun, Uttarakhand 248001</SectionHeadingAddress>
            <SectionContent>info@maangalbazaar.com</SectionContent>
            <SectionContent>8266954961/962/963</SectionContent>
          </Section>
        </SectionWrapper>
        <MobileScreenDiv>
          <div>
            <div>
              <SectionHeading>Resources</SectionHeading>
              <SectionContent>Press & Media</SectionContent>
              <SectionContent>Internship Opportunities</SectionContent>
              <SectionContent>Advertise with Us</SectionContent>
              <SectionContent>Become Our Agent</SectionContent>
              <SectionContent>Franchise Opportunities</SectionContent>
            </div>

            <div style={{ paddingTop: "0px" }}>
              <SectionHeading>Company</SectionHeading>
              <SectionContent>About Us</SectionContent>
              <SectionContent>Careers</SectionContent>
              <SectionContent>Privacy Policy</SectionContent>
              <SectionContent>Sitemap</SectionContent>
              <SectionContent>Terms & Conditions</SectionContent>
            </div>
          </div>
          <div>
            <div>
              <SectionHeading>Consumer Policies</SectionHeading>
              <SectionContent>Testimonials</SectionContent>
              <SectionContent>Feedback/Complaints</SectionContent>
              <SectionContent>DMCA Removal Request</SectionContent>
              <SectionContent>Fees and Pricing</SectionContent>
            </div>
            <div style={{ paddingTop: "12px" }}>
              <SectionHeading>Contact Us</SectionHeading>
              <SectionHeadingAddress>10-B, Mothorowala Rd, near Rawat Wedding Point and, opp. PNB Bank, Anand Vihar, Ajabpur Kalan, Dehradun, Uttarakhand 248001</SectionHeadingAddress>
              <SectionContent>info@maangalbazaar.com</SectionContent>
              <SectionContent>8266954961/962/963</SectionContent>
            </div>
          </div>
        </MobileScreenDiv>
      </StyledFooter>
      <BottomSection>
        {/* <StyledTerms>Terms and conditions</StyledTerms> */}
        <StyledTerms>©{new Date(Date.now()).getFullYear()} - Maangal Bazaar | All right reserved</StyledTerms>
      </BottomSection>
    </>
  );
};

export default Footer;
