import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './Layout';
import { LoginProvider } from './context/LoginContext';
import { Provider } from 'react-redux';
import {store} from './redux/store';
import { HelmetProvider } from 'react-helmet-async';
function App() {
  return (
    <HelmetProvider>
    <Router>
      <LoginProvider>
        <div className='App'>
          <Provider store={store}>
            <Layout />
          </Provider>
        </div>
      </LoginProvider>
    </Router>
     </HelmetProvider>
  );
}

export default App;
