import React from "react";
import styled from "styled-components";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useEffect } from "react";
import axios from "axios";
import { API_HOST } from "../../api/url";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const StyledHeading = styled.h1`
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  @media (max-width: 480px) {
    font-size: 26px;
  }
`;

const StyledDetails = styled.p`
  color: #fff;
  font-family: "Outfit", sans-serif;
  font-size: 14px;
  margin-top: 12px;
  padding-right: 16px;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const StyledLink = styled.a`
  background-color: transparent;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  font-family: "Outfit", sans-serif;
  cursor: pointer;
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const DetailsWrapper = styled.div`
  padding-left: 26px;
`;

const MainWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  padding: 10px;
  width: 300px;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(1.8143597841262817px);
  @media (max-width: 480px) {
    width: 256.913px;
    height: 130.634px;
  }
`;

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 0px;
`;

const Card = React.memo(function (props) {
  const [venue, setVenue] = useState([]);
  const { data, dataIndex, venueData, allVenueData } = props;
  const navigate = useNavigate();
  const cover = allVenueData[dataIndex]?.venueCoverImageURL;

  return (
    <div
      style={{
        width: "852px",
        height: "400px",
        userSelect: "none",
        position: "relative",
      }}
      className="my-slide-component"
    >
      <StyledImage alt="" draggable={false} src={cover} />
      <MainWrapper>
        <DetailsWrapper>
          <StyledHeading>{venueData?.venueName}</StyledHeading>
          <StyledDetails>{venueData?.venueDescription}</StyledDetails>
          {/* <StyledLink href={venueData?.venueLink} target='_blank'> */}
          <StyledLink onClick={()=> window.open(
                                `${venueData?.venueLink?venueData.venueLink:"https://maangalbazaar.com/"}`,
                                "_blank"
                              )}>
            View More <ArrowRightAltIcon sx={{cursor:"pointer", marginLeft: "4px" }} />
          </StyledLink>
        </DetailsWrapper>
      </MainWrapper>
    </div>
  );
});

export default Card;
