import React, { useState } from 'react';
import styled from 'styled-components';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Button } from '@mui/material';
import { useEffect } from 'react';
import axios from 'axios';
import { API_HOST } from '../../api/url';
import { useNavigate, useNavigation } from 'react-router-dom';



const StyledHeading = styled.h1`
 font-weight: 700;
  font-size: 32px;
  font-family: Outfit, sans-serif;
  text-align: center;
 @media(max-width:480px) {
  font-size: 25px;
    margin: 12px;
   
}
`;

const SubTitle = styled.p`
  font-weight: 600;
  font-size: 25px;
  /* text-decoration: underline; */
  font-family: 'Outfit', sans-serif;
  color: #173535;
    text-align: center;
    margin: 5px 0px 0px 0px;
     @media(max-width:480px) {
  font-size: 19px;
  
}
  
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  font-family: 'Outfit', sans-serif;
  line-height: 30px;
  width: 324px;
@media (max-width:480px) {
  font-size: 14px;
  width: 90%;
  margin: auto auto 12px auto;
}

    text-align: center;
  
`;

const StyledImage = styled.div`
z-index: 99;
box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  object-fit: cover;
 &:hover {
   transform: scale(0.98);
  }
  &:active {
   transform: scale(0.95);
  }
    position:absolute;
    left:${(prop) => prop.index == 0 ? "10%" : prop.index == 1 ? "10%" : null};
    top:${(prop) => prop.index == 0 ? "60px" : prop.index == 1 ? "400px" : null};
    right:${(prop) => prop.index == 2 ? "10%" : prop.index == 3 ? "10%" : null};
    top:${(prop) => prop.index == 2 ? "60px" : prop.index == 3 ? "400px" : null};
width:250px;
    @media (max-width:1265px){
  
      left:${(prop) => prop.index == 0 ? "2%" : prop.index == 1 ? "2%" : null};
      right:${(prop) => prop.index == 2 ? "2%" : prop.index == 3 ? "2%" : null};
  }
  @media (max-width:970px){
    min-width: 105px;
  max-width: 105px;
 margin: 5px;
    position: relative;
    top:0;
    left:0;
    right:0;
     
  }
  @media (max-width:480px){
    min-width: 100px;
  max-width: 100px;
    object-fit: cover;
  }
`;
const ImageImage = styled.img`
width:180px;
flex-shrink: 0;
height: 180px;
border-radius: 150px;
@media (max-width:970px){
    min-width: 105px;
  max-width: 105px;
  height: 105px;  
  }
@media (max-width:480px){
    min-width: 100px;
  max-width: 100px;
    height: 100px;
    object-fit: cover;
  }
`;
const ImageHeading = styled.h6`
 font-weight: 600;
  font-size: 17px;
  font-family: 'Outfit', sans-serif;
  color: #173535;
    text-align: center;
    margin: 0;
    margin-top: 10px;
`

const DetailsWrapper = styled.div`
  width: 100%;
  background: #feeecc;
padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width:480px) {
    justify-content: flex-start;
  }
   
`;

const ImagesWrapper = styled.div`

  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding:0px 0px 40px 0px;
    flex-direction: column;
    /* margin-top: 140px; */
    /* background: #feeecc; */
    background: linear-gradient(0deg, #feeecc 89%, transparent 0%); 
`;

const StyledImagesWrapper = styled.div`
padding :5px 5px;
/* border:5px solid red; */
    display: flex;  
    width: 100%;
    margin: auto;
    margin-top: 12px;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    gap:20px;
    @media (max-width:480px){
      gap:8px;
    }
`;

const MainImage = styled.img`
  min-width: 570px;
  max-width: 570px;
  cursor: pointer;
  object-fit: cover;
    min-height: 570px;
    max-height: 570px;
    margin: auto;
    border-radius: 8px;
    @media (max-width:480px) {
      min-width:95%;
      max-width:95%;
      min-height:420px;
    max-height: 420px;
      
    }
  box-shadow: 1px 1px 5px black;
  box-shadow: -1px -1px 5px black , 1px 1px 2px black;
    
  
`;

const StyledLink = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: #323540;
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
 
    margin: 0px 0 20px 0px;
  
`;


const MobileScreenCatalog = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    height:auto ;
`;

const ImageCatalog = ({ onVendorTagClick, clickedVendorTag }) => {
  const [mainImage, setMainImage] = useState({});
  const [images, setImages] = useState([]);
  const [topVendorCategories, setTopVendorCategories] = useState([]);
  const navigate = useNavigate();

  const getTopVendorCategories = async () => {
    try {
      const response = await axios.get(
        `${API_HOST}/api/v1/meta/top-vendor-categories`
      );
      // console.log(response.data.topCategories, "top categories")
      setTopVendorCategories(response.data.topCategories);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTopVendorCategories();
  }, []);

  useEffect(() => {
    if (topVendorCategories.length > 0) {
      const newMainImage = {
        src: topVendorCategories[0].categoryCoverImageURL,
        subtitle: topVendorCategories[0].categoryName,
        description: topVendorCategories[0].categoryDescription,

        value: topVendorCategories[0].categoryCanonicalName,
      };
      setMainImage(newMainImage);

      const newImages = topVendorCategories.slice(1).map((category) => ({
        src: category.categoryCoverImageURL,
        subtitle: category.categoryName,
        description: category.categoryDescription,

        value: category.categoryCanonicalName,
      }));
      setImages(newImages);
    }
  }, [topVendorCategories]);

  // useEffect(() => {
  //   console.log(mainImage);
  // }, [mainImage]);

  const handleDiscoverClick = () => {
    onVendorTagClick(
      mainImage.subtitle === 'Venues' ? 'Wedding Venues' : mainImage.subtitle
    );
    if (mainImage.value === 'VENUES') {
      navigate('/venues', {
        state: { category: 'VENUES', categoryItem: { value: 'VENUES' } },
      });
    } else {
      console.log(mainImage)
      onVendorTagClick(mainImage.value)
      const coded=btoa(mainImage.value)
      navigate(`/vendors/${coded}`, {
        state: {
          categoryItem: {
            vendorCategory: mainImage.value,
          },
        },
      });
    }
    window.scrollTo(0, 0);
  };

  const handleImageClick = (image) => {
    onVendorTagClick(image.subtitle)
    const coded=btoa(image.value)
    navigate(`/vendors/${coded}`, {
      state: {
        categoryItem: {
          vendorCategory: image.value,
        },
      },
    });
    window.scrollTo(0, 0);
  };

  return (
    <>
      <MobileScreenCatalog>
        <StyledHeading>Top Wedding Vendor Categories</StyledHeading>
        <ImagesWrapper>
          <MainImage onClick={handleDiscoverClick} src={mainImage.src} alt={mainImage.subtitle} />
          <SubTitle>{mainImage.subtitle}</SubTitle>
          <StyledImagesWrapper>
            {images.map((image, index) => (
              <StyledImage index={index} key={index}>
                <ImageImage
                  src={image.src}
                  alt={`image ${index}`}
                  onClick={() => handleImageClick(image)}
                />
                <ImageHeading>{image.subtitle}</ImageHeading>
              </StyledImage>
            ))}

          </StyledImagesWrapper>





          <DetailsWrapper>
            {/* <div> */}

            {/* <Description>{mainImage.description}</Description>
            </div>
            <StyledLink onClick={handleDiscoverClick}>
              Discover More{' '}
              <ArrowRightAltIcon sx={{ color: '#FAAA00', marginLeft: '4px' }} />
            </StyledLink> */}
            <Button
              variant='contained'
              className='mobileImageCatalogButton'
              onClick={() => {
                navigate('/vendors/categories');
                window.scrollTo(0, 0);
              }}
            >
              View All
              <ArrowRightAltIcon sx={{ color: '#173535', marginLeft: '4px' }} />
            </Button>
          </DetailsWrapper>
        </ImagesWrapper>

      </MobileScreenCatalog>
    </>
  );
};

export default ImageCatalog;
