import React from "react";
import styled from "styled-components";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Button } from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useState } from "react";
import EditModal from "./EditModal";
import { useLogin } from "../../context/LoginContext";
import axios from "axios";
import { API_HOST } from "../api/url";
import { Alert, Snackbar } from "@mui/material";
import { useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';

const ProfileWrapper = styled.div`
  margin: 1em 2em;
  height: 420px;
  @media (max-width: 480px) {
    margin: 12px;
  }
`;

const ProfileDiv = styled.div`
  height: 100px;
  padding: 30px 22px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 480px) {
    padding: 20px;
    height: 136px;
  }
`;

const StyledImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius:100px;
  margin-right: 32px;
  box-shadow: 0px 0px 3px black;
  @media (max-width: 480px) {
    margin-right: 16px;
    width: 136px;
    height: 136px;
  }
`;

const UserName = styled.h1`
  color: #1b1d27;
  font-family: Outfit;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  @media (max-width: 480px) {
    font-size: 22px;
  }
`;

const ContactText = styled.p`
  margin: 0;
  color: #1e1d2b;
  font-family: Hanken Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 480px) {
    font-size: 12px;
    margin-bottom: 6px;
  }
`;

const Contact = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  @media (max-width: 480px) {
    align-items: flex-start;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  @media (max-width: 480px) {
    flex-direction: column;
    margin-top: 16px;
  }
`;

const iconStyles = {
  fontSize: "inherit",
  marginRight: "4px",
  color: "#8181A5",
};

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  @media (max-width: 480px) {
    width: 100%;
    justify-content: space-evenly;
    align-items: flex-start;
  }
`;
// interface UserProfile{
//   userPhotoURL: string;
//   userFullName:string;
//   userMail:string;
//   userContact:string;
//   // other properties...
// };
const Profile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loader,setLoader]=useState(false)


  const handleEditClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  

  const fetchProfile = async () => {
    setLoader(true)
    try {
      
      const response = await axios.get(`${API_HOST}/api/v1/profile`);
      // console.log(response.data.userProfile)
      setProfileData(response.data.userProfile);
    } catch (err) {
      console.error(err);
    }finally{
      setLoader(false)
    }
  };

  useEffect(() => {
    setLoader(true)
    fetchProfile();
  }, []);

  return (
   
    <ProfileWrapper>
      {
         loader?<div style={{width:"80vw",textAlign:"center"}}><CircularProgress color="success" /></div> :<div>
        <ProfileDiv>
          <LeftSection>
            <StyledImage
              src={
               profileData&&profileData.hasOwnProperty("userPhotoURL")&&profileData.userPhotoURL
                  ? profileData.userPhotoURL
                  : "https://th.bing.com/th/id/OIP.AdoJAsiWdwMNG0ZTvUoTUQHaHa?pid=ImgDet&rs=1"
              }
              alt="user"
            />
            <div>
              <UserName>
                {profileData.userFullName?profileData.userFullName:""}
              </UserName>
              <ContactWrapper>
                <Contact>
                  <EmailIcon sx={iconStyles} />
                  <ContactText>
                    { profileData.userMail?profileData.userMail:""}
                  </ContactText>
                </Contact>
                <Contact>
                  <LocalPhoneIcon sx={iconStyles} />
                  <ContactText>
                    { `+91 ${profileData.userContact?profileData.userContact:""}`}
                  </ContactText>
                </Contact>
              </ContactWrapper>
              <Button
                variant="contained"
                className="profileEditButton mobileButton"
                onClick={handleEditClick}
              >
                <DriveFileRenameOutlineIcon
                  sx={{ fontSize: "18px", marginRight: "4px" }}
                />
                Edita
              </Button>
            </div>
          </LeftSection>
          <Button
            variant="contained"
            className="profileEditButton"
            onClick={handleEditClick}
          >
            <DriveFileRenameOutlineIcon
              sx={{ fontSize: "18px", marginRight: "4px" }}
            />
            Edit
          </Button>
        </ProfileDiv>
      </div>
      }
      
      <EditModal
        setSeverity={setSeverity}
        setOpen={setOpen}
        setMessage={setMessage}
        isOpen={isModalOpen}
        handleEditClick={handleEditClick}
        fetchProfileData={fetchProfile}
      />
      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </ProfileWrapper>
  );
};

export default Profile;
