import React, { useState, useRef, useEffect } from "react";
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import styled from 'styled-components';
import right from "../../assets/arrows/curved-arrow-right.png";
import left from "../../assets/arrows/curved-arrow-left.png";
import flipSound from "../../assets/arrows/flipSound.mp3";
import endSound from "../../assets/arrows/end.mp3"
import fitness from "./fitness.pdf"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const MainDiv = styled.div`
  padding-top:10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  box-sizing: border-box;
  overflow: hidden;
  width: 100vw;
  min-height: 100vh;
  /* gap:25px; */
  flex-direction: column;
`;
const MagazineDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  padding-bottom: 20px;
  width: 100vw;
`

const DocumentWrapper = styled.div`
  width: 880px;
  padding: 0;
  margin: 0;
  box-shadow: 2px 1px 20px black;
  display: flex;
  background: #13362d;
  height: 578px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 10px;
  margin-top: 40px;
`;
const DocumentWrapper2 = styled.div`
  width: 660px;
  padding: 0;
  margin: 0;
  box-shadow: 2px 1px 12px black;
  display: flex;
  background: #13362d;
  height: 432px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
  margin-top: 40px;
`;

const DocumentWrapper3 = styled.div`
  width: 500px;
  padding: 0;
  margin: 0;
  box-shadow: 2px 1px 12px black;
  display: flex;
  background: #13362d;
  height: 328px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
  margin-top: 40px;
`;

const DocumentWrapper4 = styled.div`
  width: 309px;
  padding: 0;
  margin: 0;
  box-shadow: 2px 1px 12px black;
  display: flex;
  background: #13362d;
  height: 404px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 40px;
`;
const ImageButton = styled.img`
width: 50px;
height: 50px;
margin:0px 12px 0px 3px;
cursor: pointer;
align-self: flex-end;
&:active {
  transform: scale(0.95);
  }
  @media (max-width:1048px) {
    width: 43px;
height: 43px;
margin:0px 12px 0px 3px;
  }
  @media (max-width:785px) {
    width: 36px;
height: 36px;
margin:0px 12px 0px 10px;
  }
`;
const HeadingText=styled.h1`
 color: #13362d;
 text-align: center;
`

const PdfDoublePage = React.forwardRef(({ pageNumber, widthLength }, ref) => {
  return (
    <div className="double-page" ref={ref}>
      <ReactPdfPage renderAnnotationLayer={false} renderTextLayer={false} pageNumber={pageNumber} width={widthLength} />
    </div>
  );
});

const Magazine = () => {
  const [showButton, setShowButton] = useState(false)
  const flipBookRef = useRef(null);
  const [totalPages, setTotalPages] = useState(0)
  const [array, setArray] = useState([])
  const [showMagazine, setShowMagazine] = useState([true, false, false, false])



  const handleNextPage = () => {
    if (flipBookRef.current.pageFlip().pages.currentPageIndex !== 99) {
      const audio = new Audio(flipSound);
      audio.play();
    } else {
      const audio = new Audio(endSound);
      audio.play();
    }
    flipBookRef.current.pageFlip().flipNext();
  };

  const handlePrevPage = () => {
    if (flipBookRef.current.pageFlip().pages.currentPageIndex !== 0) {
      const audio = new Audio(flipSound);
      audio.play();
    } else {
      const audio = new Audio(endSound);
      audio.play();
    }
    flipBookRef.current.pageFlip().flipPrev()
  };

  const handlePdfLoadSuccess = (pdf) => {
    setShowButton(true)
    setTotalPages(pdf.numPages);
    const newArray = new Array(pdf.numPages).fill(1)
    setArray(newArray)
  };

  function handleResize() {
    if (window.innerWidth >= 1048)
      setShowMagazine([true, false, false, false])
    else if (window.innerWidth >= 785 && window.innerWidth < 1048) {
      if(showButton)
      setShowButton(false)
      setShowMagazine([false, true, false, false])
    } else if (window.innerWidth >= 634 && window.innerWidth < 785) {
      if(showButton)
      setShowButton(false)
      setShowMagazine([false, false, true, false])
    } else if (window.innerWidth >= 0 && window.innerWidth < 634) {
      if(showButton)
      setShowButton(false)
      setShowMagazine([false, false, false, true])
    }

  }


  useEffect(() => {
    window.addEventListener('resize', handleResize);
    if (window.innerWidth >= 1048)
      setShowMagazine([true, false, false, false])
    else if (window.innerWidth >= 785 && window.innerWidth < 1048) {
      setShowMagazine([false, true, false, false])
    } else if (window.innerWidth >= 634 && window.innerWidth < 785) {
      setShowMagazine([false, false, true, false])
    } else if (window.innerWidth >= 0 && window.innerWidth < 634) {
      setShowMagazine([false, false, false, true])
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])



  
  return (
    <MainDiv>
      <div>
        
      </div>
      {/* <HeadingText>Get Ready!</HeadingText>
      <HeadingText>Our Latest Magazines Will Be Available Shortly</HeadingText>
      <Lottie style={{ width: 300, height: 400 }} animationData={commingsoon} loop={true} />; */}
      <MagazineDiv>
        {showButton ? < ImageButton onClick={handlePrevPage} src={left} alt="MB" /> : null}
        {showMagazine[0] ? <Document file={fitness} onLoadSuccess={handlePdfLoadSuccess} >
          <DocumentWrapper>
            <HTMLFlipBook
              ref={flipBookRef}
              width={440}
              height={575}
              size="stretch"
              showCover={true}
              maxShadowOpacity={0.5}
              usePortrait={false}
              drawShadow={false}
              mobileScrollSupport={true}
            // onFlip={() => playFlipSound()} 
            >
              {
                array.map((data, index) => {
                  return <PdfDoublePage pageNumber={index + 1} widthLength={440} />
                })
              }
            </HTMLFlipBook>
          </DocumentWrapper>
        </Document> : null}




        {showMagazine[1] ? <Document file={fitness} onLoadSuccess={handlePdfLoadSuccess} >
          <DocumentWrapper2>
            <HTMLFlipBook
              ref={flipBookRef}
              width={330}
              height={432}
              size="stretch"
              showCover={true}
              maxShadowOpacity={0.5}
              usePortrait={false}
              drawShadow={false}
              mobileScrollSupport={true}
            // onFlip={() => playFlipSound()} 
            >
              {
                array.map((data, index) => {
                  return <PdfDoublePage pageNumber={index + 1} widthLength={330} />
                })
              }
            </HTMLFlipBook>
          </DocumentWrapper2>
        </Document> : null}



        {showMagazine[2] ? <Document file={fitness} onLoadSuccess={handlePdfLoadSuccess} >
          <DocumentWrapper3>
            <HTMLFlipBook
              ref={flipBookRef}
              width={250}
              height={330}
              size="stretch"
              showCover={true}
              maxShadowOpacity={0.5}
              usePortrait={false}
              drawShadow={false}
            >
              {
                array.map((data, index) => {
                  return <PdfDoublePage pageNumber={index + 1} widthLength={250} />
                })
              }
            </HTMLFlipBook>
          </DocumentWrapper3>
        </Document> : null}




        {showMagazine[3] ? <Document file={fitness} onLoadSuccess={handlePdfLoadSuccess} >
          <DocumentWrapper4>
            <HTMLFlipBook
              ref={flipBookRef}
              width={310}
              height={450}
              showCover={true}
              maxShadowOpacity={0.5}
              usePortrait={true}
              drawShadow={false}
            >
              {
                array.map((data, index) => {
                  return <PdfDoublePage pageNumber={index + 1} widthLength={310} />
                })
              }
            </HTMLFlipBook>
          </DocumentWrapper4>
        </Document> : null}

        {showButton ? < ImageButton onClick={handleNextPage} src={right} alt="MB" /> : null}
      </MagazineDiv>

    </MainDiv>
  );
};

export default Magazine;
