import React, { useEffect, useState } from "react";
import Heading, { StyledH1 } from "./Heading";
import styled from "styled-components";
import CategoryItems from "./CategoryItems";
import CategoryCard from "./CategoryCard";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { items } from "./items";
import axios from "axios";
import { API_HOST } from "../../api/url";
import SkeletonCard from "./SkeletonCard";
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from "react-router-dom";
import { landingPageVendorCategories } from "../../Product-Page/Vendors/vendorData";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const StyledWrapper = styled.div`
/* border:6px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 2rem; */
  padding: 0px 4rem;
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const VerticalGrid = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
`;

const HorizontalGrid = styled.div`
  width: 100%;
  overflow: auto;
  display: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;

const NoDataDiv = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 480px) {
    height: 400px;
  }
`;

const CardSection = styled.div`
width: 100%;
min-height: 50vh;
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
`
const SideViewAllCatgories = styled.h6`
color: #13362d;
font-size: 16px;
font-weight: 700;
margin: 0;
margin-right: 10.5%;
align-self: flex-end;
@media (max-width: 1034px) {
  margin-right: 5.5%;
  }
@media (max-width: 480px) {
    font-size: 14px;
  }
  cursor: pointer;

  &:active{
    transform: scale(0.96);
    color: #0E6655 ;
    transition: all 0.5s linear;
  }
`
const SubCardSection = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: flex-start;
flex-wrap: wrap;
padding: 0;
gap: 20px;
margin: 0;
padding-top: 15px;
`


const CategoryBox = styled.div`
width: 500px;
min-height:150px ;
background: white;
box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 3px 0px;
border-radius: 5px;
/* border:0.2px solid gray; */
@media (max-width: 1034px) {
  width: 70%;
  }

  @media (max-width: 750px) {
  width: 90%;
  }

  @media (max-width: 540px) {
  width: 90%;
  }
`
const CardSection1 = styled.div`
height:150px ;
width: 100%;
padding: 25px;
position: relative;
box-sizing: border-box;
overflow: hidden;
@media (max-width: 811px) {
  padding:20px 15px;
  }
  cursor: pointer;
`;
const CardSection2 = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
flex-wrap: wrap;
gap:20px;
background: white;
position:relative;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
overflow: hidden;
/* margin-top: ${(props) => props.status ? "2px" : "0px"}; */
height: ${(props) => props.status ? "auto" : "0px"};
opacity: ${(props) => props.status ? 100 : 0};
transition: all 0.3s ease-in-out;
`;

const CardSection22 = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
flex-wrap: wrap;
gap:20px;
background: white;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
overflow: hidden;
padding: 25px;
transition: all 0.3s ease-in-out;
`;

const HeadingSection2 = styled.h6`
margin: 0;
cursor: pointer;
padding: 0;
font-size: 15px;
color: #13362d;
display: flex;
justify-content: flex-start;
align-items: center;
position: relative;
z-index: 9;
&:active{
    transform: scale(0.96);
    color:gray;
    transition: all 0.5s linear;
  }
`;

const HeadingCard = styled.h5`
margin: 0;
padding: 0;
font-size: 18px;
color: #13362d;
display: flex;
justify-content: flex-start;
align-items: center;
position: relative;
z-index: 9;
`
const DesCard = styled.h6`
margin: 0;
padding: 0;
font-size: 14px;
font-weight: 600;
color:#0E6655;
display: flex;
justify-content: flex-start;
align-items: flex-end;
position: relative;
z-index: 9;
@media (max-width: 811px) {
  width: 250px;
  }
  @media (max-width: 630px) {
  width:180px;
  }
  @media (max-width: 420px) {
  color: black;
  width:150px;
  }
`;

const CardImage = styled.img`
position: absolute;
top: 0px;
right: -25px;
transform:scale(1.2);
border-radius:50%;
object-fit: cover;
width: 160px;
min-height: 150px;
max-height: 150px;
z-index: 0;
/* object-position: top left; */
@media (max-width: 811px) {
  width: 150px;
  }

  @media (max-width: 660px) {
    width: 130px;
  }
`

const TriangleBox = styled.div`
width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 15px solid white;
      position: absolute;
      bottom: -3px;
      left:5%;
      z-index: 99;


`;

const Categories = ({ onVendorTagClick, clickedVendorTag }) => {
  // const [selected, setSelected] = useState("MAKEUP");
  // const [vendorData, setVendorData] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const [showBottom, setShowBottom] = useState([false, false, false, false, false, false, false])
  const navigate = useNavigate();
  // useEffect(() => {
  //   console.log(selected);
  // }, [selected]);

  // const fetchVendorData = async () => {
  //   setIsLoading(true);
  //   if (selected !== "VENUES") {
  //     const requestBody = {
  //       vendorCategory: selected,
  //     };
  //     try {
  //       const response = await axios.post(
  //         `${API_HOST}/api/v1/vendors/search?sortBy=RELEVANCE`,
  //         requestBody
  //       );
  //       setVendorData(response.data.searchResults.slice(0,8));
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //     try {
  //       const response = await axios.post(
  //         `${API_HOST}/api/v1/venues/search?sortBy=RELEVANCE`
  //       );
  //       setVendorData(response.data.searchResults.slice(0,8));
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   setIsLoading(false);
  // };

  // useEffect(() => {
  //   fetchVendorData();
  // }, [selected]);

  // const handleViewAllClick = () => {
  //   const selectedItem = items.find((item) => item.value === selected);
  //   if (selectedItem) {
  //     onVendorTagClick(selectedItem.category);
  //   }
  //   if (selected === "VENUES") {
  //     navigate("/venues", {
  //       state: { category: "VENUES", categoryItem: { value: "VENUES" } },
  //     });
  //     onVendorTagClick("Wedding Venues");
  //     window.scrollTo(0, 0);
  //   } else {
  //     navigate("/vendors", {
  //       state: { categoryItem: { vendorCategory: selected } },
  //     });
  //     const selectedVendor = items.find(
  //       (item) => item.vendorCategory === selected
  //     );
  //     if (selectedVendor) {
  //       onVendorTagClick(selectedVendor.category);
  //     }
  //     window.scrollTo(0, 0);
  //   }
  // };

  return (
    <StyledWrapper>
      <Heading />
      <CardSection>
        <SideViewAllCatgories onClick={() => navigate("/vendors/categories")}>View all Categories</SideViewAllCatgories>
        <SubCardSection>

          {
            landingPageVendorCategories.map((data, index) => {
              return <CategoryBox key={index}>
                <CardSection1 onClick={() => {
                  const updatedShowBottom = [false, false, false, false, false, false, false];
                  const value = !showBottom[index];
                  updatedShowBottom[index] = value;
                  setShowBottom([...updatedShowBottom])
                }} style={{ background: data.colorCode }}>
                  <HeadingCard>{data.tag}<ArrowDropDownIcon style={{ transform: showBottom[index] ? "rotate(-180deg)" : "", transition: "all 0.2s linear" }} /></HeadingCard>
                  {data?.data?.length > 0 && <DesCard>
                    {
                      data.data.map((data, index) => data.subcategoryText).slice(0, 2).join(", ")

                    }
                    {
                      data.data.length > 2 ? "...." : ""
                    }
                  </DesCard>}
                  <CardImage src={data.src} />
                  {showBottom[index]&&data?.data?.length > 0&&<TriangleBox />}
                </CardSection1>
                {data?.data?.length > 0 && <CardSection2 status={showBottom[index]}>
                  <CardSection22>
                   
                    {
                      data.data.map((item, index) => <HeadingSection2 key={index * 10} onClick={() => {
                        if (data.venue) {
                          navigate('/venues', {
                            state: {
                              category: 'VENUES',
                              categoryItem: {
                                value: item.vendorSubcategory,
                              },
                            },
                          });
                          onVendorTagClick(
                            item.subcategoryText === 'View All' ? 'Wedding Venues' : item.subcategoryText
                          );
                        } else {
                          window.open(
                            `/vendor/${item.vendorSubcategory}`,
                            '_blank'
                          );
                        }
                      }}><CircleIcon style={{ width: "8px", marginRight: "4px", marginTop: "3px" }} />{item.subcategoryText}</HeadingSection2>)
                    }
                  </CardSection22>
                </CardSection2>
                }
              </CategoryBox>
            })
          }

        </SubCardSection>
      </CardSection>









      {/* <CategoryItems
        selected={selected}
        setSelected={setSelected}
        items={items}
      />
      <VerticalGrid>
        {isLoading ? (
          <Grid container spacing={3} sx={{ marginTop: "16px" }}>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <SkeletonCard key={index} index={index} />
              </Grid>
            ))}
          </Grid>
        ) : vendorData.length > 0 ? (
          <Grid container spacing={3} sx={{ marginTop: "16px" }}>
            {vendorData.map((data, index) => (
              <Grid
                item
                xs={3}
                sm={6}
                md={vendorData.length > 3 ? 3 : 6}
                key={index}
              >
                <CategoryCard category={selected} index={index} data={data} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <NoDataDiv>
            <StyledH1 style={{ fontSize: "36px" }}>No Data Found</StyledH1>
          </NoDataDiv>
        )}
      </VerticalGrid>
      <HorizontalGrid>
        {isLoading ? (
          <div style={{ margin: "auto" }}>
            <SkeletonCard />
          </div>
        ) : vendorData.length > 0 ? (
          <Grid container spacing={0}>
            {vendorData.map((data, index) => (
              <Grid
                item
                xs={12}
                // sm={6}
                // md={3}
                key={index}
                className="weddingCategories"
              >
                <CategoryCard category={selected} index={index} data={data} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <NoDataDiv>
            <StyledH1 style={{ fontSize: "36px" }}>No Data Found</StyledH1>
          </NoDataDiv>
        )}
      </HorizontalGrid>
      <Button
        variant="contained"
        className="categoryButton"
        onClick={handleViewAllClick}
      >
        View All <ArrowRightAltIcon sx={{ marginLeft: "4px" }} />
      </Button> */}
    </StyledWrapper >
  );
};

export default Categories;
