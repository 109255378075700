export const TitleDescription = {
    BRIDAL_LEHENGAS: {
        title: "Discover Exquisite Bridal Lehengas at Maangal Bazaar",
        description: "Explore a stunning collection of bridal lehengas at Maangal Bazaar. From traditional to contemporary designs, find the perfect ensemble for your special day. Shop now and make your wedding unforgettable!"
    },
    LIGHT_LEHENGA: {
        title: "Shop Elegant Light Lehengas for Every Occasion | Maangal Bazaar",
        description: "Explore our exquisite collection of light lehengas at Maangal Bazaar. Perfect for various occasions, from weddings to festive celebrations, these elegant ensembles are sure to make you stand out. Browse now and find your perfect outfit!"
    },
    PRE_WEDDING_SHOOT: {
        title: "Capture Precious Moments: Pre-Wedding Shoot Services | Maangal Bazaar",
        description: "Elevate your pre-wedding experience with Maangal Bazaar's professional pre-wedding shoot services. From picturesque locations to expert photography, we'll ensure your love story is beautifully captured. Contact us to immortalize your journey towards forever!"
    },
    FREELANCE_MAKEUP_ARTIST: {
        title: "Transform Your Look: Freelance Makeup Artists at Maangal Bazaar",
        description: ": Discover the artistry of freelance makeup artists at Maangal Bazaar. Whether it's for a wedding, special occasion, or photoshoot, our talented professionals will enhance your natural beauty and leave you feeling confident and radiant. Book now for a personalized glam experience!"
    },
    BEAUTY_SALON: {
        title: "Enhance Your Beauty at the Best Beauty Salon | Maangal Bazaar",
        description: "Pamper yourself at Maangal Bazaar's premier beauty salon. From hair styling to skincare and makeup, our expert beauticians offer top-notch services to enhance your natural beauty and leave you feeling rejuvenated. Visit us for a transformative beauty experience!"
    },
    WEDDING_PLANNERS:{
        title:"Plan Your Dream Wedding with Expert Wedding Planners | Maangal Bazaar",
        description:"Let Maangal Bazaar's professional wedding planners turn your dream wedding into reality. From venue selection to décor and coordination, our experienced team will handle every detail, ensuring a stress-free and unforgettable celebration. Contact us today to begin planning your perfect day!"
    },
    WEDDING_DECOR:{
        title:"Elevate Your Wedding Ambiance with Stunning Wedding Decor | Maangal Bazaar",
        description:"Transform your wedding venue into a magical space with Maangal Bazaar's exquisite wedding decor services. From floral arrangements to lighting and thematic elements, our talented decorators will bring your vision to life, creating a memorable atmosphere for your special day. Contact us to create an enchanting setting that reflects your style and personality!"
    },
    JEWELLER:{
        title:"Exquisite Indian Jewellery Collection | Maangalbazaar",
        description:"Explore a stunning array of Indian jewellery, featuring intricate designs, vibrant colors, and a variety of materials including gold, silver, copper, and precious gemstones. Discover the perfect pieces to complement your bridal look or add a touch of elegance to any occasion. Shop online for a diverse selection of traditional and contemporary jewellery at Maangalbazaar."
    },
    ARTIFICIAL_JEWELLERY:{
        title:"Stunning Artificial Jewellery Collection | Maangalbazaar",
        description:"Explore a diverse range of artificial jewellery at Maangalbazaar. Discover exquisite designs, trendy styles, and versatile pieces to elevate your look for any occasion. From statement necklaces to elegant earrings, find the perfect accessories to express your unique style."
    },
    BRIDAL_JEWELLERY_ON_RENT:{
        title:"Exquisite Bridal Jewellery on Rent | Maangalbazaar",
        description:"Find the perfect bridal jewellery for your special day without the commitment of a purchase. Explore a stunning collection of bridal jewellery available for rent at Maangalbazaar. From traditional to contemporary designs, elevate your bridal ensemble with exquisite pieces that reflect your unique style and elegance."

    },
    DJS:{
        title:"Dynamic DJ Services for Unforgettable Events | Maangalbazaar",
        description:"Elevate your events with professional DJ services from Maangalbazaar. Experience the perfect blend of music and entertainment with our dynamic DJs, creating unforgettable moments for weddings, parties, and special occasions. Let our expert DJs set the tone for an exceptional celebration."
    }
}