import React, { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { API_HOST } from '../../api/url';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';


const AdWrapper = styled.img`
cursor: pointer;
max-height: 400px;
`;

const StyledP = styled.p`
  font-family: Poppins;
  font-size: 12px;
  background-color: #fff;
  opacity: 0.7;
  height: 20px;
  margin: 0;
  text-decoration: underline;
  @media (max-width: 480px) {
    font-size: 8px;
    height: fit-content;
  }
`;


const SliderContainer = styled.div`
z-index: 999;
display: flex;
width:100vw;
max-height: 400px;
justify-content: center;
align-items: center;
text-align: center;
`;


const CustomArrow = ({ onClick, direction }) => (
  <div className={`custom-arrow custom-arrow-${direction}`} onClick={onClick}>
    {direction === 'left' ? "<" : ">"}
  </div>
);

const Advertisement = ({ min,max}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [adData, setAdData] = useState([]);
  const   navigator=useNavigate()
  const fetchAds = async () => {
    try {
      const response = await axios.get(
        `${API_HOST}/api/v1/meta/advertisements`
      );
      setAdData(response.data.advertisementData);
    } catch (error) {
      console.log(error);
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    // rtl:min!==0?true:false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    customPaging: (i) => {
      return (
        <div style={{ display: "flex", width: "fit-content", zIndex: "99", margin: "0px 20px" }}>
          <div
            className={`custom-paging ${i === currentSlide ? 'ActiveSlider' : 'NonActiveSlider'}`}
          />
        </div>
      );
    },
  };

  useEffect(() => {
    fetchAds();
  }, []);

  function getImageUrl(adURL) {
    return adURL?.startsWith('http') ? adURL : `https://${adURL}`;
  }
  return (
    <>
      <SliderContainer>
        <Slider style={{ width: "100%", height: "100%" }} {...settings}>
          {
            adData.filter((data, index) => {
              if (data.imageURL && data.imageURL.slice(-1) < max&& data.imageURL.slice(-1) >= min)
                return true
              return false
            }).map((data, index) => {
              return (
                  <AdWrapper key={index} src={data.imageURL} alt='MB' onClick={()=>{
                                      const link=getImageUrl(data.adURL)
                                      window.open(link?link:'https://maangalbazaar.com/', '_blank');
                  }}>
                  
                  </AdWrapper>
              )
            })
          }
        </Slider>
      </SliderContainer>
    </>
  );
};

export default Advertisement;