import bridal_fasion from "./assests/Bridal_Fashion.webp";
import celebrity from "./assests/celebrity.webp";
import photovedioideas from "./assests/photovedioideas.webp"
import decorationIdea from "./assests/decorationideas.webp";
import photoghaphers from "./assests/photographers.webp";
import destinationWedding from "./assests/destinationwedding.webp";
import bridalMakeupArtist from "./assests/bridalmakeupartist.webp";
import jewellary from "./assests/jewellary.webp";
import honeymoontravel from "./assests/honeymoontravel.webp";
import weddingPlanner from "./assests/wedding_planner.webp";
import mhendi from "./assests/mhendi.webp";
import bridal_makup from "./assests/bridal_makup.webp";
import wedding_song from "./assests/wedding_song.webp";
import groom_fashion from "./assests/groom_fashion.webp";
import accessories from "./assests/accessories.webp";
import skin from "./assests/skin.webp";
import hair_style from "./assests/hair.webp";
import decorators from "./assests/decorators.webp";
import gifts from "./assests/gifts.webp";
import prewedding from "./assests/prewedding.webp";
import preweddingplanner from "./assests/wedding_planner.webp"
export const CategoryName={
   BRIDAL_FASHION:"Bridal Fashion",
   CELEBRITY:"Celebrity",
   Photo_VEDIO_IDEAS:"Photo & Video Ideas",
   DECORATING_IDEAS:"Decorating Ideas",
   WEDDING_PHOTOGRAPHERS:"Wedding Photographers",
   DESTINATION_WEDDING:"Destination Wedding",
   BRIDAL_MAKEUP_ARTISTS:"Bridal Makeup Artists",
   JEWELLERY:"Jewellery",
   HONEYMOON_TRAVEL:"Honeymoon & Travel",
   WEDDING_PLANNING:"Wedding Planning",
   BRIDAL_MAKEUP:"Bridal Makeup",
   MEHENDI:"Mehendi",
   WEDDING_SONGS:"Wedding Songs",
   PRE_WEDDING_PLANNING:"Pre Wedding Planning",
   WEDDING_FAVOURS_GIFTS:"Wedding Favours & Gifts",
   PRE_WEDDING:"Pre Wedding",
   GROOM_FASHION:"Groom Fashion",
   ACCESSORIES:"Accessories",
   SKINCARE_TIPS:"Skincare Tips",
   HAIRSTYLES:"Hairstyles",
   WEDDING_DECORATORS:"Wedding Decorators"
}
export const caroselData=[{
   id:"6957d82e-116e-4a66-b4d7-b48b4ac6c700",
   title:"Bridal Fashion",
   image:bridal_fasion,
   path:"660e84ff0f9b66507f617e6c"
},
{
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c701",
    title:"Celebrity",
    image:celebrity,
    path:"660e850d0f9b66507f617e70"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c702",
    title:"Photo & Video Ideas",
    image:photovedioideas,
    path:"660e85290f9b66507f617e74"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c703",
    title:"Decorating Ideas",
    image:decorationIdea,
    path:"660e853d0f9b66507f617e78"
 },{
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c704",
    title:"Wedding Photographers",
    image:photoghaphers,
    path:"660e854b0f9b66507f617e7c"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c705",
    title:"Destination Wedding",
    image:destinationWedding,
    path:"660e85590f9b66507f617e80"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c706",
    title:"Bridal Makeup Artists",
    image:bridalMakeupArtist,
    path:"660e85a20f9b66507f617e84"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c707",
    title:"Jewellery",
    image:jewellary,
    path:"660e85bd0f9b66507f617e89"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c709",
    title:"Honeymoon & Travel",
    image:honeymoontravel,
    path:"660e85d00f9b66507f617e8d"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c710",
    title:"Wedding Planning",
    image:weddingPlanner,
    path:"660e85ec0f9b66507f617e91"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c711",
    title:"Bridal Makeup",
    image:bridal_makup,
    path:"660e86080f9b66507f617e95"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c712",
    title:"Mehendi",
    image:mhendi,
    path:"660e86170f9b66507f617e99"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c713",
    title:"Wedding Songs",
    image:wedding_song,
    path:"660e86230f9b66507f617e9d"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c714",
    title:"Pre Wedding Planning",
    image:preweddingplanner,
    path:"660e863b0f9b66507f617ea1"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c715",
    title:"Wedding Favours & Gifts",
    image:gifts,
    path:"660e864a0f9b66507f617ea5"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c716",
    title:"Pre Wedding",
    image:prewedding,
    path:"660e86610f9b66507f617ea9"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c717",
    title:"Groom Fashion",
    image:groom_fashion,
    path:"660e87930f9b66507f617eb2"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c718",
    title:"Accessories",
    image:accessories,
    path:"660e87a60f9b66507f617eb6"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c720",
    title:"Skincare Tips",
    image:skin,
    path:"660e87bd0f9b66507f617eba"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c721",
    title:"Hairstyles",
    image:hair_style,
    path:"660e87d00f9b66507f617ec1"
 },
 {
    id:"6957d82e-116e-4a66-b4d7-b48b4ac6c722",
    title:"Wedding Decorators",
    image:decorators,
    path:"660e87f00f9b66507f617ec5"
 }]