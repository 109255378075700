import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import StarIcon from "@mui/icons-material/Star";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CheckAvailability from "./CheckAvailability";
import OTPModal from "./OTPModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { historySlice } from "../../../redux/slice/historySlice";

export const StyledHeading = styled.h2`
  margin: 0;
  color: #000;
  font-size: 21px;
  font-weight: 600;
  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

export const LoggedInHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledText = styled.p`
  color: #000;
  font-size: 16px;
  margin: 0;
  cursor: pointer;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0px;
`;

export const StyledSave = styled.span`
  color: #424344;
  font-size: 20px;
  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const Contact = ({ isAuthenticated, isVenue, data }) => {
  const dispatch=useDispatch()
  const location=useLocation()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    dispatch(historySlice.actions.updateHistory(location.pathname.toString()))
    navigate("/login");
  };

  return (
    <>
      {isAuthenticated === "AUTHENTICATED" ? (
        <CheckAvailability
          isAuthenticated={isAuthenticated}
          isVenue={isVenue}
          data={data}
        />
      ) : (
        <>
          <LoggedInHeader>
            <StyledHeading>
              {isVenue ? data.venueName : data.vendorName}
            </StyledHeading>
            <IconWrapper>
              <BookmarkIcon sx={{ marginRight: "4px", fontSize: "18px" }} />
              <StyledSave onClick={() => navigate("/login")}>Save</StyledSave>
            </IconWrapper>
          </LoggedInHeader>
          <div style={{ margin: "16px 0px" }}>
            <Wrapper>
              <StarIcon sx={{ fontSize: "18px", marginRight: "4px" }} />
              <StyledText>
                {isVenue
                  ? data?.venueRatingAverage?.toFixed(1)
                  : data?.vendorRatingAverage?.toFixed(1)}
                <span style={{ color: "#777" }}>
                  {" "}
                  {`(${
                    data.venueRatingCount || data.vendorRatingCount || 0
                  } reviews)`}{" "}
                </span>
              </StyledText>
            </Wrapper>
            <Wrapper>
              <LocationOnIcon sx={{ fontSize: "17px", marginRight: "4px" }} />
              <StyledText>
                {isVenue
                  ? `${data.venueCity}`
                  : data.vendorCity
                      ?.map((item) => <span key={item}>{item} </span>)
                      .reduce((prev, curr) => [prev, ", ", curr])}
                {/* <span style={{ color: "#777" }}>(View on Map)</span> */}
              </StyledText>
            </Wrapper>
          </div>
          <Button
          id="chatvenue_54"
            variant="contained"
            className="verifyButton sendMessage"
            onClick={handleClick}
          >
            Login to Send message
          </Button>
        </>
      )}
      <OTPModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default Contact;
