import React, { useMemo } from 'react';
import styled from 'styled-components';
import Card from './wedding-venues/Card';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { API_HOST } from '../api/url';
import CardSkeleton from './wedding-venues/CardSkeleton';

const SavedWrapper = styled.div`
  margin: 1em 6em;
  @media (max-width: 480px) {
    margin: 1em 20px;
  }
`;

const StyledHeading = styled.h1`
  color: #1b1d27;
  font-family: Outfit;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;

  @media (max-width: 480px) {
    font-size: 31px;
    padding-top: 20px;
    text-align: center;
  }
`;

const CardsWrapper = styled.div`
  margin: 2em 0em 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const renderLoadingSkeletons = () => (
  <Grid container spacing={2} justifyContent='center'>
    {[...Array(3)].map((_, index) => (
      <Grid item key={index} xs={12} sm={12} md={6} lg={4}>
        <CardSkeleton />
      </Grid>
    ))}
  </Grid>
);

const Saved = () => {
  const [savedData, setSavedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [savedWeddingVenuesData, setSavedWeddingVenuesData] = useState([]);
  const [savedWeddingVendorsData, setSavedWeddingVendorsData] = useState([]);

  const fetchSavedList = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_HOST}/api/v1/profile/savelist`);
      setSavedData(response.data.saveListData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const savedWeddingVenues = useMemo(
    () => savedData.filter((item) => item.savedServiceType === 'VENUE'),
    [savedData]
  );
  const savedWeddingVendors = useMemo(
    () => savedData.filter((item) => item.savedServiceType === 'VENDOR'),
    [savedData]
  );

  const fetchVenueData = async (venueSlug) => {
    try {
      const response = await axios.get(
        `${API_HOST}/api/v1/venues/${venueSlug}`
      );
      return response.data.venueData;
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchSavedWeddingVenuesData() {
    let savedWeddingVenuesData = await Promise.all(
      savedWeddingVenues.map((venue) => fetchVenueData(venue.savedServiceSlug))
    );
    setSavedWeddingVenuesData(savedWeddingVenuesData);
  }

  const fetchVendorData = async (vendorSlug) => {
    try {
      const response = await axios.get(
        `${API_HOST}/api/v1/vendors/${vendorSlug}`
      );
      return response.data.vendorData;
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchSavedWeddingVendorsData() {
    let savedWeddingVendorsData = await Promise.all(
      savedWeddingVendors.map((vendor) =>
        fetchVendorData(vendor.savedServiceSlug)
      )
    );
    setSavedWeddingVendorsData(savedWeddingVendorsData);
  }

  useEffect(() => {
    fetchSavedList();
  }, []);

  useEffect(() => {
    fetchSavedWeddingVenuesData();
  }, [savedWeddingVenues]);

  useEffect(() => {
    fetchSavedWeddingVendorsData();
  }, [savedWeddingVendors]);

  return (
    <SavedWrapper>
      <StyledHeading>Saved Wedding Venues</StyledHeading>
      <CardsWrapper>
        {isLoading ? (
          renderLoadingSkeletons()
        ) : savedWeddingVenues.length > 0 ? (
          <Grid container spacing={2} justifyContent='center'>
            {savedWeddingVenuesData.map((item, index) => {
              return (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  sx={{ paddingTop: '24px' }}
                >
                  <Card id={index} data={item} isVenue={true} />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <div>
            <StyledHeading style={{ textAlign: 'center', fontSize: '20px' }}>
              No Saved Wedding Venues Found
            </StyledHeading>
          </div>
        )}
      </CardsWrapper>
      <StyledHeading>Saved Wedding Vendors</StyledHeading>
      <CardsWrapper>
        {isLoading ? (
          renderLoadingSkeletons()
        ) : savedWeddingVendors.length > 0 ? (
          <Grid container spacing={2} justifyContent='center'>
            {savedWeddingVendorsData.map((item, index) => {
              return (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  sx={{ paddingTop: '24px' }}
                >
                  <Card id={index} data={item} isVenue={false} />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <div>
            <StyledHeading style={{ textAlign: 'center', fontSize: '20px' }}>
              No Saved Wedding Vendors Found
            </StyledHeading>
          </div>
        )}
      </CardsWrapper>
    </SavedWrapper>
  );
};

export default Saved;
