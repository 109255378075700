import axios from 'axios';

// export const API_HOST = 'https://bazarapi.ctoninja.tech';

// export const API_HOST = 'https://api.maangalbazaar.com';
// export const API_HOST="http://localhost:8051";
export const API_HOST = 'https://t-api.maangal.offiql.com';

// export const API_HOST="http://localhost:8051";
// export const API_HOST = 'https://t-api.maangal.offiql.com';


// social media links
export const INSTAGRAM_LINK='https://www.instagram.com/maangalbazaar/?hl=en';
export const FACEBOOK_LINK="https://www.facebook.com/MaangalBazaar/";
export const PINTEREST_LINK="https://in.pinterest.com/maangalbazaar/"

export const API_HOST_OMNILEADZ = 'https://api.omnileadz.com';
// export const API_HOST_OMNILEADZ="http://localhost:3333";
// export const API_HOST_OMNILEADZ = 'https://t-api.omnileadz.com';

export const BUSINESS="NjYwNDZmMWRiNjFiMTk5YmI0ZmRmODVj"
axios.defaults.withCredentials = true;

export const API_VERSION = "v1"

