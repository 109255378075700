import { Box, Skeleton, Stack } from '@mui/material';
import React from 'react';

const ReviewSkeleton = () => {
  return (
    <div>
      <Stack spacing={1} sx={{ width: '350px', marginTop: '20px' }}>
        <Skeleton variant='rounded' height={200} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Skeleton variant='circular' height={60} width={60} />
          <Box>
            <Skeleton variant='text' width='280px' />
            <Skeleton variant='text' width='150px' />
          </Box>
        </Box>
      </Stack>
    </div>
  );
};

export default ReviewSkeleton;
