import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 1em 6em;
  @media (max-width: 480px) {
    margin: 20px;
  }
`;

export const CardsWrapper = styled.div`
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FilterAndCardsWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

export const ListView = styled.div`
  width: 100%;
`;
