import React, { useState, useEffect } from 'react';
import { Box, Button, Checkbox, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import Success from '../../../assets/Success.svg';
import { useNavigate } from 'react-router-dom';
import { API_HOST } from '../../api/url';
import axios from 'axios';
import { ForgotPassword } from '../../Login-Page/Login';

const ModalHeading = styled.h2`
  color: #3d3e3d;
  text-align: center;
  font-size: 26px;
  font-family: Hanken Grotesk;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 380px;
  background: #fff;
  border-radius: 10px;
  outline: none;
  padding: 20px;
  @media (max-width: 480px) {
    width: 298px;
    height: 308px;
    padding: 20px;
  }
`;

const Text = styled.label`
  color: #858585;
  font-size: 14px;
  margin: 10px 0;
`;

const OTPWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
`;

const StyledInput = styled.input`
  height: 28px;
  outline: none;
  border: none;
  border-bottom: 1px solid #0f0f0f;
  padding-bottom: 8px;
  font-family: Outfit;
  @media (max-width: 480px) {
    height: 20px;
  }
`;

const OTPHelperText = styled.p`
  color: #000;
  text-align: center;
  font-size: 12px;
  font-family: Hanken Grotesk;
  font-weight: 300;
  line-height: 14px;
  margin-right: 8px;
`;

const HelperDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px 0px;
  justify-content: space-between;
  @media (max-width: 480px) {
    margin: 8px 0px;
    justify-content: center;
  }
`;

const ResendOTP = styled.p`
  color: #3f9e9e;
  text-align: center;
  font-size: 12px;
  font-family: Hanken Grotesk;
  font-weight: 600;
  line-height: 14px;
  cursor: pointer;
`;

const ModalDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
`;

const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SuccessHeading = styled.h2`
  color: #3d3e3d;
  text-align: center;
  font-size: 26px;
  font-family: Hanken Grotesk;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const StyledImage = styled.img`
  margin: 24px 0px;
  @media (max-width: 480px) {
    margin: auto;
  }
`;

const OTPModal = ({ isModalOpen, setIsModalOpen }) => {
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSignIn = async () => {
    if (!email || !password) {
      alert('All fields are required');
      return;
    }
    try {
      await axios.post(`${API_HOST}/api/v1/auth/login`, {
        userMail: email,
        userPassword: password,
        userType:'USER'
      });
      setSuccessMessage(true);
    
        window.location.reload();
     
    } catch (error) {
      if (!error.response) {
        alert(
          'A network error occurred. Please check your internet connection and try again.'
        );
      }
      if (error.response) {
        if (error.response.status === 400) {
          alert('Please enter valid login credentials');
        }
        if (error.response.status === 404) {
          alert(
            `Failed to load resource status code: ${error.response.status}`
          );
        }
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (email && password) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email, password]);

  return (
    <div>
      <Modal
        open={isModalOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <StyledBox>
          <IconButton className='closeButton' onClick={handleClose}>
            <CloseIcon sx={{ fontSize: '18px' }} />
          </IconButton>
          {successMessage ? (
            <SuccessWrapper>
              <SuccessHeading>Thank you for signing in</SuccessHeading>
              <StyledImage src={Success} alt='success' />
            </SuccessWrapper>
          ) : (
            <>
              <ModalDataWrapper>
                <ModalHeading>Enter the Login Credentials</ModalHeading>
                <OTPWrapper>
                  <Text htmlFor='otp'>Email</Text>
                  <StyledInput
                    type='email'
                    id='otp'
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <Text htmlFor='pass'>Password</Text>
                  <StyledInput
                    type={isShow ? 'text' : 'password'}
                    id='pass'
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />

                  <HelperDiv>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '-8px',
                      }}
                    >
                      <Checkbox
                        size='small'
                        checked={isShow}
                        onChange={(e) => setIsShow(e.target.checked)}
                      />
                      <ForgotPassword
                        onClick={() => setIsShow(!isShow)}
                        style={{
                          marginTop: '4px',
                          color: '#a4a4a4',
                          fontSize: '12px',
                        }}
                      >
                        Show password
                      </ForgotPassword>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <OTPHelperText>Don't have account?</OTPHelperText>
                      <ResendOTP onClick={() => navigate('/login')}>
                        Sign Up
                      </ResendOTP>
                    </div>
                  </HelperDiv>
                </OTPWrapper>
                <Button
                  variant='contained'
                  className='verifyButton sendMessage signinModalButton'
                  disabled={disabled}
                  onClick={handleSignIn}
                >
                  Sign In
                </Button>
              </ModalDataWrapper>
            </>
          )}
        </StyledBox>
      </Modal>
    </div>
  );
};

export default OTPModal;
