import { Skeleton, Stack } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SubLoader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MainSkeleton = styled.div``;

const CatalogLoader = () => {
  return (
    <div
      style={{
        margin: '28px 0',
      }}
    >
      <MainWrapper>
        <Skeleton
          animation='wave'
          variant='rounded'
          width={'98%'}
          height={'460px'}
          sx={{ borderRadius: '15px', marginRight: '20px' }}
        />
        <SubLoader>
          <Skeleton
            animation='wave'
            variant='rounded'
            width={'140px'}
            height={'100px'}
            sx={{ borderRadius: '15px' }}
          />{' '}
          <Skeleton
            animation='wave'
            variant='rounded'
            width={'140px'}
            height={'100px'}
            sx={{ borderRadius: '15px' }}
          />{' '}
          <Skeleton
            animation='wave'
            variant='rounded'
            width={'140px'}
            height={'100px'}
            sx={{ borderRadius: '15px' }}
          />{' '}
          <Skeleton
            animation='wave'
            variant='rounded'
            width={'140px'}
            height={'100px'}
            sx={{ borderRadius: '15px' }}
          />
        </SubLoader>
      </MainWrapper>
    </div>
  );
};

export default CatalogLoader;
