import React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  color: #6b6868;
  font-size: 12px !important;
  cursor: pointer;
`;

const StyledTypo = styled(Typography)`
  color: #0a1d3c;
  font-size: 12px !important;
  font-weight: 500;
`;

const BreadCrumb = ({ clickedVendorTag }) => {
  return (
    <>
      <Breadcrumbs
        aria-label='breadcrumb'
        separator={<NavigateNextRoundedIcon fontSize='small' />}
      >
        <StyledLink underline='hover' color='inherit' href='/'>
          Home
        </StyledLink>
        <StyledTypo>{clickedVendorTag}</StyledTypo>
      </Breadcrumbs>
    </>
  );
};

export default BreadCrumb;
