import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BreadCrumbs from "./BreadCrumbs";
import VenueHeader from "./VenueHeader";
import ImageCatalog from "./ImageCatalog";
import AboutSection from "./AboutSection";
import Reviews from "./Reviews";
import SimilarWeddingsVenue from "./SimilarWeddingsVenue";
import FeedBack from "../../Landing-Page/Testimonials/FeedBack";
import { useLocation } from "react-router-dom";
import { API_HOST } from "../../api/url";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import SideStickyComponent from "./SideStickyComponent";
import { Helmet } from 'react-helmet-async';

const MainWrapper = styled.div`
  margin: 2em 6em;
  @media (max-width: 480px) {
    margin: 24px;
  }
`;

const TestimonialWrapper = styled.div`
  display: none;
  @media (max-width: 480px) {
    display: none;
  }
`;

const VendorDetails = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { state } = useLocation();

  // const isVenue = state.isVenue;
  // console.log('vendorDetails', state);
  const fetchVenueData = async (slug) => {
    try {
      const response = await axios.get(`${API_HOST}/api/v1/vendors/${slug}`);
      setData(response.data.vendorData);

      window.scroll(0, 0);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const url = window.location.href;
    const extractedPart = url.split("/vendor_profile/")[1];
    console.log(extractedPart, "extracted part")
    fetchVenueData(extractedPart);
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100h",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="success" />
        </div>
      ) : (
        data && (
          <MainWrapper>
            <Helmet>
              <title>{`${data.metaTitle ? data.metaTitle : "Find Top Vendors for Your Shopping Needs at Maangal Bazaar"}`}</title>
              <meta name="description" content={`${data.metaDescription ? data.metaDescription : "Discover a wide range of top vendors offering high-quality products at Maangal Bazaar!"}`} />
            </Helmet>
            <BreadCrumbs data={data} isVenue={false} />
            <VenueHeader data={data} isVenue={false} />
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "sticky",
                  top: "0",
                  right: "0",
                  // display: "block",
                  // zIndex: "10",
                }}
              >
                <SideStickyComponent data={data} isVenue={false} />
              </div>
              <ImageCatalog data={data} isVenue={false} />
            </div>
            <AboutSection data={data} isVenue={false} />

            <TestimonialWrapper>
              <FeedBack />
            </TestimonialWrapper>
            <Reviews data={data} isVenue={false} />
            <SimilarWeddingsVenue productData={data} isVenue={false} />
          </MainWrapper>
        )
      )}
    </>
  );
};

export default VendorDetails;
