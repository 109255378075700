import React, { useState } from 'react';
import { Box, Button, Modal } from '@mui/material';
import styled from 'styled-components';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { API_HOST } from '../api/url';
import { useLogin } from '../../context/LoginContext';
import { useEffect } from 'react';
import { manageMedia } from '../../utils/apiHandler';
const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  border-radius: 8px;
  background: #13362d;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(6px);
  color: #fff;
  outline: none;
  padding: 28px 48px;
  @media (max-width: 480px) {
    width: 250px;
    padding: 28px 24px;
  }
`;

const StyledImage = styled.img`
  width: 84px;
  height: 84px;
  border-radius: 50%;
  @media (max-width: 480px) {
    width: 64px;
    height: 64px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  color: #b0afaf;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 6px;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const StyledInput = styled.input`
  border: none;
  border-bottom: 1px solid #fff;
  background: transparent;
  padding: 4px 0;
  font-size: 16px;
  &:focus {
    outline: none;
  }
  color: #cfcfcf;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const ImageBox = styled.div`
  display: flex;
  align-items: end;
  justify-content: center;
  margin-bottom: 28px;
  position: relative;
  @media (max-width: 480px) {
    margin-bottom: 20px;
  }
`;

const StyledButtonDiv = styled.div`
  margin-top: 16px;
  text-align: center;
`;

const IconWrapper = styled.div`
  width: 28px;
  height: 28px;
  background: #fff;
  color: #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 124px;
  cursor: pointer;
  @media (max-width: 480px) {
    width: 20px;
    height: 20px;
    right: 88px;
  }
`;

const CountryCode = styled.span`
  padding-right: 8px;
  border-bottom: 1px solid #fff;
  margin-bottom: 16px;
`;

const visibleIconStyles = {
  color: 'white',
  borderBottom: '1px solid #fff',
  marginBottom: '16px',
  marginTop: '3px',
  cursor: 'pointer',
};

const EditModal = ({
  isOpen,
  handleEditClick,
  setSeverity,
  setOpen,
  fetchProfileData,
  setMessage,
}) => {
  const { userMail, userContact, profile } = useLogin();
  const [fullName, setFullName] = useState(!(profile && profile.name));
  const [mobileNumber, setMobileNumber] = useState(userContact || '');
  const [email, setEmail] = useState(profile && profile.email);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [originalFile,setOriginalFile]=useState(null)
  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPasswordClick = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const updateProfile = async () => {
    try {
      const date=Date.now().toString()
      if(originalFile){
        await manageMedia({
          mediaFiles: [originalFile],
          fileMethod: 'PUT',
          objectKeyGenFn: (fileHandle, fileIdx, fileMethod) => {
            return `media/user-profile/${date}/${date}`;
          },
        });
      }


      const requestBody = {
        userFullName: fullName,
        userContact: mobileNumber,
        // newPassword: newPassword,
      };
      if(originalFile){
        requestBody.userPhotoObjectKey=`media/user-profile/${date}/${date}`
      }
      await axios.put(`${API_HOST}/api/v1/profile`, requestBody);
      setSeverity('success');
      setMessage('Profile Updated Successfully!');
      setOpen(true);
      setOriginalFile(null)

    } catch (error) {
      console.log(error)
      setSeverity('error');
      setMessage('An Error Occurred While Updating Profile');
      setOpen(true);
    }finally{
      fetchProfileData();
    }
    handleEditClick(!isOpen);
  };

  const fetchProfile = async () => {
    try {
      const response = await axios.get(`${API_HOST}/api/v1/profile`);
      setProfileData(response.data);
      setFullName(response.data.userProfile.userFullName);
      setMobileNumber(response.data.userProfile.userContact);
      setEmail(response.data.userProfile.userMail);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const handleIconClick = () => {
    document.getElementById('hiddenFileInput').click();
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setOriginalFile(file)

      setSelectedFile(URL.createObjectURL(file));
    }
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      onBackdropClick={() => handleEditClick(false)}
    >
      <StyledBox>
        <ImageBox>
          <StyledImage
            src={
              selectedFile
                ? selectedFile
                : profileData&&profileData.userProfile&&profileData.userProfile.userPhotoURL&&profileData.userProfile.userPhotoURL.length>0
                ? profileData.userProfile.userPhotoURL
                : 'https://th.bing.com/th/id/OIP.AdoJAsiWdwMNG0ZTvUoTUQHaHa?pid=ImgDet&rs=1'
            }
            alt='user'
          />
          <input
            type='file'
            id='hiddenFileInput'
            accept='image/*'
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <IconWrapper onClick={handleIconClick}>
            <BorderColorOutlinedIcon
              style={{ fontSize: window.innerWidth <= 480 ? 'small' : '18px' }}
            />
          </IconWrapper>
        </ImageBox>
        <InputWrapper>
          <StyledLabel htmlFor=''>Full Name</StyledLabel>
          <StyledInput
            type='text'
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper>
          <StyledLabel htmlFor=''>Mobile Number</StyledLabel>
          <div style={{ display: 'flex' }}>
            <CountryCode>+91</CountryCode>
            <StyledInput
              style={{ width: '100%' }}
              type='tel'
              disabled
              maxLength={10}
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </div>
        </InputWrapper>
        <InputWrapper>
          <StyledLabel htmlFor=''>Email ID</StyledLabel>
          <StyledInput
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled
          />
        </InputWrapper>
        <InputWrapper>
          <StyledLabel htmlFor=''>Create new password</StyledLabel>
          <div style={{ display: 'flex' }}>
            <StyledInput
              type={showPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              style={{ width: '100%' }}
            />
            {showPassword ? (
              <VisibilityOff
                onClick={handleShowPasswordClick}
                style={visibleIconStyles}
              />
            ) : (
              <Visibility
                onClick={handleShowPasswordClick}
                style={visibleIconStyles}
              />
            )}
          </div>
        </InputWrapper>
        <InputWrapper>
          <StyledLabel htmlFor=''>Confirm new password</StyledLabel>
          <div style={{ display: 'flex' }}>
            <StyledInput
              type={showConfirmPassword ? 'text' : 'password'}
              style={{ width: '100%' }}
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
            {showConfirmPassword ? (
              <VisibilityOff
                onClick={handleShowConfirmPasswordClick}
                style={visibleIconStyles}
              />
            ) : (
              <Visibility
                onClick={handleShowConfirmPasswordClick}
                style={visibleIconStyles}
              />
            )}
          </div>
        </InputWrapper>
        <StyledButtonDiv>
          <Button
            variant='contained'
            className='editProfileSubmit'
            onClick={updateProfile}
          >
            Submit
          </Button>
        </StyledButtonDiv>
      </StyledBox>
    </Modal>
  );
};

export default EditModal;
