import React, { useEffect } from 'react';
import styled from 'styled-components';
import Quote from '../../../assets/quote.svg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FeedBackCard from './FeedBackCard';
import { useState } from 'react';
import axios from 'axios';
import { API_HOST } from '../../api/url';

const StyledWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0px 6rem;
  @media (max-width: 1200px) {
    padding:0px 4rem;
  }
  @media (max-width:950px) {
    padding:0px 3rem;
  }
  @media (max-width:750px) {
    padding:0px 2rem;
  }
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const StyledImage = styled.img`
  margin-bottom: 64px;
  margin-right: 12px;
  height: 32px;
  @media (max-width: 480px) {
    display: none;
  }
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  color: #13362d;
  cursor: pointer;
  margin-right: 12px;
`;

const HeadingSection = styled.div`
  display: flex;
`;

const StyledHeading = styled.h1`
  font-weight: 700;
  font-size: 32px;
  color: #1b1d27;
  width: 70%;
  font-family: 'Outfit', sans-serif;
  @media (max-width: 480px) {
    font-size: 25px;
    margin-left: 2rem;
  }
`;

const Cards = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  gap:10px;
  box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    @media (max-width:680px) {
      margin: 0px 25px 0px 15px;
    }
`;

export const ArrowWrapper = styled.div`
`;

const Divider = styled.div`
  height: 286px;
  margin: 0 16px;
  border: 1px solid #f5f5f5;
`;

const DividerWrapper = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
`;

const FeedBack = () => {
  const [currentData, setCurrentData] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const isOnFirstSet = reviewData.indexOf(currentData[0]) === 0;
  const isOnLastSet =
    reviewData.indexOf(currentData[1]) === reviewData.length - 1;

  const handlePrevClick = () => {
    if (reviewData.length > 2) {
      const currentIndex = reviewData.indexOf(currentData[0]);
      if (currentIndex - 2 >= 0) {
        setCurrentData(reviewData.slice(currentIndex - 2, currentIndex));
      }
    }
  };

  const handleNextClick = () => {
    if (reviewData.length > 2) {
      const currentIndex = reviewData.indexOf(currentData[1]);
      if (currentIndex + 2 < reviewData.length) {
        setCurrentData(reviewData.slice(currentIndex + 1, currentIndex + 3));
      }
    }
  };

  const fetchReviews = async () => {
    try {
      const response = await axios.get(
        `${API_HOST}/api/v1/meta/platform-reviews`
      );
      setReviewData(response.data.platformReviews);
      setCurrentData(response.data.platformReviews.slice(0, 2));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  return (
    <StyledWrapper>
      <TopSection>
        <HeadingSection>
          <StyledImage src={Quote} alt='svg' />
          <StyledHeading>What Our Customer are Saying</StyledHeading>
        </HeadingSection>
        <ArrowWrapper>
          <StyledButton
            onClick={handlePrevClick}
            disabled={isOnFirstSet}
            style={{ color: isOnFirstSet ? '#D1D7D7' : 'inherit' }}
          >
            <ArrowBackIcon />
          </StyledButton>
          <StyledButton
            onClick={handleNextClick}
            disabled={isOnLastSet}
            style={{ color: isOnLastSet ? '#D1D7D7' : 'inherit' }}
          >
            <ArrowForwardIcon />
          </StyledButton>
        </ArrowWrapper>
      </TopSection>
      <Cards>
        {currentData.map((data, index) => {
          return (
            <React.Fragment key={`title-${data.title}-${index}`}>
              <FeedBackCard data={data} />
              <DividerWrapper>
                {index !== currentData.length - 1 && <Divider />}
              </DividerWrapper>
            </React.Fragment>
          );
        })}
      </Cards>
    </StyledWrapper>
  );
};

export default FeedBack;
