import React from "react";
import { Details, DetailsHead, DetailsText } from "./AboutSection";
import styled from "styled-components";
import Grid from "@mui/material/Grid";

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const METADATA_ANSWERS = {
  BRIDAL_GROOMING:{
    BEAUTY_AND_WELLNESS:{
      VENDOR_BRIDAL_GROOMING_BEAUTY_AND_WELLNESS_SERVICE_MAKEUP_BITFIELD:[
        {
          metadataString: "At Home",
          metadataValue: 1,
        },
        {
          metadataString: "Physical Centers",
          metadataValue: 2,
        },
      ],
      VENDOR_BRIDAL_GROOMING_BEAUTY_AND_WELLNESS_CONSUL_MAKEUP_BITFIELD:[
        {
          metadataString: "Free Consultation",
          metadataValue: 1,
        },
        {
          metadataString: "Included in the Service Charge",
          metadataValue: 2,
        },
      ]
    }
  },
  ASTROLOGY: {
    ASTROLOGY: {
      VENDOR_WEIGHT_LOSS_CLINICS_EXP: [
        {
          metadataString: "Less than 1 year",
          metadataValue: "<1",
        },
        {
          metadataString: "1-3 years",
          metadataValue: "1-3",
        },
        {
          metadataString: "3-5 years",
          metadataValue: "3-5",
        },
        {
          metadataString: "More than 5 years",
          metadataValue: ">5",
        },
      ],
    }
  },
  CLINICS: {
    AYURVEDIC_CLINICS: {
      VENDOR_AYURVEDIC_CLINICS_EXP: [
        {
          metadataString: "Less than 1 year",
          metadataValue: "<1",
        },
        {
          metadataString: "1-3 years",
          metadataValue: "1-3",
        },
        {
          metadataString: "3-5 years",
          metadataValue: "3-5",
        },
        {
          metadataString: "More than 5 years",
          metadataValue: ">5",
        },
      ], VENDOR_AYURVEDIC_CLINICS_CONSULTATION: [
        {
          metadataString: "Less than 300",
          metadataValue: "<300",
        },
        {
          metadataString: "400",
          metadataValue: "400",
        },
        {
          metadataString: "500",
          metadataValue: "500",
        },
        {
          metadataString: "More than 5 years",
          metadataValue: ">500",
        },
      ],
    }
  },
  FITNESS_CENTER: {
    FITNESS_CENTER: {
      VENDOR_FITNESS_CENTER_EXP: [
        {
          metadataString: "Less than 1 year",
          metadataValue: "<1",
        },
        {
          metadataString: "1-3 years",
          metadataValue: "1-3",
        },
        {
          metadataString: "3-5 years",
          metadataValue: "3-5",
        },
        {
          metadataString: "More than 5 years",
          metadataValue: ">5",
        },
      ]
    }

  },
  BRIDAL_WEAR: {
    BRIDAL_WEAR: {
      VENDOR_STORE_TYPE_BITFIELD: [
        {
          metadataString: "Retail Store / Outlet",
          metadataValue: 1,
        },
        {
          metadataString: "Studio / Boutique",
          metadataValue: 2,
        },
        {
          metadataString: "Couture Brand",
          metadataValue: 4,
        },
        {
          metadataString: "Multi-Designer Studio",
          metadataValue: 8,
        },
      ],
    },
  },
  PHOTOGRAPHY: {
    WEDDING_PHOTOGRAPHERS: {
      VENDOR_TRAVEL_COSTS: [
        {
          metadataValue: true,
          metadataString: "Travel expenses borne by photographer",
        },
        {
          metadataValue: false,
          metadataString: "Travel expenses borne by client",
        },
      ],
    },
    // PRE_WEDDING_SHOOT: {
    //   VENDOR_TRAVEL_COSTS: [
    //     {
    //       metadataValue: true,
    //       metadataString: "Travel expenses borne by photographer",
    //     },
    //     {
    //       metadataValue: false,
    //       metadataString: "Travel expenses borne by client",
    //     },
    //   ],
    // },
  },
  DEFAULTS: {
    VENDOR_AYURVEDIC_CLINICS_PRODUCTS_OFFERED: [
      {
        metadataValue: 0,
        metadataString: "{0}",
      }
    ],
    VENDOR_AYURVEDIC_CLINICS_QUALITY_CERTIFICATION: [
      {
        metadataValue: 0,
        metadataString: "{0}",
      }
    ],
    VENDOR_FITNESS_CENTER_PRODUCTS_OFFERED: [
      {
        metadataValue: 0,
        metadataString: "{0}",
      }
    ],
    VENDOR_FITNESS_CENTER_QUALITY_CERTIFICATION: [
      {
        metadataValue: 0,
        metadataString: "{0}",
      },
    ],
    VENDOR_FITNESS_CENTER_PERSONAL_TRAINING: [
      {
        metadataValue: 0,
        metadataString: "{0}",
      },
    ],
    VENDOR_TRAVEL_COSTS: [
      {
        metadataValue: true,
        metadataString: "Travel costs included",
      },
      {
        metadataValue: false,
        metadataString: "Travel costs not included",
      },
    ],
    VENDOR_PARTIAL_REFUND: [
      {
        metadataValue: true,
        metadataString: "Partial refund provided",
      },
      {
        metadataValue: false,
        metadataString: "Full refund provided",
      },
    ],
    VENDOR_CONSULTATION_OFFERED: [
      {
        metadataValue: true,
        metadataString: "Paid or Free consultation offered",
      },
      {
        metadataValue: false,
        metadataString: "Consultation not offered",
      },
    ],
    VENDOR_MAX_QUANTITY: [
      {
        metadataValue: 0,
        metadataString: "Maximum {0} quantity",
      },
    ],
    VENDOR_MIN_QUANTITY: [
      {
        metadataValue: 0,
        metadataString: "Minimum {0} quantity",
      },
    ],
    VENDOR_MAKEUP_AIRBRUSH: [
      {
        metadataValue: true,
        metadataString: "Airbrush Makeup Available",
      },
      {
        metadataValue: false,
        metadataString: "Airbrush Makeup not Available",
      },
    ],
    VENDOR_MAKEUP_HD: [
      {
        metadataValue: true,
        metadataString: "HD Makeup Available",
      },
      {
        metadataValue: false,
        metadataString: "HD Makeup not Available",
      },
    ],
    VENDOR_CONSULTATION_PAID: [
      {
        metadataValue: true,
        metadataString: "Paid consultation offered",
      },
      {
        metadataValue: false,
        metadataString: "Free consultation offered",
      },
    ],
    VENDOR_DATE_ADJUSTMENT: [
      {
        metadataValue: false,
        metadataString: "Non-Adjustable Dates",
      },
      {
        metadataValue: true,
        metadataString: "Adjustable Dates",
      },
    ],
    VENDOR_SHIPPING: [
      {
        metadataValue: true,
        metadataString:
          "We provide shipping (may incur extra charges for client)",
      },
      {
        metadataValue: false,
        metadataString: "We do not provide shipping.",
      },
    ],
    VENDOR_ASSOCIATION_LIST: [
      {
        metadataValue: [],
        metadataString: "Associated with {0}",
      },
    ],
    VENDOR_ADVANCE_PAYMENT_PERCENT: [
      {
        metadataValue: 0,
        metadataString: "{0}% Advance",
      },
    ],
    VENDOR_CATERING_NON_VEG: [
      {
        metadataValue: true,
        metadataString: "Non-Veg catering available",
      },
      {
        metadataValue: false,
        metadataString: "Veg catering only",
      },
    ],
    VENDOR_CUSTOMIZATION_OPTION: [
      {
        metadataValue: true,
        metadataString: "Customization available",
      },
      {
        metadataValue: false,
        metadataString: "Customization not available",
      },
    ],
    VENDOR_FOOD_ONSITE_PREPARATION: [
      {
        metadataValue: true,
        metadataString: "Food is prepared on location",
      },
      {
        metadataValue: false,
        metadataString: "Food is prepared at a different location",
      },
    ],
    VENDOR_INVITATION_BASE_PRICE_RANGE: [
      {
        metadataString: "Less than 50 INR",
        metadataValue: "0-50 INR",
      },
      {
        metadataString: "Between 50-100 INR",
        metadataValue: "50-100",
      },
      {
        metadataString: "Between 100-200 INR",
        metadataValue: "100-200",
      },
      {
        metadataString: "Between 200-400 INR",
        metadataValue: "200-400",
      },
      {
        metadataString: "Between More than 400 INR",
        metadataValue: "400+",
      },
      {
        metadataString: "Designing Costs",
        metadataValue: "Design Costs",
      },
      {
        metadataString: "On Request / Flexible",
        metadataValue: "As Per Requirements",
      },
    ],
    VENDOR_DELIVERY_TIME_DAYS: [
      {
        metadataValue: 0,
        metadataString: "Estimated Delivery in {0} Days",
      },
    ],
    VENDOR_STAFF_COUNT_MIN: [
      {
        metadataValue: 0,
        metadataString: "Minimum {0} Service Staff",
      },
    ],
    VENDOR_STAFF_COUNT_MAX: [
      {
        metadataValue: 0,
        metadataString: "Maximum {0} Service Staff",
      },
    ],
    VENDOR_SOCIAL_FB_LINK: [],
    VENDOR_SOCIAL_IG_LINK: [],
    VENDOR_SOCIAL_WS_LINK: [],
    VENDOR_SOCIAL_YT_LINK: [],
    VENDOR_EXPERIENCE_LIST: [
      {
        metadataValue: [],
        metadataString: "Experience with high profile clients (Celebrities, Actors) - {0}",
      },
    ],
    VENDOR_OUTSIDE_HOMETOWN_CHARGES: [
      {
        metadataValue: [],
        metadataString: "Charges around - {0}",
      },
    ],
    VENDOR_PREMIUM_DATE: [
      {
        metadataValue: "",
        metadataString: "Listed on {0}",
      },
    ],
    VENDOR_LISTING_DATE: [
      {
        metadataValue: "",
        metadataString: "Listing updated on {0}",
      },
    ],
    VENDOR_REFUND_OFFERED: [
      {
        metadataValue: false,
        metadataString: "Refund not available",
      },
      {
        metadataValue: true,
        metadataString: "Refund available",
      },
    ],
    VENDOR_SHIPPING_INTERNATIONAL: [
      {
        metadataValue: true,
        metadataString: "International Shipping",
      },
      {
        metadataValue: false,
        metadataString: "Domestic Shipping only",
      },
    ],
    VENDOR_SHOOT_LOCATION: [
      {
        metadataString: "Borne by client",
        metadataValue: false,
      },
      {
        metadataString: "Borne by photographer (Only Local)",
        metadataValue: true,
      },
    ],
    VENDOR_PRODUCTS_USED: [
      {
        metadataValue: [],
        metadataString: "Uses {0}",
      },
    ],
    VENDOR_RENTAL_SERVICE: [
      {
        metadataValue: false,
        metadataString: "Rental not available",
      },
      {
        metadataValue: true,
        metadataString: "Rental available",
      },
    ],
    VENDOR_SERVICE_TRIAL: [
      {
        metadataValue: true,
        metadataString: "Trial available",
      },
      {
        metadataValue: false,
        metadataString: "Trial not available",
      },
    ],
    VENDOR_SERVICE_DATE: [
      {
        metadataValue: "",
        metadataString: "Providing Services since {0}",
      },
    ],
    VENDOR_SERVICE_PRICING_RELATIVE: [
      {
        metadataValue: false,
        metadataString: "Fixed rate",
      },
      {
        metadataValue: true,
        metadataString: "Variable rate",
      },
    ],
    VENDOR_SERVICE_RELATIVE_PERCENTAGE: [
      {
        metadataValue: 0,
        metadataString: "{0}% of Final Price",
      },
    ],
    VENDOR_PLANNER_DECOR_POLICY: [
      {
        metadataString:
          "We offer both in-house decor as well work with external decorators",
        metadataValue: "NO_PREFERENCE",
      },
      {
        metadataString:
          "We do not offer in-house decor, we work with decorators of your choice",
        metadataValue: "NO_IN_HOUSE",
      },
      {
        metadataString:
          "We only offer in-house decor, we do not work with external decorators",
        metadataValue: "IN_HOUSE_ONLY",
      },
    ],
    VENDOR_TRIAL_PAID: [
      {
        metadataValue: true,
        metadataString: "Paid Trial",
      },
      {
        metadataValue: false,
        metadataString: "Free Trial",
      },
    ],
    VENDOR_RESTRICTED_TRAVEL: [
      {
        metadataValue: true,
        metadataString: "Open to travelling to locations",
      },
      {
        metadataValue: false,
        metadataString: "Cannot travel to other locations",
      },
    ],
    VENDOR_TRIAL_ADJUSTMENT: [
      {
        metadataValue: true,
        metadataString: "Trial pricing adjusted for booking",
      },
      {
        metadataValue: false,
        metadataString: "Trial price not adjusted for booking",
      },
    ],
    VENDOR_FOOD_VEG_PLATE_PRICE: [
      {
        metadataValue: 0,
        metadataString: `Veg Plate Price (Approx) - Rs. {0}`,
      },
    ],
    VENDOR_FOOD_NON_VEG_PLATE_PRICE: [
      {
        metadataValue: 0,
        metadataString: `Non-Veg Plate Price (Approx) - Rs. {0}`,
      },
    ],
    VENDOR_TAILORED_SERVICE: [
      {
        metadataValue: true,
        metadataString: "We provide tailored as well as outfits",
      },
      {
        metadataValue: false,
        metadataString: "We provide off-the-shelf outfits",
      },
    ],
    VENDOR_SMALL_FUNCTION_PACKAGE_PRICE: [
      {
        metadataValue: 0,
        metadataString: `Approximate small function price: Rs. {0}`,
      },
    ],
    VENDOR_BEAUTY_SALON_MAKEUP_BITFIELD: [
      {
        metadataValue: 1,
        metadataString: "Hair Styling",
      },
      {
        metadataValue: 2,
        metadataString: "Draping",
      },
      {
        metadataValue: 4,
        metadataString: "Nail Polish Change",
      },
      {
        metadataValue: 8,
        metadataString: "Makeup",
      },
      {
        metadataValue: 16,
        metadataString: "Extensions",
      },
      {
        metadataValue: 32,
        metadataString: "False Lashes",
      },
    ],
    VENDOR_FREELANCE_BRIDAL_MAKEUP_BITFIELD: [
      {
        metadataValue: 1,
        metadataString: "Hair Styling",
      },
      {
        metadataValue: 2,
        metadataString: "Draping",
      },
      {
        metadataValue: 4,
        metadataString: "Nail Polish Change",
      },
      {
        metadataValue: 8,
        metadataString: "Makeup",
      },
      {
        metadataValue: 16,
        metadataString: "Extensions",
      },
      {
        metadataValue: 32,
        metadataString: "False Lashes",
      },
    ],
    VENDOR_STORE_TYPE_BITFIELD: [
      {
        metadataValue: 1,
        metadataString: "Online",
      },
      {
        metadataValue: 2,
        metadataString: "Offline",
      },
    ],
    VENDOR_FOOD_ADDITIONAL_SERVICE_BITFIELD: [
      {
        metadataString: "Only Food",
        metadataValue: 1,
      },
      {
        metadataString: "Disposable Cutlery",
        metadataValue: 2,
      },
      {
        metadataString: "Cutlery",
        metadataValue: 4,
      },
      {
        metadataString: "Waiters / Service Staff",
        metadataValue: 8,
      },
      {
        metadataString: "Decor and Furniture",
        metadataValue: 16,
      },
    ],
    VENDOR_FOOD_CUISINE_BITFIELD: [
      {
        metadataString: "North Indian",
        metadataValue: 1,
      },
      {
        metadataString: "South Indian",
        metadataValue: 2,
      },
      {
        metadataString: "Chinese",
        metadataValue: 4,
      },
      {
        metadataString: "Japanese",
        metadataValue: 8,
      },
      {
        metadataString: "Italian",
        metadataValue: 16,
      },
      {
        metadataString: "Greek",
        metadataValue: 32,
      },
      {
        metadataString: "Lebanese / Arabic",
        metadataValue: 64,
      },
      {
        metadataString: "Thai",
        metadataValue: 128,
      },
      {
        metadataString: "Desserts / Sweets",
        metadataValue: 256,
      },
      {
        metadataString: "Bengali",
        metadataValue: 512,
      },
      {
        metadataString: "Gujarati",
        metadataValue: 1024,
      },
      {
        metadataString: "Rajasthani",
        metadataValue: 2048,
      },
      {
        metadataString: "Goan",
        metadataValue: 4096,
      },
      {
        metadataString: "Maharashtrian",
        metadataValue: 8192,
      },
      {
        metadataString: "Other Exotic",
        metadataValue: 16384,
      },
    ],
    VENDOR_FOOD_SPECIALIZATION_BITFIELD: [
      {
        metadataString: "Chaat and Snacks",
        metadataValue: 1,
      },
      {
        metadataString: "Live Counters - Ice Creams, Drinks and Desserts",
        metadataValue: 2,
      },
      {
        metadataString: "Single Cuisine",
        metadataValue: 4,
      },
      {
        metadataString: "General Catering",
        metadataValue: 8,
      },
    ],
    VENDOR_PRIMARY_PACKAGE_INCLUDED_SERVICES_BITFIELD: [],
    VENDOR_TRAVEL_LODGING: [
      {
        metadataString: "Cost of stay and travel borne by client",
        metadataValue: false,
      },
      {
        metadataString:
          "Cost of stay borne by client and travel by photographer",
        metadataValue: true,
      },
    ],
    VENDOR_PLANNER_USER_CANCELLATION: [
      {
        metadataString: "Partial Refund Offered",
        metadataValue: "Partial Refund Offered",
      },
      {
        metadataString: "No Refund Offered",
        metadataValue: "No Refund Offered",
      },
      {
        metadataString: "No Refund Offered However Date Adjustment Can Be Done",
        metadataValue: "No Refund Offered However Date Adjustment Can Be Done",
      },
      {
        metadataString: "Full Refund Offered",
        metadataValue: "Full Refund Offered",
      },
    ],
    VENDOR_PLANNER_VENDOR_CANCELLATION: [
      {
        metadataString: "Partial Refund Offered",
        metadataValue: "Partial Refund Offered",
      },
      {
        metadataString: "No Refund Offered",
        metadataValue: "No Refund Offered",
      },
      {
        metadataString: "Full Refund Offered",
        metadataValue: "Full Refund Offered",
      },
    ],
    VENDOR_WED_DECOR_MIN_OUT_PRICE: [
      {
        metadataValue: 0,
        metadataString: `price: Rs. {0}`,
      },
    ],
    VENDOR_WED_DECOR_MAX_OUT_PRICE: [
      {
        metadataValue: 0,
        metadataString: `price: Rs. {0}`,
      },
    ],
    VENDOR_WED_DECOR_MIN_IN_PRICE: [
      {
        metadataValue: 0,
        metadataString: `price: Rs. {0}`,
      },
    ],
    VENDOR_WED_DECOR_MAX_IN_PRICE: [
      {
        metadataValue: 0,
        metadataString: `price: Rs. {0}`,
      },
    ],
    VENDOR_HONEYMOON_PACKAGES_SERVICES_LIST: [
      {
        metadataValue: [],
        metadataString: "Associated with {0}",
      },
    ],
    VENDOR_TRAVEL_AGENTS_TRIPS: [
      {
        metadataString: "Within India Only",
        metadataValue: "Only within India",
      },
      {
        metadataString: "International Trips Only",
        metadataValue: "Only International",
      },
      {
        metadataString: "Both Domestic and International trips available",
        metadataValue: "Both Trips avaiable",
      },
    ],
    VENDOR_BRIDAL_WEAR_TYPE_BITFIELD: [
      {
        metadataValue: 1,
        metadataString: "Custom designed outfits from scratch",
      },
      {
        metadataValue: 2,
        metadataString: "Ready to purchase outfits",
      },
      {
        metadataValue: 4,
        metadataString: "Sample pieces on which orders can be placed",
      },
    ],
    VENDOR_INVITATION_SHIPPING: [
      {
        metadataString: "Domestic shipping available",
        metadataValue: "Domestic shipping available",
      },
      {
        metadataString: "International shipping available",
        metadataValue: "International shipping available",
      },
      {
        metadataString: "Both domestic and International shipping available",
        metadataValue: "Both domestic and International shipping available",
      },
      {
        metadataString: "Shipping options not available",
        metadataValue: "Shipping options not available",
      },
    ],
    VENDOR_INVITES_ECARD_CHARGES: [
      {
        metadataValue: 0,
        metadataString: `price: Rs. {0}`,
      },
    ],
    VENDOR_SANGEET_CHOREOGRAPHER_CHARGES: [
      {
        metadataValue: 0,
        metadataString: `price: Rs. {0}`,
      },
    ],
    VENDOR_WED_PACKAGE_INCLUDES_BITFIELD: [
      {
        metadataValue: 1,
        metadataString: "Includes equipment",
      },
      {
        metadataValue: 2,
        metadataString: "Lighting",
      },
    ],
    VENDOR_ACCESSORIES_TYPE_BITFIELD: [
      {
        metadataValue: 1,
        metadataString: "Shoes",
      },
      {
        metadataValue: 2,
        metadataString: "Clutches and Bags",
      },
      {
        metadataValue: 4,
        metadataString: "Tit bits for Mehendi Favors",
      },
      {
        metadataValue: 8,
        metadataString: "Bridal-Chura",
      },
      {
        metadataValue: 16,
        metadataString: "Bridal-kalire",
      },
    ],
    VENDOR_ACCESSORIES_RENTAL: [
      {
        metadataValue: true,
        metadataString: "Accessories avaiable on rent",
      },
      {
        metadataValue: false,
        metadataString: "Accessories are not avaiable on rent",
      },
    ],
    VENDOR_ACCESSORIES_SHIPPING: [
      {
        metadataString: "Domestic shipping available",
        metadataValue: "Domestic shipping available",
      },
      {
        metadataString: "Both domestic and International shipping available",
        metadataValue: "Both domestic and International shipping available",
      },
      {
        metadataString: "Shipping options not available",
        metadataValue: "Shipping options not available",
      },
    ],
    VENDOR_ACCESSORIES_START_PRICE: [
      {
        metadataString: "Price - 500+",
        metadataValue: "500+",
      },
      {
        metadataString: "Price - 1000+",
        metadataValue: "1000+",
      },
      {
        metadataString: "Price - 1500+",
        metadataValue: "1500+",
      },
      {
        metadataString: "Price - 2000+",
        metadataValue: "2000+",
      },
      {
        metadataString: "Price - 3000+",
        metadataValue: "3000+",
      },
    ],
    VENDOR_JEWELLER_PRODUCTS: [
      {
        metadataValue: [],
        metadataString: "Associated with {0}",
      },
    ],
    VENDOR_ARTIFICIAL_JEWELLERY_TYPES_BITFIELD: [
      {
        metadataValue: 1,
        metadataString: "Semi Precious Jewellery",
      },
      {
        metadataValue: 2,
        metadataString: "Floral or Gota Jewellery",
      },
      {
        metadataValue: 4,
        metadataString: "Artificial Jewellery on rent",
      },
    ],
    VENDOR_ARTIFICIAL_JEWELLERY_CUSTOMIZATION: [
      {
        metadataValue: true,
        metadataString: "Yes, We do",
      },
      {
        metadataValue: false,
        metadataString: "No, we do not",
      },
    ],
    VENDOR_ARTIFICIAL_JEWELLERY_BRIDAL_PACKAGE: [
      {
        metadataValue: 0,
        metadataString: `price: Rs. {0}`,
      },
    ],
    VENDOR_ARTIFICIAL_JEWELLERY_B_TYPE: [
      {
        metadataString: "Family Jewellery House",
        metadataValue: "Family Jewellery House",
      },
      {
        metadataString: "National Jewellery Brand",
        metadataValue: "National Jewellery Brand",
      },
      {
        metadataString: "Online Store",
        metadataValue: "Online Store",
      },
    ],
    VENDOR_FLORISTS_PRODUCTS: [
      {
        metadataValue: [],
        metadataString: `Associations are {0}`,
      },
    ],
    VENDOR_BARTENDERS_RANGE: [
      {
        metadataString: "Less than 5",
        metadataValue: "1-5",
      },
      {
        metadataString: "6-10",
        metadataValue: "6-10",
      },
      {
        metadataString: "11-20",
        metadataValue: "11-20",
      },
      {
        metadataString: "21-40",
        metadataValue: "21-40",
      },
      {
        metadataString: "More than 40",
        metadataValue: "40+",
      },
    ],
    VENDOR_WEIGHT_LOSS_CLINICS_PRODUCTS: [
      {
        metadataValue: [],
        metadataString: `Associations are {0}`,
      },
    ],
    VENDOR_BAND_PRODUCTS: [
      {
        metadataValue: [],
        metadataString: `Associations are {0}`,
      },
    ],
    VENDOR_FOOD_BARTENDER_TYPES: [
      {
        metadataValue: 1,
        metadataString: "General Bartenders",
      },
      {
        metadataValue: 2,
        metadataString: "Flair Bartenders",
      },
      {
        metadataValue: 4,
        metadataString: "International Bartenders",
      },
    ],
    VENDOR_FOOD_BARTENDER_AVG_CHARGES: [
      {
        metadataValue: 0,
        metadataString: `price: Rs. {0}`,
      },
    ],
    VENDOR_MEHENDI_ARTIST_CHARGES: [
      {
        metadataValue: 0,
        metadataString: `price: Rs. {0}`,
      },
    ],
    VENDOR_MEHENDI_ARTIST_FAMILY_CHARGES: [
      {
        metadataValue: 0,
        metadataString: `price: Rs. {0}`,
      },
    ],
    VENDOR_CATERING_SERVICES_USER_CANCELLATION: [
      {
        metadataString: "Partial Refund Offered",
        metadataValue: "Partial Refund Offered",
      },
      {
        metadataString: "No Refund Offered",
        metadataValue: "No Refund Offered",
      },
      {
        metadataString: "No Refund Offered However Date Adjustment Can Be Done",
        metadataValue: "No Refund Offered However Date Adjustment Can Be Done",
      },
      {
        metadataString: "Full Refund Offered",
        metadataValue: "Full Refund Offered",
      },
    ],
    VENDOR_CATERING_SERVICES_VENDOR_CANCELLATION: [
      {
        metadataString: "Partial Refund Offered",
        metadataValue: "Partial Refund Offered",
      },
      {
        metadataString: "No Refund Offered",
        metadataValue: "No Refund Offered",
      },
      {
        metadataString: "Full Refund Offered",
        metadataValue: "Full Refund Offered",
      },
    ],
    VENDOR_MITHAI_LIST: [
      {
        metadataValue: [],
        metadataString: "Associated with {0}",
      },
    ],
    VENDOR_WEIGHT_LOSS_CLINICS_CHARGES: [
      {
        metadataString: "Price - Less than 300",
        metadataValue: "<300",
      },
      {
        metadataString: "Price - 400",
        metadataValue: "400",
      },
      {
        metadataString: "Price - 500",
        metadataValue: "500",
      },
      {
        metadataString: "Price - greater than 500",
        metadataValue: ">500",
      },
    ],
    VENDOR_HOME_CATERING_FOOD_CUISINE_BITFIELD: [
      {
        metadataString: "North Indian",
        metadataValue: 1,
      },
      {
        metadataString: "South Indian",
        metadataValue: 2,
      },
      {
        metadataString: "Chinese",
        metadataValue: 4,
      },
      {
        metadataString: "Japanese",
        metadataValue: 8,
      },
      {
        metadataString: "Italian",
        metadataValue: 16,
      },
      {
        metadataString: "Lebanese",
        metadataValue: 32,
      },
      {
        metadataString: "Thai",
        metadataValue: 64,
      },
      {
        metadataString: "Bengali",
        metadataValue: 128,
      },
      {
        metadataString: "Gujarati",
        metadataValue: 256,
      },
      {
        metadataString: "Rajasthani",
        metadataValue: 512,
      },
      {
        metadataString: "Maharashtrian",
        metadataValue: 1024,
      },
      {
        metadataString: "Goan",
        metadataValue: 2048,
      },
      {
        metadataString: "Maharashtrian",
        metadataValue: 4096,
      },
      {
        metadataString: "Other Exotic",
        metadataValue: 8192,
      },
    ],
    VENDOR_FOOD_BARTENDER_TYPES_BITFIELD: [
      {
        metadataString: "General Bartenders",
        metadataValue: 1,
      },
      {
        metadataString: "Flair Bartenders",
        metadataValue: 2,
      },
      {
        metadataString: "International Bartenders",
        metadataValue: 4,
      },
    ],
    VENDOR_CAKE_SPECIALIZE_BITFIELD: [
      {
        metadataString: "Cake in a jar",
        metadataValue: 1,
      },
      {
        metadataString: "Ice Cream cakes",
        metadataValue: 2,
      },
      {
        metadataString: "Wedding cakes",
        metadataValue: 4,
      },
      {
        metadataString: "Cupcakes",
        metadataValue: 8,
      },
      {
        metadataString: "Chandelier Cakes",
        metadataValue: 16,
      },
      {
        metadataString: "For a wedding cake (fondant cake)",
        metadataValue: 32,
      },
    ],
    VENDOR_WED_PACKAGE_SERVICES_BITFIELD: [
      {
        metadataString: "Pre-Wedding (Roka)",
        metadataValue: 1,
      },
      {
        metadataString: "Wedding",
        metadataValue: 2,
      },
      {
        metadataString: "Sagan",
        metadataValue: 4,
      },
      {
        metadataString: "Cocktail Party",
        metadataValue: 8,
      },
      {
        metadataString: "Bachelor/ Spinster Party",
        metadataValue: 16,
      },
      {
        metadataString: "Private Party",
        metadataValue: 32,
      },
    ],
    VENDOR_WED_DECOR_USER_CANCELLATION: [
      {
        metadataString: "Partial Refund Offered",
        metadataValue: "Partial Refund Offered",
      },
      {
        metadataString: "No Refund Offered",
        metadataValue: "No Refund Offered",
      },
      {
        metadataString: "No Refund Offered However Date Adjustment Can Be Done",
        metadataValue: "No Refund Offered However Date Adjustment Can Be Done",
      },
      {
        metadataString: "Full Refund Offered",
        metadataValue: "Full Refund Offered",
      },
    ],
    VENDOR_WED_DECOR_VENDOR_CANCELLATION: [
      {
        metadataString: "Partial Refund Offered",
        metadataValue: "Partial Refund Offered",
      },
      {
        metadataString: "No Refund Offered",
        metadataValue: "No Refund Offered",
      },
      {
        metadataString: "Full Refund Offered",
        metadataValue: "Full Refund Offered",
      },
    ],
    VENDOR_WED_DECOR_BITFIELD: [
      {
        metadataString: "Floral Decor",
        metadataValue: 1,
      },
      {
        metadataString: "Mandap Decor",
        metadataValue: 2,
      },
      {
        metadataString: "Stage Decor",
        metadataValue: 4,
      },
      {
        metadataString: "Photo Booth",
        metadataValue: 8,
      },
    ],
    VENDOR_INVITATION_GIFTS_STORE_BITFIELD: [
      {
        metadataString: "Online",
        metadataValue: 1,
      },
      {
        metadataString: "Offline",
        metadataValue: 2,
      },
    ],
    VENDOR_INVITATIONS_STORE_TYPE_BITFIELD: [
      {
        metadataString: "Online",
        metadataValue: 1,
      },
      {
        metadataString: "Offline",
        metadataValue: 2,
      },
    ],
    VENDOR_INVITATIONS_SERVICES_TYPE_BITFIELD: [
      {
        metadataString: "Unboxed Invites",
        metadataValue: 1,
      },
      {
        metadataString: "Boxed Invites",
        metadataValue: 2,
      },
      {
        metadataString: "Digital E- Cards",
        metadataValue: 4,
      },
    ],
    VENDOR_INVITATIONS_ECO_CUSTOMIZATION_OPTION: [
      {
        metadataValue: false,
        metadataString: "No",
      },
      {
        metadataValue: true,
        metadataString: "Yes (Or on special request)",
      },
    ],
    VENDOR_WED_ENT_TRAVEL: [
      {
        metadataValue: false,
        metadataString: "No, our staff cannot travel to other cities",
      },
      {
        metadataValue: true,
        metadataString: "Yes, our staff can travel to other cities",
      },
    ],
    VENDOR_TROUSSEAU_ECO_MATERIAL: [
      {
        metadataValue: false,
        metadataString: "No",
      },
      {
        metadataValue: true,
        metadataString: "Yes (Or on special request)",
      },
    ],
    VENDOR_TROUSSEAU_SAMPLES: [
      {
        metadataString: "Yes, we provide samples",
        metadataValue: true,
      },
      {
        metadataString: "No, we do not provide samples",
        metadataValue: false,
      },
    ],
    VENDOR_TROUSSEAU_COST_ADJ: [
      {
        metadataString:
          "We do not adjust sample costs after booking confirmations.",
        metadataValue: false,
      },
      {
        metadataString: "We adjust sample costs after booking confirmation",
        metadataValue: true,
      },
    ],
    VENDOR_TROUSSEAU_STORE_TYPE_BITFIELD: [
      {
        metadataString: "Online",
        metadataValue: 1,
      },
      {
        metadataString: "Offline",
        metadataValue: 2,
      },
    ],
    VENDOR_TROUSSEAU_FREE_SAMPLES: [
      {
        metadataString: "We provide free samples to the client",
        metadataValue: false,
      },
      {
        metadataString: "We provide samples to the client at a nominal cost",
        metadataValue: true,
      },
    ],
    VENDOR_INVITATION_GIFTS_SAMPLES: [
      {
        metadataString: "Yes, we provide free or paid samples",
        metadataValue: true,
      },
      {
        metadataString: "No, we do not provide free or paid samples",
        metadataValue: false,
      },
    ],
    VENDOR_DJS_PACKAGE_INCLUDES_BITFIELD: [
      {
        metadataValue: 1,
        metadataString: "Includes equipment",
      },
      {
        metadataValue: 2,
        metadataString: "Lighting",
      },
    ],
    VENDOR_DJS_CONCELLATION_POLICY: [
      {
        metadataValue: false,
        metadataString: "We do not offer refunds or adjustments",
      },
      {
        metadataValue: true,
        metadataString: "We refund a negotiable percent of the booking amount",
      },
    ],
    VENDOR_DJS_RESTRICTED_TRAVEL: [
      {
        metadataValue: false,
        metadataString: "No, our staff cannot travel to other cities",
      },
      {
        metadataValue: true,
        metadataString: "Yes, our staff can travel to other cities",
      },
    ],
    VENDOR_DHOL_RESTRICTED_TRAVEL: [
      {
        metadataValue: false,
        metadataString: "No, our staff cannot travel to other cities",
      },
      {
        metadataValue: true,
        metadataString: "Yes, our staff can travel to other cities",
      },
    ],
    VENDOR_MAANGAL_GEET_RESTRICTED_TRAVEL: [
      {
        metadataValue: false,
        metadataString: "No, our staff cannot travel to other cities",
      },
      {
        metadataValue: true,
        metadataString: "Yes, our staff can travel to other cities",
      },
    ],
    VENDOR_DERMATOLOGY_CLINICS_CHARGES: [
      {
        metadataString: "Price - Less than 300",
        metadataValue: "<300",
      },
      {
        metadataString: "Price - 400",
        metadataValue: "400",
      },
      {
        metadataString: "Price - 500",
        metadataValue: "500",
      },
      {
        metadataString: "Price - greater than 500",
        metadataValue: ">500",
      },
    ],
    VENDOR_WEIGHT_LOSS_CLINICS_EXP: [
      {
        metadataString: "Less than 1 year",
        metadataValue: "<1",
      },
      {
        metadataString: "1-3 years",
        metadataValue: "1-3",
      },
      {
        metadataString: "3-5 years",
        metadataValue: "3-5",
      },
      {
        metadataString: "More than 5 years",
        metadataValue: ">5",
      },
    ],
    VENDOR_TRAVEL_AGENTS_SPECIALIZE_BITFIELD: [
      {
        metadataValue: 1,
        metadataString: "Cruises",
      },
      {
        metadataValue: 2,
        metadataString: "Family Trips",
      },
      {
        metadataValue: 4,
        metadataString: "Honeymoons",
      },
      {
        metadataValue: 8,
        metadataString: "Luxury Trips",
      },
    ],
    VENDOR_SANGEET_CHOREO_RESTRICTED_TRAVEL: [
      {
        metadataValue: false,
        metadataString: "No, our staff cannot travel to other cities",
      },
      {
        metadataValue: true,
        metadataString: "Yes, our staff can travel to other cities",
      },
    ],
    VENDOR_GROOM_WEAR_STORE_TYPE_BITFIELD: [
      {
        metadataValue: 1,
        metadataString: "Studio / Boutique",
      },
      {
        metadataValue: 2,
        metadataString: "Retail Store",
      },
      {
        metadataValue: 4,
        metadataString: "Couture Brand",
      },
      {
        metadataValue: 8,
        metadataString: "Multi Designer Store",
      },
    ],
    VENDOR_TRAVEL_AGENTS_SERVICES_BITFIELD: [
      {
        metadataValue: 1,
        metadataString: "Emergency Assistance",
      },
      {
        metadataValue: 2,
        metadataString: "Travel Insurance",
      },
    ],
    VENDOR_INVITATIONS_SAMPLE_PAID: [
      {
        metadataString: "We provide free samples to the client",
        metadataValue: false,
      },
      {
        metadataString: "We provide samples to the client at a nominal cost",
        metadataValue: true,
      },
    ],
    VENDOR_INVITATIONS_SAMPLES: [
      {
        metadataString: "No, we do not provide free or paid samples",
        metadataValue: false,
      },
      {
        metadataString: "Yes, we provide free or paid samples",
        metadataValue: true,
      },
    ],
    VENDOR_SANGEET_CHOREO_INCLUDES_BITFIELD: [
      {
        metadataString: "Backup dancers",
        metadataValue: 1,
      },
      {
        metadataString: "Props",
        metadataValue: 2,
      },
    ],
  },
};

export const METADATA_QUESTIONS = {
  DEFAULTS: {
    VENDOR_LISTING_DATE: "Listed since?",
    VENDOR_SERVICE_DATE: "Providing services since?",
    VENDOR_PREMIUM_DATE: "Created At?",
    VENDOR_TRAVEL_COSTS: "Price includes travel costs?",
    VENDOR_PARTIAL_REFUND: "Partial Refunds?",
    VENDOR_CONSULTATION_OFFERED: "Consultation Offered?",
    VENDOR_MAX_QUANTITY: "Maximum order quantity?",
    VENDOR_MIN_QUANTITY: "Minimum order quantity?",
    VENDOR_MAKEUP_AIRBRUSH: "AirBrush makeup?",
    VENDOR_MAKEUP_HD: "HD makeup?",
    VENDOR_CONSULTATION_PAID: "Consultation charges?",
    VENDOR_DATE_ADJUSTMENT: "Flexible event dates?",
    VENDOR_SHIPPING: "Shipping provided?",
    VENDOR_ASSOCIATION_LIST: "Associated with?",
    VENDOR_ADVANCE_PAYMENT_PERCENT: "Advance Payment?",
    VENDOR_CUSTOMIZATION_OPTION: "Customization?",
    VENDOR_FOOD_ONSITE_PREPARATION: "On-location food preparation?",
    VENDOR_TRAVEL_LODGING: "Travel and Lodging?",
    VENDOR_INVITATION_BASE_PRICE_RANGE: "Base price for a wedding card?",
    VENDOR_SHOOT_LOCATION: "Shoot location cost?",
    VENDOR_BEAUTY_SALON_MAKEUP_BITFIELD: "Bridal Makeup include?",
    VENDOR_FREELANCE_BRIDAL_MAKEUP_BITFIELD: "Bridal Makeup include?",
    VENDOR_DELIVERY_TIME_DAYS: "Delivery time?",
    VENDOR_STAFF_COUNT_MIN: "Minimum Staff?",
    VENDOR_STAFF_COUNT_MAX: "Maximum Staff?",
    VENDOR_SERVICE_TRIAL: "Trial Offered?",
    VENDOR_SHIPPING_INTERNATIONAL: "International shipping?",
    VENDOR_TRIAL_ADJUSTMENT: "Trial costs adjusted after booking?",
    VENDOR_REFUND_OFFERED: "Refunds?",
    VENDOR_CATERING_NON_VEG: "Non-vegetarian food?",
    VENDOR_SERVICE_PRICING_RELATIVE: "Pricing policy?",
    VENDOR_SERVICE_RELATIVE_PERCENTAGE: "Booking price percentage?",
    VENDOR_TRIAL_PAID: "Paid or free trials?",
    VENDOR_SMALL_FUNCTION_PACKAGE_PRICE: "Price for a small function?",
    VENDOR_RESTRICTED_TRAVEL: "Travel restrictions?",
    VENDOR_RENTAL_SERVICE: "Rental service?",
    VENDOR_TAILORED_SERVICE: "Off-the-shelf or customized items?",
    VENDOR_SOCIAL_YT_LINK: "",
    VENDOR_SOCIAL_WS_LINK: "",
    VENDOR_SOCIAL_IG_LINK: "",
    VENDOR_SOCIAL_FB_LINK: "",
    VENDOR_PLANNER_DECOR_POLICY: "Decor Policy?",
    VENDOR_PRODUCTS_USED: "Which products do you use?",
    VENDOR_OUTSIDE_HOMETOWN_CHARGES: "Outside hometown charges?",
    VENDOR_EXPERIENCE_LIST: "With which clients have you worked?",
    VENDOR_FOOD_NON_VEG_PLATE_PRICE: "Approximate price for a Non-Veg plate?",
    VENDOR_FOOD_VEG_PLATE_PRICE: "Approximate price for a Veg plate?",
    VENDOR_STORE_TYPE_BITFIELD: "Type of store?",
    VENDOR_FOOD_ADDITIONAL_SERVICE_BITFIELD: "Additional Services?",
    VENDOR_FOOD_CUISINE_BITFIELD: "Specialized Cuisines?",
    VENDOR_PRIMARY_PACKAGE_INCLUDED_SERVICES_BITFIELD:
      "Services included in package?",
    VENDOR_FOOD_SPECIALIZATION_BITFIELD: "Food Specialization?",
    VENDOR_PLANNER_USER_CANCELLATION: "On User's Cancellation?",
    VENDOR_PLANNER_VENDOR_CANCELLATION: "On Vendor's Cancellation?",
    VENDOR_WED_DECOR_MIN_OUT_PRICE: "Minimum outdoor price?",
    VENDOR_WED_DECOR_MAX_OUT_PRICE: "Maximum outdoor price?",
    VENDOR_WED_DECOR_MIN_IN_PRICE: "Minimum indoor price?",
    VENDOR_WED_DECOR_MAX_IN_PRICE: "Maximum indoor price?",
    VENDOR_HONEYMOON_PACKAGES_SERVICES_LIST: "Associated with?",
    VENDOR_TRAVEL_AGENTS_LIST: "Associated with?",
    VENDOR_TRAVEL_AGENTS_TRIPS: "Organises trips to?",
    VENDOR_BRIDAL_WEAR_TYPE_BITFIELD: "Types of outfits?",
    VENDOR_INVITATION_SHIPPING: "Shipping?",
    VENDOR_INVITES_ECARD_CHARGES: "E-Card charges?",
    VENDOR_SANGEET_CHOREOGRAPHER_CHARGES: "Destination Choreography charges?",
    VENDOR_WED_PACKAGE_INCLUDES_BITFIELD: "Package includes?",
    VENDOR_ACCESSORIES_TYPE_BITFIELD: "Type of accessories?",
    VENDOR_ACCESSORIES_RENTAL: "Accessories on rent?",
    VENDOR_ACCESSORIES_START_PRICE: "Starting price?",
    VENDOR_ACCESSORIES_SHIPPING: "Shipping available?",
    VENDOR_JEWELLER_PRODUCTS: "Products/Services offered?",
    VENDOR_ARTIFICIAL_JEWELLERY_TYPES_BITFIELD: "Our Speciality?",
    VENDOR_ARTIFICIAL_JEWELLERY_CUSTOMIZATION: "Color or design customization",
    VENDOR_ARTIFICIAL_JEWELLERY_BRIDAL_PACKAGE:
      "Bridal jewellery starting range?",
    VENDOR_BARTENDERS_RANGE: "Bartenders ranger?",
    VENDOR_ARTIFICIAL_JEWELLERY_B_TYPE: "Business type?",
    VENDOR_FLORISTS_PRODUCTS: "Associations are?",
    VENDOR_WEIGHT_LOSS_CLINICS_PRODUCTS: "Associations are?",
    VENDOR_BAND_PRODUCTS: "Associations are?",
    VENDOR_FOOD_BARTENDER_TYPES: "Bartenders we provide?",
    VENDOR_FOOD_BARTENDER_AVG_CHARGES: "Charges for 200 gathering?",
    VENDOR_MEHENDI_ARTIST_CHARGES: "Charges for both hands?",
    VENDOR_MEHENDI_ARTIST_FAMILY_CHARGES: "Charges per family member?",
    VENDOR_CATERING_SERVICES_USER_CANCELLATION: "On User's Cancellation?",
    VENDOR_CATERING_SERVICES_VENDOR_CANCELLATION: "On Vendor's Cancellation?",
    VENDOR_MITHAI_LIST: "Associated with?",
    VENDOR_WEIGHT_LOSS_CLINICS_CHARGES: "Charges for consultation?",
    VENDOR_HOME_CATERING_FOOD_CUISINE_BITFIELD: "Cuisines We offer?",
    VENDOR_FOOD_BARTENDER_TYPES_BITFIELD: "Bartender types?",
    VENDOR_CAKE_SPECIALIZE_BITFIELD: "Our Speciality ?",
    VENDOR_WED_PACKAGE_SERVICES_BITFIELD: "Our Services?",
    VENDOR_WED_DECOR_USER_CANCELLATION: "On User's Cancellation?",
    VENDOR_WED_DECOR_VENDOR_CANCELLATION: "On Vendor's Cancellation?",
    VENDOR_WED_DECOR_BITFIELD: "Decor services provided",
    VENDOR_INVITATION_GIFTS_STORE_BITFIELD: "Store type?",
    VENDOR_INVITATIONS_STORE_TYPE_BITFIELD: "Store type?",
    VENDOR_INVITATIONS_SERVICES_TYPE_BITFIELD: "Services type?",
    VENDOR_INVITATIONS_ECO_CUSTOMIZATION_OPTION: "Eco-friendly material used?",
    VENDOR_WED_ENT_TRAVEL: "Travelling to other cities?",
    VENDOR_TROUSSEAU_ECO_MATERIAL: "Eco material used ?",
    VENDOR_TROUSSEAU_SAMPLES: "Samples provided ?",
    VENDOR_TROUSSEAU_COST_ADJ: "Cost adjustment after order?",
    VENDOR_TROUSSEAU_STORE_TYPE_BITFIELD: "Store type?",
    VENDOR_INVITATION_GIFTS_SAMPLES: "Samples provided?",
    VENDOR_DJS_PACKAGE_INCLUDES_BITFIELD: "Package includes?",
    VENDOR_DJS_CONCELLATION_POLICY: "Cancellation policy?",
    VENDOR_DJS_RESTRICTED_TRAVEL: "Restricted travel?",
    VENDOR_DHOL_RESTRICTED_TRAVEL: "Restricted travel?",
    VENDOR_MAANGAL_GEET_RESTRICTED_TRAVEL: "Restricted travel?",
    VENDOR_DERMATOLOGY_CLINICS_CHARGES: "Consultation charges?",
    VENDOR_WEIGHT_LOSS_CLINICS_EXP: "Experience ?",
    VENDOR_TRAVEL_AGENTS_SPECIALIZE_BITFIELD: "Specialize in?",
    VENDOR_SANGEET_CHOREO_RESTRICTED_TRAVEL: "Restricted travel?",
    VENDOR_GROOM_WEAR_STORE_TYPE_BITFIELD: "Store type?",
    VENDOR_TRAVEL_AGENTS_SERVICES_BITFIELD: "Services include?",
    VENDOR_INVITATIONS_SAMPLE_PAID: "Paid samples?",
    VENDOR_INVITATIONS_SAMPLES: "Samples provided?",
    VENDOR_SANGEET_CHOREO_INCLUDES_BITFIELD: "Packages include?",

    //fitness center
    VENDOR_FITNESS_CENTER_EXP: "Experience ?",
    VENDOR_FITNESS_CENTER_QUALITY_CERTIFICATION: "Quality & Certification",
    VENDOR_FITNESS_CENTER_PERSONAL_TRAINING: "Personal Training Charges",
    VENDOR_FITNESS_CENTER_PRODUCTS_OFFERED: "Products Offered ?",

    //ayurvedic clinics
    VENDOR_AYURVEDIC_CLINICS_CONSULTATION: "Consultation Charges ?",
    VENDOR_AYURVEDIC_CLINICS_EXP: "Experience ?",
    VENDOR_AYURVEDIC_CLINICS_QUALITY_CERTIFICATION: "Quality & Certification",
    VENDOR_AYURVEDIC_CLINICS_PRODUCTS_OFFERED: "Products Offered ?",

    //BRIDAL_GROOMIN   BEAUTY_AND_WELLNESS
    VENDOR_BRIDAL_GROOMING_BEAUTY_AND_WELLNESS_SERVICE_MAKEUP_BITFIELD:"Our Services?",
    VENDOR_BRIDAL_GROOMING_BEAUTY_AND_WELLNESS_CONSUL_MAKEUP_BITFIELD:"Consultation charges?",


  },
};

export function getMetadataAnswer(
  vendorCategory,
  vendorSubcategory,
  metadataKey,
  metadataValue
) {
  const answerData =
    // @ts-ignore
    ((METADATA_ANSWERS[vendorCategory] || {})[vendorSubcategory] || {})[
    metadataKey
    ] || METADATA_ANSWERS.DEFAULTS[metadataKey];
  if (answerData === undefined) {
    return "N/A";
  }
  if (typeof metadataValue == "boolean") {
    const answerValues = answerData;
    const matchingAnswerValue = answerValues.filter((eachAns) => {
      return eachAns.metadataValue === metadataValue;
    });
    const maybeMatchingAnswer = matchingAnswerValue[0];
    if (maybeMatchingAnswer === undefined) {
      return "N/A";
    }
    return maybeMatchingAnswer.metadataString;
  }

  if (metadataKey.endsWith("BITFIELD") && typeof metadataValue === "number") {
    const bitfieldValues = [];

    const answerValues = answerData;
    answerValues.forEach((answerVal) => {
      if (answerVal.metadataValue & metadataValue) {
        bitfieldValues.push(answerVal.metadataString);
      }
    });
    if (bitfieldValues.length == 0) {
      return "N/A";
    }
    const joinedFieldValue = bitfieldValues.join(", ");
    return joinedFieldValue;
  }

  if (typeof metadataValue == "string") {
    if (metadataKey.endsWith("DATE")) {
      const parsedValue = new Date(metadataValue);

      const day = String(parsedValue.getDate()).padStart(2, "0");
      const month = String(parsedValue.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month because months are 0-indexed
      const year = parsedValue.getFullYear();

      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    }

    const answerValues = answerData;
    const matchingAnswerValue = answerValues.filter((eachAns) => {
      return eachAns.metadataValue === metadataValue;
    });

    const maybeMatchingAnswer = matchingAnswerValue[0] ? matchingAnswerValue[0] : { metadataString: "{0}" };

    if (maybeMatchingAnswer === undefined) {
      return "N/A";
    }

    const templateFilledValue = maybeMatchingAnswer.metadataString.replace(
      `{0}`,
      metadataValue
    );

    return templateFilledValue;
  }

  if (typeof metadataValue == "number") {
    if (answerData.length === 0 || metadataValue === undefined) {
      return "N/A";
    }

    const templateFilledValue = answerData[0].metadataString.replace(
      `{0}`,
      metadataValue
    );

    return templateFilledValue;
  }

  if (Array.isArray(metadataValue)) {
    const joinedValue = metadataValue.join(", ");
    const answerValues = answerData;
    const maybeMatchingAnswer = answerValues[0];
    if (maybeMatchingAnswer === undefined) {
      return "N/A";
    }

    if (metadataValue.length === 0) {
      return maybeMatchingAnswer.metadataString.replace(`{0}`, "N/A");
    }

    const replacedValue = maybeMatchingAnswer.metadataString.replace(
      `{0}`,
      joinedValue
    );

    return replacedValue;
  }

  return "N/A";
}

function getMetadataQuestion(vendorCategory, vendorSubcategory, metadataKey) {
  const questionText =
    // @ts-ignore
    ((METADATA_QUESTIONS[vendorCategory] || {})[vendorSubcategory] || {})[
    metadataKey
    ] || METADATA_QUESTIONS.DEFAULTS[metadataKey];
  return questionText;
}

export function getMetadataQuestionAndAnswer(
  vendorCategory,
  vendorSubcategory,
  metadataKey,
  metadataValue
) {
  const hiddenKeys = [
    "VENUE_LISTING_DATE",
    "VENDOR_LISTING_DATE",
    "VENUE_PREMIUM_DATE",
    "VENDOR_PREMIUM_DATE",
    "VENUE_SOCIAL_FB_LINK",
    "VENDOR_SOCIAL_FB_LINK",
    "VENUE_SOCIAL_WS_LINK",
    "VENDOR_SOCIAL_WS_LINK",
    "VENUE_SOCIAL_IG_LINK",
    "VENDOR_SOCIAL_IG_LINK",
    "VENUE_SOCIAL_YT_LINK",
    "VENDOR_SOCIAL_YT_LINK",
  ];

  if (hiddenKeys.includes(metadataKey)) {
    return [null, null];
  }

  return [
    getMetadataQuestion(vendorCategory, vendorSubcategory, metadataKey),
    getMetadataAnswer(
      vendorCategory,
      vendorSubcategory,
      metadataKey,
      metadataValue
    ),
  ];
}

const VendorMetaData = ({ data }) => {

  return (
    <Grid container spacing={2}>
      {data?.vendorMetadata?.filter((data, index) => {
        if (data?.propertyValue.toString().trim().length > 0)
          return true
        return false
      }).map((metadataObj) => {

        const [metadataQuestion, metadataAnswer] = getMetadataQuestionAndAnswer(
          data?.vendorCategory,
          data?.vendorSubcategory,
          metadataObj.propertyName,
          metadataObj.propertyValue
        );

        if (metadataQuestion === null || (metadataAnswer === null||metadataAnswer=="N/A")) {
          return null;
        }

        return (
          <Grid item xs={12} sm={6} md={4} key={metadataObj.propertyName}>
            <Details>
              <DetailsHead>{metadataQuestion}</DetailsHead>
              <DetailsText>{metadataAnswer}</DetailsText>
            </Details>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default VendorMetaData;
