import { useLocation } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './components/Landing-Page/LandingPage';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import ProductPage from './components/Product-Page/ProductPage';
import VenueDetails from './components/Product-Page/product-details/VenueDetails';
import Login from './components/Login-Page/Login';
import Chat from './components/Chat/Chat';
import Saved from './components/Product-Page/Saved';
import Profile from './components/Profile/Profile';
import { useState } from 'react';
import Vendors from './components/Product-Page/Vendors/Vendors';
import VendorListings from './components/Product-Page/wedding-venues/Vendors';
import { useEffect } from 'react';
import { createContext } from 'react';
import VendorDetails from './components/Product-Page/product-details/VendorDetails';
import Venues from './components/Product-Page/wedding-venues/Venues';
import Magazine from './components/magazine/magazine';
import MyPDFViewer from './components/magazine/magazine';
import Blog from './components/blog/blog';
import { CategoryBlog } from './components/blog/blogCategory';
import magazinePage from './components/magazine/magazinePage';
import MagazinePage from './components/magazine/magazinePage';
import ReadMagazine from './components/magazine/readMagazine';

export const LogInContext = createContext();
export const LocationContext = createContext();

function Layout() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [geoLocation, setGeoLocation] = useState();
  const location = useLocation();
  const isLoginPage = location.pathname === '/login'||location.pathname.slice(0,9) === '/magazine';
  const [clickedVendorTag, setClickedVendorTag] = useState('');

  useEffect(() => {
    const storedVendorTag = localStorage.getItem('clickedVendorTag');
    if (storedVendorTag) {
      setClickedVendorTag(storedVendorTag);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem('clickedVendorTag', clickedVendorTag);
    window.moveTo(0, 0);
  }, [clickedVendorTag]);

  return (
    <>
      <LogInContext.Provider value={{ setIsLoggedIn, isLoggedIn }}>
        <LocationContext.Provider value={{ geoLocation, setGeoLocation }}>
          {!isLoginPage && (
            <NavBar
              onVendorTagClick={(tag) => {
                setClickedVendorTag(tag);
              }}
              clickedVendorTag={clickedVendorTag}
            />
          )}
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route
              exact
              path='/'
              element={
                <LandingPage
                  onVendorTagClick={(tag) => {
                    setClickedVendorTag(tag);
                  }}
                  clickedVendorTag={clickedVendorTag}
                />
              }
            />
            <Route
              path='/venues'
              element={<Venues clickedVendorTag={clickedVendorTag} />}
            />
            <Route
              path='/vendors/:category'
              element={<VendorListings clickedVendorTag={clickedVendorTag} />}
            />
            <Route
              path='/vendor/:subcategory'
              element={<VendorListings clickedVendorTag={clickedVendorTag} />}
            />
            <Route path='/venues/:venueSlug' element={<VenueDetails />} />
            <Route path='/vendor_profile/:vendorSlug' element={<VendorDetails />} />
            <Route path='/chat' element={<Chat />} />
            <Route path='/saved' element={<Saved />} />
            <Route path='/profile' element={<Profile />} />
            <Route
              path='/vendors/categories'
              element={
                <Vendors
                  onVendorTagClick={(tag) => {
                    setClickedVendorTag(tag);
                  }}
                  clickedVendorTag={clickedVendorTag}
                />
              }
            />
             <Route path='/magazine' element={<MagazinePage/>} />
             <Route path='/magazine/:magazineid' element={<ReadMagazine/>} />
          </Routes>
          {!isLoginPage && <Footer />}
        </LocationContext.Provider>
      </LogInContext.Provider>
    </>
  );
}

export default Layout;
