import styled from "styled-components";
import { useParams } from 'react-router-dom';
import { CategoryName } from "./corosel/data";
import { BlogCard } from "./blogCard";
import { useEffect, useState } from "react";
import { API_HOST,BUSINESS,API_HOST_OMNILEADZ } from '../api/url';
import Lottie from "lottie-react";
import oops from "../blog/corosel/assests/oops.json"
import axios from "axios";
const MainDiv = styled.div`
box-sizing: border-box;
width: 100vw;
min-height: 30vh;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap:45px;
padding: 0px 35px;

@media (max-width:780px){
    padding: 0px 25px;
    gap:40px;
}
@media (max-width:500px){
    padding: 8px;
    margin: 0;
    gap:40px;
}
`;

const MainDivHeading = styled.h1`
font-size: 38px;
`;
const MainDivHeadingSpan = styled.span`
color: #45B39D;

`;
const BlogSection = styled.div`
box-sizing: border-box;
min-height: 250px;
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
gap:45px;
padding: 0px 35px;
position:relative;
top:-30px;

@media (max-width:780px){
    padding: 0px 25px;
    gap:45px;
}
@media (max-width:500px){
    padding: 8px;
    margin: 0;
    gap:45px;
}

`;
const Lottiediv=styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
`;

const OopsHeading = styled.h1`
width: 100%;
text-align: center;
`
export function CategoryBlog() {
    const { category } = useParams();
    const [latestBlogs, setLatestBlogs] = useState([])
    const [showOops, setShowOops] = useState(false)
    async function fetchlCategoryBlogs() {
        if (Object.keys(CategoryName).includes(category)) {
            try {
                const response = await axios.get(
                    `${API_HOST_OMNILEADZ}/blog/getblogsbaseduponcategory/${BUSINESS}/${category}`
                );
                
                setLatestBlogs(response.data)
                if (!response.data || response.data.length == 0)
                    setShowOops(true)
            } catch {
                setShowOops(true)
            }
        }
    }

    useEffect(() => {
        fetchlCategoryBlogs()
    }, [category])

    return (
        <MainDiv>
            <MainDivHeading>Read our Blogs: <MainDivHeadingSpan>{CategoryName[category]}</MainDivHeadingSpan></MainDivHeading>
            {showOops && <OopsHeading>Looks like this is empty for now. Stay tuned for fresh updates!</OopsHeading>}
            {showOops &&<Lottiediv>
                <Lottie 
                 loop={true}
                    animationData={oops}
                    height={200}
                    width={200} />
            </Lottiediv>}
            <BlogSection>
                {
                    latestBlogs.map((data, index) => {
                        return <BlogCard  date={data.createdAt}  mostpopular={index<=3} description={data.description} link={data.slug} image={data.imagebanner} title={data.title} />

                    })
                }
            </BlogSection>
        </MainDiv>

    )
}