import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Card from "../wedding-venues/Card";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import { API_HOST } from "../../api/url";
import { useEffect } from "react";
import CardSkeleton from "../wedding-venues/CardSkeleton";

const Wrapper = styled.div`
  margin: 2em 0;
  z-index: 100;
`;

const StyledHeading = styled.h2`
  color: #000;
  font-size: 22px;
  margin: 0;
  font-weight: 600;
  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GridWrapper = styled.div`
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SimilarWeddingsVenue = ({ productData, isVenue }) => {
  const [page, setPage] = useState(1);
  const itemsPerPage = 3;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleChange = (event, value) => {
    setPage(value);
  };

  const fetchSimilar = async () => {
    setIsLoading(true);
    if (isVenue) {
      try {
        const response = await axios(
          `${API_HOST}/api/v1/venues/${productData.venueSlug}/recommendations`
        );
        setData(response.data.venueRecommendations);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await axios.get(
          `${API_HOST}/api/v1/vendors/${productData.vendorSlug}/recommendations`
        );
        setData(response.data.vendorRecommendations);
      } catch (error) {
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSimilar();
  }, []);

  return (
    <Wrapper>
      {data && data.length > 0 ? (
        <>
          <Header>
            <StyledHeading>
              Browse Similar Wedding {isVenue ? "Venues" : "Vendors"}
            </StyledHeading>
            <Button
              className="browseWeddingVenuesButton"
              variant="contained"
              onClick={() => {
                if (isVenue) {
                  navigate("/venues", {
                    state: {
                      categoryItem: {
                        value: isVenue
                          ? "VENUES"
                          : productData.vendorSubcategory,
                        vendorCategory: productData.vendorCategory,
                      },
                    },
                  });
                  window.scrollTo(0, 0);
                } else {
                  const url = `/vendor/${productData.vendorSubcategory}`;

                  window.open(url, "_blank");
                }
              }}
            >
              View All
            </Button>
          </Header>
          {isLoading ? (
            <Grid container spacing={2} sx={{ marginTop: "20px" }}>
              {[...Array(3)].map((_, index) => (
                <Grid item key={index} xs={12} sm={12} md={6} lg={4}>
                  <CardSkeleton />
                </Grid>
              ))}
            </Grid>
          ) : (
            <GridWrapper>
              <Grid container spacing={2} justifyContent="center">
                {data
                  .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  .map((item, index) => {
                    return (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        sx={{ paddingTop: "24px" }}
                      >
                        <Card
                          id={index}
                          data={item}
                          isVenue={isVenue ? true : false}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
              {window.innerWidth <= 480 && data.length !== 0 && (
                <Pagination
                  count={Math.ceil(data.length / itemsPerPage)}
                  page={page}
                  onChange={handleChange}
                  variant="outlined"
                  shape="rounded"
                  sx={{ paddingTop: "40px" }}
                />
              )}
            </GridWrapper>
          )}
          {data.length === 0 && (
            <StyledHeading style={{ fontSize: "16px" }}>
              No Similar {isVenue ? "Venues" : "Vendors"}
            </StyledHeading>
          )}
        </>
      ) : (
        ""
      )}
    </Wrapper>
  );
};

export default SimilarWeddingsVenue;
