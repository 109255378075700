import styled from 'styled-components';
export const FilterWrapper = styled.div`
  @media (max-width: 480px) {
    width: 100%;
    margin: 0px 20px 20px 20px;
  }
`;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(250, 170, 0, 0.1) 0%,
      rgba(250, 170, 0, 0.1) 100%
    ),
    #fff;
  padding: 12px 10px 10px 13px;
  margin-bottom: 16px;
`;

export const FilterText = styled.p`
  margin: 0px;
  color: #faaa00;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ClearAll = styled.p`
  margin: 0px 12px;
  color: #faaa00;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 18px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 14px;
  cursor: pointer;
`;

export const FilterSectionText = styled.p`
  margin: 0;
  color: #424242;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const FilterData = styled.div`
  margin-top: -12px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 14px;
  border-radius: 4px;
  padding-bottom: 8px;
`;

export const FilterHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NoOfVenues = styled.p`
  margin: 0px 20px;
  color: #424242;
  font-size: 14px;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
`;

export const DataText = styled.p`
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

export const FilterBox = styled.div`
  width: 267px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
