export const sortList = [
  {
    item: 'Relevance',
    value: 'RELEVANCE',
  },
  {
    item: 'Popularity',
    value: 'POPULARITY',
  },
  { item: 'Nearest Location', value: 'PROXIMITY' },
  { item: 'Price: low to high', value: 'PRICE_LOW_TO_HIGH' },
  { item: 'Price: high to low', value: 'PRICE_HIGH_TO_LOW' },
];
