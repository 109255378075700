import { useContext, useEffect, useState } from "react";
import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import styled from "styled-components";
import TuneIcon from "@mui/icons-material/Tune";
import WindowIcon from "@mui/icons-material/Window";
import ViewListIcon from "@mui/icons-material/ViewList";
import { searchData } from "./searchData";
import { vendors } from "../../Landing-Page/Banner";
import { LocationContext } from "../../../Layout";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { API_HOST } from '../../api/url';
import axios from 'axios';
const HeadSection = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  align-items: center;
  @media (max-width: 480px) {
    justify-content: center;
    padding-bottom: 20px;
  }
`;

const StyledHeading = styled.h1`
  color: #1b1d27;
  text-align: center;
  font-size: 44px;
  font-family: Outfit, sans-serif;
  font-weight: 600;
  margin: 20px 0;
  @media (max-width: 480px) {
    font-size: 31px;
    padding-top: 20px;
  }
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
  }
`;

const SortList = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    margin-bottom: 16px;
    flex-direction: column;
    align-items: start;
  }
`;

const StyledItem = styled.p`
  margin: 0 8px 0 0;
  border-radius: 10px;
  border: 0.5px solid #e9e9e9;
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.selected ? "#fff" : "#4e4e4e")};
  background-color: ${(props) => (props.selected ? "#13362D" : "transparent")};
  font-size: 12px;
  font-family: Outfit, sans-serif;
  font-weight: ${(props) => (props.selected ? "600" : "normal")};
  cursor: pointer;
  @media (max-width: 480px) {
    font-size: 10px;
    padding: 5px 10px;
  }
`;

const SortBy = styled.p`
  font-size: 14px;
  font-family: Outfit, sans-serif;
  font-weight: 600;
  color: #4e4e4e;
  margin-right: 16px;
  @media (max-width: 480px) {
    font-size: 10.865px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
`;

const ListWrapper = styled.div`
  display: flex;
`;

const ShowingResult = styled.p`
font-size: 13px;
font-weight: 400;

`;
const ShowingResultSpan = styled.span`
font-size: 13px;
font-weight: 500;
`
const CityBasedOption = styled.div`
border:1px solid gray;
box-sizing: border-box;
border-radius:5px;
width: 220px;
height: 40px;
position:  relative;
`

const CityBasedOptionSection1 = styled.div`
cursor: pointer;
display: flex;
box-sizing: border-box;
justify-content: space-between;
border-radius:5px;
align-items: center;
margin: 0;
padding: 0;
width: 100%;
padding: 6px 10px;
height: 100%;
`;
const CityBasedOptionSection1p = styled.p`
font-size: 14px;
`;
const CityBasedOptionSection2 = styled.div`
position: relative;
width: 100%;
`;

const CityBasedOptionSection22 = styled.div`
width: 100%;
position: absolute;
top: 0;
max-height: 170px;
overflow-y: scroll;
background-color: #ffffff;
border:${props => props.statusID ? "1px solid gray" : "0px solid gray"};
margin-top: 4px;
z-index: 2;
border-radius: 5px;
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
gap: 0;
transition: height 0.3s ease-in-out;
height:${props => props.statusID ? "h-auto" : "0px"};

`;
const CityBasedOptionSection22p = styled.p`
font-size: 14px;
margin: 0;
padding: 0;
padding: 8px 10px;
box-sizing: border-box;
cursor: pointer;
width: 100%;
&:hover {
    background-color: #D8D8D8;
  }

`;
const VendorHeader = ({
  isFilterOpen,
  setIsFilterOpen,
  setSelectedButton,
  selectedButton,
  handleQuery,
  onSortChange,
  sortList,
  clickedVendorTag,
  selectedSort,
  category,
  type,
  totalRecords,
  cityOption = "",
  cityOptionDropDown = false,
  setCityOption,
  setCityOptionDropDown
}) => {
  const [selectedItem, setSelectedItem] = useState(
    sortList.findIndex((item) => item.item === selectedSort)
  );
  const [isFilterClicked, setIsFilterClicked] = useState(false);
  const [subCategory, setSubCategory] = useState("");
  const { geoLocation, setGeoLocation } = useContext(LocationContext);
  const [cities, setCities] = useState([])
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // console.log(query);
    }
  };

  async function handleNearestLocationClick() {
    if (!geoLocation && navigator.geolocation) {
      const result = await navigator.permissions.query({ name: "geolocation" });
      if (result.state === "granted" || result.state === "prompt") {
        try {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });
          setGeoLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        } catch (error) {
          console.error("Error getting current position:", error);
        }
      }
    }
    onSortChange("PROXIMITY");

  }


  useEffect(() => {
    const url = window.location.href;
    const extractedPart = url.split("/vendor/")[1];
    setSubCategory(extractedPart);
  }, []);

  useEffect(() => {
    if (selectedSort === "PROXIMITY") {
      handleNearestLocationClick();
    }
  }, [selectedSort]);

  useEffect(() => {
    fetchCityies()
  }, [])

  async function fetchCityies() {
    if (type == "venue") {
      const response = await axios.get(
        `${API_HOST}/api/v1/meta/getVenuesServiceCities`
      );
      setCities(response.data.venueserviceCities);
    } else {
      const response = await axios.get(
        `${API_HOST}/api/v1/meta/service-cities/allcities/allcities`
      );
      setCities(response.data.serviceCities);
    }
  }

  const subcategoryText = vendors.find(
    (vendor) => vendor.vendorSubcategory === subCategory
  )?.subcategoryText;

  // useEffect(() => {
  //   console.log(vendors.find((v) => v.vendorSubcategory === subCategory));
  // }, []);

  return (
    <>
      <HeadSection>
        <StyledHeading>{subcategoryText}</StyledHeading>
        <ButtonWrapper>
          <Button
            variant="contained"
            className={`listView ${selectedButton === "listView" ? "selected" : ""
              }`}
            onClick={() => setSelectedButton("listView")}
          >
            <ViewListIcon style={{ marginRight: "8px" }} />
            List View
          </Button>
          <Button
            variant="contained"
            className={`cardView ${selectedButton === "cardView" ? "selected" : ""
              }`}
            onClick={() => setSelectedButton("cardView")}
          >
            <WindowIcon style={{ marginRight: "8px" }} />
            Card View
          </Button>
        </ButtonWrapper>
      </HeadSection>
      <BottomSection>
        <SortList>
          <div>
            <SortBy>Sort By:</SortBy>
          </div>
          <ListWrapper>
            {sortList
              .map((list, index) => {
                return (
                  <StyledItem
                    key={index}
                    selected={selectedItem === index}
                    onClick={() => {
                      if (list.value === "PROXIMITY") {
                        handleNearestLocationClick();
                      } else {
                        onSortChange(list.value);
                      }
                      setSelectedItem(index);


                    }}
                  >
                    {list.item}
                  </StyledItem>
                );
              })}
          </ListWrapper>
        </SortList>

        <CityBasedOption>
          <CityBasedOptionSection1 onClick={(e)=>{e.stopPropagation(); setCityOptionDropDown(!cityOptionDropDown)}} >
            <CityBasedOptionSection1p>{cityOption ? cityOption : "Choose City"}</CityBasedOptionSection1p>
            <ArrowDropDownIcon style={{ transform: `${cityOptionDropDown?"rotate(180deg)":"rotate(0deg)"}`,transition:"all 0.3s ease" }} />
          </CityBasedOptionSection1>
          <CityBasedOptionSection2  >
            <CityBasedOptionSection22 onMouseLeave={()=>setCityOptionDropDown(false)} statusID={cityOptionDropDown}>
              {cities.map((item, index) => {
                return (
                  <CityBasedOptionSection22p onClick={()=>{setCityOption(item); setCityOptionDropDown(false)}} key={index}>{item}</CityBasedOptionSection22p>
                );
              })}
            </CityBasedOptionSection22>
          </CityBasedOptionSection2>
        </CityBasedOption>
        {/* <InputWrapper> */}
          {/* <Stack spacing={2} sx={{ width: '300px', marginRight: '12px' }}>
            <Autocomplete
              freeSolo
              disableClearable
              options={searchData.map((option) => option.type)}
              onChange={(event) => {
                handleQuery(event.target.value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Search brands, retailers, products'
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                  sx={{
                    '& .MuiFormLabel-root': {
                      color: 'primary.main',
                    },
                    '& .MuiInputBase-root': {
                      borderRadius: '6px !important',
                      background: ' rgba(255, 255, 255, 0.9) !important',
                      outline: 'none  !important',
                      fontFamily: 'Outfit  !important',
                      fontSize: '14px  !important',
                    },
                  }}
                  own={handleKeyDown}
                />
              )}
            />
          </Stack> */}
          {/* {selectedButton === 'cardView' && (
            <Button
              className='filterButton'
              variant='contained'
              onClick={() => {
                setIsFilterClicked(!isFilterClicked);
                setIsFilterOpen(!isFilterOpen);
              }}
              style={{
                color: isFilterClicked ? '#FAAA00' : '#454b5f',
                backgroundColor: isFilterClicked ? '#FEF6E5' : '#fff',
              }}
            >
              <TuneIcon />
            </Button>
          )} */}
        {/* </InputWrapper> */}
      </BottomSection>
      <ShowingResult>Showing <ShowingResultSpan>{totalRecords} results</ShowingResultSpan> as per your search criteria</ShowingResult>
    </>
  );
};

export default VendorHeader;
