import React from 'react';
import styled from 'styled-components';
import StarIcon from '@mui/icons-material/Star';
import user from '../../../assets/Venue-Details/user.svg';
import StarHalfIcon from '@mui/icons-material/StarHalf';

const Card = styled.div`
  padding-right: 20px;
  margin: 2em 0;
  width: 375px;
  @media (max-width: 480px) {
    max-width: 100%;
    padding: 0;
    margin: 1em 1em 0em 1em;
  }
`;

const Description = styled.p`
  color: #595959;
  font-size: 16px;
  font-family: Hanken Grotesk;
  line-height: 24px;
  margin: 3px 0;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: -15px 0 0 50px;
  
`;

const Rating = styled.p`
  color: #5e5e5e;
  font-size: 16px;
  font-family: Hanken Grotesk;
  font-weight: 600;
  line-height: 34px;
  margin: 0 4px;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.p`
  margin: 0;
  color: #585866;
  font-size: 16px;
  font-family: Hanken Grotesk;
  font-weight: 600;
  line-height: 26px;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const Occupation = styled.p`
  margin: 0;
  color: #5a7184;
  font-size: 12px;
  font-family: Hanken Grotesk;
  @media (max-width: 480px) {
    font-size: 10px;
  }
`;

const StyledImage = styled.img`
  height: 42px;
  width: 42px;
  border-radius: 50%;
  @media (max-width: 480px) {
    width: 32px;
    height: 32px;
  }
`;

export const ReviewInitial = styled.div`
  height: 42px;
  width: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255, 233, 197);
  color: rgb(211, 107, 30);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 41.774px;
  @media (max-width: 480px) {
    width: 32px;
    height: 32px;
  }
`;

const ReviewCard = ({ item }) => {
  const roundedRating = Math.floor(item.reviewRating);
  const stars = [];
  for (let i = 0; i < roundedRating; i++) {
    stars.push(
      <StarIcon key={i} sx={{ fontSize: '18px', color: '#F9AD3D' }} />
    );
  }
  if (item.reviewRating % 1 !== 0) {
    stars.push(
      <StarHalfIcon
        key={roundedRating}
        sx={{ fontSize: '18px', color: '#F9AD3D' }}
      />
    );
  }

  return (
    <Card>
      <UserWrapper>
        {/* <StyledImage src={user} alt='userImage' /> */}
        <ReviewInitial>
          <p>{item.userName.charAt(0).toUpperCase()}</p>
        </ReviewInitial>
        <div
          style={{
            margin: '12px 12px',
          }}
        >
          <UserName>{item.userName}</UserName>
          {/* <Occupation>Vedic Astro - Numerology</Occupation> */}
        </div>
      </UserWrapper>
      <RatingWrapper>
        {stars}
        <Rating>{item.reviewRating}</Rating>
      </RatingWrapper>
      <Description>{item.reviewDescription}</Description>
      
    </Card>
  );
};
export default ReviewCard;
