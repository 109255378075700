import { createContext, useContext, useState } from "react";

const LoginContext = createContext();

export function useLogin() {
  return useContext(LoginContext);
}

export function LoginProvider({ children }) {
  const [userMail, setUserMail] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [userContact, setUserContact] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [profile, setProfile] = useState(null);

  const value = {
    userMail,
    setUserMail,
    userFullName,
    setUserFullName,
    userContact,
    setUserContact,
    userPassword,
    setUserPassword,
    profile,
    setProfile,
  };

  return (
    <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
  );
}
