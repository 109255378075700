import React, { useEffect, useState } from "react";
import { LoggedInHeader as Row, StyledHeading } from "./Contact";
import { useLocation, useNavigate } from "react-router-dom";
import { DetailsHead, DetailsText } from "./AboutSection";
import { Button } from "@mui/material";
import axios from "axios";
import { API_HOST } from "../../api/url";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { historySlice } from "../../../redux/slice/historySlice";
import { jwtDecode } from 'jwt-decode';
const VendorContact = ({ isAuthenticated, isVenue, data }) => {
  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch()
  const [timeoutId, setTimeoutId] = useState(null);
  const [isViewContact, setIsViewContact] = useState(false);
  const [disabled, setDisabled] = useState(false)
  const [contact, setContact] = useState("");
  const [maincontact, setMainContact] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [userProfile, setUserProfile] = useState(null)
  const [vendorContactForApi,setVendorContactForApi]=useState("")
  const handleButtonClick = async () => {
    if (isAuthenticated === "UNAUTHENTICATED") {
      dispatch(historySlice.actions.updateHistory(location.pathname.toString()))
      navigate("/login ");
    } else {
      try {
        AnalyticsChantInitiate(data,vendorContactForApi)
        const requestBody = {
          serviceSlug: data.vendorSlug,
          serviceType: "VENDOR",
        };
        const response = await axios.post(
          `${API_HOST}/api/v1/conversations`,
          requestBody
        );
        const conversationId = response.data.conversationId;
        navigate("/chat", { state: { conversationId } });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleViewContactClick = async () => {

    if (isAuthenticated === "UNAUTHENTICATED") {
      dispatch(historySlice.actions.updateHistory(location.pathname.toString()))
      navigate("/login");
    } else if (contact === "" || contact == null) {

      setIsLoading(true);
      try {
        const response = await axios.get(
          `${API_HOST}/api/v1/vendors/${data.vendorSlug}/contact`
        );
        setMainContact(response.data.vendorContact)
        setContact(response.data.vendorContact);
        AnalyticsViewContacts(data, response.data.vendorContact)
        setIsViewContact(true);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(true);
      if (navigator.clipboard.writeText) {
        navigator.clipboard.writeText(contact)
          .then(() => {
            setContact("Copied")
            setIsLoading(false);
            setDisabled(true)
            const id = setTimeout(() => {
              setContact(maincontact)
              setDisabled(false)
            }, 2500)

            setTimeoutId(id);
          })
          .catch(err => {
            setContact("Try Again")
            setIsLoading(false);
          });
      }
    }
  };


  async function AnalyticsViewContacts(vendorData, vendorContact) {
    if (!userProfile)
      return
    try {
      const body = {
        userData: {
          linkedServiceSlug: userProfile.linkedServiceSlug,
          linkedServiceType: userProfile.linkedServiceType,
          userContact: userProfile.userContact,
          userFullName: userProfile.userFullName,
          userType: userProfile.userType
        },
        vendorData: {
          vendorCity: vendorData.vendorCity,
          vendorCoverImageURL: vendorData.vendorCoverImageURL,
          vendorName: vendorData.vendorName,
          vendorSubcategory: vendorData.vendorSubcategory,
          vendorCategory: vendorData.vendorCategory,
          vendorSlug: vendorData.vendorSlug,
          vendorContact: vendorContact,
          type:"vendor"
        }
      }
      axios.post(`${API_HOST}/api/v1/analytics/addnewviewcontactanalytics/Vendor_Venue_View_Contact`, body);
    } catch (error) {

    }

  }

  async function AnalyticsChantInitiate(vendorData, vendorContactForApi) {
    if (!userProfile||!vendorContactForApi){
      return
    }
    
    try {
      const body = {
        userData: {
          linkedServiceSlug: userProfile.linkedServiceSlug,
          linkedServiceType: userProfile.linkedServiceType,
          userContact: userProfile.userContact,
          userFullName: userProfile.userFullName,
          userType: userProfile.userType
        },
        vendorData: {
          vendorCity: vendorData.vendorCity,
          vendorCoverImageURL: vendorData.vendorCoverImageURL,
          vendorName: vendorData.vendorName,
          vendorSubcategory: vendorData.vendorSubcategory,
          vendorCategory: vendorData.vendorCategory,
          vendorSlug: vendorData.vendorSlug,
          vendorContact: vendorContactForApi,
          type:"vendor"
        }
      }
      axios.post(`${API_HOST}/api/v1/analytics/addnewviewcontactanalytics/Vendor_Venue_Chat_Initiate`, body);
    } catch (error) {

    }

  }

  async function getUserProfileData() {
    try {
      const response = await axios.get(`${API_HOST}/api/v1/profile`);
      const response2 = await axios.get(
        `${API_HOST}/api/v1/vendors/${data.vendorSlug}/contact`
      );
      setVendorContactForApi(response2.data.vendorContact)
      setUserProfile(response.data.userProfile)
    } catch (error) {

    }
  }
  useEffect(() => {
    getUserProfileData()
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <div style={{}}>
      <Row>
        <StyledHeading>Price Estimate</StyledHeading>
      </Row>
      <hr style={{ color: "rgba(0, 0, 0, 0.50)" }} />
      <Row>
        {data.vendorServiceRate === -1 ? (
          <DetailsText style={{ fontSize: "16px", fontWeight: 500 }}>
            On Request
          </DetailsText>
        ) : (
          <DetailsText style={{ fontSize: "16px", fontWeight: 500 }}>
            ₹ {data.vendorServiceRate} + Taxes
          </DetailsText>
        )}
        <DetailsHead style={{ fontSize: "14px" }}>
          Base Service Rate
        </DetailsHead>
      </Row>
      <hr style={{ color: "rgba(0, 0, 0, 0.50)" }} />
      {data?.vendorPackages?.length > 0 &&
        data.vendorPackages.map((packageItem, index) => {
          return (
            <div key={index * 10}>
              <Row>
                <DetailsText style={{ fontSize: "16px", fontWeight: 500 }}>
                  ₹ {packageItem.packagePrice} + Taxes
                </DetailsText>
                <DetailsHead style={{ fontSize: "14px" }}>
                  {packageItem.packageName}
                </DetailsHead>
              </Row>
              <hr style={{ color: "rgba(0, 0, 0, 0.50)" }} />
            </div>
          );
        })}
      <Row style={{ marginTop: "6px" }}>
        <Button

          id="chatvendor_47"
          variant="contained"
          className="verifyButton sendMessage"
          onClick={handleButtonClick}
          sx={{ width: "54% !important" }}
        >
          {isAuthenticated === "UNAUTHENTICATED"
            ? "Login to Send message"
            : "Send Message"}
        </Button>
        <Button
          id="vendorcontact_48"
          disabled={disabled}
          variant="outlined"
          className="viewContact"
          onClick={handleViewContactClick}
          sx={{ width: "44%" }}
        >
          {isLoading ? (
            <CircularProgress size={20} sx={{ color: "#13362d" }} />
          ) : isViewContact ? (
            contact === null ? (
              "NA"
            ) : (
              contact
            )
          ) : (
            "View Contact"
          )}{" "}
        </Button>
      </Row>
    </div>
  );
};

export default VendorContact;
