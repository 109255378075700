import React, { useState } from 'react';
import styled from 'styled-components';
import BreadCrumb from '../wedding-venues/BreadCrumb';
import { vendorCategories } from './vendorData';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { bridalGrooming, honeymoon } from '../../../assets/vendors/index';
import { useNavigate } from 'react-router-dom';
import { boutique } from './vendorData';
import { Helmet } from 'react-helmet-async';
const VendorsWrapper = styled.div`
  margin: 2em 6em;
  @media (max-width: 480px) {
    margin: 20px;
  }
`;

const Heading = styled.h1`
  color: #1b1d27;
  font-family: Outfit;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  @media (max-width: 480px) {
    font-size: 34px;
  }
`;

const Row = styled.div`
  display: flex;
  height: ${(props) => (props.isClicked ? '100%' : '320px')};
  @media (max-width: 480px) {
    flex-direction: column;
    height: 100%;
  }
`;

const SubImage = styled.div`
  height: 300px;
  border-radius: 12px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url(${(props) => props.src}), lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px 12px 0px 12px;
  cursor: pointer;
  @media (max-width: 480px) {
    margin: 0px 0px 0px 0px;
    height: ${(props) => (props.isClicked ? '100%' : '210px')};
  }
`;

const MainImage = styled.div`
  height: 300px;
  border-radius: 12px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url(${(props) => props.src}), lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px 12px 0px 12px;
  cursor: pointer;
  @media (max-width: 480px) {
    margin: 0px 0px 0px 0px;
    height: ${(props) => (props.isClicked ? '100%' : '210px')};
  }
`;

const MainImageWrapper = styled.div`
  width: ${(props) => {
    if (props.src === 'boutique') {
      return '60%';
    } else if (props.isSubImageClicked) {
      return '25% !important';
    } else {
      return '50%';
    }
  }};
  transition: width 0.5s ease-in-out;

  &:hover {
    width: ${(props) => {
    if (props.src === boutique) {
      return '60%';
    } else if (props.isMain) {
      return '25%';
    } else {
      return '50%';
    }
  }};
  }
  @media (max-width: 480px) {
    width: 100% !important;
    height: ${(props) => (props.isClicked ? '100%' : '210px')};
    margin: 10px 0px;
  }
`;

const SubImageWrapper = styled.div`
  width: ${(props) => {
    if (props.isSubImageClicked) {
      return '60% !important';
    } else if (props.src === honeymoon) {
      return '80% !important';
    } else {
      return '25%';
    }
  }};
  transition: width 0.5s ease-in-out;

  ~ ${MainImageWrapper} {
    width: ${(props) => {
    return props.isSubImageClicked ? '25%' : '50%';
  }};
  }

  &:hover {
    width: ${(props) => {
    if (props.src === honeymoon) {
      return '60%';
    } else {
      return props.isMain ? '25%' : '50%';
    }
  }};
    ~ ${MainImageWrapper} {
      width: ${(props) => {
    return props.src === honeymoon ? '40%' : '25%';
  }};
    }
  }

  @media (max-width: 480px) {
    width: 100% !important;
    height: ${(props) => (props.isClicked ? '100%' : '210px')};
    margin: 10px 0px;
  }
`;

const DiscoverMoreWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  @media (max-width: 480px) {
    display: none;
  }
`;

const Text = styled.p`
  font-family: Hanken Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`;

const Tag = styled.p`
  margin: 0 12px;
  font-family: Hanken Grotesk;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 480px) {
    font-size: 26px;
  }
`;

const OverLayText = styled.div`
  padding: 28px 0px 0px 28px;
  color: #ffffff;
  @media (max-width: 480px) {
    padding: 26px 0px 0px 26px;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  box-shadow: 2px 4px 44px 0px rgba(0, 0, 0, 0.08);
  padding: 36px 20px;
  justify-content: space-between;
  margin: 0px 12px 20px 12px;
  @media (max-width: 480px) {
    padding: 20px;
    margin: 0;
    flex-direction: column;
  }
`;

const StyledItem = styled.p`
  color: #585e73;
  font-family: Hanken Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  line-height: normal;
  :hover {
    text-decoration: underline;
  }
  @media (max-width: 480px) {
    margin-bottom: 4px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  @media (max-width: 480px) {
    grid-gap: 0;
  }
`;

const Vendors = ({ onVendorTagClick, clickedVendorTag }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [clickedTag, setClickedTag] = useState(null);
  const [isSubImageWrapperClicked, setIsSubImageWrapperClicked] =
    useState(false);
  const navigate = useNavigate();

  const groupedCategories = [];
  for (let i = 0; i < vendorCategories.length; i += 3) {
    groupedCategories.push(vendorCategories.slice(i, i + 3));
  }

  return (
    <VendorsWrapper>
      <Helmet>
        <title>Find Top Vendors for Your Shopping Needs at Maangal Bazaar</title>
        <meta name="description" content="Discover a wide range of top vendors offering high-quality products at Maangal Bazaar. Shop for electronics, clothing, home goods, and more. Explore our diverse selection and find the perfect vendor for your shopping needs. Visit us now!" />
      </Helmet>
      <BreadCrumb clickedVendorTag='Vendors' />
      <Heading>Wedding Categories</Heading>
      <Grid>
        {groupedCategories.map((group, index) => (
          <Row
            key={`group-${index}`}
            isClicked={
              showMenu && group.some((category) => category.tag === clickedTag)
            }
          >
            {group.map((category, index) => {
              const ImageWrapper = category.isMain
                ? MainImageWrapper
                : SubImageWrapper;
              const Image = category.isMain ? MainImage : SubImage;
              return (
                <React.Fragment key={`${category.tag}-${index}`}>
                  <ImageWrapper
                    key={`category-${category.tag}`}
                    src={category.src}
                    isClicked={clickedTag === category.tag}
                    isSubImageClicked={
                      !category.isMain && clickedTag === category.tag
                    }
                    onClick={() => {
                      setIsSubImageWrapperClicked(!isSubImageWrapperClicked);
                      if (clickedTag === category.tag) {
                        setShowMenu(false);
                        setClickedTag(null);
                      } else {
                        setShowMenu(true);
                        setClickedTag(category.tag);
                      }
                    }}
                    isSubImageWrapperClicked={isSubImageWrapperClicked}
                  >
                    <Image src={category.src}>
                      <OverLayText>
                        <Tag>{category.tag}</Tag>
                        <DiscoverMoreWrapper>
                          <Text>Discover More</Text>
                          <ArrowRightAltIcon />
                        </DiscoverMoreWrapper>
                      </OverLayText>
                    </Image>
                    {showMenu &&
                      clickedTag === category.tag &&
                      category.data && (
                        <Menu>
                          <div>
                            {category.data
                              .slice(0, Math.ceil(category.data.length / 2))
                              .map((item, index) => {
                                return (
                                  <StyledItem
                                    key={`menu-item-${index}`}
                                    // onClick={() => {
                                    //   navigate('/vendors', {
                                    //     state: {
                                    //       category: 'VENDORS',
                                    //       categoryItem: {
                                    //         value: item.vendorSubcategory,
                                    //         vendorCategory: category.value,
                                    //       },
                                    //     },
                                    //   });
                                    //   window.scrollTo(0, 0);
                                    //   onVendorTagClick(item.subcategoryText);
                                    // }}
                                    onClick={() => {
                                      window.open(
                                        `/vendor/${item.vendorSubcategory}`,
                                        '_blank'
                                      );
                                      // onVendorTagClick(item.subcategoryText);
                                    }}
                                  >
                                    {item.subcategoryText}
                                  </StyledItem>
                                );
                              })}
                          </div>
                          {category.data.length > 1 && (
                            <div>
                              {category.data
                                .slice(Math.ceil(category.data.length / 2))
                                .map((item, index) => {
                                  return (
                                    <StyledItem
                                      key={`menu-item-${index}`}
                                      // onClick={() => {
                                      //   navigate('/vendors', {
                                      //     state: {
                                      //       category: 'VENDORS',
                                      //       categoryItem: {
                                      //         value: item.vendorSubcategory,
                                      //         vendorCategory: category.value,
                                      //       },
                                      //     },
                                      //   });
                                      //   window.scrollTo(0, 0);
                                      //   onVendorTagClick(item.subcategoryText);
                                      // }}
                                      onClick={() => {
                                        window.open(
                                          `/vendor/${item.vendorSubcategory}`,
                                          '_blank'
                                        );
                                      }}
                                    >
                                      {item.subcategoryText}
                                    </StyledItem>
                                  );
                                })}
                            </div>
                          )}
                        </Menu>
                      )}
                  </ImageWrapper>
                </React.Fragment>
              );
            })}
          </Row>
        ))}
      </Grid>
    </VendorsWrapper>
  );
};

export default Vendors;
