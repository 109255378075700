import styled from 'styled-components';
import { useState } from "react"
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import { magazineData } from './magazineData';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';
const navbarValues = [
    {
        label: "Venues",
        href: "https://www.maangalbazaar.com/venues"
    },
    {
        label: "Vendors",
        href: "https://www.maangalbazaar.com/vendors/categories"
    },
    {
        label: "Blog",
        href: "https://blog.maangalbazaar.com/"
    },
    {
        label: "Magazine",
        href: "https://www.maangalbazaar.com/magazine"
    }
]
const MainLayout = styled.div`
width:100%;
position: relative;
min-height: 100vh;
height: auto;
background-color: #F2F5F4;
`
const Navbar = styled.div`
height: 65px;
padding: 5px 3%;
background-color: white;
display: flex;
justify-content: space-between;
align-items: center;
`;

const LogoImage = styled.img`
height: 90%;
@media (max-width:610px) {
    height: 70%;
}
`;
const LogoImageA = styled.a`
height: 90%;
display: flex;
justify-content: center;
align-items: center;
`;
const NavbarOptionCollection = styled.ul`
display: flex;
justify-content: flex-start;
align-items: center;
gap:15px;
overflow: hidden;
@media (max-width:513px) {
    display: none;
}
`
const NavbarOption = styled.a`
text-decoration: none;
cursor: pointer;
font-size: 16px;
color:  black;
font-weight: 400;
text-align: center;
max-width: fit-content;
transition: all 0.2s ease;

&:hover {
    font-weight: 600;
  }
  @media (max-width:513px) {
    display: none;
}

@media (max-width:610px) {
    font-size: 14px;  
}
`

const NavbarOptionCollection2 = styled.ul`
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
width: 70%;
height: 80%;
gap:15px;
overflow: hidden;
`
const NavbarOption2 = styled.a`
text-decoration: none;
cursor: pointer;
font-size: 16px;
color:  black;
font-weight: 400;
text-align: center;
max-width: fit-content;
transition: all 0.2s ease;

&:hover {
    font-weight: 600;
  }

`

const SocialMediaSection = styled.div`
height: 100%;
display: flex;
justify-content: center;
align-items: center;
gap: 5px;

`;
const SocialMediaLogoA = styled.a`

`
const SocialMediaLogoImg = styled.img`
 width: ${(props) => (props.index === 0 ? '34px' : '30px')};
cursor: pointer;
@media (max-width:610px) {
    width: ${(props) => (props.index === 0 ? '29px' : '25px')};
}
`
const Hamberger = styled.div`
font-size: 30px;
display: none;
@media (max-width:513px) {
    display: block;
}
cursor: pointer;

`
const SideBar = styled.div`
position: fixed;
background-color: #d6d6d672;
width: 100vw;
height: 100vh;
top: 0;
left: 0;
transform:${(props) => (props.show ? "translateX(0vw)" : "translateX(-150vw)")};
@media (min-width:513px) {
    display: none;
}
`;
const SideBarContent = styled.div`
background-color: white;
width: 280px;
height: 100%;
padding-top: 100px;
position: relative;
transform:${(props) => (props.show ? "translateX(0vw)" : "translateX(-150vw)")};
transition: all 0.3s ease;
`;
const BannerImage = styled.img`
width: 100vw;
height: 474px;
object-fit: cover;
@media (max-width:700px) {
    height: 400px;
}

@media (max-width:500px) {
    height: 350px;
}
`;
const MagazineSection = styled.div`
display: flex;
justify-content: center;
align-items: flex-start;
padding: 3% 3%;
margin: 0;
gap:30px;
height: auto;
flex-wrap: wrap;

`;
const MainContainer = styled.div`
background-color: white;
width: 500px;
border-radius: 10px;
box-shadow: 0px 0px 3px gray;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
padding: 25px 30px;
box-sizing: border-box;
`
const ImageContainer = styled.div`
width: 100%;
height: 270px;
display: flex;
justify-content: center;
align-items: center;
`;
const ImageContainerImage = styled.img`

height: 100%;
`
const ContainerButton=styled.button`
background-color: #2D6355;
color: white;
width: 100%;
padding: 15px 20px;
border-radius: 10px;
border: 0;
margin: 20px 0px;
cursor: pointer;
`;
const ContainerH1 = styled.h5`
margin: 0;
padding: 0;
font-weight: 500;

color: #000;

font-family: Inter;
font-size: 24px;
font-style: normal;
line-height: normal;
`;
const ContainerP = styled.p`
margin: 0;
padding: 0;
font-size: 16px;
margin-top: 10px;
font-family: Inter;
font-style: normal;
font-weight: 400;
`
export default function MagazinePage() {
    const navigate =useNavigate()
    const [showMenu, setShowMenu] = useState(false)
    console.log(showMenu)

    function navigateToReadMagazine(data){
        window.scrollTo(0, 0);
        navigate(`/magazine/${data.id}`)
    }

    return <MainLayout>
        <Navbar>
            <LogoImageA href="https://www.maangalbazaar.com/"><LogoImage alt="O" src="https://omnileadzdev.s3.amazonaws.com/NjYwNDZmMWRiNjFiMTk5YmI0ZmRmODVj/1716370017481_blob" /></LogoImageA>
            <NavbarOptionCollection>
                {
                    navbarValues.map((data, index) => <NavbarOption href={data.href}>{data.label}</NavbarOption>)
                }
            </NavbarOptionCollection>

            <SocialMediaSection>
                <SocialMediaLogoA href="https://www.facebook.com/MaangalBazaar/"><SocialMediaLogoImg index={0} alt="O" src="https://omnileadzdev.s3.amazonaws.com/NjYwNDZmMWRiNjFiMTk5YmI0ZmRmODVj/1718174067937_blob" /></SocialMediaLogoA>
                <SocialMediaLogoA href="https://www.instagram.com/maangalbazaar/?hl=en"><SocialMediaLogoImg index={1} alt="O" src="https://omnileadzdev.s3.amazonaws.com/NjYwNDZmMWRiNjFiMTk5YmI0ZmRmODVj/1718174079780_blob" /></SocialMediaLogoA>
                <SocialMediaLogoA href="https://in.pinterest.com/maangalbazaar/"><SocialMediaLogoImg index={2} alt="O" src="https://omnileadzdev.s3.amazonaws.com/NjYwNDZmMWRiNjFiMTk5YmI0ZmRmODVj/1718174079791_blob" /></SocialMediaLogoA>
            </SocialMediaSection>
            <Hamberger><MenuIcon onClick={() => setShowMenu(true)} /></Hamberger>

        </Navbar>

        <SideBar onClick={() => { setShowMenu(false) }} show={showMenu}>
            <SideBarContent show={showMenu} onClick={(e) => { e.stopPropagation(); }}>
                <NavbarOptionCollection2>
                    {
                        navbarValues.map((data, index) => <NavbarOption2 href={data.href}>{data.label}</NavbarOption2>)
                    }
                </NavbarOptionCollection2>
                <ClearIcon onClick={() => { setShowMenu(false) }} style={{ backgroundColor: "#d6d6d672", borderRadius: "44px", padding: "5px", position: "absolute", top: "10px", right: "10px", cursor: "pointer" }} />
            </SideBarContent>
        </SideBar>

        <BannerImage alt="O" src="https://omnileadzdev.s3.amazonaws.com/NjYwNDZmMWRiNjFiMTk5YmI0ZmRmODVj/1718179400797_blob" />

        <MagazineSection>
            {
                magazineData.map((data, index) => {
                    return <MainContainer>

                        <ImageContainer>
                            <ImageContainerImage src={data.image}/>
                        </ImageContainer>
                        <ContainerButton onClick={()=>navigateToReadMagazine(data)}>Read Now</ContainerButton>
                        <ContainerH1>{data.title}</ContainerH1>
                        <ContainerP>{data.description}</ContainerP>

                    </MainContainer>
                })
            }

        </MagazineSection>
        <Footer/>
    </MainLayout>
}