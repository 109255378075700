import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Venue2 from "../../../assets/venue2.svg";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from "react-router-dom";
import MehandiListView from "../../../assets/mehandiListView.svg";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import axios from "axios";
import { API_HOST } from "../../api/url";
import { MembershipDiv, PremiumIcons, locationTypes } from "./Card";
import Premium from "../../../assets/premium.svg";
import featured from "../../../assets/featured.svg";

function filterVendorMetadata(vendorMetadata) {
  const propertiesToRemove = [
    "VENDOR_PREMIUM_DATE",
    "VENDOR_LISTING_DATE",
    "VENDOR_SOCIAL_IG_LINK",
    "VENDOR_SOCIAL_WS_LINK",
    "VENDOR_SOCIAL_FB_LINK",
    "VENDOR_SOCIAL_YT_LINK",
  ];
  return vendorMetadata.filter(
    (item) => !propertiesToRemove.includes(item.propertyName)
  ).length;
}

const ListViewCardWrapper = styled.div`
  height: 258px;
  width: 100%;
  border: 1px solid rgb(237, 237, 237);
  flex: 1;
  border-radius: 16px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledImage = styled.img`
  height: 258px;
  width: 384px;
  border-radius: 16px 0px 0px 16px;
  object-fit: contain;
`;

const DetailsWrapper = styled.div`
  padding: 22px 12px 22px 22px;
  width: 100%;
`;

const HeadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Heading = styled.h1`
  margin: 0;
  color: #222;
  font-family: Hanken Grotesk;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`;

const IconWrapper = styled.div`
  padding: 10px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  :hover {
    background: #e2e6e5;
  }
`;

const RatesDiv = styled.div`
  display: flex;
`;

const StyledRates = styled.p`
  margin: 0px 16px 18px 0px;
  font-weight: 500;
  font-size: 16px;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const Span = styled.span`
  color: #777777;
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const AlignCenterDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const LawnP = styled.p`
  font-size: 16px;
  color: #777;
  margin: 0;
  text-transform: capitalize;
  @media (max-width: 14px) {
    font-size: 14px;
  }
`;

const StyledLocation = styled.p`
  margin: 0;
  font-size: 16px;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTags = styled.p`
  margin: 0px 8px 0px 0px;
  padding: 10px;
  border-radius: 5px;
  background: #ededed;
  color: rgba(0, 0, 0, 0.5);
  @media (max-width: 480px) {
    padding: 8.951px;
    font-size: 14px;
  }
`;

const MoreP = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: underline;
  margin-left: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const StyledP = styled.p`
  margin: 0;
  color: #222;
  font-size: 16px;
  font-family: Hanken Grotesk;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const ListViewCard = ({ data = {}, isVenue }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [isSaved, setIsSaved] = useState(data.venueSaved || data.vendorSaved);
  const [iconColor, setIconColor] = useState(
    (data.venueSaved || data.vendorSaved) && isAuthenticated === "AUTHENTICATED"
      ? "#13362D"
      : "inherit"
  );
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/login");
  };

  const checkAuthentication = async () => {
    try {
      const response = await axios.post(`${API_HOST}/api/v1/auth/refresh`);
      setIsAuthenticated(response.data.authStatus);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  const saveVenue = async (event) => {
    event.stopPropagation();
    if (isAuthenticated === "AUTHENTICATED") {
      if (isVenue) {
        try {
          await axios.post(
            `${API_HOST}/api/v1/venues/${data.venueSlug}/save-to-list`
          );
          setIsSaved(true);
          setIconColor("13362D");
        } catch (error) {
          console.log("error:", error);
        }
      } else {
        try {
          await axios.post(
            `${API_HOST}/api/v1/vendors/${data.vendorSlug}/save-to-list`
          );
          setIsSaved(true);
          setIconColor("13362D");
        } catch (error) {
          console.log("error:", error);
        }
      }
    } else {
      handleRedirect();
    }
  };

  const removeVenue = async (event) => {
    event.stopPropagation();
    if (isVenue) {
      try {
        await axios.delete(
          `${API_HOST}/api/v1/venues/${data.venueSlug}/save-to-list`
        );
        setIsSaved(false);
        setIconColor("inherit");
      } catch (error) {
        console.log("error:", error);
      }
    } else {
      try {
        await axios.delete(
          `${API_HOST}/api/v1/vendors/${data.vendorSlug}/save-to-list`
        );
        setIsSaved(false);
        setIconColor("inherit");
      } catch (error) {
        console.log("error:", error);
      }
    }
  };

  return (
    <ListViewCardWrapper
      onClick={() => {
        const url = isVenue
          ? `/venues/${data.venueSlug}`
          : `/vendors/${data.vendorSlug}`;

        window.open(url, "_blank");
      }}
    >
      <FlexWrapper>
        <div style={{ position: "relative" }}>
          {(isVenue
            ? data.venuePremiumStatus === 0
            : data.vendorPremiumStatus === 0) && (
            <MembershipDiv style={{ position: "absolute", top: 0, left: 0 }}>
              <PremiumIcons src={Premium} alt="premium" />
              <p style={{ margin: 0 }}>Premium</p>
            </MembershipDiv>
          )}
          {(isVenue
            ? data.venuePremiumStatus === 1
            : data.vendorPremiumStatus === 1) && (
            <MembershipDiv style={{ position: "absolute", top: 0, left: 0 }}>
              <PremiumIcons src={featured} alt="Featured" />
              <p style={{ margin: 0 }}>Featured</p>
            </MembershipDiv>
          )}
          <StyledImage
            src={isVenue ? data.venueCoverImageURL : data.vendorCoverImageURL}
            alt="venue"
          />
        </div>
        <DetailsWrapper>
          <HeadingWrapper>
            <Heading>
              {" "}
              {data.venueName ? data.venueName : data.vendorName}
            </Heading>
            <IconWrapper
              onClick={
                isSaved && isAuthenticated === "AUTHENTICATED"
                  ? removeVenue
                  : saveVenue
              }
            >
              {isSaved ? (
                <BookmarkIcon sx={{ fontSize: "18px", color: iconColor }} />
              ) : (
                <BookmarkBorderOutlinedIcon
                  sx={{ fontSize: "18px", color: iconColor }}
                />
              )}
            </IconWrapper>
          </HeadingWrapper>
          {isVenue && data && data.venuePricingRate && (
            <>
              <RatesDiv>
                <StyledRates>
                  <Span>Veg:</Span>{" "}
                  {data.venuePricingRate.length > 0
                    ? `  ₹${data.venuePricingRate[0]}`
                    : "NA"}
                </StyledRates>
                <StyledRates>
                  <Span>Non Veg:</Span>{" "}
                  {data.venuePricingRate.length > 1
                    ? `  ₹${data.venuePricingRate[1]}`
                    : "NA"}
                </StyledRates>
              </RatesDiv>
            </>
          )}
          <SpaceBetweenDiv>
            <AlignCenterDiv>
              <LocationOnIcon
                sx={{ fontSize: "18px", paddingRight: "4px" }}
                className="LocationIcon"
              />
              <StyledLocation>
                {data.venueCity ? data.venueCity : data.vendorCity}
              </StyledLocation>
            </AlignCenterDiv>
            {!isVenue &&
              (data.vendorServiceRate === -1 ? (
                <StyledRates style={{ marginTop: "4px " }}>
                  <Span>Package</Span> : On Request
                </StyledRates>
              ) : (
                <StyledRates style={{ marginTop: "4px " }}>
                  <Span>Package</Span> : ₹{data.vendorServiceRate}
                </StyledRates>
              ))}
            {isVenue && (
              <LawnP>
                {data?.venueLocationTypes
                  ?.slice(0, 4)
                  .reduce((acc, item) => {
                    const locationType = locationTypes.find(
                      (type) => type.value === item
                    );
                    return locationType ? [...acc, locationType.text] : acc;
                  }, [])
                  .join(", ")}
              </LawnP>
            )}
          </SpaceBetweenDiv>
          <FlexDiv style={{ marginTop: "12px" }}>
            {/* {!isVenue && <StyledTags>Trial not Available</StyledTags>} */}
            {/* {isVenue ? (
              data.venueMaxCapacity > 0 &&
              data.venueMinCapacity > 0 && (
                <StyledTags>{`${data.venueMinCapacity} - ${data.venueMaxCapacity} pax`}</StyledTags>
              )
            ) : (
              <StyledTags>Freelance artist</StyledTags>
            )} */}
            {isVenue &&
              data &&
              data.venueMetadata &&
              data.venueMetadata.find(
                (property) => property.propertyName === "VENUE_ROOM_COUNT"
              ) && (
                <StyledTags>
                  {
                    data.venueMetadata.find(
                      (property) => property.propertyName === "VENUE_ROOM_COUNT"
                    ).propertyValue
                  }{" "}
                  rooms
                </StyledTags>
              )}
            {isVenue
              ? data.venueMetadata?.length > 2
              : data.vendorMetadata?.length > 2 && (
                  <StyledTags>
                    {isVenue
                      ? `${data.venueMetadata?.length || 0} services available`
                      : `${
                          filterVendorMetadata(data.vendorMetadata) || 0
                        } services available`}
                  </StyledTags>
                )}
          </FlexDiv>
          <FlexDiv style={{ marginTop: "12px" }}>
            <StarIcon
              sx={{ fontSize: "18px", paddingRight: "4px" }}
              className="StarIcon"
            />
            <StyledP>
              {isVenue
                ? `${
                    Number.isInteger(data?.venueRatingAverage)
                      ? data?.venueRatingAverage
                      : data?.venueRatingAverage?.toFixed(1)
                  } (${data?.venueRatingCount} reviews)`
                : `${
                    Number.isInteger(data?.vendorRatingAverage)
                      ? data?.vendorRatingAverage
                      : data?.vendorRatingAverage?.toFixed(1)
                  } (${data?.vendorRatingCount} reviews)`}
            </StyledP>
          </FlexDiv>
        </DetailsWrapper>
      </FlexWrapper>
    </ListViewCardWrapper>
  );
};

export default ListViewCard;
