import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../../assets/logo.svg";
import {
  Autocomplete,
  Box,
  Button,
  Popper,
  Stack,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ProfileDropDown from "./ProfileDropDown";
import NavBarItems from "./NavBarItems";
import chat from "../../assets/chat.svg";
import { categories } from "../Landing-Page/Banner/index";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { useLogin } from "../../context/LoginContext";
import axios from "axios";
import { API_HOST } from "../api/url";
import Snackbar from "@mui/material/Snackbar";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Alert from "@mui/material/Alert";
import Grow from "@mui/material/Grow";
import { useDispatch } from "react-redux";
import { historySlice } from "../../redux/slice/historySlice";
const StyledWrapper = styled.div`
  height: 80px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 480px) {
    justify-content: space-between;
    padding: 20px;
    height: 50px;
  }
  position: relative;
  z-index: 4;
`;

const SearchWrapper = styled.div`
  position: relative;
  width:340px;
  @media (max-width: 1129px) {
    width:280px;
  }
  @media (max-width: 984px) {
    width:240px;

  }
  @media (max-width: 788px) {
    display: none;
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

const StyledImage = styled.img`
  position: relative;
  z-index: 1;
  @media (max-width: 480px) {
    display: none;
  }
`;

const MobileLOGO = styled.img`
  width: 140px;
  height: 50px;
`;

const MenuIconWrapper = styled.div`
  display: none;
  cursor: pointer;
  @media (max-width: 480px) {
    display: block;
    margin-left: 14px;
  }
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 4px;
  @media (max-width: 480px) {
    display: none;
  }
`;

const UserName = styled.p`
  color: #585866;
  font-family: Hanken Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin: 0;
`;

const UserImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
`;

const ChatWrapper = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  padding: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: #13362d;
  margin: 0 14px;
  cursor: pointer;
  z-index: 1;
  @media (max-width: 480px) {
    display: none;
  }
`;

const MobileNav = styled.div`
  display: none;
  @media (max-width: 480px) {
    width: 100%;
    display: flex;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;
    height: 50px;
    position: relative;
    z-index: 4;
  }
`;

const MobileWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: #13362d;
  margin: 0 8px;
  cursor: pointer;
  z-index: 1;
`;

const NavBar = ({ onVendorTagClick, clickedVendorTag }) => {
  const dispatch = useDispatch()
  const [query, setQuery] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const location = useLocation();
  const { userFullName, profile } = useLogin();

  const handleUserWrapperClick = () => {
    setIsClicked(!isClicked);
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      console.log(query);
    }
  };

  const handleDropdownItemClick = (item) => {
    setSelectedItem(item);
  };

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const fetchProfile = async () => {
    try {
      const response = await axios.get(`${API_HOST}/api/v1/profile`);
      // console.log(response.data, "chec this navhar logo")
      setProfileData(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const handleAutocompleteChange = (event, newValue) => {
    setQuery(newValue);
    const selectedCategory = categories.find(
      (category) => category.category === newValue
    );
    if (selectedCategory) {
      if (newValue === "Wedding Venues") {
        navigate("/venues", {
          state: {
            categoryItem: selectedCategory,
          },
        });
      } else {
        navigate("/vendors", {
          state: {
            categoryItem: selectedCategory,
          },
        });
      }
    } else {
      setOpenSnackbar(true);
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [location]);

  useEffect(() => {
    setQuery("");
  }, [location]);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };


  useEffect(() => {
    const handleBodyStyles = () => {
      if (isMenuOpen) {
        document.body.style.height = '100vh';
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.height = 'auto';
        document.body.style.overflow = 'visible';
      }
    };

    handleBodyStyles();

    return () => {
      document.body.style.height = 'auto';
      document.body.style.overflow = 'visible';
    };
  }, [isMenuOpen]);



  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  function handleResize() {
    if (isMenuOpen) {
      setIsMenuOpen(false)
    }



  }

  window.addEventListener('resize', handleResize);
  return (
    <StyledWrapper >
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          Please select an option from the dropdown.
        </Alert>
      </Snackbar>

      <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
        <StyledImage src={Logo} alt="LOGO" />
      </div>
      <NavBarItems
        isMenuOpen={isMenuOpen}
        onVendorTagClick={onVendorTagClick}
        clickedVendorTag={clickedVendorTag}
        setIsMenuOpen={setIsMenuOpen}
      />
      <div style={{ display: "flex", alignItems: "center" }}>

        <SearchWrapper>
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Autocomplete
              freeSolo
              disableClearable
              options={categories.map((option) => option.category)}
              onChange={handleAutocompleteChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Venues or vendor categories"
                  id="NAVBAR_AUTOCOMPLETE"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  onKeyDown={handleKeyDown}
                />
              )}
            />
          </Stack>
        </SearchWrapper>


        {!profileData && !profile ? (
          <Button
            id="login_computer_13"
            className="navbarButton"
            variant="contained"
            onClick={() => {
              dispatch(historySlice.actions.updateHistory(location.pathname.toString()))
              navigate("/login")

            }}
          >
            Login
          </Button>
        ) : (
          <>
            <ChatWrapper onClick={() => navigate("/chat")}>
              <img src={chat} alt={chat} />
            </ChatWrapper>
            <div style={{ position: "relative" }}>
              <UserWrapper
                onClick={handleUserWrapperClick}
                style={{
                  backgroundColor: isClicked
                    ? "rgba(19, 54, 45, 0.11)"
                    : "#fff",
                }}
              >
                <UserImage
                  src={
                    profileData.userProfile && profileData.userProfile.userPhotoURL && profileData.userProfile.userPhotoURL.length > 0
                      ? profileData.userProfile.userPhotoURL
                      : "https://th.bing.com/th/id/OIP.AdoJAsiWdwMNG0ZTvUoTUQHaHa?pid=ImgDet&rs=1"
                  }
                  alt="user"
                />
                <UserName>
                  {(profile && profile.name) ||
                    userFullName ||
                    profileData?.userProfile.userFullName}
                </UserName>
                <KeyboardArrowDownIcon
                  sx={{ fontSize: "20px", marginLeft: "8px", color: "#898989" }}
                />
              </UserWrapper>
              {isDropdownOpen && (
                <ProfileDropDown
                  handleDropdownItemClick={handleDropdownItemClick}
                  navigate={navigate}
                  selectedItem={selectedItem}
                />
              )}
            </div>
          </>
        )}
      </div>
      <>
        <MobileNav >
          <div>
            <MobileLOGO src={Logo} alt="LOGO" onClick={() => navigate("/")} />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {(profileData || profile) && (
              <MobileWrapper onClick={() => navigate("/chat")}>
                <img src={chat} alt={chat} />
              </MobileWrapper>
            )}
            <MobileWrapper onClick={handleClick}>
              <SearchRoundedIcon sx={{ color: "#fff", fontSize: "20px" }} />
            </MobileWrapper>
            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              placement="bottom-start"
              style={{ width: "100%", zIndex: 999, }}
            >
              <Box style={{ position: "relative", top: "26px" }}>
                <Stack spacing={2} sx={{ width: "100%" }}>
                  <Autocomplete
                    freeSolo
                    disableClearable
                    options={categories.map((option) => option.category)}
                    onChange={handleAutocompleteChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Venues or vendor categories"
                        id="MOBILE_NAVBAR_AUTOCOMPLETE"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                        onKeyDown={handleKeyDown}
                      />
                    )}
                  />
                </Stack>
              </Box>
            </Popper>
            <MenuIconWrapper onClick={() => setIsMenuOpen(!isMenuOpen)}>
              {!isMenuOpen ? (
                <MenuIcon sx={{ fontSize: " 24px", color: "#173535" }} />
              ) : (
                <CloseIcon sx={{ fontSize: "24px", color: "#173535" }} />
              )}
            </MenuIconWrapper>
          </div>
        </MobileNav>
      </>
    </StyledWrapper>
  );
};

export default NavBar;
