import { Avatar, Button, Snackbar, Alert } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useAPIRequest } from '../../utils/apiHandler';
import { io } from 'socket.io-client';
import { API_HOST, API_VERSION } from '../api/url';
import axios from 'axios';

const Header = styled.div`
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  background: #fff;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  @media (max-width: 480px) {
    height: 60px;
    padding-left: 0;
  }
`;

const LogoSpan = styled.span`
  color: #d36b1e;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 41.774px;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const Logo = styled.div`
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #ffe9c5;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 480px) {
    width: 36px;
    height: 36px;
  }
`;

const Name = styled.h1`
  margin: 0;
  color: #666;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 12px;
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const InputBoxWrapper = styled.div`
  padding: 20px;
  position: relative;
  margin-top: auto;
  @media (max-width: 480px) {
    padding: 20px 0px 20px 0;
  }
`;

const StyledInput = styled.input`
  height: 68px;
  width: 100%;
  border-radius: 12px;
  background: #f1f2f6;
  border: none;
  outline: none;
  color: #8181a5;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 40px;
  padding-right: 10rem;
  box-sizing: border-box;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const ChatSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 50%;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 480px) {
    padding: 20px 0px 20px 0;
  }
`;

const VendorMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledVendorImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-top: 6px;
  margin-right: 8px;
`;

const VendorMessage = styled.p`
  margin: 0;
  padding: 6px 21px 6px 15px;
  border-radius: 18.5px 18.5px 18.5px 0px;
  background: #f6f6f6;
  color: #1c1d21;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  max-width: 400px;
  word-wrap: break-word;
  @media (max-width: 480px) {
    max-width: 200px;
  }
`;

const Time = styled.p`
  margin: 0;
  color: #8181a5;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin-top: 8px;
`;

const ChatScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const UserMessageWrapper = styled(VendorMessageWrapper)`
  align-self: end;
  justify-content: flex-end;
`;

const UserMessage = styled.p`
  margin: 0;
  padding: 5px 15px 8px 16px;
  border-radius: 17px 17px 0px 17px;
  background: #396b6b;
  color: #fff;
  text-align: right;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  max-width: 400px;
  word-wrap: break-word;
  @media (max-width: 480px) {
    max-width: 200px;
  }
`;

const TimeMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const StyledUserImage = styled(StyledVendorImage)`
  margin-right: 0;
  margin-left: 8px;
`;

const ChatScreen = ({ conversationId, conversationData, initialMessage }) => {
  const {
    userMail,
    userName,
    serviceType,
    serviceSlug,
    serviceMail,
    serviceName,
    serviceLastReadMessage,
    userLastReadMessage,
    conversationStatus,
  } = conversationData;

  const [messageText, setMessageText] = useState(initialMessage || '');
  const [messageFetchComplete, setMessageFetchComplete] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [socketConnected, setSocketConnected] = useState(false);
  const [messageQueue, setMessageQueue] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isBooked, setIsBooked] = useState(false);

  const chatSectionRef = useRef(null);

  const {
    isLoading,
    isSuccess,
    isError,
    responseCode,
    responseData,
    requestError,
  } = useAPIRequest({
    requestMethod: 'GET',
    requestEndpoint: '/conversations/:conversationId/messages',
    urlParams: {
      conversationId: conversationId,
    },
    requestDeps: [conversationId],
  });

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (messageText.trim() !== '') {
      setMessageQueue((messageQueue) => {
        const updatedQueue = [...messageQueue, messageText];
        return updatedQueue;
      });
    }
  };

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess && responseData) {
        const { responseStatus } = responseData;
        if (responseStatus === 'SUCCESS') {
          const { conversationMessages } = responseData;
          setChatMessages(conversationMessages);
          setMessageFetchComplete(true);
        }
      }
    }
  }, [isLoading, isSuccess, responseData]);

  const ioHandle = useMemo(() => {
    const ioHandle = io(
      `${API_HOST}/api/${API_VERSION}/conversations/${conversationId}`,
      {
        withCredentials: true,
      }
    );

    if (messageFetchComplete) {
      ioHandle.on('connect', () => {
        setSocketConnected(true);
        // console.log(
        //   `Connected to socket ${conversationId} successfully with id ${ioHandle.id}`
        // );
      });

      ioHandle.on('disconnect', () => {
        setSocketConnected(false);
        console.log(`Disconnected from socket ${conversationId}`);
      });

      ioHandle.on('connect_error', (err) => {
        console.error(err);
      });

      ioHandle.on('messageReceived', (messagePayload) => {
        const { messageData } = messagePayload;
        const { messageId } = messageData;
        delete messageData['messageId'];

        setChatMessages((prevMessages) => {
          return [
            ...prevMessages,
            {
              messageId: messageId,
              messageData: messageData,
            },
          ];
        });

        ioHandle
          .emitWithAck('messageRead', {
            messageId: messageId,
          })
          .then((ackData) => {
            const { responseStatus } = ackData;
            if (responseStatus === 'SUCCESS') {
              console.debug(`Acknowledged message ${messageId} to server`);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      });
    }

    return ioHandle;
  }, [messageFetchComplete, conversationId]);

  useEffect(() => {
    if (ioHandle.connected) {
      const queueCopy = [...messageQueue];
      const firstElementOfQueue = queueCopy[0] || undefined;
      if (firstElementOfQueue === undefined) {
        return;
      }

      console.log(`Attempting to send message ${firstElementOfQueue}`);
      ioHandle
        .emitWithAck('messageSent', { messageText: firstElementOfQueue })
        .then((ackData) => {
          const { responseStatus } = ackData;
          if (responseStatus === 'SUCCESS') {
            const { messageId } = ackData;
            console.log(
              `Message ${messageId} with content ${firstElementOfQueue} acknowledged by server`
            );
            const filteredMessages = queueCopy.slice(1);

            setMessageQueue(filteredMessages);
            setMessageText('');
            setChatMessages((prevMessages) => {
              return [
                ...prevMessages,
                {
                  messageId: messageId,
                  messageData: {
                    messageContent: firstElementOfQueue,
                    messageTimestamp: new Date().toISOString(),
                    messageAuthorType: 'USER',
                  },
                },
              ];
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      console.log('Socket not connected when sending message');
    }
  }, [messageQueue]);

  useEffect(() => {
    if (chatSectionRef.current) {
      chatSectionRef.current.scrollTo({
        top: chatSectionRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [chatMessages]);

  useEffect(() => {
    return () => {
      ioHandle?.disconnect();
    };
  }, [conversationId]);

  useEffect(() => {
    setChatMessages([]);
    setMessageQueue([]);
    setMessageFetchComplete(false);
  }, [conversationId]);

  const handleBook = async () => {
    try {
      await axios.post(
        `${API_HOST}/api/v1/conversations/${conversationId}/book`
      );
      setSnackbarMessage('Vendor/Venue booked successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      window.location.reload();
    } catch (error) {
      setSnackbarMessage('An error occurred while booking vendor/venue');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <ChatScreenWrapper>
        <Header>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Logo>
              <LogoSpan>{serviceName.toUpperCase().substring(0, 2)}</LogoSpan>
            </Logo>
            <Name>{serviceName}</Name>
          </div>
          <Button
            variant='contained'
            className='bookButton'
            onClick={handleBook}
            disabled={
              conversationData.conversationStatus === 'BOOKED' ? true : false
            }
          >
            {conversationData.conversationStatus === 'BOOKED'
              ? 'Booked'
              : 'Book'}
          </Button>
        </Header>
        {isLoading ? null : (
          <>
            <ChatSection ref={chatSectionRef}>
              {chatMessages.map((chatMessage) => {
                const { messageId, messageData } = chatMessage;
                const {
                  messageUnread,
                  messageAuthorType,
                  messageTimestamp,
                  messageContent,
                } = messageData;

                const messageTimestampDate = new Date(messageTimestamp);

                if (messageAuthorType === 'USER') {
                  return (
                    <UserMessageWrapper key={messageId}>
                      <div style={{ display: 'flex' }}>
                        <TimeMessageWrapper>
                          <UserMessage>{messageContent}</UserMessage>
                          <Time>
                            {messageTimestampDate.toLocaleString('en-IN')}
                          </Time>
                        </TimeMessageWrapper>
                        {/* <StyledUserImage src={User2} alt='User2' /> */}

                        <Avatar
                          sx={{
                            width: 24,
                            height: 24,
                            fontSize: '10px',
                            marginLeft: '8px',
                            marginTop: '6px',
                            alignSelf: 'self-end',
                            color: ' #d36b1e',
                            background: ' #ffe9c5',
                          }}
                        >
                          {conversationData.userName.charAt(0).toUpperCase()}
                        </Avatar>
                      </div>
                    </UserMessageWrapper>
                  );
                } else if (messageAuthorType === 'SERVICE') {
                  return (
                    <VendorMessageWrapper key={messageId}>
                      <div style={{ display: 'flex' }}>
                        {/* <StyledVendorImage src={User1} alt='User1' /> */}
                        <Avatar
                          sx={{
                            width: 24,
                            height: 24,
                            fontSize: '10px',
                            marginRight: '8px',
                            marginTop: '6px',
                            alignSelf: 'self-end',
                            color: ' #d36b1e',
                            background: ' #ffe9c5',
                          }}
                        >
                          {serviceName.toUpperCase().substring(0, 2)}
                        </Avatar>
                        <div>
                          <UserMessage>{messageContent}</UserMessage>
                          <Time>
                            {messageTimestampDate.toLocaleString('en-IN')}
                          </Time>
                        </div>
                      </div>
                    </VendorMessageWrapper>
                  );
                }
              })}
            </ChatSection>
            <InputBoxWrapper>
              <form onSubmit={handleSendMessage}>
                <StyledInput
                  type='text'
                  placeholder='Add your message'
                  value={messageText}
                  onChange={(e) => {
                    // console.log(e.target.value);
                    setMessageText(e.target.value);
                  }}
                />
                <Button
                  variant='contained'
                  className='sendButton'
                  type='submit'
                >
                  Send
                </Button>
              </form>
            </InputBoxWrapper>
          </>
        )}
      </ChatScreenWrapper>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ChatScreen;
