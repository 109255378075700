import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import Card from "./Card";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import Filter from "./Filter";
import ListViewCard from "./ListViewCard";
import axios from "axios";
import {
  venueFilters,
  photographyFilters,
  makeupFilters,
  preWeddingFilters,
  preWeddingLocationsFilters,
  bridalGroomingFilters,
  bridalWearFilters,
  groomWearFilters,
  mehendiFilters,
  dJsFilters,
  bartendersSubCategoryFilters,
  cakeSubCategoryFilters,
  foodFilters,
  invitationFilters,
  invitationGiftsSubCategoryFilters,
  sangeetChoreographersFilters,
  jewelleryAccesoriesSubCategoryFilters,
  jewelleryFilters,
  weddingEntertainmentFilters,
  trosseauPackersSubCategoryFilters,
  panditsFilters,
  planningAndDecorFilters,
  weddingPlannerSubCategoryFilters,
} from "./filters";
import { sortList } from "./sortList";
import CardSkeleton from "./CardSkeleton";
import ListSkeleton from "./ListSkeleton";
import { API_HOST } from "../../api/url";
import BreadCrumb from "./BreadCrumb";
import { destinationWeddingVenues, venueTypes } from "../../NavBar/vendors";
import { useLocation } from "react-router-dom";
import {
  Wrapper,
  CardsWrapper,
  FilterAndCardsWrapper,
  ListView,
} from "./styles/VenuesStyles";
import Banner from "../Banner/Banner";
import { categories, vendors } from "../../Landing-Page/Banner";
import VendorHeader from "./VendorHeader";
import { LocationContext } from "../../../Layout";
import { Helmet } from 'react-helmet-async';
import { TitleDescription } from "../../../assets/meta/titileanddescription";
const categoryFilters = {
  VENUES: venueFilters,
  PHOTOGRAPHY: photographyFilters,
  MAKEUP: makeupFilters,
  PRE_WEDDING: preWeddingFilters,
  PLANNING_AND_DECOR: planningAndDecorFilters,
  BRIDAL_WEAR: bridalWearFilters,
  GROOM_WEAR: groomWearFilters,
  MEHENDI: mehendiFilters,
  MUSIC_AND_DANCE: dJsFilters,
  JEWELLERY_AND_ACCESSORIES: jewelleryFilters,
  INVITES_AND_GIFTS: invitationFilters,
  FOOD: foodFilters,
  PANDITS: panditsFilters,
  BRIDAL_GROOMING: bridalGroomingFilters,
};
// function shuffleArray(array) {
//   for (let i = array.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     [array[i], array[j]] = [array[j], array[i]];
//   }
// }

const VendorListings = ({ clickedVendorTag }) => {   //main function
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState("cardView");
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([])
  const [selectedFilters, setSelectedFilters] = useState({});
  const [query, setQuery] = useState("");
  const [selectedSort, setSelectedSort] = useState("Relevance");
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  let categoryid = location.pathname.split("/").pop();
  const [subCategory, setsubCategory] = useState("");
  const { categoryItem, city, category } = location.state || {};
  const { geoLocation } = useContext(LocationContext);
  const [cityOption,setCityOption]=useState("")
  const [cityOptionDropDown,setCityOptionDropDown]=useState(false)
  const handleSortChange = (sortOption) => {
    setSelectedSort(sortOption);
  };

  const filterMap = {
    "Pre Wedding Shoot Locations": preWeddingLocationsFilters,
    "Pre Wedding Photographers": preWeddingFilters,
    DJs: dJsFilters,
    "Sangeet Choreographer": sangeetChoreographersFilters,
    "Wedding Entertainment": weddingEntertainmentFilters,
    Accessories: jewelleryAccesoriesSubCategoryFilters,
    "Trousseau Packers": trosseauPackersSubCategoryFilters,
    Favors: invitationGiftsSubCategoryFilters,
    "Mehendi Favors": invitationGiftsSubCategoryFilters,
    "Invitation Gifts": invitationGiftsSubCategoryFilters,
    Bartenders: bartendersSubCategoryFilters,
    Cake: cakeSubCategoryFilters,
    "Wedding Planners": weddingPlannerSubCategoryFilters,
    // 'Banquet Halls': venueFilters,
    // 'Destination Wedding': venueFilters,
    // 'Lawns / Farmhouses': venueFilters,
    // 'Kalyana Mandapam': venueFilters,
    // 'Small Function / Party Halls': venueFilters,
    // '4 Star & Above Hotels': venueFilters,
    // Resorts: venueFilters,
  };

  const FilterData = () => {
    setFilterData(
      filterMap[clickedVendorTag] || categoryFilters[categoryItem?.value]
    );
  };

  const itemsPerPage = 12;

  const totalPages = Math.ceil(totalPage / itemsPerPage);

  const fetchData = async (vendor, vendorCategory, subCategory) => {
    setLoading(true);
    try {
      const serviceType = vendor && vendor.isServiceTypeIncluded;
      const subText = vendor && vendor.subcategoryText;
      let requestBody = {
        vendorCategory: categoryItem
          ? categoryItem.vendorCategory
          : vendorCategory,
      };

      if (serviceType) {
        requestBody.vendorServiceTypes = [subText];
      } else {
        requestBody.vendorSubcategory = categoryItem
          ? categoryItem.value
          : subCategory;
      }
      const type=location.pathname.split("/").slice(-2,-1)
      if (categoryid&&Array.isArray(type)&&type[0]==="vendors") {
        const value=atob(categoryid)
        requestBody={
          vendorCategory:value
        }
      }
      if (city||cityOption) {
        requestBody.vendorCity = cityOption?cityOption:city;
      }
      // console.log(selectedSort,"check sort kya ha ")
      let response;
      if (selectedSort == "PROXIMITY") {
        if (geoLocation) {
          response = await axios.post(
            `${API_HOST}/api/v1/vendors/search?sortBy=${selectedSort.toUpperCase()}&userLong=${geoLocation.latitude}&userLat=${geoLocation.longitude}`,
            requestBody
          );
        }
        else {
          response = {
            data: {
              searchResults: [],
              totalRecords: 0
            }
          }
        }
      } else {
        response = await axios.post(
          `${API_HOST}/api/v1/vendors/search?sortBy=${selectedSort.toUpperCase()}`,
          requestBody
        );
      }
      const tempData = response.data.searchResults;
      setPage(1)
      setData(tempData);
      setShowData(tempData.slice(0, 12))
      setTotalPage(response.data.totalRecords);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleFilterChange = (checkedState, filters) => {
    const newSelectedFilters = Object.entries(checkedState).reduce(
      (acc, [filterName, checkedValues]) => {
        acc[filterName] = filters
          .find((filter) => filter.name === filterName)
          .data.filter((_, index) => checkedValues[index])
          .map((data) => data.value);
        return acc;
      },
      {}
    );
    setSelectedFilters(newSelectedFilters);
  };

  const handleChange = (event, value) => {
    setPage(Number(value));
    const startIndex = (value - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedItems = data.slice(startIndex, endIndex);
    setShowData(displayedItems)
    document.getElementById('cardViewVendor').scrollIntoView({ behavior: 'smooth' });



  };

  const handleQuery = (e) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    FilterData();
  }, [categoryItem?.value, clickedVendorTag]);

  useEffect(() => {
    const url = window.location.href;
    const extractedPart = url.split("/vendor/")[1];
    setsubCategory(extractedPart);
    const vendor = vendors.find((v) => v.vendorSubcategory === extractedPart);

    const vendorCategory = vendor ? vendor.vendorCategory : "";
    fetchData(vendor, vendorCategory, extractedPart);
  }, [
    selectedFilters,
    selectedSort,
    categoryItem,
    category,
    clickedVendorTag,
    cityOption
  ]);


  useEffect(() => {
    if (window.innerWidth <= 480) {
      setSelectedButton("cardView");
    }
    setCityOption("")
  }, []);
  // console.log(atob(categoryid))
  return (
    <>
      <Helmet>
        <title>{`${TitleDescription[`${subCategory}`]?.title ? TitleDescription[`${subCategory}`]?.title : "Find Top Vendors for Your Shopping Needs at Maangal Bazaar"}`}</title>
        <meta name="description" content={`${TitleDescription[`${subCategory}`]?.description ? TitleDescription[`${subCategory}`]?.description : "Discover a wide range of top vendors offering high-quality products at Maangal Bazaar!"}`} />
      </Helmet>
      <Banner />
      <Wrapper      id="cardViewVendor">
        {/* <BreadCrumb clickedVendorTag={clickedVendorTag} /> */}
        {window.location.href.includes("/vendor/") && (
          <VendorHeader
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={(value) => {
              if (selectedButton !== "listView") setIsFilterOpen(value);
            }}
            selectedButton={selectedButton}
            setSelectedButton={setSelectedButton}
            handleQuery={handleQuery}
            onSortChange={handleSortChange}
            sortList={sortList}
            clickedVendorTag={clickedVendorTag}
            selectedSort={selectedSort}
            city={city}
            category={categoryItem ? categoryItem : subCategory}
            type={"vendor"}
            totalRecords={totalPage}
            cityOption={cityOption}
            setCityOption={setCityOption}
            setCityOptionDropDown={setCityOptionDropDown}
            cityOptionDropDown={cityOptionDropDown}
          />
        )}

        {window.location.href.includes("/vendors") && (
          <Header
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={(value) => {
              if (selectedButton !== "listView") setIsFilterOpen(value);
            }}
            selectedButton={selectedButton}
            setSelectedButton={setSelectedButton}
            handleQuery={handleQuery}
            onSortChange={handleSortChange}
            sortList={sortList}
            clickedVendorTag={clickedVendorTag}
            selectedSort={selectedSort}
            city={city}
            category={categories.find((data,index)=>data.vendorCategory==atob(categoryid))?.category?categories.find((data,index)=>data.vendorCategory==atob(categoryid))?.category:""}
            type={"vendors"}
            totalRecords={totalPage}
            cityOption={cityOption}
            setCityOption={setCityOption}
            setCityOptionDropDown={setCityOptionDropDown}
            cityOptionDropDown={cityOptionDropDown}
          />
        )}

        <CardsWrapper
          style={{
            alignItems: selectedButton === "listView" ? "flex-start" : "center",
          }}
        >
          <FilterAndCardsWrapper>
            {/* {(isFilterOpen || selectedButton === 'listView') && (
            <Filter
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
              selectedButton={selectedButton}
              onFilterChange={handleFilterChange}
              filters={filterData}
            />
          )} */}
            {showData.length > 0 ? (
              selectedButton === "cardView" ? (
                <Grid container spacing={2} justifyContent="center">
                  {isFilterOpen && window.innerWidth <= 480
                    ? null
                    : showData.map((item, index) => {
                      return (
                        <Grid
                          item
                          key={`item-venue-${index}`}
                          xs={12}
                          sm={12}
                          md={isFilterOpen ? 12 : 6}
                          lg={isFilterOpen ? 6 : 4}
                          sx={{ paddingTop: "24px" }}
                        >
                          <Card isVenue={false} data={item} id={index} />
                        </Grid>
                      );
                    })}
                </Grid>
              ) : (
                <ListView>
                  {showData.map((item, index) => {
                    return (
                      <ListViewCard key={index} isVenue={false} data={item} />
                    );
                  })}
                </ListView>
              )
            ) : loading ? (
              selectedButton === "cardView" ? (
                <Grid container spacing={2}>
                  {[...Array(6)].map((_, index) => (
                    <Grid
                      item
                      key={index}
                      xs={12}
                      sm={12}
                      md={isFilterOpen ? 12 : 6}
                      lg={isFilterOpen ? 6 : 4}
                    >
                      <CardSkeleton />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <ListView>
                  {[...Array(6)].map((_, index) => {
                    return <ListSkeleton key={index} />;
                  })}
                </ListView>
              )
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  fontFamily: "Outfit",
                }}
              >
                <h1>No Data Found</h1>
              </div>
            )}
          </FilterAndCardsWrapper>
          {data.length > 0 && (
            <div style={{ margin: "auto" }}>
              {isFilterOpen && window.innerWidth <= 480 ? null : (
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handleChange}
                  variant="outlined"
                  shape="rounded"
                  sx={{
                    paddingTop: "40px",
                  }}
                />
              )}
            </div>
          )}
        </CardsWrapper>
      </Wrapper>
    </>
  );
};

export default VendorListings;
