import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BreadCrumbs from "./BreadCrumbs";
import VenueHeader from "./VenueHeader";
import ImageCatalog from "./ImageCatalog";
import AboutSection from "./AboutSection";
import Reviews from "./Reviews";
import SimilarWeddingsVenue from "./SimilarWeddingsVenue";
import FeedBack from "../../Landing-Page/Testimonials/FeedBack";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API_HOST } from "../../api/url";
import { CircularProgress } from "@mui/material";
import SideStickyComponent from "./SideStickyComponent";
import { Helmet } from 'react-helmet-async';

const MainWrapper = styled.div`
  margin: 2em 6em;
  @media (max-width: 480px) {
    margin: 24px;
  }
`;

const TestimonialWrapper = styled.div`
  display: none;
  @media (max-width: 480px) {
    display: none;
  }
`;

const ProductDetails = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const { state } = useLocation();

  const fetchVenueData = async (slug) => {
    try {
      const response = await axios.get(`${API_HOST}/api/v1/venues/${slug}`);
      setData(response.data.venueData);
      window.scroll(0, 0);
    } catch (error) {
      console.log(error);
    }finally{
      setLoading(false);
    }
   
  };
  useEffect(() => {
    const url = window.location.href;
    const extractedPart = url.split("/venues/")[1];
    fetchVenueData(extractedPart);
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100h",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="success" />
        </div>
      ) : (
        data && (
          <MainWrapper>
             <Helmet>
              <title>{`${data.metaTitle ?data.metaTitle: "Discover Your Dream Destination - Your One-Stop Solution for Memorable Getaways"}`}</title>
              <meta name="description" content={`${data.metaDescription? data.metaDescription: "Plan your perfect getaway with Vedikant Resorts The Mallard Corbett. Whether it's a luxurious hotel stay, an adventurous travel package, or a romantic honeymoon, we offer tailored experiences to suit every traveler's needs. Start your journey today"}`} />
            </Helmet>
            <BreadCrumbs data={data} isVenue={true} />
            <VenueHeader data={data} isVenue={true} />
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "sticky",
                  top: "0",
                  right: "0",
                  // display: "block",
                  // zIndex: "10",
                }}
              >
                <SideStickyComponent data={data} isVenue={true} />
              </div>
              <ImageCatalog data={data} isVenue={true} />
            </div>
            <AboutSection  data={data} isVenue={true} />
            <TestimonialWrapper>
              <FeedBack />
            </TestimonialWrapper>
            <Reviews data={data} isVenue={true} />
            <SimilarWeddingsVenue productData={data} isVenue={true} />
          </MainWrapper>
        )
      )}
    </>
  );
};

export default ProductDetails;
