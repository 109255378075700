import React from 'react';
import styled from 'styled-components';
import mainImage from '../../../assets/Wedding_Banner_Venues/mainImage.png';
import flower1 from '../../../assets/Wedding_Banner_Venues/flower1.png';
import flower2 from '../../../assets/Wedding_Banner_Venues/flower2.png';
import WeddingBackgroundBanner from '../../../assets/Wedding_Banner_Venues/WeddingBackgroundBanner.png';

const BannerWrapper = styled.div`
  width: 100%;
  height: 400px;
  background: url(${WeddingBackgroundBanner}),
    lightgray -36.986px 0px / 105.137% 100% no-repeat;
  display: flex;
  justify-content: center;
  @media (max-width: 769px) {
    display: none;
  }
`;

const StyledHeading = styled.h1`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -20%);
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.4);
  font-size: 44px;
  font-family: Cormorant, serif;
  padding: 0px 8px;
  width: 80%;
  padding: 0px 8px;
  @media (max-width: 769px) {
    font-size: 30px;
  }
`;

const MainImage = styled.img`
  width: 74%;
  height: 74%;
`;

const Flower1 = styled.img`
  height: 68%;
  width: 68%;
  position: relative;
  top: 62px;
  right: -13%;
`;

const Flower2 = styled.img`
  height: 68%;
  width: 68%;
  position: relative;
  left: -15%;
  top: 62px;
`;

const Banner = () => {
  return (
    <BannerWrapper>
      <div
        style={{
          display: 'flex ',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <StyledHeading>
          Plan your love journey with us by your side
        </StyledHeading>
        <Flower1 src={flower1} alt={flower1} />
        <MainImage src={mainImage} alt={mainImage} />
        <Flower2 src={flower2} alt={flower2} />
      </div>
    </BannerWrapper>
  );
};

export default Banner;
