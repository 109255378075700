import { venue } from "../../../assets/product-details";
import {
  bridalWear,
  decor,
  food,
  gifts,
  groomWear,
  honeymoon,
  jewellery,
  makeup,
  mehendi,
  music,
  pandits,
  photographer,
  preWedding,panditCategory,fitness, bridal_grooming,venues
} from "../../../assets/vendors/index";
import makeupWc from "../../../assets/weddingCategories/Makeup.jpg";
import foodWc from "../../../assets/weddingCategories/Food.jpg";
import bridalWearWc from "../../../assets/weddingCategories/bridalwear.jpg";
import jewellaryWc from "../../../assets/weddingCategories/jewellery.webp";
import photographyWc from "../../../assets/weddingCategories/photography.jpg";
import decorWc from "../../../assets/weddingCategories/decorwc.jpeg"
import bridalgrooming from "../../../assets/weddingCategories/bridal_grooming.jpg"


export const boutique =
  "https://th.bing.com/th/id/OIP._r5-C6Q5wxH0NBKlUna7HAHaE8?pid=ImgDet&w=3600&h=2400&rs=1";
export const clinics =
  "https://th.bing.com/th/id/OIP.8oy-23nz2QNIt9ntVABwvAHaE8?pid=ImgDet&rs=1";
export const furniture =
  "https://th.bing.com/th/id/OIP.yWo7htfP8N7FOcXr3G4G4gHaGL?pid=ImgDet&rs=1";


// for landing page Wedding categories
export const landingPageVendorCategories = [
  {
    src:venues,
    tag: "Venues",
    colorCode:"#FDD6D2",
    venue:true,
    data: [
      {
        subcategoryText: "Banquet Halls",
        vendorSubcategory: "BANQUET",
      },
      {
        subcategoryText: "Lawns / Farmhouses",
        vendorSubcategory: "LAWN",
      },
      {
        subcategoryText: "Resorts",
        vendorSubcategory: "RESORT",
      },
      {
        subcategoryText: "Small Function / Party Halls",
        vendorSubcategory: "SMALL_FUNCTION_LOCATION",
      },
      {
        subcategoryText: "Destination Wedding",
        vendorSubcategory: "DESTINATION_WEDDING",
      },
      {
        subcategoryText: "4 Star & Above Hotels",
        vendorSubcategory: "4_STAR_PLUS_HOTEL",
      },
      {
        subcategoryText: "Exotic Hometay",
        vendorSubcategory: "EXOTIC_HOMESTAY",
      },
    ],
    value: "VENUES",
  },
  {
    src: makeupWc,
    tag: "Makeup",
    colorCode:"#FDEBD0",
    data: [
      {
        subcategoryText: "Freelance Makeup Artist",
        vendorSubcategory: "FREELANCE_MAKEUP_ARTIST",
      },
      {
        subcategoryText: "Beauty Salon",
        vendorSubcategory: "BEAUTY_SALON",
      },
    ],
    value: "MAKEUP",
  },
  {
    src: photographyWc,
    tag: "Photography",
    colorCode:"#D6EAF8",
    data: [
      {
        subcategoryText: "Wedding Photographers",
        vendorSubcategory: "WEDDING_PHOTOGRAPHERS",
      },
      {
        subcategoryText: "Pre Wedding Shoot",
        vendorSubcategory: "PRE_WEDDING_SHOOT",
      },
    ],
    value: "PHOTOGRAPHY",
  },
  {
    src:  decorWc,
    tag: "Planning & Decor",
    colorCode:"#FCF3CF",
    data: [
      {
        subcategoryText: "Wedding Planners",
        vendorSubcategory: "WEDDING_PLANNERS",
      },
      {
        subcategoryText: "Wedding decor",
        vendorSubcategory: "WEDDING_DECOR",
      },
      { subcategoryText: "Florists", vendorSubcategory: "FLORISTS" },
    ],
    value: "PLANNING_AND_DECOR",
  },
  {
    src: jewellaryWc,
    tag: "Jewellery & Accessories",
    colorCode:"#F2D7D5",
    data: [
      { subcategoryText: "Jeweller", vendorSubcategory: "JEWELLER" },
      {
        subcategoryText: "Artificial Jewellery",
        vendorSubcategory: "ARTIFICIAL_JEWELLERY",
      },
      {
        subcategoryText: "Bridal Jewellery on Rent",
        vendorSubcategory: "RENTAL_BRIDAL_JEWELLERY",
      },
      { subcategoryText: "Accessories", vendorSubcategory: "ACCESSORIES" },
    ],
    value: "JEWELLERY_AND_ACCESSORIES",
  },
  {
    src: bridalWearWc,
    tag: "Bridal Wear",
    colorCode:"#D1F2EB",
    data: [
      {
        subcategoryText: "Bridal Lehengas",
        vendorSubcategory: "BRIDAL_LEHENGAS",
      },
      { subcategoryText: "Light Lehenga", vendorSubcategory: "LIGHT_LEHENGA" },
      { subcategoryText: "Sarees", vendorSubcategory: "SAREES" },
      {
        subcategoryText: "Cocktail Gowns",
        vendorSubcategory: "COCKTAIL_GOWNS",
      },
      {
        subcategoryText: "Designer Suits",
        vendorSubcategory: "DESIGNER_SUITS",
      },
      {
        subcategoryText: "Bridal Lehenga on Rent",
        vendorSubcategory: "BRIDAL_LEHENGA_ON_RENT",
      },
    ],
    value: "BRIDAL_WEAR",
  },
  {
    src: mehendi, 
    colorCode:"#E8DAEF",
    tag: "Mehendi",
    data: [
      {
        subcategoryText: "Mehendi Artist",
        vendorSubcategory: "MEHENDI_ARTIST",
      },
    ],
    value: "MEHENDI",
  },
  {
    src: foodWc,
    tag: "Food",
    colorCode:"#C5CAE9",
    data: [
      {
        subcategoryText: "Catering Services",
        vendorSubcategory: "CATERING_SERVICES",
      },
      { subcategoryText: "Cake", vendorSubcategory: "CAKE" },
      { subcategoryText: "Bartenders", vendorSubcategory: "BARTENDERS" },
      {
        subcategoryText: "Home Catering",
        vendorSubcategory: "HOME_CATERING",
      },
      { subcategoryText: "Mithai ", vendorSubcategory: "MITHAI" },
      {
        subcategoryText: "Chaat And Food Stalls",
        vendorSubcategory: "CHAAT_AND_FOOD_STALLS",
      },
    ],
    value: "FOOD",
  },
];

//THIS ARRAY CONTAIN VALUES THAT ARE VISIBLE  CATEGORIES WHNE USER CLICK ON VENDORS FROM NAVBAR
export const vendorCategories = [
  {
    src: mehendi,
    tag: "Mehendi",
    isMain: false,
    data: [
      {
        subcategoryText: "Mehendi Artist",
        vendorSubcategory: "MEHENDI_ARTIST",
      },
    ],
    value: "MEHENDI",
  },
  {
    src: photographyWc,
    tag: "Photography",
    isMain: true,
    data: [
      {
        subcategoryText: "Wedding Photographers",
        vendorSubcategory: "WEDDING_PHOTOGRAPHERS",
      },
      {
        subcategoryText: "Pre Wedding Shoot",
        vendorSubcategory: "PRE_WEDDING_SHOOT",
      },
    ],
    value: "PHOTOGRAPHY",
  },
  {
    src: makeupWc,
    tag: "Makeup",
    isMain: false,
    data: [
      {
        subcategoryText: "Freelance Makeup Artist",
        vendorSubcategory: "FREELANCE_MAKEUP_ARTIST",
      },
      {
        subcategoryText: "Beauty Salon",
        vendorSubcategory: "BEAUTY_SALON",
      },
    ],
    value: "MAKEUP",
  },
  {
    src: decorWc,
    tag: "Planning & Decor",
    isMain: true,
    data: [
      {
        subcategoryText: "Wedding Planners",
        vendorSubcategory: "WEDDING_PLANNERS",
      },
      {
        subcategoryText: "Wedding decor",
        vendorSubcategory: "WEDDING_DECOR",
      },
      { subcategoryText: "Florists", vendorSubcategory: "FLORISTS" },
    ],
    value: "PLANNING_AND_DECOR",
  },
  {
    src: bridalWearWc,
    tag: "Bridal Wear",
    isMain: false,
    data: [
      {
        subcategoryText: "Bridal Lehengas",
        vendorSubcategory: "BRIDAL_LEHENGAS",
      },
      { subcategoryText: "Light Lehenga", vendorSubcategory: "LIGHT_LEHENGA" },
      { subcategoryText: "Sarees", vendorSubcategory: "SAREES" },
      {
        subcategoryText: "Cocktail Gowns",
        vendorSubcategory: "COCKTAIL_GOWNS",
      },
      {
        subcategoryText: "Designer Suits",
        vendorSubcategory: "DESIGNER_SUITS",
      },
      {
        subcategoryText: "Bridal Lehenga on Rent",
        vendorSubcategory: "BRIDAL_LEHENGA_ON_RENT",
      },
      // { subcategoryText: 'Lingerie', vendorSubcategory: 'LINGERIE' },
    ],
    value: "BRIDAL_WEAR",
  },
  {
    src:  bridalgrooming,
    tag: "Bridal Grooming",
    isMain: true,
    data: [
      { subcategoryText: "Beauty & Wellness", vendorSubcategory: "BEAUTY_AND_WELLNESS" }
    ],
    value: "BRIDAL_GROOMING",
  },
  {
    src: jewellaryWc,
    tag: "Jewellery & Accessories",
    isMain: true,
    data: [
      { subcategoryText: "Jeweller", vendorSubcategory: "JEWELLER" },
      {
        subcategoryText: "Artificial Jewellery",
        vendorSubcategory: "ARTIFICIAL_JEWELLERY",
      },
      {
        subcategoryText: "Bridal Jewellery on Rent",
        vendorSubcategory: "RENTAL_BRIDAL_JEWELLERY",
      },
      { subcategoryText: "Accessories", vendorSubcategory: "ACCESSORIES" },
    ],
    value: "JEWELLERY_AND_ACCESSORIES",
  },
  {
    src: groomWear,
    tag: "Groom Wear",
    isMain: false,
    data: [
      { subcategoryText: "Sherwani", vendorSubcategory: "SHERWANI" },
      {
        subcategoryText: "Wedding Suits Or Tuxes",
        vendorSubcategory: "WEDDING_SUITS_OR_TUXES",
      },
      {
        subcategoryText: "Sherwani On Rent",
        vendorSubcategory: "SHERWANI_ON_RENT",
      },
      {
        subcategoryText: "Indo Western Outfits",
        vendorSubcategory: "INDO_WESTERN_OUTFITS",
      },
      {
        subcategoryText: "Bandhgalas",
        vendorSubcategory: "BANDHGALAS",
      },
      {
        subcategoryText: "Kurta Pyjama Sets",
        vendorSubcategory: "KURTA_PYJAMA_SETS",
      },
      {
        subcategoryText: "Waistcoats And Nehru Jackets",
        vendorSubcategory: "WAISTCOATS_AND_NEHRU_JACKETS",
      },
      {
        subcategoryText: "Groom Accessories",
        vendorSubcategory: "GROOM_ACCESSORIES",
      },
      {
        subcategoryText: "Saafa And Turban",
        vendorSubcategory: "SAAFA_AND_TURBAN",
      },
    ],
    value: "GROOM_WEAR",
  },
  {
    src: gifts,
    tag: "Invites & Gifts",
    isMain: false,
    data: [
      { subcategoryText: "Invitations", vendorSubcategory: "INVITATIONS" },
      { subcategoryText: "E-Invites", vendorSubcategory: "E_INVITES" },
      {
        subcategoryText: "Trousseau Packers",
        vendorSubcategory: "TROUSSEAU_PACKERS",
      },
      {
        subcategoryText: "Invitation Gifts",
        vendorSubcategory: "INVITATION_GIFTS",
      },
    ],
    value: "INVITES_AND_GIFTS",
  },
  {
    src: furniture,
    tag: "Furniture",
    isMain: true,
    data: [
      { subcategoryText: "Furniture", vendorSubcategory: "FURNITURE" },
      {
        subcategoryText: "Modular kitchen",
        vendorSubcategory: "MODULAR_KITCHEN",
      },
      { subcategoryText: "Interiors", vendorSubcategory: "INTERIORS" },
    ],
    value: "FURNITURE",
  },
  {
    src: music,
    tag: "Music & Dance",
    isMain: false,
    data: [
      { subcategoryText: "DJs", vendorSubcategory: "DJS" },
      { subcategoryText: "Band", vendorSubcategory: "BAND" },
      {
        subcategoryText: "Sangeet Choreographer",
        vendorSubcategory: "SANGEET_CHOREOGRAPHER",
      },
      {
        subcategoryText: "Wedding Entertainment",
        vendorSubcategory: "WEDDING_ENTERTAINMENT",
      },
      {
        subcategoryText: "Dhol & damau",
        vendorSubcategory: "DHOL_AND_DAMAU",
      },
      { subcategoryText: "Maangal geet", vendorSubcategory: "MAANGAL_GEET" },
    ],
    value: "MUSIC_AND_DANCE",
  },
  {
    src: foodWc,
    tag: "Food",
    isMain: true,
    data: [
      {
        subcategoryText: "Catering Services",
        vendorSubcategory: "CATERING_SERVICES",
      },
      { subcategoryText: "Cake", vendorSubcategory: "CAKE" },
      { subcategoryText: "Bartenders", vendorSubcategory: "BARTENDERS" },
      {
        subcategoryText: "Home Catering",
        vendorSubcategory: "HOME_CATERING",
      },
      { subcategoryText: "Mithai ", vendorSubcategory: "MITHAI" },
      {
        subcategoryText: "Chaat And Food Stalls",
        vendorSubcategory: "CHAAT_AND_FOOD_STALLS",
      },
    ],
    value: "FOOD",
  },
  {
    src: clinics,
    tag: "Clinics",
    isMain: true,
    data: [
      {
        subcategoryText: "Dermatology clinics",
        vendorSubcategory: "DERMATOLOGY_CLINICS",
      },
      {
        subcategoryText: "Ayurvedic Clinics",
        vendorSubcategory: "AYURVEDIC_CLINICS",
      },
    ],
    value: "CLINICS",
  },
  {
    src: boutique,
    tag: "Boutiques",
    isMain: true,
    data: [{ subcategoryText: "Boutiques", vendorSubcategory: "BOUTIQUES" }],
    value: "BOUTIQUES",
  },
  {
    src: honeymoon,
    tag: "Honeymoon",
    isMain: false,
    data: [
      {
        subcategoryText: "Honeymoon Packages",
        vendorSubcategory: "HONEYMOON_PACKAGES",
      },
      {
        subcategoryText: "Travel Agents",
        vendorSubcategory: "TRAVEL_AGENTS",
      },
    ],
    value: "HONEYMOON",
  },
  {
    src: panditCategory,
    tag: "Astrology",
    isMain: false,
    data: [
      { subcategoryText: "Astrology", vendorSubcategory: "ASTROLOGY" },
    ],
    value: "ASTROLOGY",
  },
  {
    src: fitness,
    tag: "Fitness Center",
    isMain: true,
    data: [
      { subcategoryText: "Gym", vendorSubcategory: "GYM" },
      { subcategoryText: "Yoga Center", vendorSubcategory: "YOGA_CENTER" },
      { subcategoryText: "Zumba Classes", vendorSubcategory: "ZUMBA_CLASSES" },
    ],
    value: "FITNESS_CENTER",
  }

];
