const venueFilters = [
  {
    name: "No. of guest",
    data: [
      { text: "<100", count: 4, value: [0, 100] },
      { text: "100-250 ", count: 5, value: [100, 250] },
      { text: "250-500", count: 6, value: [250, 500] },
      { text: "500-1000", count: 7, value: [500, 1000] },
      { text: ">1000", count: 7, value: [1000, 2000] },
    ],
  },
  {
    name: "Price per plate",
    data: [
      { text: "<1000", count: 8, value: [0, 1000] },
      { text: "₹1000 - ₹1500", count: 9, value: [1000, 1500] },
      { text: "₹1500 - ₹2000", count: 10, value: [1500, 2000] },
      { text: "₹2000 - ₹3000", count: 11, value: [2000, 3000] },
      { text: ">₹3000", count: 12, value: [3000, 5000] },
      { text: "Rental", count: 12, value: [0, 0] },
      {
        text: "Price on Request",
        count: 12,
        value: [0, 0],
      },
    ],
  },
  {
    name: "Rental Cost",
    data: [
      { text: "<=25K", count: 13, value: [0, 25000] },
      { text: "25K-50K", count: 14, value: [25000, 50000] },
      { text: "50K-1L", count: 15, value: [50000, 100000] },
      { text: "1L-2L", count: 16, value: [100000, 200000] },
      { text: "2L-5L", count: 17, value: [200000, 500000] },
      { text: "5L+", count: 17, value: [500000, 1000000] },
    ],
  },
  {
    name: "Venue type",
    data: [
      {
        text: "4 Star & Above Hotels",
        count: 18,
        value: "4 Star & Above Hotels",
      },
      { text: "Banquet Hall", count: 18, value: "Banquet Hall" },
      {
        text: "Lawn / farmhouses",
        count: 19,
        value: "Lawn / farmhouses",
      },
      { text: "Hotels", count: 18, value: "Hotels" },
      {
        text: "Country / Golf Club",
        count: 18,
        value: "Country / Golf Club",
      },
      { text: "Resort", count: 20, value: "Resort" },
      {
        text: "Restaurant / Party Lounge Bar",
        count: 18,
        value: "Restaurant / Party Lounge Bar",
      },
      {
        text: "Heritage Property",
        count: 18,
        value: "Heritage Property",
      },
      {
        text: "Destination Wedding",
        count: 18,
        value: "Destination Wedding",
      },
      // {
      //   text: "Kalyana Mandapam",
      //   count: 18,
      //   value: "Kalyana Mandapam",
      // },
      {
        text: "Small Functions / Party Halls",
        count: 18,
        value: "Small Functions / Party Halls",
      },
      {
        text: "Venues With Rooms",
        count: 18,
        value: "Venues With Rooms",
      },
      {
        text: "5 Star Hotel / Luxury Property",
        count: 18,
        value: "5 Star Hotel / Luxury Property",
      },
    ],
  },
  {
    name: "Space",
    data: [
      { text: "Indoor", count: 21, value: "Indoor" },
      { text: "Outdoor", count: 22, value: "Outdoor" },
      { text: "Poolside", count: 23, value: "Poolside" },
      { text: "Terrace", count: 24, value: "Terrace" },
    ],
  },
  {
    name: "Room Count",
    data: [
      { text: "<30", count: 26, value: [0, 30] },
      { text: "30-60", count: 27, value: [30, 60] },
      { text: "60-100", count: 28, value: [60, 100] },
      { text: "100-200", count: 29, value: [100, 200] },
      { text: "200-1000", count: 30, value: [200, 1000] },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
];

const photographyFilters = [
  {
    name: "No. of Days",
    data: [
      {
        text: "1 day",
        count: 4,
        value: 1,
      },
      {
        text: "2 days",
        count: 2,
        value: 2,
      },
      {
        text: "3 days",
        count: 4,
        value: 3,
      },
      {
        text: "4 days",
        count: 4,
        value: 4,
      },
    ],
  },
  {
    name: "Services",
    data: [
      { text: "Photo + Video", count: 5, value: "PHOTO_AND_VIDEO" },
      {
        text: "Photo Package",
        count: 7,
        value: "PHOTO",
      },
    ],
  },
  {
    name: "Budget (Photo + Video)",
    data: [
      {
        text: "<50K",
        count: 9,
        value: [0, 50000],
      },
      { text: "50K - 1L", count: 10, value: [50000, 100000] },
      { text: "1L - 1.5L", count: 10, value: [100000, 150000] },
      { text: "1.5L - 2L", count: 10, value: [150000, 200000] },
      { text: ">2L", count: 10, value: [200000, 500000] },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const makeupFilters = [
  {
    name: "Price (Bridal Makeup)",
    data: [
      { text: "<12K", count: 1, value: [0, 12000] },
      { text: "12K - 16K", count: 1, value: [12000, 16000] },
      { text: "16K - 20K", count: 1, value: [16000, 20000] },
      { text: "20K - 25K", count: 1, value: [20000, 25000] },
      { text: "25k+", count: 1, value: [25000, 100000] },
    ],
  },
  {
    name: "Price (Engagement)",
    data: [
      { text: "0 - 12K", count: 10, value: [0, 12000] },
      { text: "12K - 18K", count: 10, value: [12000, 18000] },
      { text: "18K - 25K", count: 10, value: [18000, 25000] },
      { text: ">25K", count: 10, value: [25000, 100000] },
    ],
  },
  {
    name: "Specialty",
    data: [
      { text: "North Indian", count: 10, value: "NORTH_INDIAN" },
      { text: "South Indian", count: 10, value: "SOUTH_INDIAN" },
      { text: "Bengali", count: 10, value: "BENGALI" },
      { text: "Muslim", count: 10, value: "MUSLIM" },
      { text: "Marathi", count: 10, value: "MARATHI" },
      { text: "Christian", count: 10, value: "CHRISTIAN" },
      { text: "Gujarati", count: 10, value: "GUJARATI" },
      { text: "Konkani", count: 10, value: "KONKANI" },
    ],
  },
  {
    name: "Travel to Venue",
    data: [
      {
        text: "Travels to venue",
        count: 8,
        value: "TRAVELS_TO_VENUE",
      },
      {
        text: "Doesn't travel to venue",
        count: 8,
        value: "DOESNT_TRAVEL_TO_VENUE",
      },
    ],
  },
  {
    name: "Airbrush",
    data: [
      {
        text: "Offers HD",
        count: 10,
        value: "OFFERS_HD",
      },
      {
        text: "Offers Airbrush & HD",
        count: 10,
        value: "OFFERS_AIRBRUSH_AND_HD",
      },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const preWeddingFilters = [
  {
    name: "Pricing (Stills)",
    data: [
      { text: "<10K", count: 10, value: [0, 10000] },
      { text: "10K - 25K", count: 10, value: [10000, 25000] },
      { text: "25K - 50K", count: 10, value: [25000, 50000] },
      { text: ">50K", count: 10, value: [50000, 200000] },
    ],
  },
  {
    name: "Services Offered",
    data: [
      { text: "Still Photography", count: 10, value: "STILL_PHOTOGRAPHY" },
      { text: "Videography", count: 10, value: "VIDEOGRAPHY" },
      { text: "Albums", count: 10, value: "ALBUMS" },
    ],
  },
  {
    name: "Pricing (Films)",
    data: [
      { text: "<25K", count: 10, value: [0, 25000] },
      { text: "25K - 50K", count: 10, value: [25000, 50000] },
      { text: "50K - 75K", count: 10, value: [50000, 75000] },
      { text: "75K - 1L", count: 10, value: [75000, 100000] },
      { text: ">1L", count: 10, value: [100000, 1000000] },
    ],
  },
  {
    name: "Experience",
    data: [
      { text: "3 - 5", count: 10, value: [3, 5] },
      { text: "5 - 7", count: 10, value: [5, 7] },
      { text: ">7", count: 10, value: [7, 10] },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const preWeddingLocationsFilters = [
  {
    name: "Space Available",
    data: [
      { text: "Indoor", count: 21, value: "Indoor" },
      { text: "Outdoor", count: 22, value: "Outdoor" },
      {
        text: "Indoor with Outdoor",
        count: 10,
        value: "POOLSIDE_WITH_OUTDOOR",
      },
      { text: "Poolside", count: 23, value: "Poolside" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const bridalWearFilters = [
  {
    name: "Store Type",
    data: [
      { text: "Retail Shop", count: 10, value: "RETAIL_SHOP" },
      { text: "Studio / Boutique", count: 10, value: "STUDIO_OR_BOUTIQUE" },
      { text: "Couture Brand", count: 10, value: "COUTURE_BRAND" },
      {
        text: "Multi Designer Studio",
        count: 10,
        value: "MULTI_DESIGNER_STUDIO",
      },
      { text: "Rental Store", count: 10, value: "RENTAL_STORE" },
    ],
  },
  {
    name: "Budget",
    data: [
      { text: "$$", count: 10, value: "" },
      { text: "$$$", count: 10, value: "" },
      { text: "$$$$", count: 10, value: "" },
      { text: "$$$$$", count: 10, value: "" },
    ],
  },
  {
    name: "Outfit Type",
    data: [
      { text: "Bridal Lehengas", count: 10, value: "BRIDAL_LEHENGAS" },
      { text: "Light Lehenga", count: 10, value: "LIGHT_LEHENGA" },
      { text: "Trousseau Sarees", count: 10, value: "TROUSSEAU_SAREES" },
      { text: "Cocktail Gowns", count: 10, value: "COCKTAIL_GOWNS" },
      { text: "Anarkalis", count: 10, value: "ANARKALIS" },
      {
        text: "Bridal Lehenga On Rent",
        count: 10,
        value: "BRIDAL_LEHENGA_ON_RENT",
      },
      {
        text: "Christian Wedding Gowns",
        count: 10,
        value: "CHRISTIAN_WEDDING_GOWNS",
      },
      {
        text: "Kanjeevaram / Silk Sarees",
        count: 10,
        value: "KANJEEVARAM_OR_SILK_SAREES",
      },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const groomWearFilters = [
  {
    name: "Outfit Type",
    data: [
      { text: "Sherwani", count: 0, value: "SHERWANI" },
      {
        text: "Wedding Suits Or Tuxes",
        count: 0,
        value: "WEDDING_SUITS_OR_TUXES",
      },
      { text: "Kurta Pyjama", count: 0, value: "KURTA_PYJAMA" },
      { text: "Bandhgala", count: 0, value: "BANDHGALA" },
      {
        text: "Waistcoats And Nehru Jackets",
        count: 0,
        value: "WAISTCOATS_And_NEHRU_JACKETS",
      },
      { text: "Indo Western", count: 0, value: "INDO_WESTERN" },
      { text: "Sherwani On Rent", count: 0, value: "SHERWANI_ON_RENT" },
      { text: "Saafa And Turban", count: 0, value: "SAAFA_AND_TURBAN" },
    ],
  },
  {
    name: "Accessories",
    data: [
      { text: "Safa for Groom", count: 10, value: "SAFA_FOR_GROOM" },
      { text: "Groom Pagri", count: 10, value: "GROOM_PAGRI" },
      { text: "Juttis", count: 10, value: "JUTTIS" },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const mehendiFilters = [
  {
    name: "Bridal Mehendi Price",
    data: [
      { text: "<5K", count: 10, value: [0, 5000] },
      { text: "5K - 10K", count: 10, value: [5000, 10000] },
      { text: "10K - 20K", count: 10, value: [10000, 20000] },
      { text: ">20K", count: 10, value: [20000, 100000] },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const dJsFilters = [
  {
    name: "Starting Prices",
    data: [
      { text: "<20K", count: 10, value: [0, 20000] },
      { text: "20K - 40K", count: 10, value: [20000, 40000] },
      { text: "40K - 80K", count: 10, value: [40000, 80000] },
      { text: ">80K", count: 10, value: [80000, 1000000] },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const sangeetChoreographersFilters = [
  {
    name: "Starting price for 10 songs",
    data: [
      { text: "<30K", count: 10, value: [0, 30000] },
      { text: "30K - 50K", count: 10, value: [30000, 50000] },
      { text: "50K - 75K", count: 10, value: [50000, 75000] },
      { text: ">75k", count: 10, value: [75000, 100000] },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const weddingEntertainmentFilters = [
  {
    name: "Event",
    data: [
      {
        text: "Pre-Wedding (Roka)",
        count: 0,
        value: "",
      },
      {
        text: "Wedding",
        count: 0,
        value: "",
      },
      {
        text: "Sagan",
        count: 0,
        value: "",
      },
      {
        text: "Cocktail Party",
        count: 0,
        value: "",
      },
      {
        text: "Bachelor/ Spinster Party",
        count: 0,
        value: "",
      },
      {
        text: "Private Party",
        count: 0,
        value: "",
      },
    ],
  },
  {
    name: "Travel Preferences",
    data: [
      {
        text: "Travels Same City",
        count: 0,
        value: "",
      },
      {
        text: "Travels Domestic",
        count: 0,
        value: "",
      },
      {
        text: "Travels International",
        count: 0,
        value: "",
      },
      {
        text: "Cannot travel",
        count: 0,
        value: "",
      },
    ],
  },
  {
    name: "Type of Artist",
    data: [
      {
        text: "Anchor",
        count: 0,
        value: "",
      },
      {
        text: "Live Singer",
        count: 0,
        value: "",
      },
      {
        text: "Live Band",
        count: 0,
        value: "",
      },
      {
        text: "Guest Appearance",
        count: 0,
        value: "",
      },
      {
        text: "Dancers",
        count: 0,
        value: "",
      },
    ],
  },
  {
    name: "Pricing",
    data: [
      {
        text: "<7.5K",
        count: 0,
        value: [0, 7500],
      },
      {
        text: "7.5K - 12K",
        count: 0,
        value: [7500, 12000],
      },
      {
        text: "12K - 20K",
        count: 0,
        value: [12000, 20000],
      },
      {
        text: "20K - 30K",
        count: 0,
        value: [20000, 30000],
      },
      {
        text: "30K - 50K",
        count: 0,
        value: [30000, 50000],
      },
      {
        text: "50K - 75K",
        count: 0,
        value: [50000, 75000],
      },
      {
        text: ">75K",
        count: 0,
        value: [75000, 100000],
      },
    ],
  },
  {
    name: "Experience",
    data: [
      {
        text: "1 - 3",
        count: 0,
        value: [1, 3],
      },
      {
        text: "3 - 5",
        count: 0,
        value: [],
      },
      {
        text: "5 - 10",
        count: 0,
        value: [],
      },
      {
        text: ">10",
        count: 0,
        value: [10, 20],
      },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const jewelleryFilters = [
  {
    name: "Type",
    data: [
      {
        text: "Artificial",
        count: 0,
        value: "ARTIFICIAL",
      },
      {
        text: "Gold",
        count: 0,
        value: "GOLD",
      },
      {
        text: "Diamond",
        count: 0,
        value: "DIAMOND",
      },
      {
        text: "Temple",
        count: 0,
        value: "TEMPLE",
      },
      {
        text: "Polki",
        count: 0,
        value: "POLKI",
      },
      {
        text: "Silver",
        count: 0,
        value: "SILVER",
      },
      {
        text: "Engagement Rings",
        count: 0,
        value: "ENGAGEMENT_RINGS",
      },
      {
        text: "Flower Jewellery",
        count: 0,
        value: "FLOWER_JEWELLERY",
      },
      {
        text: "Gotta Patti",
        count: 0,
        value: "GOTTA_PATTI",
      },
      {
        text: "Bridal Jewellery On Rent",
        count: 0,
        value: "BRIDAL_JEWELLERY_ON_RENT",
      },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const jewelleryAccesoriesSubCategoryFilters = [
  {
    name: "Accessory Type",
    data: [
      { text: "Shoes", count: 0, value: "SHOES" },
      { text: "Clutches and Bags", count: 0, value: "CLUTCHES_AND_BAGS" },
      {
        text: "Tit bits for Mehendi Favors",
        count: 0,
        value: "TIT_BITS_FOR_MEHENDI",
      },
      { text: "Bridal Chura", count: 0, value: "BRIDAL_CHURA" },
      { text: "Bridal-kalire", count: 0, value: "BRIDAL_KALIRE" },
    ],
  },
  {
    name: "Starting price per piece",
    data: [
      {
        text: "Rs 500+",
        count: 0,
        value: [500, 1000],
      },
      {
        text: "Rs 1000+",
        count: 0,
        value: [1000, 1500],
      },
      {
        text: "Rs 1500+",
        count: 0,
        value: [1500, 2000],
      },
      {
        text: "Rs 2000+",
        count: 0,
        value: [2000, 3000],
      },
      {
        text: "Rs 3000+",
        count: 0,
        value: [3000, 5000],
      },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const invitationFilters = [
  {
    name: "Starting price per card (Printed)",
    data: [
      {
        text: "50 - 100 Rs",
        count: 10,
        value: [50, 100],
      },
      {
        text: "100- 200 Rs",
        count: 10,
        value: [100, 200],
      },
      {
        text: "200 - 400 Rs",
        count: 10,
        value: [200, 400],
      },
      {
        text: "400 Rs onwards",
        count: 10,
        value: [400, 10000],
      },
      {
        text: "<50 Rs",
        count: 10,
        value: [0, 50],
      },
      {
        text: "Design Cost Only",
        count: 10,
        value: [],
      },
      {
        text: "On Request",
        count: 10,
        value: [],
      },
    ],
  },
  {
    name: "Specialty",
    data: [
      {
        text: "Unboxed Invites",
        count: 10,
        value: "",
      },
      {
        text: "Boxed Invites",
        count: 10,
        value: "",
      },
      {
        text: "Digital E- Cards",
        count: 10,
        value: "",
      },
    ],
  },
  {
    name: "Minimum Order Quantity",
    data: [
      {
        text: "<30",
        count: 1,
        value: [0, 30],
      },
      {
        text: "30 - 50",
        count: 1,
        value: [30, 50],
      },
      {
        text: "50 - 100",
        count: 1,
        value: [50, 100],
      },
      {
        text: "100 -150",
        count: 1,
        value: [100, 150],
      },
      {
        text: ">150",
        count: 1,
        value: [150, 1000],
      },
    ],
  },

  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const trosseauPackersSubCategoryFilters = [
  {
    name: "Specialty",
    data: [
      {
        text: "Wedding Gift Packing",
        count: 0,
        value: "",
      },
      {
        text: "Fruit Basket Packing",
        count: 0,
        value: "",
      },
      {
        text: "Bridal Trousseau Packing",
        count: 0,
        value: "",
      },
      {
        text: "Bridesmaids Boxes",
        count: 0,
        value: "",
      },
      {
        text: "Sagan Envelopes",
        count: 0,
        value: "",
      },
      {
        text: "Saree Gift Packing",
        count: 0,
        value: "",
      },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const invitationGiftsSubCategoryFilters = [
  {
    name: "Favor Type",
    data: [
      { text: "Chocolates", count: 0, value: "" },
      { text: "Cookies and Biscuits", count: 0, value: "" },
      { text: "Mithai/ Sweets", count: 0, value: "" },
      { text: "Flavored Tea", count: 0, value: "" },
      { text: "Honey, Jam, & Preserves", count: 0, value: "" },
      { text: "Dry Fruits", count: 0, value: "" },
      { text: "Cake in a Jar, Cupcakes etc", count: 0, value: "" },
      { text: "Non Edible Gifts", count: 0, value: "" },
      { text: "Mehendi Favors", count: 0, value: "" },
      { text: "Brownies", count: 0, value: "" },
      { text: "Candies", count: 0, value: "" },
      { text: "Personalized Items", count: 0, value: "" },
      { text: "Silver Wedding Gifts", count: 0, value: "" },
      { text: "Luxury Gifting", count: 0, value: "" },
      { text: "Edible", count: 0, value: "" },
      { text: "Coffee favors", count: 0, value: "" },
      { text: "Soaps/ Candles", count: 0, value: "" },
      { text: "Macaroons", count: 0, value: "" },
      { text: "Accessories", count: 0, value: "" },
      { text: "Gift Baskets", count: 0, value: "" },
    ],
  },
  {
    name: "Specialty",
    data: [
      {
        text: "Invitation Gifts",
        count: 10,
        value: "",
      },
      {
        text: "Mehendi Favors",
        count: 10,
        value: "",
      },
      {
        text: "Wedding Favors/ Guest Gifting",
        count: 10,
        value: "",
      },
      {
        text: "Wedding gifts for the bride / groom",
        count: 10,
        value: "",
      },
      {
        text: "Bridesmaids Gifts",
        count: 10,
        value: "",
      },
      {
        text: "Groomsmen Gifts ",
        count: 10,
        value: "",
      },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const foodFilters = [
  {
    name: "Veg Price per plate",
    data: [
      {
        text: "<1000 Rs",
        count: 1,
        value: [0, 1000],
      },
      {
        text: "Rs 1000 - Rs 1500",
        count: 1,
        value: [1000, 1500],
      },
      {
        text: "Rs 1500 - Rs 2000",
        count: 1,
        value: [1500, 2000],
      },
      {
        text: "Rs 2000 - Rs 3000",
        count: 1,
        value: [2000, 3000],
      },
      {
        text: ">Rs 3000",
        count: 1,
        value: [3000, 10000],
      },
    ],
  },
  {
    name: "Specialty",
    data: [
      {
        text: "Food Counters",
        count: 10,
        value: "",
      },
      {
        text: "Full Service Catering",
        count: 10,
        value: "",
      },
      {
        text: "Small Function Catering",
        count: 10,
        value: "",
      },
      {
        text: "Chaat And Food Stalls",
        count: 10,
        value: "",
      },
      {
        text: "Dessert",
        count: 10,
        value: "",
      },
      {
        text: "Paan",
        count: 10,
        value: "",
      },
    ],
  },
  {
    name: "Cuisines",
    data: [
      {
        text: "Vegetarian only",
        count: 1,
        value: "",
      },
      {
        text: "Chaat And Food Stalls",
        count: 1,
        value: "",
      },
      {
        text: "Jain Catering",
        count: 1,
        value: "",
      },
      {
        text: "South Indian",
        count: 1,
        value: "",
      },
      {
        text: "Bengali Food Menu",
        count: 1,
        value: "",
      },
      {
        text: "North Indian / Mughlai",
        count: 1,
        value: "",
      },
      {
        text: "Continental (Thai / Italian / Chinese)",
        count: 1,
        value: "",
      },
      {
        text: "Kashmiri",
        count: 1,
        value: "",
      },
      {
        text: "Vegan",
        count: 1,
        value: "",
      },
    ],
  },
  {
    name: "Max Capacity",
    data: [
      {
        text: "<100",
        count: 1,
        value: [0, 100],
      },
      {
        text: "100 - 500",
        count: 1,
        value: [100, 500],
      },
      {
        text: "500 - 1000",
        count: 1,
        value: [500, 1000],
      },
      {
        text: ">1000",
        count: 1,
        value: [1000, 1500],
      },
    ],
  },
  {
    name: "Min Capacity",
    data: [
      {
        text: "<30 minimum people",
        count: 1,
        value: [0, 30],
      },
      {
        text: "<50 minimum people",
        count: 1,
        value: [30, 50],
      },
      {
        text: "<70 minimum people",
        count: 1,
        value: [50, 70],
      },
      {
        text: "<100 minimum people",
        count: 1,
        value: [70, 100],
      },
      {
        text: "<200 minimum people",
        count: 1,
        value: [200, 200],
      },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const bartendersSubCategoryFilters = [
  {
    name: "Type",
    data: [
      {
        text: "General Bartenders",
        count: 1,
        value: "",
      },
      {
        text: "Flair Bartenders",
        count: 1,
        value: "",
      },
      {
        text: "International Bartenders",
        count: 1,
        value: "",
      },
    ],
  },
  {
    name: "Services",
    data: [
      {
        text: "Only Bartenders",
        count: 1,
        value: "",
      },
      {
        text: "Glassware",
        count: 1,
        value: "",
      },
      {
        text: "Mixers & Garnishes",
        count: 1,
        value: "",
      },
      {
        text: "Ice",
        count: 1,
        value: "",
      },
      {
        text: "Theme based bar counters",
        count: 1,
        value: "",
      },
    ],
  },
  {
    name: "Pricing for 200 guests",
    data: [
      {
        text: "<10K",
        count: 1,
        value: [0, 10000],
      },
      {
        text: "10K - 20K",
        count: 1,
        value: [10000, 20000],
      },
      {
        text: "20K - 50K",
        count: 1,
        value: [20000, 50000],
      },
      {
        text: "50K - 1L",
        count: 1,
        value: [50000, 100000],
      },
      {
        text: ">1L",
        count: 1,
        value: [100000, 1000000],
      },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const cakeSubCategoryFilters = [
  {
    name: "Price per Kg",
    data: [
      {
        text: "500 - 1000",
        count: 1,
        value: [500, 1000],
      },
      {
        text: "1000 - 1500",
        count: 1,
        value: [1000, 1500],
      },
      {
        text: "1500 - 2500",
        count: 1,
        value: [1500, 2500],
      },
      {
        text: "2500 - 5000",
        count: 1,
        value: [2500, 5000],
      },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const panditsFilters = [
  {
    name: "Languages",
    data: [
      {
        text: "Hindi",
        count: 1,
        value: "HINDI",
      },
      {
        text: "Tamil",
        count: 1,
        value: "TAMIL",
      },
      {
        text: "Telugu",
        count: 1,
        value: "TELUGU",
      },
      {
        text: "Marathi",
        count: 1,
        value: "MARATHI",
      },
      {
        text: "Kannada",
        count: 1,
        value: "KANNADA",
      },
      {
        text: "Punjabi",
        count: 1,
        value: "PUNJABI",
      },
      {
        text: "Bengali",
        count: 1,
        value: "BENGALI",
      },
      {
        text: "Sanskrit",
        count: 1,
        value: "SANSKRIT",
      },
      {
        text: "English",
        count: 1,
        value: "ENGLISH",
      },
      {
        text: "Gujarati",
        count: 1,
        value: "GUJARATI",
      },
      {
        text: "Urdu",
        count: 1,
        value: "URDU",
      },
    ],
  },
  {
    name: "Culture",
    data: [
      {
        text: "Hindu",
        count: 1,
        value: "HINDU",
      },
      {
        text: "Muslim",
        count: 1,
        value: "MUSLIM",
      },
      {
        text: "Christian",
        count: 1,
        value: "CHRISTIAN",
      },
    ],
  },
  {
    name: "Experience",
    data: [
      {
        text: "<1 year",
        count: 1,
        value: [0, 1],
      },
      {
        text: "1 - 3 years",
        count: 1,
        value: [1, 3],
      },
      {
        text: "3 - 5 years",
        count: 1,
        value: [3, 5],
      },
      {
        text: ">5 years",
        count: 1,
        value: [5, 10],
      },
    ],
  },
  {
    name: "Pricing for Wedding",
    data: [
      {
        text: "<11K",
        count: 1,
        value: [0, 11000],
      },
      {
        text: "11K - 21K",
        count: 1,
        value: [11000, 21000],
      },
      {
        text: "21K - 31K",
        count: 1,
        value: [21000, 31000],
      },
      {
        text: "31K - 51K",
        count: 1,
        value: [31000, 51000],
      },
      {
        text: ">51K",
        count: 1,
        value: [51000, 100000],
      },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const bridalGroomingFilters = [
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const planningAndDecorFilters = [
  {
    name: "Banquet Decor Price",
    data: [
      { text: "<75K", count: 0, value: [0, 75000] },
      { text: "75K - 1.5L", count: 0, value: [75000, 150000] },
      { text: "1.5L - 2.5L", count: 0, value: [150000, 250000] },
      { text: "2.5L - 4L", count: 0, value: [250000, 400000] },
      { text: "4L - 6L", count: 0, value: [400000, 600000] },
      { text: ">6L", count: 0, value: [600000, 1000000] },
    ],
  },
  {
    name: "Home Function Decor",
    data: [
      {
        text: "<30K",
        count: 0,
        value: [0, 30000],
      },
      {
        text: "30K - 50K",
        count: 0,
        value: [30000, 50000],
      },
      {
        text: "50K - 75K",
        count: 0,
        value: [50000, 75000],
      },
      {
        text: "75K - 1.2L",
        count: 0,
        value: [75000, 120000],
      },
      {
        text: ">1.2L",
        count: 0,
        value: [120000, 500000],
      },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

const weddingPlannerSubCategoryFilters = [
  {
    name: "Budget",
    data: [
      { text: "<2.5L", count: 1, value: [0, 250000] },
      { text: "2.5- 4L", count: 1, value: [250000, 400000] },
      { text: "4 -6L", count: 1, value: [400000, 600000] },
      { text: ">6L", count: 1, value: [600000, 1000000] },
    ],
  },
  {
    name: "Rating",
    data: [
      { text: "All Ratings", count: 31, value: "All Ratings" },
      { text: "Rated <4", count: 32, value: "Rated <4" },
      { text: "Rated 4+", count: 33, value: "Rated 4+" },
      { text: "Rated 4.5+", count: 34, value: "Rated 4.5+" },
      { text: "Rated 4.8+", count: 35, value: "Rated 4.8+" },
    ],
  },
  {
    name: "Review count",
    data: [
      { text: "<5 reviews", count: 20, value: [0, 5] },
      {
        text: "5+ reviews",
        count: 20,
        value: [5, 15],
      },
      {
        text: "15+ reviews",
        count: 10,
        value: [15, 30],
      },
      {
        text: "30+ reviews",
        count: 10,
        value: [30, 100],
      },
    ],
  },
];

export {
  venueFilters,
  photographyFilters,
  makeupFilters,
  preWeddingFilters,
  preWeddingLocationsFilters,
  bridalGroomingFilters,
  bridalWearFilters,
  groomWearFilters,
  mehendiFilters,
  dJsFilters,
  bartendersSubCategoryFilters,
  cakeSubCategoryFilters,
  foodFilters,
  invitationFilters,
  invitationGiftsSubCategoryFilters,
  sangeetChoreographersFilters,
  jewelleryAccesoriesSubCategoryFilters,
  jewelleryFilters,
  weddingEntertainmentFilters,
  trosseauPackersSubCategoryFilters,
  panditsFilters,
  planningAndDecorFilters,
  weddingPlannerSubCategoryFilters,
};
