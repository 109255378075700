import { Box, Skeleton } from '@mui/material';
import React from 'react';

const ListSkeleton = () => {
  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          height: '258px',
          marginTop: '24px',
          border: '1px solid  #EDEDED',
          borderRadius: '16px',
        }}
      >
        <Skeleton
          animation='wave'
          variant='rounded'
          height=' 258px'
          width='384px'
          sx={{ borderRadius: '16px' }}
        />
        <Box sx={{ flexGrow: 1, marginLeft: '16px', padding: '12px' }}>
          <Skeleton
            animation='wave'
            variant='rounded'
            width='100%'
            height={40}
          />
          <Skeleton
            animation='wave'
            variant='text'
            height='284px'
            sx={{
              width: ' 100%',
              fontSize: '16px',
              marginTop: '-40px',
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default ListSkeleton;
