import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
padding:0px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 680px;
  @media (max-width: 880px) {
    width: 98%;
  }
`;

export const StyledH1 = styled.h1`
  font-weight: 700;
  font-size: 32px;
  font-family: 'Outfit', sans-serif;
  line-height: 48px;
  @media (max-width: 480px) {
    text-align: center;
    font-size: 25px;
  }
`;

const Text = styled.p`
  font-weight: 300;
  font-family: 'Outfit', sans-serif;
  font-size: 17px;
  text-align: center;
  margin-top: 0;
  word-break: break-all;
  @media (max-width: 480px) {
    font-size: 15px;
  }
`;

const Heading = () => {
  return (
    <Wrapper>
      <StyledH1>Wedding Categories</StyledH1>
      <Text>
      Every Brushstroke Weaves the Canvas of Your Perfect Day, Unveiling Unforgettable Moments, Detail by Detail, Crafted with Precision and Cherished Forever
      </Text>
    </Wrapper>
  );
};

export default Heading;
