import styled from 'styled-components';
import { useState, useEffect } from "react"
import { useParams } from 'react-router-dom';
import { magazineData } from './magazineData';
const MainLayout = styled.div`
width:100vw;
position: relative;
min-height: 99vh;
height: auto;
background-color: #F2F5F4;
`;

export default function ReadMagazine() {
    const { magazineid } = useParams()
    const [magazineDataDynamic, setMagazineDataDynamic] = useState(null)




    useEffect(() => {
        const result = magazineData.find((data, index) => data.id == magazineid)
        if (result && result?.iframe) {
            setMagazineDataDynamic(result?.iframe)
        }
    }, [magazineid])


    return <MainLayout>
        {magazineDataDynamic}
    </MainLayout>
}