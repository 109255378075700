import styled from "styled-components";
import { caroselData } from "./corosel/data";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from "react-router";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { useEffect, useRef, useState } from "react";
import { API_HOST,API_HOST_OMNILEADZ,BUSINESS } from '../api/url';
import axios from "axios";
import { BlogCard } from "./blogCard";
const MainDiv = styled.div`
box-sizing: border-box;
width:100vw;
min-height: 50vh;
`;

const BannerSection = styled.div`
box-sizing: border-box;
position: relative;
width: 100%;
height: 390px;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;

`;
const MainBanner = styled.img`
position: absolute;
z-index: -5;
top:0;
left: 0;
width: 100%;
height: 390px;
filter: blur(1.1px);
object-position: 30% 50%;
object-fit: cover;
@media (max-width:850px) {
    object-position: 50% 90%;  
}

`;
const MainBannerHeading = styled.h1`
font-weight: 800;
font-size: 42px;
color: white;
text-shadow: 1px 1px 1px black;
margin-top: 50px;
/* border-bottom:2px solid white; */
padding: 0px 20px;
text-align: center;
@media (max-width:550px){
    font-size: 32px;  
}
&::selection{
    background-color: transparent;
}
`;
const SearchDiv = styled.div`
box-sizing: border-box;
width:830px;
height: 50px;
border-radius: 44px;
background-color: white;
box-shadow: 1px 1px 3px black;
display: flex;
justify-content: flex-start;
align-items: center;
overflow: hidden;
padding: 9px 16px;
@media (max-width:900px) {
    width: 90%;
}
`;
const SearchInput = styled.input`
box-sizing: border-box;
width: 100%;
height: 100%;
outline: none;
border: 0;
padding: 0px 10px;
font-size: 18px;
font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


`;
const SearchButton = styled.button`
background-color:  #1a3f35;
color: white;
height: 100%;
padding: 0px 15px;
border-radius: 44px;
font-weight: 600;
font-size: 16px;
cursor: pointer;
box-shadow: 0.5px 0.5px 1px black;
&:active{
    transform: scale(0.98);
}

`;
const MainCaraselDiv = styled.div`
position: relative;

`;
const CaroselDiv = styled.div`
top:-40px;
position: relative;
box-sizing: border-box;;
width: 95%;
padding: 0px 40px 0px 20px;
display: flex;
justify-content: flex-start;
align-items: flex-start;
gap:40px;
overflow: scroll;
scroll-behavior: smooth;
margin:auto
`;

const CaroselImageDiv = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
&::selection{
    background-color: transparent;
}
`;
const CaroselImageHeading = styled.p`
text-align: center;
font-size: ${(props) => props.main == "latest" ? "18px" : "17px"};
font-weight:${(props) => props.main == "latest" ? 700 : 500};
&::selection{
    background-color: transparent;
}
`;
const CaroselImage = styled.img`
&::selection{
    background-color: transparent;
}
box-sizing: border-box;
border-radius: 150px;
object-fit: cover;
object-position: 50% 10%;
width: 120px;
height: 120px;
margin-top: 5px;
box-shadow: 1px Explore Insights and Inspiration
1px 2px black;
cursor: pointer;
&:hover{
    box-shadow: 1px 1px 5px black;
}
`;

const CaroselImageAlter = styled.div`
box-sizing: border-box;
border-radius: 150px;
width: 120px;
height: 120px;
margin-top: 5px;
box-shadow: 1px 1px 2px black;
cursor: pointer;
display: flex;
background-color: gray;

background-image: url("https://omnileadzdev.s3.amazonaws.com/NjVhNjU1NzhkMTE5NzMwMWE5NzRiNGI1/1711694683914_pexels-photo-12769896 (1).webp");
justify-content: center;
align-items: center;
&:hover{
    box-shadow: 1px 1px 5px black;
}
filter: blur(0.6px); 
background-size: cover;

`;

const ArrowButton = styled.div`
position: absolute;
color: white;
border-radius: 44px;
background-color:  #1a3f35;
display: flex;
justify-content: center;
align-items: center;
width: 45px;
height: 45px;
z-index: 9999;
left:${(props) => props.side == "left" ? "1.2%" : null};
right:${(props) => props.side == "right" ? "1.2%" : null};
top:10%;
box-shadow: 0.5px 0.5px 2px inset white;
cursor: pointer;
&:hover{
    box-shadow: 0.5px 0.5px 3px black;
}
&:active{
    transform:scale(0.98)
}

`;

const BlogSection = styled.div`
box-sizing: border-box;
min-height: 250px;
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
gap:25px;
padding: 0px 3px;
position:relative;
top:-30px;

@media (max-width:780px){
    padding: 0px 25px;
}
@media (max-width:500px){
    padding: 8px;
    margin: 0;
}

`;
const OopsHeading = styled.h1`
width: 100%;
text-align: center;
margin:0;
padding:0;`;

export default function Blog() {
    const [leftButton, setLeftButton] = useState(false);
    const [rightButton, setRightButton] = useState(true)
    const [latestBlogs, setLatestBlogs] = useState([])
    const [showOops, setShowOops] = useState(false)

    const navigator = useNavigate()
    const ref = useRef(null)

    function CaroselSlider(direction) {

        if (!ref.current)
            return
        let width = 200;
        if (direction == "LEFT")
            ref.current.scrollLeft = ref.current.scrollLeft - width
        else {
            ref.current.scrollLeft = ref.current.scrollLeft + width
        }
        /* console.log(ref.current.scrollLeft, "scroll amount", ref.current.scrollWidth - 930) */
        if (ref.current.scrollLeft > 0)
            setLeftButton(true)
        else if (ref.current.scrollLeft == 0)
            setLeftButton(false)

        /* if(ref.current.scrollWidth-930==ref.current.scrollLeft)
        setRightButton(false)
        else
        setRightButton(true) */

    }
    /* async function fetchAllLatestBlogs() {
        try {
            const response = await axios.get(
                `${API_HOST}/api/v1/blog/getLatestBlogs`
            );
            setLatestBlogs(response.data.latestBlogs)
            if (response.data.latestBlogs.length == 0)
                setShowOops(true)
        } catch {
            setShowOops(true)
        }
    } */

    async function fetchAllLatestBlogsFromOmnileadz() {
        try {
            const response = await axios.get(
                `${API_HOST_OMNILEADZ}/blog/getlatestblogs/${BUSINESS}`
            );
            /* console.log(response) */
            setLatestBlogs(response.data)
            if (response.data.length == 0)
                setShowOops(true)
        } catch {
            setShowOops(true)
        }
    }

    useEffect(() => {
        /* fetchAllLatestBlogs() */
        fetchAllLatestBlogsFromOmnileadz()
    }, [])


    return (
        <MainDiv>
            <BannerSection>
                <MainBanner src="https://omnileadzdev.s3.amazonaws.com/NjYwNDZmMWRiNjFiMTk5YmI0ZmRmODVj/1711619859420_pexels-jeegzart-photography-16375835.jpg" alt="mb" />
                <MainBannerHeading>Explore Insights and Inspiration</MainBannerHeading>
            </BannerSection>
            <MainCaraselDiv>
                {leftButton && <ArrowButton onClick={() => CaroselSlider("LEFT")} side="left"><KeyboardArrowLeftIcon /></ArrowButton>}
                <CaroselDiv ref={ref}>
                    <CaroselImageDiv>
                        <CaroselImageAlter>
                            <ExpandCircleDownIcon style={{ color: "white", borderRadius: "44px", fontSize: "44px", backdropFilter: "blur(2px)" }} />
                        </CaroselImageAlter>
                        <CaroselImageHeading main="latest">Latest</CaroselImageHeading>
                    </CaroselImageDiv>
                    {
                        caroselData.map((data, index) => {
                            return <CaroselImageDiv key={index * 10}>
                                <CaroselImage onClick={() => navigator(`${data.path}`)} src={data.image} alt="MB" />
                                <CaroselImageHeading main="others">{data.title}</CaroselImageHeading>
                            </CaroselImageDiv>

                        })
                    }

                </CaroselDiv>
                {rightButton && <ArrowButton onClick={() => CaroselSlider("RIGHT")} side="right"><KeyboardArrowRightIcon /></ArrowButton>}

            </MainCaraselDiv>
            <BlogSection>
                {
                    latestBlogs.map((data, index) => {
                        return <BlogCard date={data.createdAt} mostpopular={false} description={data.description} link={data.slug} image={data.imagebanner} title={data.title} />

                    })
                }
                {showOops&&<OopsHeading>Looks like this is empty for now. Stay tuned for fresh updates!</OopsHeading>}
            </BlogSection>

        </MainDiv>
    )
}




/* <SearchDiv>
                  <SearchIcon style={{ color: "blue" }} />
                  <SearchInput type="text" spellCheck={false} />
                  <SearchButton>Search</SearchButton>
              </SearchDiv> */