import React, { useEffect, useState } from "react";
import styled from "styled-components";
import img2 from "../../../assets/PortfolioImages/img2.svg";
import axios from "axios";
import { API_HOST } from "../../api/url";
import CollectionsIcon from "@mui/icons-material/Collections";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import PhotoIcon from "@mui/icons-material/Photo";
import PortFolioModal from "./PortFolioModal";
import { Box, CircularProgress } from "@mui/material";

const PortFolioWrapper = styled.div`
  margin: 2em 0 1em 0;
`;

const TabWrapper = styled.div`
  height: 60px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    height: 34px;
  }
`;

const DivWrapper = styled.div`
  width: 25%;
  background: ${(props) => (props.selected ? "#13362d" : "transparent")};
  height: 100%;
  border-radius: ${(props) => (props.first ? "10px 0 0 10px" : "0")};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color ease-in-out;
  @media (max-width: 480px) {
    width: 33.33%;
    border-radius: ${(props) =>
    props.first ? "10px 0 0 10px" : props.last ? "0 10px 10px 0" : "0"};
  }
`;

const Tab = styled.p`
  margin: 0;
  color: ${(props) => (props.selected ? "#fff" : "#000")};
  font-size: 18px;
  font-family: Outfit;
  font-style: normal;
  font-weight: 500;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const StyledImage = styled.img`
  /* width: 100%;
  height: 100%; */
  height: 130px;
  width: 130px;
  object-fit: cover;
  cursor: pointer;
  @media (max-width: 480px) {
    width: 75px;
    height: 75px;
  }
`;

const ImageDiv = styled.div`

  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 12px;
`;

const InnerDiv = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.selectedTab === 1 || props.selectedTab === 2
      ? "repeat(2, 1fr)"
      : "repeat(4, 1fr)"};
  grid-gap: 10px;
  padding: 28px;
  @media (max-width: 480px) {
    grid-template-columns: ${(props) =>
    props.selectedTab === 2 && "repeat(1, 1fr)"};
    padding: 5px;
    gap: 10px;
  }
`;

const MoreImages = styled.div`
  position: relative;
  height: 130px;
  width: 130px;
  border: 0.94px solid #dfdfdf;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.backgroundImage});
    filter: blur(5.5px);
    z-index: -1;
  }
  @media (max-width: 480px) {
    width: 75px;
    height: 75px;
  }
`;

const StyledNumber = styled.p`
  color: #000;
  opacity: 0.5;
  text-align: center;
  font-size: 34px;
  font-weight: bold;
  line-height: 45.34px;
  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const Albums = styled.div`
  /* background-image: url(${img2}); */
  height: 180px;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const AlbumCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  position: absolute;
  bottom: 12px;
  right: 12px;
`;

const AlbumText = styled.p`
  margin: 12px;
  font-family: Poppins;
  color: #fff;
  font-size: 14px;
`;

const Message = styled.div`

  margin: 12px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-family: Poppins;
  flex-direction: column;
  align-items: center;
  color: #a4a4a4;
`;

const section = [
  {
    name: "Portfolio",
    noOfItems: 12,
  },
  {
    name: "Albums",
    noOfItems: 8,
  },
  { name: "Videos", noOfItems: 4 },
];

const PortFolio = ({ data, isVenue ,selectedTab, setSelectedTab}) => {

  const [portfolioData, setPortfolioData] = useState([]);
  const [portFolioPhotos, setPortFolioPhotos] = useState([]);
  const [portFolioAlbums, setPortFolioAlbums] = useState([]);
  const [portFolioVideos, setPortFolioVideos] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(
    portFolioPhotos.findIndex((photo) => photo.photoURL === selectedImage)
  );
  // console.log("currentImageIndex", currentImageIndex);
  const [modalPhotos, setModalPhotos] = useState([]);
  const fetchMetaData = async () => {
    try {
      const response = await axios.get(
        `${API_HOST}/api/v1/${isVenue ? "venues" : "vendors"}/${isVenue ? data.venueSlug : data.vendorSlug
        }/portfolio`
      );
      setPortfolioData(response.data.portfolioMetadata);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMetaData();
  }, []);

  const fetchPortfolioPhotos = async () => {
    try {
      const response = await axios.get(
        `${API_HOST}/api/v1/${isVenue ? "venues" : "vendors"}/${isVenue ? data.venueSlug : data.vendorSlug
        }/portfolio/photos`
      );
      setPortFolioPhotos(response.data.portfolioPhotos);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPortFolioAlbums = async () => {
    try {
      const response = await axios.get(
        `${API_HOST}/api/v1/${isVenue ? "venues" : "vendors"}/${isVenue ? data.venueSlug : data.vendorSlug
        }/portfolio/albums`
      );
      const albums = response.data.portfolioAlbums;
      const albumsWithPhotos = await Promise.all(
        albums.map(async (album) => {
          const photosResponse = await axios.get(
            `${API_HOST}/api/v1/${isVenue ? "venues" : "vendors"}/${isVenue ? data.venueSlug : data.vendorSlug
            }/portfolio/albums/${album.albumId}/photos`
          );
          return { ...album, photos: photosResponse.data.albumPhotos };
        })
      );
      setPortFolioAlbums(albumsWithPhotos);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPortFolioVideos = async () => {
    try {
      const response = await axios.get(
        `${API_HOST}/api/v1/${isVenue ? "venues" : "vendors"}/${isVenue ? data.venueSlug : data.vendorSlug
        }/portfolio/videos`
      );
      setPortFolioVideos(response.data.portfolioVideos);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPortFolioVideos();
  }, []);

  useEffect(() => {
    fetchPortFolioAlbums();
  }, []);

  useEffect(() => {
    fetchPortfolioPhotos();
  }, []);

  const handleTabClick = (index) => {
    setSelectedTab(index);
  };

  const handleImageClick = (imageURL) => {
    setSelectedImage(imageURL);
    setModalPhotos(portFolioPhotos);
    setIsModalOpen(true);
  };

  const handleNextImage = () => {
    if (currentImageIndex < modalPhotos.length - 1) {
      setSelectedImage(modalPhotos[currentImageIndex + 1].photoURL);
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const handlePreviousImage = () => {
    if (currentImageIndex > 0) {
      setSelectedImage(modalPhotos[currentImageIndex - 1].photoURL);
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const handleAlbumClick = (album) => {
    setSelectedImage(album.photos[0].photoURL);
    setModalPhotos(album.photos);
    setIsModalOpen(true);
  };

  return (
    <>
      <PortFolioWrapper id="portfolio-albums-videos-section">
        <TabWrapper>
          {section.map((item, index) => {
            const isSelected = selectedTab === index;
            return (
              <DivWrapper
                key={index}
                onClick={() => handleTabClick(index)}
                selected={isSelected}
                first={index === 0}
                last={index === section.length - 1}
              >
                <Tab selected={isSelected}>
                  {item.name} ({index === 0 && portFolioPhotos.length}
                  {index === 1 && portFolioAlbums.length}
                  {index === 2 && portFolioVideos.length})
                </Tab>
              </DivWrapper>
            );
          })}
        </TabWrapper>
        <ImageDiv>
          {selectedTab === 0 && portFolioPhotos.length === 0 && (
            <Message>
              <PhotoIcon sx={{ fontSize: "60px", color: "#a4a4a4;" }} />
              <p>Portfolio photos are not available.</p>
            </Message>
          )}
          {selectedTab === 1 && portFolioAlbums.length === 0 && (
            <Message>
              <PhotoLibraryIcon sx={{ fontSize: "60px", color: "#a4a4a4;" }} />
              <p>Portfolio albums are not available.</p>
            </Message>
          )}
          {selectedTab === 2 && portFolioVideos.length === 0 && (
            <Message >
              <VideoLibraryIcon sx={{ fontSize: "60px", color: "#a4a4a4;" }} />
              <p>Portfolio videos are not available.</p>
            </Message>
          )}
          <InnerDiv selectedTab={selectedTab}>
            {selectedTab === 0 && (
              <>
                {portFolioPhotos.length <= 12
                  ? portFolioPhotos.map((photo, index) => {
                    return (
                      <StyledImage
                        key={index}
                        src={photo.photoURL}
                        alt={`Portfolio photo ${photo.photoId}`}
                        onClick={() => {
                          handleImageClick(photo.photoURL);
                          setCurrentImageIndex(index);
                        }}
                      />
                    );
                  })
                  : portFolioPhotos.slice(0, 11).map((photo, index) => {
                    return (
                      <StyledImage
                        key={index}
                        src={photo.photoURL}
                        alt={`Portfolio photo ${photo.photoId}`}
                        onClick={() => {
                          handleImageClick(photo.photoURL);
                          setCurrentImageIndex(index);
                        }}
                      />
                    );
                  })}
                {portFolioPhotos.length > 12 && (
                  <MoreImages
                    backgroundImage={portFolioPhotos[11].photoURL}
                    onClick={() => {
                      handleImageClick(portFolioPhotos[11].photoURL);
                      setCurrentImageIndex(11);
                    }}
                  >
                    <StyledNumber>+{portFolioPhotos.length - 11}</StyledNumber>
                  </MoreImages>
                )}
              </>
            )}
            {selectedTab === 1 &&
              portFolioAlbums.slice(0, 3).map((album, index) => {
                return (
                  <>
                    <Albums
                      key={`${album.albumId} - ${index}`}
                      style={{
                        backgroundImage: `url(${album?.photos[0]?.photoURL})`,
                      }}
                      onClick={() => {
                        handleAlbumClick(album);
                      }}
                    >
                      <AlbumText style={{ color: "#fff" }}>
                        {album.albumName}
                      </AlbumText>
                      <AlbumCount>
                        <CollectionsIcon
                          sx={{ color: "#fff", fontSize: "18px" }}
                        />
                        <AlbumText style={{ margin: "0px 0px 0px 8px" }}>
                          {album.albumImageCount}
                        </AlbumText>
                      </AlbumCount>
                    </Albums>
                  </>
                );
              })}
            {selectedTab === 1 && portFolioAlbums.length > 3 && (
              <MoreImages
                backgroundImage={portFolioAlbums[4].coverPhoto}
                style={{
                  height: "180px",
                  width: "100%",
                }}
              >
                <StyledNumber>+{portFolioAlbums.length - 3}</StyledNumber>
              </MoreImages>
            )}
            {selectedTab === 2 &&
              (portFolioVideos.length === 0 ? (
                null
              ) : (
                portFolioVideos.map((item, index) => {
                  return (
                    <Box
                      sx={{ width: "100%", height: "100%" }}
                      key={`${item.videoName} - ${item.videoId}`}
                      component={"iframe"}
                      src={`https://www.youtube.com/embed/${item.videoURL.split("v=")[1]
                        }`}
                      title={portFolioVideos.videoName}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    />
                  );
                })
              ))}
          </InnerDiv>
        </ImageDiv>
      </PortFolioWrapper>
      <PortFolioModal
        selected={selectedImage}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onNextImage={handleNextImage}
        onPreviousImage={handlePreviousImage}
        isFirstImage={currentImageIndex === 0}
        isLastImage={currentImageIndex === modalPhotos.length - 1}
        photos={modalPhotos}
      />
    </>
  );
};

export default PortFolio;
