import React, { useState } from "react";
import styled from "styled-components";
import StarIcon from "@mui/icons-material/Star";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Send from "../../../assets/Send.svg";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ReviewModal from "./ReviewModal";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { API_HOST } from "../../api/url";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import { historySlice } from "../../../redux/slice/historySlice";
const StyledHeading = styled.h1`
  color: #000;
  font-size: 44px;
  font-weight: 700;
  text-transform: capitalize;
  margin: 12px 0px;
  @media (max-width: 480px) {
    display: none;
  }
`;

const SubHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 12px 0px;
    width: 90%;
  }
`;

const ItemsWrapper1 = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ItemsWrapper2 = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const ItemText = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  color: #000;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  @media (max-width: 480px) {
    margin-bottom: 12px;
    font-size: 16px;
  }
`;

const VenueHeader = ({ data, isVenue }) => {
  console.log(data)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const navigate = useNavigate();
  const dispatch=useDispatch()
  const location=useLocation()
  const [isSaved, setIsSaved] = useState(data.venueSaved || data.vendorSaved);
  const [iconColor, setIconColor] = useState(
    (data.venueSaved || data.vendorSaved) && isAuthenticated === "AUTHENTICATED"
      ? "rgb(0, 128, 45)"
      : "inherit"
  );
  
  const handleRedirect = () => {
    navigate("/login");
  };

  const handleClick = () => {
    if (isAuthenticated === "AUTHENTICATED") {
      setIsModalOpen(!isModalOpen);
    } else {
      dispatch(historySlice.actions.updateHistory(location.pathname.toString()))
      handleRedirect();
    }
  };

  const postReview = async (venueSlug, reviewData) => {
    if (isAuthenticated === "AUTHENTICATED") {
      try {
        const response = await axios.post(
          `${API_HOST}/api/v1/venues/${venueSlug}/reviews`,
          reviewData
        );
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    } else {
      handleRedirect();
    }
  };

  const SaveVenue = async () => {
    if (isAuthenticated === "AUTHENTICATED") {
      if (isVenue) {
        setIsSaved(!isSaved);
        try {
          await axios.post(
            `${API_HOST}/api/v1/venues/${data.venueSlug}/save-to-list`
          );
          setIsSaved(true);
          setIconColor("rgb(0, 128, 45)");
        } catch (error) {
          console.log("error:", error);
        }
      } else {
        setIsSaved(!isSaved);
        try {
          await axios.post(
            `${API_HOST}/api/v1/vendors/${data.vendorSlug}/save-to-list`
          );
          setIsSaved(true);
          setIconColor("rgb(0, 128, 45)");
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      handleRedirect();
    }
  };

  const removeVenue = async () => {
    if (isVenue) {
      setIsSaved(false);
      try {
        await axios.delete(
          `${API_HOST}/api/v1/venues/${data.venueSlug}/save-to-list`
        );
        setIsSaved(false);
        setIconColor("inherit");
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsSaved(false);
      try {
        await axios.delete(
          `${API_HOST}/api/v1/vendors/${data.vendorSlug}/save-to-list`
        );
        setIsSaved(false);
        setIconColor("inherit");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const checkAuthentication = async () => {
    try {
      const response = await axios.post(`${API_HOST}/api/v1/auth/refresh`);
      setIsAuthenticated(response.data.authStatus);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleShareClick = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setSeverity("success");
      setSuccessMsg("URL copied to clipboard");
      setOpen(true);
    });
  };

  return (
    <div>
      <StyledHeading>{data.venueName || data.vendorName}</StyledHeading>
      <SubHeadingWrapper>
        <ItemsWrapper1>
          <ItemText style={{ marginRight: "16px" }}>
            <StarIcon sx={{ marginRight: "8px", fontSize: "18px" }} />
            {(data.venueRatingAverage &&
            Number.isInteger(data?.venueRatingAverage)
              ? data?.venueRatingAverage
              : data?.venueRatingAverage?.toFixed(1)) ||
              (data.vendorRatingAverage &&
              Number.isInteger(data?.vendorRatingAverage)
                ? data?.vendorRatingAverage
                : data?.vendorRatingAverage?.toFixed(1)) ||
              0}
            <span style={{ color: "#777", marginLeft: "4px" }}>
              {" "}
              {`(${
                data.venueRatingCount || data.vendorRatingCount || 0
              } reviews)`}
            </span>
          </ItemText>
          <ItemText>
            <LocationOnIcon sx={{ marginRight: "8px", fontSize: "18px" }} />
            {isVenue ? data.venueCity : data.vendorCity}
            {(data.venueLatitude||data.vendorLatitude)?<a
              style={{ color: "#777", marginLeft: "10px" }}
              target="_blank"
              rel="noreferrer"
              href={`https://www.google.com/maps/?q=${
                isVenue ? data.venueLatitude : data.vendorLatitude
              },${isVenue ? data.venueLongitude : data.vendorLongitude}`}
            >
              (View on Map)
            </a>:null
            }
          </ItemText>
        </ItemsWrapper1>
        <ItemsWrapper2>
          <ItemText style={{ marginRight: "16px" }} onClick={handleClick}>
            <DriveFileRenameOutlineIcon
              sx={{ marginRight: "8px", fontSize: "18px" }}
            />
            Write a review
          </ItemText>
          <ItemText style={{ marginRight: "16px" }} onClick={handleShareClick}>
            <img
              src={Send}
              alt="send"
              style={{ marginRight: "8px", width: "18px" }}
            />
            Share
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity={severity}
                sx={{ width: "100%" }}
              >
                {successMsg}
              </Alert>
            </Snackbar>
          </ItemText>
          <ItemText
            onClick={
              isSaved && isAuthenticated === "AUTHENTICATED"
                ? removeVenue
                : SaveVenue
            }
          >
            <BookmarkIcon
              sx={{
                marginRight: "8px",
                fontSize: "18px",
                color: iconColor,
              }}
            />
            {isSaved ? "Saved" : "Save"}
          </ItemText>
        </ItemsWrapper2>
      </SubHeadingWrapper>
      <ReviewModal
        isOpen={isModalOpen}
        handleClick={handleClick}
        name={data.venueName || data.vendorName}
        venueSlug={data.venueSlug}
        postReview={postReview}
        isVenue={isVenue}
        setSuccessMsg={setSuccessMsg}
        setOpen={setOpen}
        setIsModalOpen={setIsModalOpen}
        setSeverity={setSeverity}
        data={data}
      />
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {successMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default VenueHeader;
