import React, { useState } from "react";
import styled from "styled-components";
import { useLocation } from 'react-router-dom';
import { hamburgerMenuItems } from "./hamburgerMenuItems";
import { useNavigate } from "react-router-dom";
import { StyledTagImage, VendorItemWraper } from "./NavBarItems";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import axios from "axios";
import { API_HOST } from "../api/url";
import { useLogin } from "../../context/LoginContext";
import { DropdownItem } from "./ProfileDropDown";
import { LS_AUTH_TOKEN } from "../../utils/apiHandler";
import { useDispatch } from "react-redux";
import { historySlice } from "../../redux/slice/historySlice";

const MainWrapper = styled.div`
  padding: 20px;
  overflow-y: auto;
  height: 80vh;
  /* box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1); */
`;

const SignIn = styled.p`
  color: #1e1e1e;
  font-family: Hanken Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Heading = styled.p`
  color: #1e1e1e;
  font-family: Hanken Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
`;

const Category = styled.p`
  color: #1e1e1e;
  font-family: Hanken Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 8px 0;
`;

const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Tag = styled.p`
  color: #353535;
  font-family: Hanken Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 8px 0;
  cursor: pointer;
`;

const HamburgerMenuWrapper = styled.div`
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
`;

const HamburgerMenu = ({
  onVendorTagClick,
  isMenuOpen,
  setIsMenuOpen,
  profileData,
}) => {
  const dispatch=useDispatch()
  const location = useLocation();
  const [isClicked, setIsClicked] = useState(null);
  const navigate = useNavigate();
  const { userFullName, profile, setProfile } = useLogin();

  const handleLogOut = async () => {
    if (!profile) {
      try {
        await axios.post(`${API_HOST}/api/v1/auth/logout`);
        window.localStorage.removeItem(LS_AUTH_TOKEN);
        axios.defaults.headers.common.Authorization = undefined;
        navigate("/");
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    } else {
      setProfile(null);
      navigate("/");
      window.location.reload();
    }
  };

  return (
    <MainWrapper>
      <HamburgerMenuWrapper
        open={isMenuOpen}
        onBackdropClick={() => setIsMenuOpen(false)}
      >
        <Heading  onClick={()=>window.open("https://blog.maangalbazaar.com","_blank")}>Blog</Heading>
        <Heading onClick={()=>navigate("/magazine")}>Magazine</Heading>
        <hr />
        {!profileData && !profile ? (
          <SignIn 
          id="login_mobile_14"
          onClick={() => {
            dispatch(historySlice.actions.updateHistory(location.pathname.toString()))
            navigate("/login")
          
          }
          }>Sign In/Sign Up</SignIn>
           ) : (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Heading
                style={{ marginBottom: "24px" }}
                onClick={() => navigate("/profile")}
              >
                Hi,{" "}
                {(profile && profile.name.split(" ")[0]) ||
                  userFullName ||
                  profileData?.userProfile.userFullName.split(" ")[0]}
              </Heading>
              <DropdownItem
                onClick={handleLogOut}
                style={{
                  color: "#1e1e1e",
                  fontWeight: 600,
                  marginTop: "-4px",
                }}
              >
                Logout
              </DropdownItem>
            </div>
            <hr />
            <Heading
              onClick={() => {
                navigate("/saved");
              }}
              style={{
                color: "#1e1e1e",
                fontWeight: 600,
              }}
            >
              Saved Wedding Venues/Vendors
            </Heading>
          </>
        )}
        <hr />
        <Heading
          onClick={() => {
            onVendorTagClick("Wedding Venues");
            navigate("/venues", {
              state: {
                category: "VENUES",
                categoryItem: { value: "VENUES" },
              },
            });
          }}
        >
          Venues
        </Heading>
        <hr />
        <Heading
          style={{ marginBottom: "24px" }}
          onClick={() => navigate("/vendors/categories")}
        >
          Wedding Categories
        </Heading>
        <div>
          {hamburgerMenuItems.map((vendor, index) => {
            return (
              <div
                style={{ margin: "12px 0" }}
                key={`item-${vendor.category}-${index}`}
              >
                <CategoryWrapper onClick={() => setIsClicked(index)}>
                  <VendorItemWraper>
                    <StyledTagImage
                      src={vendor.image}
                      alt={vendor.category}
                      style={{ marginRight: "8px" }}
                    />
                    <Category>{vendor.category}</Category>
                  </VendorItemWraper>
                  {isClicked === index ? (
                    <ChevronRightRoundedIcon />
                  ) : (
                    <KeyboardArrowDownRoundedIcon />
                  )}
                </CategoryWrapper>
                <div>
                  {isClicked === index && (
                    <div>
                      {vendor.tags.map((tag, index) => {
                        return (
                          <Tag
                            key={`category-${tag.subcategoryText}-${index}`}
                            onClick={() => {
                              window.open(
                                `/vendor/${tag.vendorSubcategory}`,
                                "_blank"
                              );
                              // onVendorTagClick(
                              //   tag === 'View All'
                              //     ? vendor.category
                              //     : tag.subcategoryText
                              // );
                            }}
                          >
                            {tag.subcategoryText}
                          </Tag>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </HamburgerMenuWrapper>
    </MainWrapper>
  );
};

export default HamburgerMenu;
