import React, { useState } from "react";
import styled from "styled-components";
import image1 from "../../../assets/Venue-Details/image1.svg";
import image4 from "../../../assets/Venue-Details/image4.svg";
import axios from "axios";
import { API_HOST } from "../../api/url";
import { useEffect } from "react";
import PortFolioModal from "./PortFolioModal";
import CatalogLoader from "./CatalogLoader";

const CatalogWrapper = styled.div`
  margin: 28px 0;
  height: 460px;
  width: 58%;
  border-radius: 16px;
  display: flex;
  @media (max-width: 480px) {
    flex-direction: column;
    height: 100%;
    align-items: center;
    width: 100%;
  }
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
`;

const MainImageWrapper = styled.div`
  flex: 8;
  /* margin-right: 32px; */
  @media (max-width: 480px) {
    width: 100%;
    margin: auto;
    flex: 0;
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
  }
`;

const MainImage = styled.img`
  width: 98%;
  height: 100%;
  object-fit: contain;
  border-radius: 15px;
  /* width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 15px; */
  @media (max-width: 480px) {
    height: 170px;
    width: 100%;
    border-radius: 8px;
  }
`;

const SubImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  flex: 1;
  @media (max-width: 480px) {
    flex-direction: row;
    gap: 6px;
    width: 100%;
  }
`;

const StyledSubImage = styled.img`
  width: 140px;
  height: 100px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 10px;
  @media (max-width: 480px) {
    width: 20vw;
    height: 15vw;
    object-fit: cover;
    border-radius: 6px;
  }
`;

const MoreImages = styled.div`
  position: relative;
  width: 140px;
  height: 100px;
  border-radius: 10px;
  border: 0.94px solid #dfdfdf;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${image4});
    filter: blur(5.5px);
    z-index: -1;
  }
  @media (max-width: 480px) {
    width: 20vw;
    height: 15vw;
  }
`;

const StyledNumber = styled.p`
  color: #fff;
  opacity: 0.5;
  text-align: center;
  font-size: 34px;
  font-weight: bold;
  line-height: 45.34px;
  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const ImageCatalog = (data, isVenue) => {
  const [photos, setPhotos] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(
    photos.findIndex((photo) => photo.photoURL === selectedImage)
  );
  // const [mainImage, setMainImage] = useState(
  //   photos && photos[0] ? photos[0].photoURL : image1
  // );
  // console.log('data+:', data);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPortfolioPhotos = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${API_HOST}/api/v1/${data.isVenue ? "venues" : "vendors"}/${
          data.isVenue ? data.data.venueSlug : data.data.vendorSlug
        }/portfolio/photos`
      );
      setPhotos(response.data.portfolioPhotos);
      // setMainImage(response.data.portfolioPhotos[0].photoURL);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPortfolioPhotos();
  }, []);

  const handleImageClick = (imageURL) => {
    setSelectedImage(imageURL);
    setIsModalOpen(true);
  };

  const handleNextImage = () => {
    if (currentImageIndex < photos.length - 1) {
      setSelectedImage(photos[currentImageIndex + 1].photoURL);
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const handlePreviousImage = () => {
    if (currentImageIndex > 0) {
      setSelectedImage(photos[currentImageIndex - 1].photoURL);
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  return (
    <>
      {isLoading ? (
        <CatalogLoader />
      ) : (
        <CatalogWrapper style={{ overflow: "hidden" }}>
          <MainImageWrapper
            style={{ textAlign: photos.length > 4 ? "" : "center" }}
          >
            <MainImage
              src={
                data.data.venueCoverImageURL || data.data.vendorCoverImageURL
                  ? data.isVenue
                    ? data.data.venueCoverImageURL
                    : data.data.vendorCoverImageURL
                  : image1
              }
              alt="mainImage"
              style={{
                objectFit: "contain",
                scale: "1.2",
              }}
            />
          </MainImageWrapper>
          {/* {photos.length > 3 && (
            <SubImagesWrapper>
              {photos &&
                photos.length > 3 &&
                photos?.slice(0, 3).map((img, index) => {
                  return (
                    <StyledSubImage
                      key={img.photoId}
                      src={img.photoURL}
                      alt={index}
                      onClick={() => {
                        handleImageClick(photos[index].photoURL);
                      }}
                    />
                  );
                })}
              {photos.length > 3 && (
                <MoreImages
                  onClick={() =>
                    handleImageClick(
                      // photos && photos[0] ? photos[0].photoURL : mainImage
                      photos && photos[0] && photos[0].photoURL
                    )
                  }
                >
                  <StyledNumber>+{photos && photos.length - 4}</StyledNumber>
                </MoreImages>
              )}
            </SubImagesWrapper>
          )} */}
        </CatalogWrapper>
      )}
      <PortFolioModal
        selected={selectedImage}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onNextImage={handleNextImage}
        onPreviousImage={handlePreviousImage}
        isFirstImage={currentImageIndex === 0}
        isLastImage={currentImageIndex === photos.length - 1}
      />
    </>
  );
};

export default ImageCatalog;
