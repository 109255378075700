import React, { useEffect } from "react";
import styled from "styled-components";
import Venue1 from "../../../assets/Venues/venue1.svg";
import StarIcon from "@mui/icons-material/Star";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";
import MehandiVendor from "../../../assets/MehandiVedor.svg";
import { useState } from "react";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import axios from "axios";
import { API_HOST } from "../../api/url";
import Premium from "../../../assets/premium.svg";
import featured from "../../../assets/featured.svg";

function filterVendorMetadata(vendorMetadata) {
  const propertiesToRemove = [
    "VENDOR_PREMIUM_DATE",
    "VENDOR_LISTING_DATE",
    "VENDOR_SOCIAL_IG_LINK",
    "VENDOR_SOCIAL_WS_LINK",
    "VENDOR_SOCIAL_FB_LINK",
    "VENDOR_SOCIAL_YT_LINK",
  ];
  return vendorMetadata.filter(
    (item) => !propertiesToRemove.includes(item.propertyName)
  ).length;
}

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 391px;
  height: 442px;
  /* background-image: ${(props) =>
    props.isVenuePage
      ? `url(${props.data.venueCoverImageURL})`
      : `url(${props.data.vendorCoverImageURL})`}; */
  border-radius: 16px;
  border: 1px solid #ededed;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: auto !important;
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 480px) {
    max-width: 350px;
    height: 410px;
    &:hover {
      transform: none;
    }
  }
`;

const DetailsWrapper = styled.div`
  border-radius: 0px 0px 15px 15px;
  background: #fff;
  width: 100%;
  height: 170px;
  font-family: "Hanken Grotesk", sans-serif;
`;

const StyledHeading = styled.p`
  color: #222;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledP = styled.p`
  margin: 0;
  color: #222;
  font-size: 16px;
  font-family: Hanken Grotesk;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const StyledRates = styled.p`
  margin: 12px 16px 4px 0px;
  font-weight: 500;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const StyledLocation = styled.p`
  margin: 0;
  font-size: 16px;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const StyledTags = styled.p`
  margin: 0px 4px 0px 0px;
  padding: 7px;
  border-radius: 5px;
  background: #ededed;
  color: rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  @media (max-width: 480px) {
    padding: 8.951px;
    font-size: 14px;
  }
`;

const PaddingDiv = styled.div`
  padding: 12px;
  @media (max-width: 480px) {
    padding: 16px 12px;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const RatesDiv = styled.div`
  display: flex;
`;

const Span = styled.span`
  color: #777777;
`;

const Hr = styled.hr`
  background: #ededed;
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

const AlignCenterDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const LawnP = styled.p`
  font-size: 16px;
  color: #777;
  margin: 0;
  text-transform: capitalize !important;
  @media (max-width: 14px) {
    font-size: 14px;
  }
`;

const MoreP = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: underline;
  margin-left: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const IconWrapper = styled.div`
  padding: 10px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const MembershipDiv = styled.div`
  /* height: 30px; */
  background: rgb(19, 54, 45);
  width: fit-content;
  display: flex;
  align-items: center;
  color: rgb(0, 0, 0);
  border: 1px solid rgb(19, 54, 45);
  font-family: Hanken Grotesk, sans-serif;
  color: rgb(255, 255, 255);
  padding: 4px 20px;
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 16px 0px;
`;

export const PremiumIcons = styled.img`
  height: 18px;
  margin-right: 5px;
`;

const StyledImage = styled.img`
  height: 252px;
  width: 100%;
  /* height: 242px; */
  object-fit: contain;
  border-radius: 16px 16px 0px 0px;
  @media (max-width: 480px) {
    height: 229px !important;
  }
`;

export const locationTypes = [
  {
    value: "LAWN",
    text: "Lawn",
  },
  {
    value: "DESTINATION_WEDDING",
    text: "Destination Wedding",
  },
  {
    value: "4_STAR_PLUS_HOTEL",
    text: "4 Star and Above Hotel",
  },
  {
    value: "EXOTIC_HOMESTAY",
    text: "Exotic Homestay",
  },
  // {
  //   value: "KALYANA_MANDAPAM",
  //   text: "Kalyana Mandapam",
  // },
  {
    value: "SMALL_FUNCTION_LOCATION",
    text: "Small Party Hall",
  },
  {
    value: "RESORT",
    text: "Resort",
  },
  {
    value: "BANQUET",
    text: "Banquet",
  },
];

const Card = ({ data = {}, isVenue }) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [isSaved, setIsSaved] = useState(data.venueSaved || data.vendorSaved);
  const [iconColor, setIconColor] = useState(
    (data.venueSaved || data.vendorSaved) && isAuthenticated === "AUTHENTICATED"
      ? "#13362D"
      : "inherit"
  );

  const handleRedirect = () => {
    navigate("/login");
  };

  const saveVenue = async (event) => {
    event.stopPropagation();
    if (isAuthenticated === "AUTHENTICATED") {
      if (isVenue) {
        try {
          await axios.post(
            `${API_HOST}/api/v1/venues/${data.venueSlug}/save-to-list`
          );
          setIsSaved(true);
          setIconColor("13362D");
        } catch (error) {
          console.log("error:", error);
        }
      } else {
        try {
          await axios.post(
            `${API_HOST}/api/v1/vendors/${data.vendorSlug}/save-to-list`
          );
          setIsSaved(true);
          setIconColor("13362D");
        } catch (error) {
          console.log("error:", error);
        }
      }
    } else {
      handleRedirect();
    }
  };

  const removeVenue = async (event) => {
    event.stopPropagation();
    if (isVenue) {
      try {
        await axios.delete(
          `${API_HOST}/api/v1/venues/${data.venueSlug}/save-to-list`
        );
        setIsSaved(false);
        setIconColor("inherit");
      } catch (error) {
        console.log("error:", error);
      }
    } else {
      try {
        await axios.delete(
          `${API_HOST}/api/v1/vendors/${data.vendorSlug}/save-to-list`
        );
        setIsSaved(false);
        setIconColor("inherit");
      } catch (error) {
        console.log("error:", error);
      }
    }
  };

  const checkAuthentication = async () => {
    try {
      const response = await axios.post(`${API_HOST}/api/v1/auth/refresh`);
      setIsAuthenticated(response.data.authStatus);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  return (
    <StyledCard
      isVenuePage={isVenue}
      data={data}
      onClick={() => {
        const url = isVenue
          ? `/venues/${data.venueSlug}`
          : `/vendor_profile/${data.vendorSlug}`;

        window.open(url, "_blank");
      }}
    >
      <div style={{ overflow: "hidden", alignSelf: "center" }}>
        <StyledImage
          src={isVenue ? data.venueCoverImageURL : data.vendorCoverImageURL}
          alt="productImg"
          // style={{ rotate: "90deg" }}
        />
      </div>
      {(isVenue
        ? data.venuePremiumStatus === 0
        : data.vendorPremiumStatus === 0) && (
        <MembershipDiv>
          <PremiumIcons src={Premium} alt="premium" />
          <p style={{ margin: 0 }}>Premium</p>
        </MembershipDiv>
      )}
      {(isVenue
        ? data.venuePremiumStatus === 1
        : data.vendorPremiumStatus === 1) && (
        <MembershipDiv>
          <PremiumIcons src={featured} alt="Featured" />
          <p style={{ margin: 0 }}>Featured</p>
        </MembershipDiv>
      )}
      <IconWrapper
        onClick={
          isSaved && isAuthenticated === "AUTHENTICATED"
            ? removeVenue
            : saveVenue
        }
      >
        {isSaved ? (
          <BookmarkIcon sx={{ fontSize: "18px", color: iconColor }} />
        ) : (
          <BookmarkBorderOutlinedIcon
            sx={{ fontSize: "18px", color: iconColor }}
          />
        )}
      </IconWrapper>
      <DetailsWrapper>
        <PaddingDiv isVenuePage={isVenue}>
          <StyledWrapper>
            <StyledHeading style={{ textTransform: "capitalize" }}>
              {data.venueName ? data.venueName : data.vendorName}
            </StyledHeading>
            <FlexDiv>
              <StarIcon
                sx={{ fontSize: "18px", paddingRight: "4px" }}
                className="StarIcon"
              />
              <StyledP>
                {isVenue
                  ? `${
                      Number.isInteger(data?.venueRatingAverage)
                        ? data?.venueRatingAverage
                        : data?.venueRatingAverage?.toFixed(1)
                    } (${data?.venueRatingCount} reviews)`
                  : `${
                      Number.isInteger(data?.vendorRatingAverage)
                        ? data?.vendorRatingAverage
                        : data?.vendorRatingAverage?.toFixed(1)
                    } (${data?.vendorRatingCount} reviews)`}
              </StyledP>
            </FlexDiv>
          </StyledWrapper>
          {isVenue && data && data.venuePricingRate && (
            <>
              <RatesDiv>
                <StyledRates>
                  <Span>Veg:</Span>{" "}
                  {data.venuePricingRate?.length > 0
                    ? `  ₹${data.venuePricingRate[0]}`
                    : "NA"}
                </StyledRates>
                <StyledRates>
                  <Span>Non Veg:</Span>{" "}
                  {data.venuePricingRate?.length > 1
                    ? `  ₹${data.venuePricingRate[1]}`
                    : "NA"}
                </StyledRates>
              </RatesDiv>
              <Hr />
            </>
          )}
          <SpaceBetweenDiv>
            <AlignCenterDiv>
              <LocationOnIcon
                sx={{ fontSize: "18px", paddingRight: "4px" }}
                className="LocationIcon"
              />
              <StyledLocation>
                {data.venueCity ? data.venueCity : data.vendorCity}
              </StyledLocation>
            </AlignCenterDiv>
            {isVenue && (
              <LawnP>
                {data?.venueLocationTypes
                  ?.slice(0, 2)
                  .reduce((acc, item) => {
                    const locationType = locationTypes.find(
                      (type) => type.value === item
                    );
                    return locationType ? [...acc, locationType.text] : acc;
                  }, [])
                  .join(", ")}
              </LawnP>
            )}
          </SpaceBetweenDiv>
          {!isVenue &&
            (data.vendorServiceRate === -1 ? (
              <StyledRates style={{ marginTop: "4px " }}>
                <Span>Package</Span> : On Request
              </StyledRates>
            ) : (
              <StyledRates style={{ marginTop: "4px " }}>
                <Span>Package</Span> : ₹{data.vendorServiceRate}
              </StyledRates>
            ))}
          <FlexDiv style={{ marginTop: "8px" }}>
            {/* {isVenue ? (
              data.venueMaxCapacity > 0 &&
              data.venueMinCapacity > 0 && (
                <StyledTags>
                  {data.venueMinCapacity} - {data.venueMaxCapacity} pax
                </StyledTags>
              )
            ) : (
              <StyledTags>Freelance artist</StyledTags>
            )}
            {isVenue ? (
              data &&
              data.venueMetadata &&
              data.venueMetadata.find(
                (property) => property.propertyName === "VENUE_ROOM_COUNT"
              ) && (
                <StyledTags>
                  {
                    data.venueMetadata.find(
                      (property) => property.propertyName === "VENUE_ROOM_COUNT"
                    ).propertyValue
                  }{" "}
                  rooms
                </StyledTags>
              )
            ) : (
              <StyledTags>Trial not available</StyledTags>
            )} */}
            {isVenue
              ? data.venueMetadata?.length > 2
              : data.vendorMetadata?.length > 2 && (
                  <StyledTags>
                    {isVenue
                      ? `${data.venueMetadata?.length || 0} services available`
                      : `${
                          filterVendorMetadata(data.vendorMetadata) || 0
                        } services available`}
                  </StyledTags>
                )}
          </FlexDiv>
        </PaddingDiv>
      </DetailsWrapper>
    </StyledCard>
  );
};

export default Card;
