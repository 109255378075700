import {API_HOST, API_VERSION} from "../components/api/url";
import {useCallback, useEffect, useState} from "react";

export const LS_AUTH_TOKEN = "mw-auth-token"

export async function makeAPIRequest(args) {
    try {
        let {requestEndpoint, requestMethod = "GET", urlParams = {}, queryParams = {}, bodyParams = {}} = args

        const urlParamKeys = Object.keys(urlParams)

        urlParamKeys.forEach((paramKey) => {
            const paramValue = urlParams[paramKey]
            requestEndpoint = requestEndpoint.replace(`:${paramKey}`, encodeURIComponent(paramValue))
        })

        const apiUrl = new URL(
            `/api/${API_VERSION}` + (requestEndpoint.startsWith("/") ? "" : "/") + requestEndpoint,
            API_HOST
        )

        for (const [queryKey, queryValue] of Object.entries(queryParams)) {
            apiUrl.searchParams.set(queryKey, queryValue.toString())
        }

        let apiHeaders = {
            "Content-Type": "application/json"
        }

        const lsToken = window.localStorage.getItem(LS_AUTH_TOKEN)
        if (lsToken !== null){
            apiHeaders = {
                ...apiHeaders,
                "Authorization": `Bearer ${lsToken}`
            }
        }

        const apiResponse = await fetch(
            apiUrl,
            {
                headers: apiHeaders,
                credentials: "include",
                cache: "no-cache",
                method: requestMethod,
                body: (requestMethod !== "GET") ?
                    JSON.stringify(bodyParams)
                    : undefined
            }
        )

        const responseData = await apiResponse.json()

        return {
            isSuccess: true,
            isError: false,
            responseCode: apiResponse.status,
            responseData: responseData,
            requestError: undefined
        }
    } catch (err) {
        console.error(err)
        return {
            isSuccess: false,
            isError: true,
            responseCode: 0,
            responseData: undefined,
            requestError: err
        }
    }
}

export function useAPIRequest(args) {
    const {requestEndpoint, requestMethod = "GET", urlParams = {}, queryParams = {}, bodyParams = {}, requestDeps = []} = args

    const [isLoading, setIsLoading] = useState(true)
    const [
        responseData,
        setResponseData
    ] = useState({
        isSuccess: false,
        isError: false,
        responseCode: -1,
        requestError: undefined,
        responseData: undefined
    })

    const requestFn = useCallback(async () => {
        const responseObject = await makeAPIRequest(args)
        setResponseData(responseObject)
        setIsLoading(false)
    }, [requestEndpoint, requestMethod, urlParams, queryParams, bodyParams])

    useEffect(() => {
        requestFn()
    }, [...requestDeps])

    return {
        isLoading: isLoading,
        ...responseData
    }
}

export async function manageMedia(args) {
    try {
      let { mediaFiles, objectKeyGenFn, fileMethod } = args;
      // console.log(mediaFiles)
      fileMethod = Array.isArray(fileMethod)
        ? fileMethod
        : Array(mediaFiles.length).fill(fileMethod);
      const fileRequests = [];
      
      await Promise.all(
        mediaFiles.map(async (fileObj, fileIdx) => {
         
          const reqMethod = fileMethod[fileIdx];
          const objectKey = objectKeyGenFn(fileObj, fileIdx, reqMethod);
          const {
            isSuccess: isPresignedSuccess,
            isError: isPresignedError,
            responseCode: presignedResponseCode,
            responseData: presignedResponseData,
            requestError: presignedRequestError,
          } = await makeAPIRequest({
            requestMethod: 'POST',
            requestEndpoint: '/media/presigned-url',
            bodyParams: {
              requestMethod: reqMethod,
              objectKey: objectKey,
              objectSizeBytes: fileObj.size,
              objectFileName: fileObj.name,
              objectContentType: fileObj.type,
            },
          });
          if (isPresignedSuccess) {
            const { responseStatus: presignedResponseStatus } =
              presignedResponseData;
            if (presignedResponseStatus === 'SUCCESS') {
              const { presignedUrl, requestId } = presignedResponseData;
              fileRequests.push({
                fileHandle: fileObj,
                objectKey: objectKey,
                presignedURL: presignedUrl,
                requestId: requestId,
                requestMethod: reqMethod,
                requestStatus: 'WAIT',
                requestAcknowledged: false,
              });
            }
          }
        })
      );
      if (fileRequests.length !== mediaFiles.length) {
        return fileRequests;
      }
      const mappedRequests = await Promise.all(
        fileRequests.map(async (fileRequest, fileIdx) => {
          const {
            fileHandle,
            requestMethod,
            requestAcknowledged,
            requestId,
            requestStatus,
            presignedURL,
          } = fileRequest;
          if (requestMethod === 'GET') {
            return {
              ...fileRequest,
              requestAcknowledged: true,
              requestStatus: 'ACK',
            };
          }
          const { size, name, type } = fileHandle;
          try {
            const s3Response = await fetch(presignedURL, {
              body: fileHandle,
              method: requestMethod,
            });
            if (s3Response.ok) {
              const {
                isSuccess,
                requestError,
                isError,
                responseCode,
                responseData,
              } = await makeAPIRequest({
                requestEndpoint: `/media/requests/:requestId`,
                requestMethod: requestMethod,
                urlParams: {
                  requestId: requestId,
                },
                bodyParams: {
                  mediaContentType: type,
                  mediaSizeBytes: size,
                  mediaFileName: name,
                },
              });
              if (isSuccess) {
                const { responseStatus } = responseData;
                if (responseStatus === 'SUCCESS') {
                  return {
                    ...fileRequest,
                    requestStatus: 'ACK',
                    requestAcknowledged: true,
                  };
                }
              }
              return {
                ...fileRequest,
                requestStatus: 'ERR',
                requestAcknowledged: false,
              };
            } else {
              throw new Error('Error uploading file to URL');
            }
          } catch (err) {
            console.error(err);
            const {
              isSuccess,
              requestError,
              isError,
              responseCode,
              responseData,
            } = await makeAPIRequest({
              requestEndpoint: `/media/requests/:requestId`,
              requestMethod: 'POST',
              urlParams: {
                requestId: requestId,
              },
            });
            if (isSuccess) {
              const { responseStatus } = responseData;
              if (responseStatus === 'SUCCESS') {
                return {
                  ...fileRequest,
                  requestStatus: 'ERR',
                  requestAcknowledged: true,
                };
              }
            }
            return {
              ...fileRequest,
              requestStatus: 'ERR',
              requestAcknowledged: false,
            };
          }
        })
      );
      return mappedRequests;
    } catch (err) {
      return [];
    }
  }
  