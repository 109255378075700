import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Modal } from "@mui/material";
import styled from "styled-components";
import Star from "../../../assets/Star.svg";
import StarIcon from "@mui/icons-material/Star";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { API_HOST } from "../../api/url";

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 368px;
  width: 500px;
  border-radius: 8px;
  background: #13362d;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(6px);
  color: #fff;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledStar = styled.img`
  height: 100px;
  width: 100px;
  margin-top: -40px;
`;

const StyledTitle = styled.p`
  color: var(--white-100, #fff);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const StyledTextArea = styled.textarea`
  height: 80px;
  width: 68%;
  outline: none;
  resize: none;
  padding: 12px 24px;
  color: var(--white-75, rgba(255, 255, 255, 0.75));
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  border-radius: 4px;
  border: 1px solid #051019;
  background: rgba(5, 16, 25, 0.5);
  margin-top: 12px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledButtonDiv = styled.div`
  margin-top: 24px;
  text-align: center;
`;

const ReviewModal = ({
  isOpen,
  handleClick,
  name,
  venueSlug,
  postReview,
  isVenue,
  data,
  setSuccessMsg,
  setOpen,
  setIsModalOpen,
  setSeverity,
}) => {
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");

  const handleRating = (value) => {
    setRating(value);
  };

  const handleSubmit = async () => {
    const reviewData = {
      reviewTitle: "Review",
      reviewRating: rating,
      reviewDescription: reviewText,
    };
    if (isVenue) {
      try {
        await postReview(venueSlug, reviewData);
        setSeverity("success");
        setSuccessMsg("Review Uploaded Successfully!");
        setOpen(true);
        setIsModalOpen(false);
        window.location.reload();
      } catch (error) {
        setSeverity("error");
        setSuccessMsg("An Error Occurred While Uploading Review");
        setOpen(true);
        setIsModalOpen(false);
      }
      handleClick(false);
    } else {
      try {
        await axios.post(
          `${API_HOST}/api/v1/vendors/${data.vendorSlug}/reviews`,
          reviewData
        );
        setSeverity("success");
        setSuccessMsg("Review Uploaded Successfully!");
        setOpen(true);
        setIsModalOpen(false);
        window.location.reload();
      } catch (error) {
        setSeverity("error");
        setSuccessMsg("An Error Occurred While Uploading Review");
        setOpen(true);
        setIsModalOpen(false);
      }
    }
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledBox>
        <IconButton className="reviewClose" onClick={() => handleClick(false)}>
          <CloseIcon />
        </IconButton>
        <StyledStar src={Star} alt="star" />
        <StyledTitle>{name}</StyledTitle>
        <div>
          {[1, 2, 3, 4, 5].map((_, index) => {
            return (
              <StarIcon
                key={index}
                sx={{
                  padding: "0px 4px 12px 0px",
                  cursor: "pointer",
                  color:
                    index < rating ? "#FFB800" : "rgba(255, 255, 255, 0.75)",
                }}
                onClick={() => handleRating(index + 1)}
              />
            );
          })}
        </div>
        <StyledTextArea
          placeholder="Write your review here...."
          value={reviewText}
          onChange={(e) => setReviewText(e.target.value)}
        />
        <StyledButtonDiv>
          <Button
            variant="contained"
            className="editProfileSubmit"
            onClick={handleSubmit}
          >
            Submit Reviews
          </Button>
        </StyledButtonDiv>
      </StyledBox>
    </Modal>
  );
};

export default ReviewModal;
