import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAPIRequest } from '../../utils/apiHandler';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChatScreen from './ChatScreen';
import ChatCard from './ChatCard';
import { useLocation } from 'react-router-dom';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import { Skeleton } from '@mui/material';

const MainChatScreenWrapper = styled.div`
  margin: 2em 6em;
  @media (max-width: 480px) {
    margin: 1em 20px 1em 20px;
  }
`;

const StyledHeading = styled.h1`
  color: #1b1d27;
  font-family: Outfit;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  @media (max-width: 480px) {
    font-size: 38px;
    text-align: center;
    margin: 24px 0px;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
  z-index: 1;
  margin-top: 1em;
  @media (max-width: 480px) {
    height: 420px;
  }
`;

const ChatListWrapper = styled.div`
  width: 30%;
  flex-shrink: 0;
  border: 1px solid #f5f5f5;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const ChatScreenWrapper = styled.div`
  width: 70%;
  flex-shrink: 0;
  border: 1px solid #f5f5f5;
  @media (max-width: 480px) {
    display: none;
  }
`;

const CardsWrapper = styled.div`
  height: 80%;
  overflow-y: scroll;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px 20px;
  outline: none;
  border-radius: 4px;
  border: 1px solid #d9dcea;
  background: #fff;
  color: #13362d;
  font-family: Hanken Grotesk;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
`;

const SelectWrapper = styled.div`
  margin: 18px 12px 12px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 480px) {
    display: ${(props) => (props.chatClicked ? 'none' : 'flex')};
  }
`;

const MobileChatScreenWrapper = styled.div`
  display: none;
  @media (max-width: 480px) {
    flex-shrink: 0;
    border: 1px solid #f5f5f5;
    display: flex;
    width: 100%;
    height: 450px;
  }
`;

const BackButton = styled.div`
  display: none;
  @media (max-width: 480px) {
    display: block;
  }
`;

const MessageWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 20px;
  background-color: whitesmoke;
  color: gray;
`;

const SkeletonWrapper = styled.div`
  /* margin: 2em 6em; */
  display: flex;
  justify-content: space-between;
  @media (max-width: 480px) {
    margin: 1em 20px 1em 20px;
  }
`;

const Chat = () => {
  const [conversationData, setConversationData] = useState([]);
  const { state } = useLocation();
  const [activeConversation, setActiveConversation] = useState(
    state?.conversationId || null
  );
  const [selectedOption, setSelectedOption] = useState('all');
  const message = state?.message;

  const queryMap = {
    all: {},
    venues: {
      serviceType: 'VENUE',
    },
    vendors: {
      serviceType: 'VENDOR',
    },
    booked: {
      conversationStatus: 'BOOKED',
    },
  };

  const {
    isLoading,
    isSuccess,
    isError,
    requestError,
    responseCode,
    responseData,
  } = useAPIRequest({
    requestEndpoint: '/conversations',
    requestMethod: 'GET',
    queryParams: queryMap[selectedOption],
    requestDeps: [selectedOption],
  });

  useEffect(() => {
    if (isSuccess && responseData) {
      const { responseStatus } = responseData;
      if (responseStatus === 'SUCCESS') {
        const { userConversations } = responseData;
        setConversationData(userConversations);
      }
    }
  }, [isLoading, isSuccess, responseData]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  if (isLoading) {
    return null;
  }

  const activeConversationObject =
    conversationData &&
    conversationData.filter(
      (conversationDat) => conversationDat.conversationId === activeConversation
    )[0];

  return (
    <>
      <MainChatScreenWrapper>
        {isLoading ? (
          <SkeletonWrapper>
            <Skeleton
              variant='rectangular'
              sx={{
                height: '500px',
                width: '30%',
                borderRadius: '20px 0px 0px 20px',
              }}
            />
            <Skeleton
              variant='rectangular'
              sx={{
                height: '500px',
                width: '70%',
                marginLeft: '16px',
                borderRadius: '0px 20px 20px 0px',
              }}
            />
          </SkeletonWrapper>
        ) : (
          <>
            {activeConversation && (
              <BackButton
                onClick={() => {
                  setActiveConversation(null);
                }}
              >
                <IconButton className='chatBackButton'>
                  <ArrowBackIcon />
                </IconButton>
              </BackButton>
            )}
            <StyledHeading>Chats with the vendors</StyledHeading>
            <MainWrapper>
              <Wrapper chatClicked={activeConversation !== null}>
                <ChatListWrapper>
                  <SelectWrapper>
                    <Select onChange={(e) => setSelectedOption(e.target.value)}>
                      <option value='all'>All</option>
                      <option value='vendors'>Vendors</option>
                      <option value='venues'>Venues</option>
                      <option value='booked'>Booked</option>
                    </Select>
                  </SelectWrapper>
                  <CardsWrapper>
                    {conversationData.map((conversationObject) => {
                      const { conversationId, conversationData } =
                        conversationObject;
                      return (
                        <ChatCard
                          conversationId={conversationId}
                          key={conversationId}
                          conversationData={conversationData}
                          onChatClick={() => {
                            setActiveConversation(conversationId);
                          }}
                        />
                      );
                    })}
                  </CardsWrapper>
                </ChatListWrapper>
                <ChatScreenWrapper>
                  {/* {activeConversation !== null ? (
              <ChatScreen
                conversationData={activeConversationObject.conversationData}
                conversationId={activeConversationObject.conversationId}
                initialMessage={message}
              />
            ) : null} */}
                  {conversationData.length === 0 && (
                    <MessageWrapper>
                      <ChatRoundedIcon
                        sx={{ height: '50px', width: '50px', color: 'gray' }}
                      />
                      <p>No chats to display.</p>
                    </MessageWrapper>
                  )}
                  {conversationData.length > 0 && !activeConversation && (
                    <MessageWrapper>
                      <ChatRoundedIcon
                        sx={{ height: '50px', width: '50px', color: 'gray' }}
                      />
                      <p>Select chat to display.</p>
                    </MessageWrapper>
                  )}
                  {activeConversationObject && (
                    <ChatScreen
                      conversationData={
                        activeConversationObject.conversationData
                      }
                      conversationId={activeConversationObject.conversationId}
                      initialMessage={message}
                    />
                  )}
                </ChatScreenWrapper>
              </Wrapper>
              <div style={{ margin: 'auto' }}>
                <MobileChatScreenWrapper
                  chatClicked={activeConversation !== null}
                >
                  {activeConversationObject && (
                    <ChatScreen
                      conversationData={
                        activeConversationObject.conversationData
                      }
                      conversationId={activeConversationObject.conversationId}
                      initialMessage={message}
                    />
                  )}
                </MobileChatScreenWrapper>
              </div>
            </MainWrapper>
          </>
        )}
      </MainChatScreenWrapper>
    </>
  );
};

export default Chat;
