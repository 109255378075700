import axios from "axios";
import React from "react";
import styled from "styled-components";
import { API_HOST } from "../api/url";
import { useLogin } from "../../context/LoginContext";

const Dropdown = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  width: 101px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #e4e5eb;
  background: #fff;
  margin-top: 4px;
  z-index: 200;
`;

export const DropdownItem = styled.p`
  cursor: pointer;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
`;

const ProfileDropDown = ({
  handleDropdownItemClick,
  navigate,
  selectedItem,
}) => {
  const { profile, setProfile } = useLogin();

  const handleLogOut = async () => {
    if (!profile) {
      try {
        await axios.post(`${API_HOST}/api/v1/auth/logout`);
        handleDropdownItemClick("Logout");
        window.location.reload();
        navigate("/");
      } catch (err) {
        console.log(err);
      }
    } else {
      setProfile(null);
      window.location.reload();
      navigate("/");
    }
  };

  return (
    <div>
      <Dropdown>
        <DropdownItem
          onClick={() => {
            handleDropdownItemClick("Profile");
            navigate("/profile");
          }}
          style={{
            color: selectedItem === "Profile" ? "#424242" : "#4F4F4F",
            fontWeight: selectedItem === "Profile" ? 600 : 400,
          }}
        >
          Profile
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            handleDropdownItemClick("Saved");
            navigate("/saved");
          }}
          style={{
            color: selectedItem === "Saved" ? "#424242" : "#4F4F4F",
            fontWeight: selectedItem === "Saved" ? 600 : 400,
          }}
        >
          Saved
        </DropdownItem>
        <DropdownItem
          onClick={handleLogOut}
          style={{
            color: selectedItem === "Logout" ? "#424242" : "#4F4F4F",
            fontWeight: selectedItem === "Logout" ? 600 : 400,
          }}
        >
          Logout
        </DropdownItem>
      </Dropdown>
    </div>
  );
};

export default ProfileDropDown;
