// this array use in autocomplete search bar
export const categories = [
  { category: "Wedding Venues", vendorCategory: "VENUES", value: "VENUES" },
  { category: "Photographer", vendorCategory: "PHOTOGRAPHY" },
  { category: "Makeup", vendorCategory: "MAKEUP" },
  { category: "Planning & Decor", vendorCategory: "PLANNING_AND_DECOR" },
  { category: "Bridal wear", vendorCategory: "BRIDAL_WEAR" },
  { category: "Bridal Grooming", vendorCategory: "BRIDAL_GROOMING" },
  { category: "Groom wear", vendorCategory: "GROOM_WEAR" },
  { category: "Mehendi", vendorCategory: "MEHENDI" },
  {
    category: "Jewellery & Accessories",
    vendorCategory: "JEWELLERY_AND_ACCESSORIES",
  },
  { category: "Invites & Gifts", vendorCategory: "INVITES_AND_GIFTS" },
  { category: "Furniture", vendorCategory: "FURNITURE" },
  { category: "Music & Dance", vendorCategory: "MUSIC_AND_DANCE" },
  { category: "Food", vendorCategory: "FOOD" },
  {
    category: "Clinics",
    vendorCategory: "CLINICS",
  },
  {
    category: "Boutiques",
    vendorCategory: "BOUTIQUES",
  },
  { category: "Honeymoon", vendorCategory: "HONEYMOON" },
  { category: "Astrology", vendorCategory: "ASTROLOGY" },
  { category: "Fitness Center", vendorCategory: "FITNESS_CENTER" },

];




// this array contain data related to Category name on page vendor/subcategory
export const vendors = [
  {
    subcategoryText: "Wedding Photographers",
    vendorCategory: "PHOTOGRAPHY",
    vendorSubcategory: "WEDDING_PHOTOGRAPHERS",
    isServiceTypeIncluded: false,  // this is useed if it is true than the subcattext will send to and api
  },
  {
    subcategoryText: "Pre Wedding Shoot",
    vendorCategory: "PHOTOGRAPHY",
    vendorSubcategory: "PRE_WEDDING_SHOOT",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Freelance Makeup Artist",
    vendorCategory: "MAKEUP",
    vendorSubcategory: "FREELANCE_MAKEUP_ARTIST",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Beauty Salon",
    vendorCategory: "MAKEUP",
    vendorSubcategory: "BEAUTY_SALON",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Wedding Planners",
    vendorCategory: "PLANNING_AND_DECOR",
    vendorSubcategory: "WEDDING_PLANNERS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Wedding decor",
    vendorCategory: "PLANNING_AND_DECOR",
    vendorSubcategory: "WEDDING_DECOR",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Florists",
    vendorCategory: "PLANNING_AND_DECOR",
    vendorSubcategory: "FLORISTS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Beauty & wellness",
    vendorCategory: "BRIDAL_GROOMING",
    vendorSubcategory: "BEAUTY_AND_WELLNESS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Bridal Lehengas",
    vendorCategory: "BRIDAL_WEAR",
    vendorSubcategory: "BRIDAL_LEHENGAS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Light Lehenga",
    vendorCategory: "BRIDAL_WEAR",
    vendorSubcategory: "LIGHT_LEHENGA",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Sarees",
    vendorCategory: "BRIDAL_WEAR",
    vendorSubcategory: "SAREES",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Cocktail Gowns",
    vendorCategory: "BRIDAL_WEAR",
    vendorSubcategory: "COCKTAIL_GOWNS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Designer Suits",
    vendorCategory: "BRIDAL_WEAR",
    vendorSubcategory: "DESIGNER_SUITS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Bridal Lehenga On Rent",
    vendorCategory: "BRIDAL_WEAR",
    vendorSubcategory: "BRIDAL_LEHENGA_ON_RENT",
    isServiceTypeIncluded: false,
  },
  // {
  //   subcategoryText: 'Lingerie',
  //   vendorCategory: 'BRIDAL_WEAR',
  //   vendorSubcategory: 'LINGERIE',
  // },
  {
    subcategoryText: "Sherwani",
    vendorCategory: "GROOM_WEAR",
    vendorSubcategory: "SHERWANI",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Wedding Suits Or Tuxes",
    vendorCategory: "GROOM_WEAR",
    vendorSubcategory: "WEDDING_SUITS_OR_TUXES",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Sherwani On Rent",
    vendorCategory: "GROOM_WEAR",
    vendorSubcategory: "SHERWANI_ON_RENT",
    isServiceTypeIncluded: false,
  },
  {
    vendorCategory: "GROOM_WEAR",
    subcategoryText: "Indo Western Outfits",
    vendorSubcategory: "INDO_WESTERN_OUTFITS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Bandhgalas",
    vendorCategory: "GROOM_WEAR",
    vendorSubcategory: "BANDHGALAS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Kurta Pyjama Sets",
    vendorCategory: "GROOM_WEAR",
    vendorSubcategory: "KURTA_PYJAMA_SETS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Waistcoats And Nehru Jackets",
    vendorCategory: "GROOM_WEAR",
    vendorSubcategory: "WAISTCOATS_AND_NEHRU_JACKETS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Groom Accessories",
    vendorCategory: "GROOM_WEAR",
    vendorSubcategory: "GROOM_ACCESSORIES",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Saafa And Turban",
    vendorCategory: "GROOM_WEAR",
    vendorSubcategory: "SAAFA_AND_TURBAN",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Mehendi Artist",
    vendorCategory: "MEHENDI",
    vendorSubcategory: "MEHENDI_ARTIST",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Jeweller",
    vendorCategory: "JEWELLERY_AND_ACCESSORIES",
    vendorSubcategory: "JEWELLER",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Artificial Jewellery",
    vendorSubcategory: "ARTIFICIAL_JEWELLERY",
    vendorCategory: "JEWELLERY_AND_ACCESSORIES",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Bridal Jewellery On Rent",
    vendorCategory: "JEWELLERY_AND_ACCESSORIES",
    vendorSubcategory: "BRIDAL_JEWELLERY_ON_RENT",
    isServiceTypeIncluded: true,
  },
  {
    subcategoryText: "Accessories",
    vendorCategory: "JEWELLERY_AND_ACCESSORIES",
    vendorSubcategory: "ACCESSORIES",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Invitations",
    vendorCategory: "INVITES_AND_GIFTS",
    vendorSubcategory: "INVITATIONS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "E-Invites",
    vendorCategory: "INVITES_AND_GIFTS",
    vendorSubcategory: "E_INVITES",
    isServiceTypeIncluded: true,
  },
  {
    subcategoryText: "Trousseau Packers",
    vendorCategory: "INVITES_AND_GIFTS",
    vendorSubcategory: "TROUSSEAU_PACKERS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Invitation Gifts",
    vendorCategory: "INVITES_AND_GIFTS",
    vendorSubcategory: "INVITATION_GIFTS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Furniture",
    vendorCategory: "FURNITURE",
    vendorSubcategory: "FURNITURE",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Modular kitchen",
    vendorCategory: "FURNITURE",
    vendorSubcategory: "MODULAR_KITCHEN",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Interiors",
    vendorCategory: "FURNITURE",
    vendorSubcategory: "INTERIORS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "DJs",
    vendorCategory: "MUSIC_AND_DANCE",
    vendorSubcategory: "DJS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Band",
    vendorCategory: "MUSIC_AND_DANCE",
    vendorSubcategory: "BAND",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Sangeet Choreographer",
    vendorCategory: "MUSIC_AND_DANCE",
    vendorSubcategory: "SANGEET_CHOREOGRAPHER",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Wedding Entertainment",
    vendorCategory: "MUSIC_AND_DANCE",
    vendorSubcategory: "WEDDING_ENTERTAINMENT",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Dhol & damau",
    vendorCategory: "MUSIC_AND_DANCE",
    vendorSubcategory: "DHOL_AND_DAMAU",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Maangal geet",
    vendorCategory: "MUSIC_AND_DANCE",
    vendorSubcategory: "MAANGAL_GEET",
    isServiceTypeIncluded: false,
  },

  {
    subcategoryText: "Catering Services",
    vendorCategory: "FOOD",
    vendorSubcategory: "CATERING_SERVICES",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Cake",
    vendorCategory: "FOOD",
    vendorSubcategory: "CAKE",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Chaat And Food Stalls",
    vendorCategory: "FOOD",
    vendorSubcategory: "CHAAT_AND_FOOD_STALLS",
    isServiceTypeIncluded: true,
  },
  {
    subcategoryText: "Bartenders",
    vendorCategory: "FOOD",
    vendorSubcategory: "BARTENDERS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Home Catering",
    vendorCategory: "FOOD",
    vendorSubcategory: "HOME_CATERING",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Mithai",
    vendorCategory: "FOOD",
    vendorSubcategory: "MITHAI",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Dermatology clinics",
    vendorCategory: "CLINICS",
    vendorSubcategory: "DERMATOLOGY_CLINICS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Ayurvedic Clinics",
    vendorCategory: "CLINICS",
    vendorSubcategory: "AYURVEDIC_CLINICS",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Boutiques",
    vendorCategory: "BOUTIQUES",
    vendorSubcategory: "BOUTIQUES",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Honeymoon Packages",
    vendorCategory: "HONEYMOON",
    vendorSubcategory: "HONEYMOON_PACKAGES",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Travel Agents",
    vendorCategory: "HONEYMOON",
    vendorSubcategory: "TRAVEL_AGENTS",
    isServiceTypeIncluded: false,
  }, {
    subcategoryText: "Astrology",
    vendorCategory: "ASTROLOGY",
    vendorSubcategory: "ASTROLOGY",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Gym",
    vendorCategory: "FITNESS_CENTER",
    vendorSubcategory: "GYM",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Yoga Center",
    vendorCategory: "FITNESS_CENTER",
    vendorSubcategory: "YOGA_CENTER",
    isServiceTypeIncluded: false,
  },
  {
    subcategoryText: "Zumba Classes",
    vendorCategory: "FITNESS_CENTER",
    vendorSubcategory: "ZUMBA_CLASSES",
    isServiceTypeIncluded: false,
  },
  
];
